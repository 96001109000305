import React from 'react'
import './styles.scss'
import AppheaderRightContent from 'components/AppheaderRightContent'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';


type headerLayoutProps = {
    title: string
    status?: string
    to?: string
}


const AppHeaderLayout: React.FC<headerLayoutProps> = ({ title, status, to }) => {
    const history = useHistory()
    // const [open, setOpen] = useState<boolean>(false)

    // const selectSellerHandler = () => {
    //     setOpen(false);
    //     history.push("/admin/seller");

    // }

    return (
        <div className='app-headers'>

            <ArrowBackIcon className="header-back-icon" onClick={() => history.push({ pathname: to })} />
            <div className="title-status-wrapper">
                <p className="header-title">{title}</p>
                <p className="header-status">{status}</p>

            </div>
                <div className="header-logout-wrapper">
                    <AppheaderRightContent />
                </div>
        </div>
    )

}

export default AppHeaderLayout

