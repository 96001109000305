import React, { useEffect, useMemo, useState } from 'react'
import StorefrontIcon from '@mui/icons-material/Storefront'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import SellIcon from '@mui/icons-material/Sell'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import DangerousIcon from '@mui/icons-material/Dangerous'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import GradingIcon from '@mui/icons-material/Grading'
import moment from 'moment'
import './styles.scss'
import AppHeaderLayout from 'components/AppHeaderLayout'
import DashboardCard from 'components/DashboardCard'
import { AccountsService } from 'services/accounts.service'
import { showNotification, STATUS } from 'common/constant'
import {
  IErrorResponse,
  IErrorResponses,
  ISuccessResponse,
  ISuccessResponses
} from 'services/interfaces/common.interface'
import { OnPurchaseOrderService } from 'services/purchaseOrder.service'

const DashboardPage: React.FC = () => {
  const [orders, setOrders] = useState<any>([])
  const [oms_status, setOmsStatus] = useState<any>([])
  const [pending, setPending] = useState<number>(0)
  const [accepted, setAccepted] = useState<number>(0)
  const [inward, setInward] = useState<number>(0)
  const [rejected, setRejected] = useState<number>(0)
  const [SupplierCode, setSupplierCode] = useState<string>('')

  const accountsService = new AccountsService()
  const getPurchaseOrderList: OnPurchaseOrderService = new OnPurchaseOrderService()

  const [dashboardData, setDashboardData] = useState<any>({})

  const getDashboardData = async (): Promise<void> => {
    try {
      const dashboardReponse: ISuccessResponse | IErrorResponse = await accountsService.getDashboardData()

      if (dashboardReponse.status === STATUS.SUCCESS) {
        const { data } = dashboardReponse as ISuccessResponse
        setDashboardData(data)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to fetch dashboard informations')
    }
  }

  const getPurchaseOrders = async () => {
    try {
      const companycode = 'PMPL1'
      const branchcode = '0'
      // const fromdate = '20200101'
      // const enddate = '20210810'
      const fromdate = moment().subtract(120, 'd').format('YYYYMMDD')
      const enddate = moment().format('YYYYMMDD')
      const status = 'SALL'

      const Purchaseorderlistdetails: ISuccessResponse | IErrorResponse =
        await getPurchaseOrderList.getPurchaseOrderListDatas(
          companycode,
          branchcode,
          SupplierCode,
          fromdate,
          enddate,
          status
        )
      const getdata = Purchaseorderlistdetails as ISuccessResponse
      let purchase_list = getdata.data

      let accepted = 0,
        pending = 0,
        inward = 0,
        rejected = 0

      purchase_list = purchase_list.map((item) => {
        if (item.getPurchaseOrdersAdditionalStatus === 'ACTI' || item.AdditionalStatus === '') pending++
        else if (item.AdditionalStatus === 'PROC') accepted++
        else if (item.AdditionalStatus === 'SHIP') inward++
        else if (item.AdditionalStatus === 'CANC') rejected++
        return item
      })

      setOrders(purchase_list)
      setPending(pending)
      setAccepted(accepted)
      setInward(inward)
      setRejected(rejected)

      purchase_list = purchase_list.slice(0, 10)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Purchase Order list')
    }
  }

  const getReviewData = async () => {
    try {
      const seller_code = localStorage.getItem('seller_code')
      const getPurchaseOrderSupplierCode: ISuccessResponses | IErrorResponses =
        await getPurchaseOrderList.getPurchaseOrderSuppliercode(seller_code)

      if (getPurchaseOrderSupplierCode.StatusMessage === STATUS.SUCCESS) {
        const SupplierCode = getPurchaseOrderSupplierCode as ISuccessResponses
        setSupplierCode(SupplierCode.Data.accountsdetails[0].external.supplier_id)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Seller Code')
    }
  }

  const getOmsData = async () => {
    try {
      const seller_code = localStorage.getItem('seller_code')
      const oms_data: any = await getPurchaseOrderList.getOmsStatus(seller_code)

      if (oms_data.data.success) {
        const status = {}; let total=0;
        oms_data.data.result.map((item) => {
          status[item._id] = item.count
          total+=item.count;
          return item
        })
        status['total'] = total
        setOmsStatus(status)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Seller Code')
    }
  }

  useEffect(() => {
    getReviewData()
    getDashboardData()
    getOmsData()
  }, [])

  useMemo(() => {
    if (SupplierCode !== '') getPurchaseOrders()
  }, [SupplierCode])

  return (
    <div>
      <AppHeaderLayout title="Dashboard Overview" />
      <p className="dashboard-sub-heading">Listings</p>
      <div className="cards-wrapper">
        <DashboardCard
          link="/sellerLayout/InventoryManagement/ActiveInventory"
          title="Active"
          count={dashboardData?.active_product}
          color="brown"
          Icon={<ShoppingCartIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          link="/sellerLayout/ListedProducts/Pending"
          title="Ready for Activation"
          count={dashboardData?.pending_product}
          color="blue"
          Icon={<SellIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          link="/sellerLayout/InventoryManagement/InActiveInventory"
          title="Inactive"
          count={dashboardData?.inactive_product}
          color="brown"
          Icon={<ThumbDownOffAltIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          link="/sellerLayout/InventoryManagement/ActiveInventory"
          title="Out of Stock"
          count={dashboardData?.out_of_stock}
          color="red"
          Icon={<ErrorOutlineIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          link="/sellerLayout/InventoryManagement/ActiveInventory"
          title="Low Stock"
          count={dashboardData?.low_stock}
          color="brown"
          Icon={<WarningAmberIcon className="dashboard-card-icon" />}
        />
      </div>

      <p className="dashboard-sub-heading">Purchase Orders</p>
      <div className="cards-wrapper">
        <DashboardCard
          link="/sellerLayout/purchaseOrder/Pending"
          title="Pending PO"
          count={pending?.toString()}
          color="brown"
          Icon={<AssignmentIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          link="/sellerLayout/purchaseOrder/Accepted"
          title="Accepted PO"
          count={accepted?.toString()}
          color="blue"
          Icon={<AssignmentTurnedInIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          link="/sellerLayout/purchaseOrder/Invoiced"
          title="Pending for Inward"
          count="0"
          color="green"
          Icon={<AssignmentIndIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          link="/sellerLayout/purchaseOrder/Rejected"
          title="Rejected"
          count={rejected?.toString()}
          color="red"
          Icon={<DangerousIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          link="/sellerLayout/purchaseOrder/AllOrders"
          title="Total"
          count={orders?.length}
          color="green"
          Icon={<AddBusinessIcon className="dashboard-card-icon" />}
        />
      </div>

      <p className="dashboard-sub-heading">Fulfillment Order </p>

      <div className="cards-wrapper">
        <DashboardCard
          title="New Order"
          count={oms_status['Accepted'] || 0}
          color="brown"
          Icon={<PhoneIphoneIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          title="Packed Order"
          count={oms_status['Packed'] || 0}
          color="blue"
          Icon={<StorefrontIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          title="Shipped"
          count={oms_status['Shipped'] || 0}
          color="brown"
          Icon={<LocalShippingIcon className="dashboard-card-icon" />}
        />
        <DashboardCard
          title="Delivered"
          count={oms_status['Delivered'] || 0}
          color="green"
          Icon={<DoneAllIcon className="dashboard-card-icon" />}
        />
        <DashboardCard title="Total" count={oms_status['total']|| 0} color="blue" Icon={<GradingIcon className="dashboard-card-icon" />} />
      </div>
    </div>
  )
}

export default DashboardPage
