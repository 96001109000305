import React, { useEffect, useState } from 'react'
import VerifiedIcon from '@mui/icons-material/Verified'
import CancelIcon from '@mui/icons-material/Cancel'
import Dialog from '@mui/material/Dialog'
import ActionButton from 'components/ActionButton'
import { AccountsService } from 'services/accounts.service'
import { showNotification, STATUS } from 'common/constant'
import useAdminEvaluation from 'hooks/useAdminEvaluation'

import './style.scss'


const BankVerificationStatus: React.FC = () => {
  const { isBusinessUser } = useAdminEvaluation();
  const [open, setOpen] = useState<boolean>(false)
  const accountsService = new AccountsService();
  const [verficationStatus, setVerificationStatus]= useState<boolean>(false);

  /* getting current bank account verification status */
  const getVerificationStatus = async (): Promise<void> => {
    try {
      const sellerCode: string = localStorage.getItem('seller_code') as string;
      const bankVerificationStatus = await accountsService.getBankVerificationStatus(sellerCode);

      if(bankVerificationStatus.status === STATUS.SUCCESS) {
        setVerificationStatus(bankVerificationStatus.data.bank_verified)
      } else {
        showNotification(STATUS.FAILURE, 'Unable to get the bank verification Details');
      }

    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get the bank verification Details');
    }
  }

  /* Updating current bank account verificatin status */
  const changeAccountVerificationStatus = async (status: boolean): Promise<void> => {
    try {
      const sellerCode: string = localStorage.getItem('seller_code') as string;
      const verificationStatusUpdateResult = await accountsService.setBankVerificationStatus(sellerCode, status);
  
      if(verificationStatusUpdateResult.status === STATUS.SUCCESS) {
        getVerificationStatus()
        showNotification(STATUS.SUCCESS, 'Bank Verification Status updated successfully');
      } else {
        showNotification(STATUS.FAILURE, 'Unable to update bank Details');  
      } 
      setOpen(false); 

    } catch (error) {
      setOpen(false);
      showNotification(STATUS.FAILURE, 'Unable to update bank Details');
    }

  }

  const statusChangeVerificationHandler = (newStatus: boolean) => {
    
    if(newStatus !== verficationStatus) {
      changeAccountVerificationStatus(newStatus);
    }
    setOpen(false); 
  };

  useEffect(() => {
    getVerificationStatus();
  }, []);

  return (
    <div className="verification-wrapper">

      <div className="bank-verification-status">
        {verficationStatus ? (
          <>
            <VerifiedIcon className="verified" />
            <p className="verification-text verified">Bank Account Verified</p>
          </>
        ) : (
          <>
            <CancelIcon className="not-verified" />
            <p className="verification-text not-verified">Bank Account Not Verified</p>
          </>
        )}
      </div>
      {isBusinessUser &&
        <div className="verification-control-container bank-verification-status">
          <ActionButton label="Modify Verification status" type="button" onClick={() => setOpen(true)} />
          <Dialog open={open}>
            <div className="bank-account-verification-Dialog">
              <CancelIcon className='close-icon' onClick={() => setOpen(false)}/>
              <h3 className="dialog-heading">Bank Account Verification</h3>
              <p className="info-text">
               Important Note: Kindly verify the bank account only if you able to make successful transaction of &#8377; 1.00 to &#8377; 1.50.
              </p>
              <div className="dual-option-buttons">
                <ActionButton label="Reject" type="button" varient="outlined" onClick={() => statusChangeVerificationHandler(false)} />
                <ActionButton label="Verify" type="button" onClick={() => statusChangeVerificationHandler(true)} />
              </div>
            </div>
          </Dialog>
        </div>
      }
    </div>
  )
}

export default BankVerificationStatus
