import React, { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'
import { KeycloakInstance } from 'keycloak-js'


const LoginPage: React.FC = () => {
  const { keycloak } = useKeycloak<KeycloakInstance>()
  const history = useHistory()

  useEffect(() => {
     if(keycloak?.authenticated)
      history.push('/ssoLogin')
     
  }, [keycloak?.authenticated])

  return <div className="login-page-wrapper" />
}

export default LoginPage
