import React from 'react'
import './styles.scss'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

type InputLabelInputProps = {
    label: string
    content?: string
    number?: string
    textarea?: boolean
    value?: string
    register?: any
    name?: string
    error?: boolean
    errorText?: string
    hintText?: string
    hint?: boolean
    type?: string
    onClick?: () => void;
    onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void;
    tooltip?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const InputLabelinputWarehouse: React.FC<InputLabelInputProps> = ({ label, textarea, register, name, errorText, error, hintText, value, hint, onChange, onClick, type, tooltip, ...props }) => {
    return (
        <div className="input-label-input-warehouse">
            <p className="text-label">{label}
                {tooltip ?
                    <Tooltip title={tooltip} placement="top">
                        <ErrorIcon  className="tooltip-icon"/>
                    </Tooltip>
                    : <> </>
                }
            </p>


            {textarea ?
                <div className="input-with-verified-icon">
                    <textarea ref={register} name={name} className="text-area-content" value={value} />

                    <EditOutlinedIcon className="verified-icon" onClick={onClick} />

                </div>

                :
                <>

                    <div className={props.disabled ? 'input-disabled' : 'input-with-verified-icon'}>
                        <input ref={register} name={name} type={type} className={props.disabled ?'disabled-icon':"text-label-content"} onChange={onChange}  {...props} />
                    </div>

                    {hint && !error ? <p className="hint-text"> {hintText}</p> : ''}
                    {error ? <p className="error-text">* {errorText}</p> : ''}
                </>


            }

        </div>
    )
}

export default InputLabelinputWarehouse
