import React from 'react'



type TInputFieldProps = {
    text :string,
    error?: boolean,
    errorText? : string,
    register?: any,
    name?: string
    hint?: string
} & React.InputHTMLAttributes<HTMLInputElement>




const InputWithLabelsNotRequired: React.FC<TInputFieldProps> = ({register, name, text, hint, error, errorText, ...props}) => {

    return (
        <div className="input-with-labels">
            <label className="txt-label">{text} :</label>
            <div className="input-and-error">
            <input ref={register} autoComplete="false" className={error ? 'input-field-box-error' : 'input-field-box'} name={name} placeholder={text} {...props}/>
            { error ? <p className="error-text">* {errorText}</p> : '' }
            { hint ? <p className="hint-text">{hint}</p> : '' }
            </div>
            
        </div>
    )
}

export default InputWithLabelsNotRequired
