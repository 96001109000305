import React from 'react';

import './styles.scss'
import buffer from 'assets/buffer.gif';
import poorvikaLogo from 'assets/login.jpg'

type ISpinnerProps =  {
    loading: boolean
}

/* Created to implement the loading effect on the API calls */
const Spinner: React.FC<ISpinnerProps> = ({loading}) => {
    return (
        <div className={loading ? 'spinner-dialog' : 'hide-element'}>
            <div className="spinner-card">
            <img src={poorvikaLogo} alt="loader" className="application-brand"/>
            <p className="loading-text">Loading ...</p>
            <img src={buffer} alt="loader" className="spinner-animation"/>
            </div>
        </div>
    )
}

export default Spinner
