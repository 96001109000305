import React from 'react';

import SignUpForm from 'containers/form/SignupForm/SignUpForm';
import './SignUp.scss';

const SignUp: React.FC = () => {


    return (
        <div className="background">
      <div className="signupForm">
        <h1>Create Your Account</h1>  
        <SignUpForm/>        
      </div>
    </div>
    )
}

export default SignUp