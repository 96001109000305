import { LIST_OF_ROLES } from "./constant";

/* List of menu & side menu permissions */

export const LEGAL_DETAILS_SUBMENU: string [] = [LIST_OF_ROLES.LEAGAL_VIEW, LIST_OF_ROLES.LEAGAL_UPDATE];
export const BANK_DETAILS_SUBMENU: string [] = [LIST_OF_ROLES.BANK_VIEW, LIST_OF_ROLES.BANK_UPDATE];
export const TAX_AND_FINANCIALS_DETAILS_SUBMENU: string [] = [LIST_OF_ROLES.TAX_FINANCE_VIEW, LIST_OF_ROLES.TAX_FINANCE_UPDATE];
export const ADD_CONTACT = [LIST_OF_ROLES.CONTACT_CREATE];
export const MODIFY_CONTACT = [LIST_OF_ROLES.CONTACT_UPDATE];
export const CONTACT_SUBMENU = [LIST_OF_ROLES.CONTACT_VIEW]
