export const LOGIN = "LOGIN";
export const USER = "USER";
export const USERUPDATE = "USERUPDATE";

export type User = {
    type: typeof LOGIN,
    payload: {
        firstName: string,
        lastName: string,
        fullName: string,
        dateOfBirth: Date,
        email: string,
        password: string,
        sellerOnBoardingStep: string,
        isActive: boolean,
        Permission:any
    }
}

export type UserDetails = {
    type: typeof USER,
    payload: {
        firstName: string,
        lastName: string,
        fullName: string,
        dateOfBirth: Date,
        email: string,
        password: string
    }
}


export type UserUpdate = {
    type: typeof USERUPDATE,
    payload: {
        firstName: string,
        lastName: string,
        fullName: string,
        dateOfBirth: Date,
        email: string,
        password: string
    }
}

export type UserDispatchTypes = User | UserDetails | UserUpdate