import React from 'react'
import { NavLink } from 'react-router-dom'

type CardContentProps = {
    onSelected: (locationDetails: any) => void;
    label: string
    to: string
    isActive?: any
}

const NavlinkCardcontent: React.FC<CardContentProps> = ({ to, label, onSelected }) => {
    return (
        <div>
            <NavLink to={to} onClick={() => onSelected(label)} className="select-content" >
                <div className="select-indicator" />
                <p>{label}</p>
            </NavLink>

        </div>
    )


}


export default NavlinkCardcontent