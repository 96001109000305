import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './styles.scss'
import { Accordion, AccordionDetails, AccordionSummary, Step, StepButton, Stepper, Typography } from '@material-ui/core'
import PostAddTwoToneIcon from '@material-ui/icons/PostAddTwoTone';
import moment from 'moment';
import { useSelector } from 'react-redux';

import ActionButton from 'components/ActionButton'
// import PersonalInformationReviewForm from 'pages/RootAdmin/onBoardingDetails/PersonalInformationReviewForm/Index'
import LegalInfomationReviweForm from 'pages/RootAdmin/onBoardingDetails/LegalInformationReviewForm'
import BillingAndTaxingReviewForm from 'pages/RootAdmin/onBoardingDetails/BillingAndTaxingReviewForm/intex'
import PrimaryContactReviewForm from 'pages/RootAdmin/onBoardingDetails/PrimaryContactReviewForm'
import UploadDocumentsReviewForm from 'pages/RootAdmin/onBoardingDetails/UploadDocumentsReviewForm'
import DetailsConfirmationPage from 'pages/RootAdmin/onBoardingDetails/DetailsConfirmationPage'
// import PersonPinTwoToneIcon from '@material-ui/icons/PersonPinTwoTone';
// import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
// import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
// import ContactPhoneTwoToneIcon from '@material-ui/icons/ContactPhoneTwoTone';

import { OnboardingService } from 'services/onBoarding.service';
import { showNotification, STATUS } from 'common/constant';
import { IHistoryLog } from 'services/interfaces/onBoarding.interface';
import { RootStore } from 'redux/store';
import { ISuccessResponse, IErrorResponse } from 'services/interfaces/common.interface';



const AccountVerificationWizard: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const onboardingService = new OnboardingService();
  const [historylog, sethistorylog] = useState<any>(false)
  const selectedUserEmail = useSelector((state: RootStore) => state.onBoardingData.userEmail?.email);
  // const [legallog, setlegallog] = useState<any>(false)
  // const [billinglog, setbillinglog] = useState<any>(false)
  // const [contactlog, setcontactlog] = useState<any>(false)
  // const [documentlog, setdocumentlog] = useState<any>(false)


  const history = useHistory()
  const steps = [
    // 'Personal Information',
    'Legal Informations',
    'Billing & Taxing Informations',
    'Primary Contact',
    'Taxation & Documents',
    'Review & Activate'
  ]

  const handleStep = (index: number) => {
    setActiveStep(index)
  }

  const getHistorylog = async () => {
    
    try {
      const historyInformations: ISuccessResponse | IErrorResponse = await onboardingService.getHistorylog(selectedUserEmail);
      
      sethistorylog(historyInformations.data)

   



    } catch (error) {
      showNotification(STATUS.FAILURE, 'Failed to get Rejection notes')
    }
  }
  
  useEffect(() => {
    
    getHistorylog();

  }, [])



  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      // case 0:
      //   return <PersonalInformationReviewForm onSelected={()=>getHistorylog() } />
      case 0:
        return <LegalInfomationReviweForm onSelected={()=>getHistorylog() } />
      case 1:
        return <BillingAndTaxingReviewForm onSelected={()=>getHistorylog() } />
      case 2:
        return <PrimaryContactReviewForm onSelected={()=>getHistorylog() } />
      case 3:
        return <UploadDocumentsReviewForm onSelected={()=>getHistorylog() } />
      case 4:
        return <DetailsConfirmationPage />

      default:
        return 'Unknown stepIndex'
    }
  }


  return (
    <div className="accounts-verification-wizard">
      <div>
        <ActionButton varient="outlined" label="< Back" onClick={() => history.goBack()} />
      </div>
      <div className="stepper-main-accordian">
        <div className="stepper-and-form" >
          <Stepper activeStep={activeStep} nonLinear alternativeLabel>
            {steps.map((label: string, index: number) => (
              <Step key={label}>
                <StepButton onClick={() => handleStep(index)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <div >
            <div>{getStepContent(activeStep)}</div>
          </div>

        </div>


        <div className="accordian-wrapper"  >
          <Accordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography >History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">



                {historylog ? (
                  <div>
                    {historylog.map((logNotes: IHistoryLog) => {
                      return (
                        <div className="rejection-notes" key={logNotes.step}>
                          <div>
                            <PostAddTwoToneIcon className="document-icon" />
                          </div>
                          <div className="details-card-wrapper">
                            <div className="accordian-details-heading">
                            <p className="rejection-step" >
                              {logNotes.step}
                              </p>
                              <p className="log-date">{moment(logNotes.created_at).format("DD-MM-YYYY HH:mmA")}</p>

                            
                            </div>
                             <p className="log-notes">{logNotes.notes}
                            </p>
                          </div>
                        </div>

                      )

                    })}
                  </div>
                )
                  : (
                    <p className="log-notes">No logs</p>
                  )}



              </div>

            </AccordionDetails>
          </Accordion>
        </div>
      </div>



    </div>
  )
}

export default AccountVerificationWizard
