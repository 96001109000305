import React, { useEffect, useState } from 'react'
import './styles.scss'
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import FileDragandDrop from 'components/FileDragAndDrop';


type InputLabelInputProps = {
    label: string
    number?: string
    textarea?: boolean
    file?: string

}

const InputFieldWithButton: React.FC<InputLabelInputProps> = ({ label, file }) => {

    const [fileName, setFileName] = useState<string>('');
    const [fileAvailability, setFileAvailability] = useState<boolean>(false);
    const [remoteFile, setRemoteFile] = useState<string>("")

    const fileNameSplitter = (remoteUrl: string) => {
        const splittedFileName: string = remoteUrl ? remoteUrl.split("/")[remoteUrl.split("/").length - 1] : "Document Not Exists";
        setFileName(splittedFileName);
        if (remoteUrl) { setRemoteFile(remoteUrl); setFileAvailability(true); }
    }


    useEffect(() => {
        fileNameSplitter(file as string);
    }, [file]);



    return (
        <div className="input-label-input-field">
            <p className="text-label">{label}</p>
            <div className="icon-input-file-wrapper">
                <SwapVerticalCircleOutlinedIcon className="drag-drop-icon" />
                <p className="Drap-drop-content">{fileName}</p>
                {fileAvailability ?
                        <input
                            type="button"
                            className="view-document-btn-wrapper"
                            onClick={() => window.open(remoteFile || file, '_blank')}
                            value="View"
                        />
                    

                    : <FileDragandDrop
                        text="Browse"
                        fileSelected={() => ''}
                        fileUploaded={fileNameSplitter}
                    />
                }

            </div>


        </div>
    )
}

export default InputFieldWithButton