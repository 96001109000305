import React, { useEffect, useState } from 'react'

import './styles.scss'
// import LegalInformation from 'assets/legal informations.png'
import { useForm } from 'react-hook-form'

import ActionButton from 'components/ActionButton'
// import SelectDropDownWithLabel from 'components/SelectDropDown/input'
import InputWithLabels from 'components/InputWithLabels'
import { OnboardingService } from 'services/onBoarding.service'
import { showNotification, STATUS } from 'common/constant'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import WarningTextLayer from 'components/WarningTextLayer'
import InputWithLabelsNotRequired from 'components/InputLabelsNotrequired'
import InputWithDropDown from 'components/InputwithDropdown'
import { Istate } from 'services/interfaces/state.interface'
import slugify from 'common/slugify'
import { LoginService } from 'services/login.service'
import CodeInput from 'components/CodeInput'
import InputWithEmailVerification from 'components/InputWithEmailVerification'
import MobileOTPVerification from 'components/MobileOTPVerification'
import Spinner from 'components/Spinner'

type TLegalInformationProps = {
  nextStep: () => void
  movePrevious: () => void

}

type ILegalForm = {
  accountName: string,
  email: string,
  gstNumber: string,
  mobile: string,
  type: string
  name: string
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  pin: string
  gstStatus: string

}

const legalFormValidations = {
  accountName: {
    required: { value: true, message: "Account name can't be Empty" }
  },
  code: {
    required: { value: true, message: " Account code can't be Empty" }
  },
  mobile: {
    required: { value: true, message: "Phone Number can't be Empty" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" }
  },
  email: {
    required: { value: true, message: "Email field can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: ' Email format is invalid' }
  },
  gstNumber: {
    required: { value: true, message: 'GST Number is required' },
    minLength: { value: 15, message: 'Should be 15 characters' }
  },
  name: {
    required: { value: true, message: "Legal Name can't be Empty" }
  },
  type: {
    required: { value: true, message: '' }
  },
  address1: { required: { value: true, message: "Address Field can't be empty" } },
  address2: { required: { value: true, message: "Address Field can't be empty" } },

  city: { required: { value: true, message: "city Field can't be empty" } },
  state: { required: { value: true, message: "state Field can't be empty" } },
  pin: {
    required: { value: true, message: "pincode  Field can't be empty" },
    minLength: { value: 6, message: "pincode must be 6 characters long" }
  }
}

const LegalInformationForm: React.FC<TLegalInformationProps> = ({ nextStep }) => {
  const { register, handleSubmit, errors, setValue, setError } = useForm<ILegalForm>({ mode: 'all' })

  /* State value - variables  */
  const [code, setCode] = useState<string>('')
  const [rejectionNotes, setRejectionNotes] = useState<string>("")
  const [mobileVerified, setMobileVerified] = useState(false);
  const [buttonText, setButtonText] = useState('GET OTP');
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpVisibility, setOTPVisibility] = useState(false);
  const [otpNumber, setOtpNumber] = useState('')
  const [gstStatus, setgstStatus] = useState<string>('');
  const [countDownStarted, setCountDownStarted] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  let countDownTime = 30;

  /* Services  */
  const loginService = new LoginService();
  const onBoardingService = new OnboardingService();


  const onSubmit = async (formData: ILegalForm) => {
    try {

      if (!mobileVerified) {
        const otpVerificationResponse: ISuccessResponse | IErrorResponse = await onBoardingService.verifyOTP(mobileNumber, otpNumber);

        if (otpVerificationResponse.status === STATUS.FAILURE) {
          showNotification(STATUS.FAILURE, "Unable to verify OTP");

          return
        }
      }
      formData.gstStatus = gstStatus
      const saveLegalDataResponse: ISuccessResponse | IErrorResponse = await onBoardingService.saveOnBoardingData({ ...formData, code, step: 'LEGAL_INFORMATION' })

      if (saveLegalDataResponse.status === STATUS.SUCCESS) {
        nextStep()
      } else {
        showNotification(STATUS.FAILURE, 'Unable to save legal Informations')
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to save legal Informations')
    }
  }

  const gstInVerify = async (gstNumber: string) => {

    const filteredGSTNumber = gstNumber?.replace(/[^a-z0-9]/gi,'').substring(0,15);
    setValue("gstNumber", filteredGSTNumber)
    
    if (gstNumber.length === 15) {
      const regexPattern  = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

      if(!regexPattern.test(filteredGSTNumber)) {
        setError('gstNumber', {
          type: "manual", 
          message: "GST Number format is invalid"
        })
        showNotification('failure', "GST Number Format is invalid")

        return 
  
      }

      try {
        setLoading(true);
        const { data }: ISuccessResponse | IErrorResponse = await onBoardingService.getGstIndetails(gstNumber);
        if (!data.error) {
          if(data.data.sts !== "Cancelled" && data.data.sts !== ''){
          const accountInformations = data.data;
          const addressData = accountInformations.pradr.addr;
          setValue("name", accountInformations.lgnm);
          setValue("address1", addressData.bno + ", " + addressData.flno);
          setValue("address2", addressData.st);
          setValue("city", addressData.dst);
          setValue("state", addressData.stcd);
          setValue("pin", addressData.pncd);
          setgstStatus(accountInformations.sts)
          }
          else if(data.data.sts === "Cancelled"){
            setValue("name", "");
            setValue("address1", "");
            setValue("address2", "");
            setValue("city", "");
            setValue("state", "");
            setValue("pin", "");
            setgstStatus("")
            showNotification(STATUS.FAILURE, 'GST Status is Cancelled')
            setError('gstNumber', {
              type: "manual", 
              message: "GST Status is Cancelled"
            })
          }
          else{
            setValue("name", "");
            setValue("address1", "");
            setValue("address2", "");
            setValue("city", "");
            setValue("state", "");
            setValue("pin", "");
            setgstStatus("")
            showNotification(STATUS.FAILURE, 'Please Enter valid GST Number')
            setError('gstNumber', {
              type: "manual", 
              message: "Please Enter valid GST Number"
            })
          }
        }
        setLoading(false);
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get GST Informations')
        setLoading(false);
      }
    } else {
      setValue("name", "");
      setValue("address1", "");
      setValue("address2", "");
      setValue("pin", "");
      // showNotification(STATUS.FAILURE, 'Please Enter valid GST Number')
    }
  }

  useEffect(() => {
    const getPersonalData = async () => {
      try {
        const personalData: ISuccessResponse | IErrorResponse = await onBoardingService.getOnboardingData(1)

        if (personalData.status === STATUS.SUCCESS) {
          const successData = personalData as ISuccessResponse;
          setCode(successData.data.account_code);

          setValue("accountName", successData.data.account_name);
          setValue("email", successData.data.email);
          setValue("gstNumber", successData.data.gstNumber);
          setValue("name", successData.data.name);
          setValue("type", successData.data.type);
          setValue("address1", successData.data.address1);
          setValue("address2", successData.data.address2);
          setValue("address3", successData.data.address3);
          setValue("city", successData.data.city);
          setValue("pin", successData.data.pincode);
          setValue("state", successData.data.state);

          setRejectionNotes(successData.data.rejection_notes)

          if (successData.data.mobile_verified) {
            setMobileVerified(successData.data.mobile_verified);
            setMobileNumber(successData.data.mobile)
            setButtonText('Verified')
          }

        } else {
          showNotification(STATUS.FAILURE, 'Unable to get legal informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get legal informations')
      }
    }

    getPersonalData()
  }, [])

  const autoCodeValidationHandler = async (code: string) => {
    if (!code) {
      setCode('')

      return
    }

    try {
      const checkAccount: ISuccessResponse | IErrorResponse = await loginService.checkCodeAuthMode(code)

      if (checkAccount.status === STATUS.SUCCESS) {
        setCode(slugify(code))
      } else {
        autoCodeValidationHandler(code + ' ' + Math.random().toString(36).substring(2, 7))
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, (error as Error)?.message);
    }
  }

  const OTPVerificationHandler = async () => {

    if(mobileNumber.length < 10) {
      showNotification('failure', "Mobile Number length is invalid ")

      return
    }

    try {
      if (countDownStarted) return;
      const initiateOTP: ISuccessResponse | IErrorResponse = await onBoardingService.getOTP(mobileNumber);

      if (initiateOTP.status === STATUS.SUCCESS) {
        showNotification(STATUS.SUCCESS, 'OTP Successfully sent')
        setOTPVisibility(true)
        setCountDownStarted(true);
        countDownTime = 30;
        countDownTimer();
      } else {
        showNotification(STATUS.FAILURE, initiateOTP.message)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to send OTP')
    }
  }



  const countDownTimer = async () => {
    countDownTime--;

    if (countDownTime > 0) {
      setTimeout(() => {
        if (!mobileVerified) {
          setButtonText((countDownTime < 10) ? `0:0${countDownTime}` : `0:${countDownTime}`);
          countDownTimer();
        } else {
          setCountDownStarted(false)
          setButtonText('Verified')
        }
      }, 1000)
    } else {
      setCountDownStarted(false);

      if (mobileVerified) {
        setButtonText('Verified')
      } else {
        setButtonText(`RESEND`);
      }
    }

  }

  const verifyOTPHandler = async () => {
    const otpVerificationResponse: ISuccessResponse | IErrorResponse = await onBoardingService.verifyOTP(mobileNumber, otpNumber);

    if (otpVerificationResponse.status === STATUS.SUCCESS) {
      showNotification(STATUS.SUCCESS, otpVerificationResponse.message);
      countDownTime = -1
  
      setMobileVerified(true)
     
      setOTPVisibility(false)

    } else {
      showNotification(STATUS.FAILURE, "Unable to verify OTP");

    }

  }

  const mobileNumberInputHandler = async(value: string): Promise<void> => {

    /* filter only number from a string and limit the length to 10 */
    const filteredMobileNumber = value.replace(/\D/g,'').substring(0,10);
    setValue("mobile",filteredMobileNumber );
    setMobileNumber(filteredMobileNumber);
    
  }



  return (
    <div className="legal-information-form-page">
      <WarningTextLayer info={rejectionNotes} />
      <Spinner loading={loading}/>
      <form action="" className="legal-information-form" onSubmit={handleSubmit(onSubmit)}>
        <p className="form-subtitle">Account Details</p>
        <InputWithLabels
          error={errors.accountName?.type !== undefined}
          errorText={errors.accountName ? errors.accountName.message : ''}
          name="accountName"
          register={register(legalFormValidations.accountName)}
          text="Company Name"
          onChange={(event) => autoCodeValidationHandler(slugify(event.target.value))}
        />

        <CodeInput
          codeSaved={(newCode) => autoCodeValidationHandler(newCode)}
          isModeEdit={false}
          name="code"
          register={register}
          text="Account Code *"
          value={code}
        />

        <InputWithEmailVerification name="email" register={register} text="Email" isEmailVerified />

        {mobileVerified ? <MobileOTPVerification
          buttonText='Verified'
          disabled={mobileVerified}
          value={mobileNumber}
          error={errors.mobile?.type !== undefined}
          errorText={errors.mobile ? errors.mobile.message : ''}
          getOTPClicked={()=> {OTPVerificationHandler()}}
          name="mobile"
          register={register(legalFormValidations.mobile)}
          text="Mobile Number"
        /> : <MobileOTPVerification
          buttonText={buttonText}
          error={errors.mobile?.type !== undefined}
          errorText={errors.mobile ? errors.mobile.message : ''}
          onChange={(event) => mobileNumberInputHandler(event.target.value)}
          getOTPClicked={()=> {OTPVerificationHandler()}}
          name="mobile"
          register={register(legalFormValidations.mobile)}
          text="Mobile Number"
        />}
        {otpVisibility ? (
          <div className="otp-verify-wrapper">
            <label className="txt-label">OTP :</label>
            <input

              type="password"
              onChange={(event) => setOtpNumber(event.target.value)}

              className="input-field-box"

            />
            {/* <InputWithLabels
              hint="(OTP Sent to your Mobile Number)"
              onChange={(event) => {
                setOtpNumber(event.target.value)
              }}
              text="OTP"
              type="password"
            /> */}
            <button className="btn-get-otp" type="button" onClick={verifyOTPHandler}>
              Verify
            </button>
          </div>
        ) : (
          ''
        )}

        <p className="form-subtitle">Legal Company Details</p>
        <InputWithLabels
          text="GST Number"
          type="text"
          name="gstNumber"
          onChange={(event) => gstInVerify(event.target.value)}
          error={errors.gstNumber?.type !== undefined}
          errorText={errors.gstNumber ? errors.gstNumber.message : ''}
          register={register(legalFormValidations.gstNumber)}
        />
        <InputWithLabels
          text="Legal Name"
          type="text"
          name="name"
          error={errors.name?.type !== undefined}
          errorText={errors.name ? errors.name.message : ''}
          register={register(legalFormValidations.name)}
        />
        <p className="form-subtitle">Address</p>
        <InputWithLabels
          text="Address 1"
          name="address1"
          error={errors.address1?.type !== undefined}
          errorText={errors.address1 ? errors.address1.message : ''}
          register={register(legalFormValidations.address1)}
        />
        <InputWithLabels
          text="Address 2"
          name="address2"
          error={errors.address2?.type !== undefined}
          errorText={errors.address2 ? errors.address2.message : ''}
          register={register(legalFormValidations.address2)}
        />
        <InputWithLabelsNotRequired
          text="Address 3"
          name="address3"
          error={errors.address2?.type !== undefined}
          errorText={errors.address2 ? errors.address2.message : ''}
          register={register}
        />
        <InputWithLabels
          text="City"
          name="city"
          error={errors.city?.type !== undefined}
          errorText={errors.city ? errors.city.message : ''}
          register={register(legalFormValidations.city)}
        />

        <InputWithDropDown
          dropdown={Istate}
          text="State"
          name="state"
          error={errors.state?.type !== undefined}
          errorText={errors.state ? errors.state.message : ''}
          register={register(legalFormValidations.state)}

        />
        <InputWithLabels
          text="Pin Code"
          name="pin"
          maxLength={6}
          onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
          error={errors.pin?.type !== undefined}
          errorText={errors.pin ? errors.pin.message : ''}
          register={register(legalFormValidations.pin)}
        />
        <div>
          {/* <ActionButton label="< Back" varient="outlined" onClick={() => movePrevious()} /> */}
          <ActionButton label="Save & Next" type="submit" />
        </div>
      </form>
    </div>
  )
}

export default LegalInformationForm
