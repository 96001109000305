import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { RootStore } from 'redux/store';
import { useHistory } from 'react-router-dom';


import './styles.scss'
import LandingCard from 'components/landingCard';
import ProfileManagement from 'assets/images/Product-details.svg'
import PurchaseOrders from 'assets/images/Purchase-Orders.svg'
import OrderManagement from 'assets/images/Order_Management.svg'
import UsersRoles from 'assets/images/Users-Roles.svg'
import Store from 'assets/images/store.svg'
import ProductListing from 'assets/images/Product-Listing.svg'
import PoorvikaLogo from 'assets/images/poorvika-Logo.svg'
import AppheaderRightContent from 'components/AppheaderRightContent';
import { isVisible, LIST_OF_ROLES } from 'common/constant';






const LandingPage: React.FC = () => {

    const history = useHistory<History>()
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
    
    const role = localStorage.getItem('role');
    const isAdmin = (role === "ROOT_ADMIN" || role === "PMPL_CATALOGUE_MANAGER" ||role === "PMPL_OPERATIONS_MANAGER" ||role === "PMPL_FINANCE_MANAGER" )
    const isSeller = (role === 'SELLER_ADMIN')

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            history.push('/login')
         }
      }, [])
    


    return (
        <div>
            <div className="landing-header">
                <div>
                    <img className="poorvikaheader-logo" draggable="false" src={PoorvikaLogo} />

                </div>

                <AppheaderRightContent />


            </div>
            <div className="landing-page-content">

                {isVisible([LIST_OF_ROLES.LEAGAL_CREATE, LIST_OF_ROLES.LEAGAL_UPDATE, LIST_OF_ROLES.LEAGAL_VIEW, LIST_OF_ROLES.BANK_CREATE, LIST_OF_ROLES.BANK_UPDATE, LIST_OF_ROLES.BANK_VIEW,
                LIST_OF_ROLES.CONTACT_CREATE, LIST_OF_ROLES.CONTACT_UPDATE, LIST_OF_ROLES.CONTACT_VIEW, LIST_OF_ROLES.TAX_FINANCE_CREATE, LIST_OF_ROLES.TAX_FINANCE_UPDATE,
                LIST_OF_ROLES.TAX_FINANCE_VIEW, LIST_OF_ROLES.KYC_CREATE, LIST_OF_ROLES.KYC_UPDATE, LIST_OF_ROLES.KYC_VIEW, LIST_OF_ROLES.SELLER_SUMMARY]
                    , { user: userPermission }) ?<>
                    <LandingCard title="Profile Details" label="View and edit your profile details" img={ProfileManagement} onClick={() => history.push('/sellerLayout/profileDetials/Overview')} />
                     </>
                    : <></>}

                <LandingCard title="Purchase Orders" label="View and edit your profile details" img={PurchaseOrders} onClick={() => history.push('/sellerLayout/purchaseOrder/Pending')}/>

                {isVisible([LIST_OF_ROLES.PRODUCT_ADD, LIST_OF_ROLES.PRODUCT_VIEW, LIST_OF_ROLES.PRODUCT_EDIT, LIST_OF_ROLES.PRODUCT_DELIST, LIST_OF_ROLES.PRODUCT_APPROVE_REJECT, LIST_OF_ROLES.PRODUCT_BULK_UPLOAD], { user: userPermission }) ?

                    <LandingCard title="Product Listing" label="View and edit your profile details" img={ProductListing} onClick={() => history.push('/sellerLayout/productListing/searchProduct')} />
                    : <></>}

                {isVisible([LIST_OF_ROLES.WAREHOUSE_CREATE, LIST_OF_ROLES.WAREHOUSE_EDIT, LIST_OF_ROLES.WAREHOUSE_DELETE, LIST_OF_ROLES.WAREHOUSE_LIST,
                LIST_OF_ROLES.WAREHOUSE_VIEW_STOCK, LIST_OF_ROLES.WAREHOUSE_UPDATE_STOCK, LIST_OF_ROLES.WAREHOUSE_ADD_PRODUCT,
                LIST_OF_ROLES.EXPORT_INVENTORY, LIST_OF_ROLES.BULK_UPLOAD_STOCK], { user: userPermission }) ?
                    <LandingCard title="Location & Inventory" label="View and edit your profile details" img={Store} onClick={() => history.push('/sellerLayout/inventory/ActiveLocations')} />
                    : <></>}

                <LandingCard title="Order Management" label="View and edit your profile details" img={OrderManagement} onClick={() => history.push('/sellerLayout/orderManagement')}  />

                {isSeller ?
                <LandingCard title="Users & Roles" label="View and edit your profile details" img={UsersRoles} onClick={() => history.push('/sellerLayout/usersRoles')}/>

                :
                <></>}
                 {isAdmin ?
                <LandingCard title="Users & Roles" label="View and edit your profile details" img={UsersRoles} onClick={() => history.push('/sellerLayout/usersRoles')}/>

                :
                <></>}




            </div>

            {/* {logout ?
                <div className="header-card-wrapper">
                    <div className="header-card-content">
                        <p>Manage Profiles</p>
                        <p onClick={logoutHandler}>Logout</p>

                    </div>

                </div>
                : <> </>

            } */}

        </div>


    )




}

export default LandingPage

    