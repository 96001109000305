import React from 'react'

import './styles.scss'

type TInputFieldProps = {
    text?: string,
    error?: boolean,
    errorText?: string,
    register?: any,
    name?: string
    hint?: string,
    dropdown: string[] | [],
    notrequired?: boolean, 
    onChange?: (value: string) => void
} & React.InputHTMLAttributes<HTMLInputElement>

const InputLabelDownWithDropDown: React.FC<TInputFieldProps> = ({ register, name, text, onChange, hint, error, errorText, dropdown, notrequired, ...props }) => {

    const selectionHandler = (value: string) => {
        if(onChange) onChange(value)
    };

    return (
        <div className="input-with-dropdown-down">
            <label className="txt-label">{text} {notrequired ? '' : <span className="label-star">*</span>}  :</label>
            <div className="input-and-error">
                <input ref={register} list={name} autoComplete="false" className={error ? 'input-field-box-error' : 'input-field-box'} name={name} placeholder={text} {...props} onChange={(event) => selectionHandler(event.target.value)} />
                {dropdown.length ? <datalist id={name}>
                    {dropdown.map((element: string) => {
                        return <option key={element} value={element} />
                    })}
                </datalist> : <datalist id="drop-down" />}
                {error ? <p className="error-text">* {errorText}</p> : ''}
                {hint ? <p className="hint-text">{hint}</p> : ''}
            </div>

        </div>
    )
}

export default InputLabelDownWithDropDown
