import React, { useState } from 'react'
import { NavLink, Route } from 'react-router-dom'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useSelector } from 'react-redux';
import { Breadcrumbs, Grid } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


import './styles.scss'
import AppHeaderLayout from 'components/AppHeaderLayout'
import SearchProduct from 'pages/ProductListing/SearchProduct';
import TrackProducts from 'pages/ProductListing/TrackRequests';
import { isVisible, LIST_OF_ROLES } from 'common/constant';
import { RootStore } from 'redux/store';
import ProductListingHistory from 'pages/AuditLogs/ProductListing';


const ProductListing: React.FC = () => {

    const role:string | null= localStorage.getItem('role');
    const isAllowed:boolean = (role === "ROOT_ADMIN" || role === "PMPL_CATALOGUE_MANAGER" ||role === "PMPL_OPERATIONS_MANAGER" ||role === "PMPL_FINANCE_MANAGER" )

    // const role = localStorage.getItem('role') === "ROOT_ADMIN" ? true : false
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
    const [linkHeader, setLinkHeader] = useState<string>("Search all product");

    const breadcrumbs = [
        <div className='bread-crumb-navlink' key="1" >Product Listing </div>,
        <div className='bread-crumb-navlink' key="2" > {linkHeader} </div>,
    ];

    return (
        <div className="product-list-page">
            <AppHeaderLayout title="Product Listings :" to={isAllowed ? "/admin/seller/sellerLayout/productListing/searchProduct" : "/sellerLayout/productListing/searchProduct"} />
            <Grid item md={11} xs={12} className='bread-crumb-layout'>
                <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <div className="product-list-layout">
                <div className="pd-sidemenu">
                    {isVisible([LIST_OF_ROLES.PRODUCT_ADD], { user: userPermission }) ?
                        <NavLink onClick={() => setLinkHeader('Search all product')} to='/sellerLayout/productListing/searchProduct' className="select-content" >
                            <div className="select-indicator">Search all product</div>
                            <KeyboardArrowRightIcon className="keyboard-right-icon" />
                        </NavLink>
                        : <></>}
                    {isVisible([LIST_OF_ROLES.PRODUCT_VIEW], { user: userPermission }) ?
                        <NavLink to='/sellerLayout/ListedProducts/ALLProducts' onClick={() => setLinkHeader('View Listed Products')} className="select-content" >
                            <div className="select-indicator">View Listed Products</div>
                            <KeyboardArrowRightIcon className="keyboard-right-icon" />


                        </NavLink>
                        : <></>}
                        <NavLink to='/sellerLayout/productListing/history' onClick={() => setLinkHeader('History')} className="select-content" >
                            <div className="select-indicator">History</div>
                            <KeyboardArrowRightIcon className="keyboard-right-icon" />


                        </NavLink>

                    {/* <NavLink to={role ? '/admin/seller/sellerLayout/Addproducts' : '/sellerLayout/Addproducts'} className="select-content" >
                        <div className="select-indicator">Add a product to list</div>
                        <KeyboardArrowRightIcon className="keyboard-right-icon" />


                    </NavLink> */}
                    {/* <NavLink to={role ? "/admin/seller/sellerLayout/productListing/trackProduct" : '/sellerLayout/productListing/trackProduct'} className="select-content" >
                        <div className="select-indicator">Track approval requests</div>
                        <KeyboardArrowRightIcon className="keyboard-right-icon" />


                    </NavLink>
                    {isVisible([LIST_OF_ROLES.PRODUCT_BULK_UPLOAD], { user: userPermission }) ?
                        <NavLink to={role ? "/admin/seller/sellerLayout/productListing/bulkupload" : '/sellerLayout/productListing/bulkupload'} className="select-content" >
                            <div className="select-indicator">Product list bulk upload / download</div>
                            <KeyboardArrowRightIcon className="keyboard-right-icon" />


                        </NavLink>
                        : <></>} */}

                </div>
                <div className="pd-sidemenu-container">
                    <Route component={() => <SearchProduct searchInput title="Search for the Product" to="/sellerLayout/productListing/searchProduct" buttonlabel="Search" />} path="/sellerLayout/productListing/searchProduct"/>
                    <Route component={TrackProducts} path="/sellerLayout/productListing/trackProduct"/>
                    <Route component={ProductListingHistory} path="/sellerLayout/productListing/history"/>
                </div>
            </div>

        </div>
    )

}
export default ProductListing
