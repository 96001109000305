import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "common/constant";
import { BaseService } from "services/base.service";
import { IAuditLogRequest } from "services/interfaces/auditLogs.interface";
import { IErrorResponse, ISuccessResponse } from "services/interfaces/common.interface";

export class AuditLogService extends BaseService {

    

    public async getLogs(logRequest: IAuditLogRequest): Promise<ISuccessResponse | IErrorResponse>   {
        try {
            const {data} = await this.httpClient.get(API_ENDPOINTS.GET_AUDIT_LOGS, { params: {...logRequest} });
            
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }




}