import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakInstance } from 'keycloak-js'


import loginPageIllustration from 'assets/images/Delivery-address-amico.svg'
import PoorvikaLogo from 'assets/images/poorvika-Logo.svg'
import LoginForm from 'containers/form/LoginForm/LoginForm'
import SignupLink from 'components/common/SignupLink'
import { BASE_DOMAIN_URL } from 'common/constant'

import './LoginPage.scss'
import LinkButton from 'components/LinkButton'


const LoginPage: React.FC = () => {
  const [isBusinessLogin, setBusinessLogin] = useState(false)
  const history = useHistory()
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>()

  const keycloakLogin = useCallback(() => {
    keycloak?.login({ redirectUri: `${BASE_DOMAIN_URL}/sso-login` })
  }, [keycloak?.authenticated])

 
  const loginTypeHandler = (loginType: boolean) => {
    setBusinessLogin(loginType)
  }



  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      const role = localStorage.getItem('role');
      const isAdmin = (role === "ROOT_ADMIN" || role === "PMPL_CATALOGUE_MANAGER" ||role === "PMPL_OPERATIONS_MANAGER" ||role === "PMPL_FINANCE_MANAGER" )
      
      if (isAdmin) {
        history.push('/sellerLayout/admin/accounts')
      } else {
        history.push('/onBoarding')
      }
    }
  }, [])

  return (
    <div className="login-page-wrapper">
      <div className="login-page">
        <div className="left-content">
          <div className="left-content-align">
            <img className="poorvika-svg-logo" alt='poorvika-svg-logo' draggable="false" src={PoorvikaLogo} />
            <h3 className="seller-portal-name-wrapper">SELLER PORTAL</h3>
            <img
              alt="login illustration"
              className="login-illustration"
              draggable="false"
              src={loginPageIllustration}
            />
          </div>
        </div>
        <div className="right-content">
          <div>
            <h3 className="sign-in-name-wrapper">Sign in</h3>
            <p className="seller-account-name">To access your seller account</p>
            <div onClick={() => loginTypeHandler(false)}>
              <LoginForm isBusinessLogin={isBusinessLogin} />
              <p className="privacy-wrapper">
                Upon login, I agree to Poorvika’s
                <span className="privacy-content">Privacy Policy & Terms</span>
              </p>
              <p className="registration-seller">
                <SignupLink /> for new account
              </p>
              <p className="registration-seller">
                <LinkButton text="Click Here" onClick={() => history.push("/resendActivationLink")}/> to Generate new activation Link 
              </p>

              {initialized && (
                <p className="registration-seller">
                    <button className="btn-sso-login" onClick={keycloakLogin}> SSO Login </button>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
