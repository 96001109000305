import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Autocomplete, TextField, FormControl, Pagination } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import ChipsButton from 'components/ChipsButton'
import loaderGif from 'assets/buffer.gif'
import SellerStatus from 'components/SellerStatus'
import { ACCOUNT_EMAIL } from 'redux/actions/OnBoardingActionTypes'
import { store } from 'redux/store'
import { AccountsService } from 'services/accounts.service'
import { IAccountList } from 'services/interfaces/accounts.interface'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { LoginService } from 'services/login.service'

import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import './styles.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type sortInformations = {
  name: string
  code: string
  email: string
  type: string
  onboardingStep: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300
  },
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ff632b',
    '&~&': {
      background: '#ff632b'
    }
  },
  mark: {
    background: 'black'
  },
  rail: {
    background: 'grey'
  },
  track: {
    background: '#ff632b !important'
  },
  valueLabel: {
    '&>*': {
      background: 'black'
    }
  }
}))

const AccountsListPage: React.FC = () => {
  const classes = useStyles()

  const [accounts, setAccounts] = useState<IAccountList[] | []>([])
  const [all_accounts, setAllAccounts] = useState<IAccountList[] | []>([])
  const [totalPages, setTotalPages] = useState(1)
  // const [accountsCount, setAccountsCount] = useState(1);
  const [searchText, setSearchText] = useState<string>('')
  const [activeColor, setActiveColor] = useState(false)
  const [pagecount, setpageCount] = useState(5)
  const [loader, setLoader] = useState(false)
  const [sortingType, setSortingType] = useState<string>('1')
  const [sort, setSort] = useState<any>({ sortOn: false, sortvalue: '1' })
  const [sortColumn, setSortColumn] = useState<string>('')
  const [orderValue, setOrderValue] = useState<string>('')

  const [filterName, setFilterName] = useState<any>({})
  const [filterCode, setFilterCode] = useState<any>({})
  const [filterEmail, setFilterEmail] = useState<any>({})
  const [filterType, setFilterType] = useState<string>('')
  const [filterStep, setFilterStep] = useState<any>('')
  const [account_type, setAccountType] = useState<any>([])
  const [onboarding_step, setOnboardingStep] = useState<any>([])

  const accountService = new AccountsService()
  const loginService = new LoginService()
  const history = useHistory()

  useEffect(() => {
    const account_type: any = all_accounts.map((item) => {
      return item.type
    })
    const onboarding_step: any = all_accounts.map((item) => {
      return item.sellerOnBoardingStep
    })
    const uniques_type = Array.from(new Set(account_type))
    const uniques_step = Array.from(new Set(onboarding_step))
    setAccountType(uniques_type)
    setOnboardingStep(uniques_step)
  }, [accounts])

  const getAllAccountsList = async () => {

    try {
      const accountListResponse: ISuccessResponse | IErrorResponse = await accountService.getAllAccounts(activeColor)

      if (accountListResponse.status === STATUS.SUCCESS) {
        const accountList = accountListResponse as ISuccessResponse
        setAllAccounts(accountList.data)

      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to fetch the account list')
    }
  }

  const getAccountsList = async (
    pageCount?: number,
    page?: number,
    searchQuery?: any,
    isactive?: boolean,
    orderbyValues?: string,
    sortType?: string
  ) => {
    setLoader(true)

    try {
      const accountListResponse: ISuccessResponse | IErrorResponse = await accountService.getAccounts(
        pageCount,
        page,
        searchQuery,
        isactive,
        orderbyValues,
        sortType
      )

      if (isactive) {
        setActiveColor(true)
        setTotalPages(1)
      } else {
        setActiveColor(false)
      }
      setLoader(false)

      if (accountListResponse.status === STATUS.SUCCESS) {
        const accountList = accountListResponse as ISuccessResponse
        setAccounts(accountList.data.accounts)
        setTotalPages(accountList.data.totalPages)
      }
    } catch (error) {
      setLoader(false)

      showNotification(STATUS.FAILURE, 'Unable to fetch the account list')
    }
  }

  const verifyWizardHandler = (email: string) => {
    store.dispatch({
      type: ACCOUNT_EMAIL,
      payload: { email }
    })
    history.push('/sellerLayout/admin/verify')
  }

  const resendActivationLinkHandler = async (email: string): Promise<void> => {
    try {
      const resendLinkResponse: ISuccessResponse | IErrorResponse = await loginService.getActivationLink(email)

      if (resendLinkResponse.status === STATUS.SUCCESS) {
        showNotification('success', resendLinkResponse.message)
        history.push('/login')
      } else {
        showNotification('failure', resendLinkResponse.message)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, (error as Error)?.message)
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (sort.sortOn) {
      getAccountsList(5, value, searchText, activeColor, orderValue, sort.sortvalue)
    } else {
      getAccountsList(5, value, searchText, activeColor)
      setpageCount(5)
    }
  }

  const isActivehandler = async (isActivestatus) => {
    getAccountsList(pagecount, 1, '', isActivestatus)
  }

  const searchHandler = async (text: string) => {
    setSearchText(text)

    if (!text.length) {
      getAccountsList(5, 0, '')
    }
  }

  const getSortAccountsList = async (data: sortInformations) => {
    console.log(data)
    console.log(activeColor)
    getAccountsList(5, 0, data, activeColor)
  }

  const handleProductFilter = () => {
    const data: sortInformations = {
      name: filterName?.name || '',
      code: filterCode?.code || '',
      type: filterType || '',
      onboardingStep: filterStep || '',
      email: filterEmail?.email || ''
    }
    getSortAccountsList(data)
  }

  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    setSort({ sortOn: true, sortvalue: sortType })
    setOrderValue(columnName)
    setSortColumn(columnName)
    getAccountsList(5, 0, '', activeColor, columnName, sortType)
    setSortingType(sortingType === '1' ? '-1' : '1')
  }

  useEffect(() => {
    getAccountsList(5, 1)
    getAllAccountsList()
  }, [])

  return (
    <div className="accounts-list-page">
      <div className="chips-container">
        <div style={{ display: 'flex' }}>
          <ChipsButton label="In Active" varient={!activeColor} onClick={() => isActivehandler(false)} />
          <ChipsButton label="Active" varient={activeColor} onClick={() => isActivehandler(true)} />
        </div>
        <div className="search-wrapper">
          <div className="search-container">
            <input
              className="search-input"
              placeholder="Search"
              type="text"
              onChange={(event) => searchHandler(event.target.value)}
            />
            <button className="search-icon-button" onClick={() => getAccountsList(5, 0, searchText, activeColor)}>
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="accounts-table-wrapper">
        <div className="filter-head ">
          <div className="filter-item">
            <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
          </div>
          <div className="filter-item">
            <FormControl fullWidth>
              <Autocomplete
                classes={classes}
                getOptionLabel={(option: any) => option.name || ''}
                id="combo-box-demo"
                options={all_accounts || []}
                renderInput={(params) => <TextField {...params} label="Name" size="small" variant="outlined" />}
                style={{ width: 230 }}
                value={filterName}
                onChange={(e, val) => setFilterName(val)}
              />
            </FormControl>
          </div>
          <div className="filter-item">
            <FormControl fullWidth>
              <Autocomplete
                classes={classes}
                getOptionLabel={(option: any) => option.code || ''}
                id="combo-box-demo"
                options={all_accounts || []}
                renderInput={(params) => <TextField {...params} label="Code" size="small" variant="outlined" />}
                style={{ width: 200 }}
                value={filterCode}
                onChange={(e, val) => setFilterCode(val)}
              />
            </FormControl>
          </div>
          <div className="filter-item">
            <FormControl fullWidth>
              <Autocomplete
                classes={classes}
                getOptionLabel={(option: any) => option.email || ''}
                id="combo-box-demo"
                options={all_accounts || []}
                renderInput={(params) => <TextField {...params} label="Email" size="small" variant="outlined" />}
                style={{ width: 300 }}
                value={filterEmail}
                onChange={(e, value: any) => setFilterEmail(value)}
              />
            </FormControl>
          </div>

          <div className="filter-item">
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: any) => option || ''}
              id="combo-box-demo"
              options={account_type || []}
              renderInput={(params) => <TextField {...params} label="Account Type" size="small" variant="outlined" />}
              style={{ width: 180 }}
              value={filterType}
              onChange={(e, value: any) => setFilterType(value)}
            />
          </div>

          <div className="filter-item">
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: any) => option || ''}
              id="combo-box-demo"
              options={onboarding_step || []}
              renderInput={(params) => (
                <TextField {...params} label="Onboarding Step" size="small" variant="outlined" />
              )}
              style={{ width: 220 }}
              value={filterStep}
              onChange={(e, value: any) => setFilterStep(value)}
            />
          </div>

          <div className="filter-item">
            <ActionButton
              label="Apply"
              onClick={() => {
                handleProductFilter()
              }}
            />
            <ActionButton
              label="Clear"
              onClick={() => {
                getAccountsList(5, 1)
                                  }}
            />
          </div>
        </div>
        <table className="accounts-list-table">
          <thead>
            <th>
              Name
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'name' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('name', sortingType)}
              />
            </th>
            <th>
              Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'code' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('code', sortingType)}
              />
            </th>
            <th>
              Email
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'email' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('email', sortingType)}
              />
            </th>
            <th>
              Type
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'type' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('type', sortingType)}
              />
            </th>
            <th>
              Created
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'createddate' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('createddate', sortingType)}
              />
            </th>
            <th>On Boarding Step</th>
            <th>Status</th>
            <th>Actions</th>
            {!activeColor ? <th>Activation Link</th> : <></>}
          </thead>
          {loader ? (
            <tr>
              <td className="empty-data" colSpan={12}>
                <img className="loaderGif" src={loaderGif} />
              </td>
            </tr>
          ) : (
            <> </>
          )}
          {!loader && accounts.length ? (
            <tbody>
              {accounts.map((accountData: IAccountList) => {
                return (
                  <tr key={accountData.email} className="account-line-item">
                    <td>{accountData.name}</td>
                    <td>{accountData.code}</td>
                    <td>{accountData.email}</td>
                    <td>{accountData.type}</td>
                    <td>{moment(accountData.createddate).format('DD-MM-YYYY hh:mm A')}</td>
                    <td>{accountData.sellerOnBoardingStep.split('_').join(' ')}</td>
                    <td>
                      <SellerStatus status={accountData.isActive} />
                    </td>
                    <td>
                      <ActionButton
                        label={activeColor ? 'Edit' : 'Verify'}
                        onClick={() => verifyWizardHandler(accountData.email)}
                      />
                    </td>
                    {!activeColor ? (
                      <td>
                        <ActionButton label="RESEND" onClick={() => resendActivationLinkHandler(accountData.email)} />
                      </td>
                    ) : (
                      <></>
                    )}
                    <td />
                  </tr>
                )
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="empty-data" colSpan={12}>
                  No data to display
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <div className="all-products-pagination">
          <Pagination
            className="all-products-pagination"
            count={totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  )
}

export default AccountsListPage
