import React, { useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas';
import Dialog from '@material-ui/core/Dialog'

import ActionButton from 'components/ActionButton'
import FileDragandDrop from 'components/FileDragAndDrop'
import InputWithLabels from 'components/InputWithLabels'
import { OnboardingService } from 'services/onBoarding.service'
import { ON_BORADING_STEPS, showNotification, STATUS } from 'common/constant'
import WarningTextLayer from 'components/WarningTextLayer'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { RootStore } from 'redux/store'
import './styles.scss'


const documentValidations = {
  gstin: {
    required: { value: false, message: 'GST Number is required' },
    minLength: { value: 15, message: 'Should contain 15 characters' }
  },
  pan: { required: { value: false, message: 'PAN Number is required' } },
  panExpiry: {
    required: { value: false, message: 'PAN Exipry date is required' }
  },
  tan: { required: { value: false, message: 'TAN is Required' } },
  cin: { required: { value: false, message: 'CIN is Required' } }
}

type IDocumentUploadData = {
  gstin: string
  pan: string
  panExpiry: string
  tan: string
  cin: string
}

type TDocumentsReviewFormProps = {

  onSelected: () => void;
}



const UploadDocumentsReviewForm: React.FC<TDocumentsReviewFormProps> = ({ onSelected }) => {
  let sigPad: any = {};
  const [gstDocument, setGstDocument] = useState('')
  const [tanDocument, setTanDocument] = useState('')
  const [cinDocument, setCinDocument] = useState('')
  const [signature, setSignature] = useState('')
  const [agreement, setAgreement] = useState('')
  const [panDocument, setPanDocument] = useState('')
  const [rejectionNotes, setRejectionNotes] = useState<string>("")
  const [signDialog, setSignDialog] = useState<boolean>(false)
  const selectedUserEmail = useSelector((state: RootStore) => state.onBoardingData.userEmail?.email);

  const { register, handleSubmit, errors, setValue } = useForm<IDocumentUploadData>({ mode: 'all' })
  const onboardingService = new OnboardingService()

  const onSubmit = async (formData: IDocumentUploadData) => {

    if (rejectionNotes) {

      const data = {
        gstin: {
          id: formData.gstin,
          file: gstDocument
        },
        pan: {
          id: formData.pan,
          exp_date: new Date(formData.panExpiry)
        },
        cin: {
          id: formData.cin,
          file: cinDocument
        },
        tan: {
          id: formData.tan,
          file: tanDocument
        },
        signature: {
          file: signature
        },
        agreement: {
          file: agreement
        }
      }

      try {
        const saveRejectionNotesResponse: ISuccessResponse | IErrorResponse = await onboardingService.updateOnBoardingData(
          {
            ...data,
            step: ON_BORADING_STEPS[3],
            email: selectedUserEmail as string,
            rejection_notes: {
              step: ON_BORADING_STEPS[3],
              notes: rejectionNotes
            }
          })

        if (saveRejectionNotesResponse.status === STATUS.SUCCESS) {
          showNotification(STATUS.SUCCESS, 'Rejection notes saved successfully')
        } else {
          showNotification(STATUS.FAILURE, 'Rejection notes not saved')
        }
        onSelected();
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Rejection notes not saved')
      }

    } else {
      showNotification(STATUS.FAILURE, 'Add Rejection Notes')
    }



  }

  /* Need to check conditionally whether signature canvas exists  */
  const signatureCaptureHandler = async () => {
    if(!sigPad.isEmpty()) {
      setSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'));
      setSignDialog(false)
   } else {
     showNotification('failure', "Empty signature not allowed! Use mouse to draw your signature")
   }
  }



  useEffect(() => {
    const getTaxationInfo = async () => {
      try {
        const getTaxationResponse: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(4, selectedUserEmail as string)

        if (getTaxationResponse.status === STATUS.SUCCESS) {
          const successData = getTaxationResponse as ISuccessResponse

          setValue('gstin', successData.data.gstin)
          setValue('pan', successData.data.pan)
          setValue('cin', successData.data.cin)
          setValue('tan', successData.data.tan)
          setValue('gstin', successData.data.gstin)

          /* Documents */
          setAgreement(successData.data.agreement)
          setSignature(successData.data.signature)
          setGstDocument(successData.data.gstDocument)
          setCinDocument(successData.data.cinDocument)
          setTanDocument(successData.data.tanDocument)
          setPanDocument(successData.data.panDocument)

          setRejectionNotes(successData.data.rejection_notes)
        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
      }
    }

    getTaxationInfo()
  }, [])

  return (
    <div className="document-uploading-page">
      <WarningTextLayer info={rejectionNotes} closeIcon />

      <p className="form-subtitle ">Documents</p>


      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field">
              <InputWithLabels
                text="GSTIN"
                maxLength={15}
                name="gstin"
                error={errors.gstin?.type !== undefined}
                errorText={errors.gstin ? errors.gstin.message : ''}
                register={register(documentValidations.gstin)}

              />
            </div>
            <div className="drop-area">
              {gstDocument ? (
                <div>
                  <input
                    type="button"
                    className="btn-to-link"
                    onClick={() => window.open(gstDocument, '_blank')}
                    value="Click to View GST Document"
                  />
                  <CloseIcon onClick={() => setGstDocument('')} />
                </div>
              ) : (
                <FileDragandDrop
                  text="Click to upload or Drag Drop GST Document"
                  fileSelected={(file) => setGstDocument(file)}
                />
              )}
            </div>
          </div>
        </div>

        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field">
              <InputWithLabels
                text="TAN"
                notrequired
                name="tan"
                error={errors.tan?.type !== undefined}
                errorText={errors.tan ? errors.tan.message : ''}
                register={register(documentValidations.tan)}
              />
            </div>
            <div className="drop-area">
              {tanDocument ? (
                <div>
                  <input
                    type="button"
                    className="btn-to-link"
                    onClick={() => window.open(tanDocument, '_blank')}
                    value="Click to View TAN Document"
                  />
                  <CloseIcon onClick={() => setTanDocument('')} />
                </div>
              ) : (
                <FileDragandDrop
                  text="Click to upload or Drag Drop TAN Document"
                  fileSelected={(file) => setTanDocument(file)}
                />
              )}
            </div>
          </div>
        </div>

        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field">
              <InputWithLabels
                text="CIN"
                notrequired
                name="cin"
                error={errors.cin?.type !== undefined}
                errorText={errors.cin ? errors.cin.message : ''}
                register={register(documentValidations.cin)}
              />
            </div>
            <div className="drop-area">
              {cinDocument ? (
                <div>
                  <input
                    type="button"
                    className="btn-to-link"
                    onClick={() => window.open(cinDocument, '_blank')}
                    value="Click to View CIN Document"
                  />
                  <CloseIcon onClick={() => setCinDocument('')} />
                </div>
              ) : (
                <FileDragandDrop
                  text="Click to upload or Drag Drop CIN Document"
                  fileSelected={(file) => setCinDocument(file)}
                />
              )}
            </div>
          </div>
        </div>

        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field">
              <InputWithLabels
                text="PAN"
                notrequired
                name="pan"
                error={errors.pan?.type !== undefined}
                errorText={errors.pan ? errors.pan.message : ''}
                register={register(documentValidations.pan)}
              />
            </div>
            <div className="drop-area">
              {panDocument ? (
                <div>
                  <input
                    type="button"
                    className="btn-to-link"
                    onClick={() => window.open(panDocument, '_blank')}
                    value="Click to View PAN Document"
                  />
                  <CloseIcon onClick={() => setPanDocument('')} />
                </div>
              ) : (
                <FileDragandDrop
                  text="Click to upload or Drag Drop PAN Document"
                  fileSelected={(file) => setPanDocument(file)}
                />
              )}
            </div>
          </div>
        </div>

        <div className="aggrement-wrapper">
          <p className="form-subtitle text-doc-wrapper">Signature & Agreement</p>

          <ActionButton label="Download Agreement " onClick={() => window.open("https://storage.googleapis.com/seller-portal/1626255719708local.pdf")} />

        </div>

        <div className="field-and-drag-drop-signature-and-agreement">
          <div className="drop-area">
            {signature ? (
              <div className="sign-content-wrap">
                <img src={signature} className="digital-sign-img" onClick={() => {setSignDialog(true)}} />
                <CloseIcon onClick={() => setSignature('')} />
              </div>
            ) : (
              <>
              <ActionButton label="Digital Signature" type="button" onClick={() => setSignDialog(true)}/>
              </> 

            )}
          </div>
          <div className="drop-area">
            {agreement ? (
              <div>
                <input
                  type="button"
                  className="btn-to-link"
                  onClick={() => window.open(agreement, '_blank')}
                  value="Click to View Agreement"
                />
                <CloseIcon onClick={() => setAgreement('')} />
              </div>
            ) : (
              <FileDragandDrop
                text="Click to upload or Drag Drop Agreement"
                fileSelected={(file) => setAgreement(file)}
              />
            )}
          </div>
        </div>
        <p className="form-subtitle">Rejection notes</p>
        <InputWithLabels
          text="Rejection Notes"
          value={rejectionNotes}
          onChange={(event) => setRejectionNotes(event.target.value)}
        />
        <div>
          <ActionButton label="Add Rejection Notes & Save" type="submit" />
        </div>
        <Dialog open={signDialog} className="digital-signature-dialog">
        {signature ? <>
        <div className="image-viewer">        
        <p className="singature-panel-hint">View Signature</p>
        <div className="image-wrapper">
        <img src={signature} className="digital-sign-img"/>
        </div>
        <div className="dialog-action-btn">
        <ActionButton label="Modify" varient="outlined"  type="button" onClick={() => setSignature('')} />
        <ActionButton label="Close" type="button" onClick={() => setSignDialog(false)} />
        </div>
         </div>
        </> : <>
        <p className="singature-panel-hint">Use mouse to sign and save</p>
        <SignatureCanvas
          penColor="blue"
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          ref={(ref) => {
            sigPad = ref;
          }}
        />
        <div className="dialog-action-btn">
          <ActionButton label="Clear" type="button" varient="outlined" onClick={() => sigPad.clear()} />
          <ActionButton label="Save" type="button" onClick={() => signatureCaptureHandler()} />
        </div>
        </>}
      </Dialog>
      </form>
    </div>
  )
}

export default UploadDocumentsReviewForm
