import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { AuditLogService } from 'services/auditLogs.service';
import { showNotification, STATUS } from 'common/constant';
import loaderGif from 'assets/buffer.gif'
import { IPriceModificationLogs } from 'services/interfaces/inventory.interface';
import moment from 'moment';

import './styles.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type TPriceHistory = {
  erpItemCode: string
}

type TPriceIconProps = {
  oldValue: number, 
  newValue: number
}

/* showing the price modification icons */
const PriceIconEvaluation: React.FC<TPriceIconProps> = ({oldValue, newValue}) => {

  const isPriceIncreased = (): boolean => (oldValue < newValue);

  if(oldValue === newValue) {
    return <></>
  }

  return(<>
  {isPriceIncreased() ? <FontAwesomeIcon className='increment' icon={faSortUp as IconProp}/> : <FontAwesomeIcon className='decrement' icon={faSortDown as IconProp}/>}
  </>)
  
}

const PriceHistory: React.FC<TPriceHistory> = ({erpItemCode}) => {
  const [priceLogs, setPriceLogs] = useState<IPriceModificationLogs[]>([]);
  const [loading, setLoading]= useState<boolean>(false);
  const auditLogService = new AuditLogService();

  const getPriceLogs = async () => {
    try {
      const params = {
        seller_code: localStorage.getItem('seller_code') as string, 
        email: localStorage.getItem('sellerMail') || localStorage.getItem('userMail') as string,
        module_name: 'Pricing', 
        erp_item_code: erpItemCode
      }
      setLoading(true);
      const priceUpdates = await auditLogService.getLogs(params);

      if(priceUpdates.status === STATUS.SUCCESS) {
        setPriceLogs(priceUpdates.data)
        setLoading(false);
      } else {
        showNotification(STATUS.FAILURE, "Unable to get price modification data ")
        setLoading(false);
      }
      
    } catch (error) {
      showNotification(STATUS.FAILURE, "Unable to get price modification data ")
      setLoading(false)
    }

  }

  


  useEffect(() => {
    getPriceLogs();
  }, [erpItemCode]);

  return (
    <div className='price-history-view'>
        <p>Price Update History</p>
        <table>
          <tr className='price-history-header'>
            <th>Date</th>
            <th>Previous MRP</th>
            <th>Current MRP</th>
            <th>Previous Wholesale Price</th>
            <th>Current Wholesale Price</th>
            <th>Updated By</th>
          </tr>
         {(priceLogs.length && !loading) ? priceLogs.map((element: IPriceModificationLogs) =>{
          return <tr key={element.row_added_dttm}>
            <td>{moment(element.row_added_dttm).format("DD-MM-YYYY hh:mm A")}</td>
            <td><del>&#x20b9; {element.old_value.Mrp}</del></td>
            <td>&#x20b9; {element.new_value.Mrp} <PriceIconEvaluation oldValue={element.old_value.Mrp} newValue={element.new_value.Mrp}/> </td>
            <td><del>&#x20b9; {element.old_value.Wholesale}</del></td>
            <td>{element.new_value.Wholesale} <PriceIconEvaluation oldValue={element.old_value.Wholesale} newValue={element.new_value.Wholesale}/></td>
            <td>{element.row_updated_by}</td>
          </tr>
         }): <></> }
         {(priceLogs.length === 0 && !loading) ? <tr>
           <td colSpan={6}> <p>Price Modification Information Not available</p></td>
         </tr> : <></>}
         {loading ? <tr>
           <td colSpan={6}> <img className="loaderGif" src={loaderGif} alt="loading" /></td>
         </tr> : <></>}
        </table>
   
    </div>
  )
}

export default PriceHistory