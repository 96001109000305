import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import './styles.scss'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { STATUS, showNotification, validatePermission, LIST_OF_ROLES } from 'common/constant'
import ActionButton from 'components/ActionButton'
import { OnboardingService } from 'services/onBoarding.service'
import { RootStore, store } from 'redux/store'
import CardHeader from 'components/CardHeader'
import { LEGAL_INFORMATIONS } from 'redux/actions/profile.action'
import { AccountsService } from 'services/accounts.service'



type ILegalForm  = {
    type: string
    name: string
    registeredaddress: string
    communicationaddress: string
    gstNumber: string
    city: string
    state: string
    pin: string
    address1: string
    address2: string
    address3: string
    email: string
    accountName: string
}




const formValidations = {
    gstin: {
        pattern: { value: /^[0-9a-zA-Z]+$/, message: 'Invalid GST Number' }
    },
    password: {
        required: { value: true, message: '* Password field is empty' },
        minLength: { value: 6, message: '* Password must be at least 6 characters long' }
    }
}
const LegalDetails: React.FC = () => {
    const { register, setValue, handleSubmit, setError, watch } = useForm<ILegalForm>()
    const onBoardingService = new OnboardingService();
    const accountService = new AccountsService();
    const companyLegalDetailsEditMode = useSelector((store: RootStore) => store.profile.legalDetails.edit);
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);


    const onSubmit = async (legalInfo) => {
        legalInfo.name = watch('name')
        
        try {

            const updateResponse: ISuccessResponse | IErrorResponse = await accountService.updateProfile(legalInfo, "LEAGAL_DETAILS");

            if(updateResponse.status === STATUS.SUCCESS) {
                showNotification(STATUS.SUCCESS, 'Legal Details updated successfully')
                store.dispatch({type: LEGAL_INFORMATIONS, payload: {legalDetails: {edit: false}}});
            } else {
                showNotification(STATUS.FAILURE, 'Unable to update Legal Details')
            }
            
        } catch (error) {
            showNotification(STATUS.FAILURE, 'Unable to update Legal Details')
        }
        

    }

    const gstInVerify = async (gstNumber: string) => {

        const filteredGSTNumber = gstNumber?.replace(/[^a-z0-9]/gi,'').substring(0,15);
        
        setValue("gstNumber", filteredGSTNumber)
        
        if (gstNumber.length === 15) {
          const regexPattern  = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

          if(!regexPattern.test(filteredGSTNumber)) {
            setError('gstNumber', {
              type: "manual", 
              message: "GST Number format is invalid"
            })
            showNotification('failure', "GST Number Format is invalid")
            
            return 
      
          }

          try {
            const { data }: ISuccessResponse | IErrorResponse = await onBoardingService.getGstIndetails(gstNumber);

            if (!data.error) {
              const accountInformations = data.data;
              const addressData = accountInformations.pradr.addr;
              setValue("name", accountInformations.lgnm);
              setValue("address1", addressData.bno + ", " + addressData.flno);
              setValue("address2", addressData.st);
              setValue("city", addressData.dst);
              setValue("state", addressData.stcd);
              setValue("pin", addressData.pncd);    
    
            }
          } catch (error) {

            showNotification(STATUS.FAILURE, 'Unable to get GST Informations')
          }
        } else {
          setValue("name", "");
          setValue("address1", "");
          setValue("address2", "");
          setValue("pin", "");
          // showNotification(STATUS.FAILURE, 'Please Enter valid GST Number')
        }
      }


    useEffect(() => {

        const role = localStorage.getItem('role');
        const isAllowed:boolean = (role === "ROOT_ADMIN" || role === "PMPL_CATALOGUE_MANAGER" ||role === "PMPL_OPERATIONS_MANAGER" ||role === "PMPL_FINANCE_MANAGER" )
        const sellerMail: string = localStorage.getItem('sellerMail') as string
        const getPersonalData = async () => {
            try {
                const personalData: ISuccessResponse | IErrorResponse = await onBoardingService.getOnboardingData(1, isAllowed ? sellerMail as string : "")

                if (personalData.status === STATUS.SUCCESS) {
                    
                    const successData = personalData as ISuccessResponse;
                    setValue("accountName", successData.data.account_name);
                    setValue("email", successData.data.email);


                    setValue("address1", successData.data.address1);
                    setValue("address2", successData.data.address2);
                    setValue("address3", successData.data.address3);
                    setValue("city", successData.data.city);
                    setValue("pin", successData.data.pincode);
                    setValue("state", successData.data.state);
                    setValue("gstNumber", successData.data.gstNumber);
                    setValue("name", successData.data.name);
                    setValue("type", successData.data.type);
                    setValue("registeredaddress",
                        successData.data.address1 + ', ' +
                        successData.data.address2 + ', ' +
                        successData.data.address3 + ', ' +
                        successData.data.city + ', ' +
                        successData.data.state + ', ' +
                        successData.data.pincode + ' .'
                    );
                    setValue("communicationaddress",
                        successData.data.address1 + ', ' +
                        successData.data.address2 + ', ' +
                        successData.data.address3 + ', ' +
                        successData.data.city + ', ' +
                        successData.data.state + ', ' +
                        successData.data.pincode + ' .'
                    );

                    setValue("city", successData.data.city);
                    setValue("pin", successData.data.pincode);
                    setValue("state", successData.data.state);


                } else {
                    showNotification(STATUS.FAILURE, 'Unable to get legal informations')
                }
            } catch (error) {
                showNotification(STATUS.FAILURE, 'Unable to get legal informations')
            }
        }
       

        getPersonalData()
    }, [])

    const hasLegalEditPermission = (): boolean => {
        const permission = validatePermission([LIST_OF_ROLES.LEAGAL_UPDATE], userPermission);

        return permission;
    };

    return (
        <div className="legal-details-page">
            <div className="legal-layout-wrapper">
            <CardHeader title="Company Legal Details" edit={hasLegalEditPermission() && !companyLegalDetailsEditMode} action={LEGAL_INFORMATIONS}/>

                <form className="legal-details-content" onSubmit={handleSubmit(onSubmit)}>
                    <InputLabelwithDowninput
                        label="GST Number *"
                        register={register(formValidations.gstin)}
                        name="gstNumber"
                        hint={true}
                        disabled={!companyLegalDetailsEditMode}
                        hintText={companyLegalDetailsEditMode ? "Verified" : ""}
                        onChange={(event) => gstInVerify(event)}
                    />
                    <InputLabelwithDowninput
                        label="Legal Name as per GSTIN *"
                        register={register}
                        disabled
                        name="name"
                    />
                    <p className="profile-sub-heading">Registered Address</p>

                    <InputLabelwithDowninput
                        label="Address 1 *"
                        register={register}
                        disabled={!companyLegalDetailsEditMode}
                        name="address1"

                    />
                    <InputLabelwithDowninput
                        label="Address 2 *"
                        register={register}
                        disabled={!companyLegalDetailsEditMode}
                        name="address2"

                    />

                    <InputLabelwithDowninput
                        label="Address 3 "
                        register={register}
                        disabled={!companyLegalDetailsEditMode}
                        name="address3"

                    />
                    <InputLabelwithDowninput
                        label="City *"
                        register={register}
                        disabled={!companyLegalDetailsEditMode}
                        name="city"

                    />

                    <InputLabelwithDowninput
                        label="State *"
                        register={register}
                        disabled={!companyLegalDetailsEditMode}
                        name="state"

                    />

                    
                    <InputLabelwithDowninput
                        label="PIN Code *"
                        register={register}
                        disabled={!companyLegalDetailsEditMode}
                        name="pin"

                    />

                    {/* <InputLabelwithDowninput
                        label="Registered Address*"
                        textarea
                        name="registeredaddress"
                        register={register}
                        disabled
                    />
                    
                    <div className="form-data-hide">
                    <InputLabelwithDowninput
                        label="Communication Address*"
                        textarea
                        name="communicationaddress"
                        disabled
                        register={register}

                    />
                        <InputLabelwithDowninput
                            label="Address 1"
                            name="address1"

                            register={register}
                        />
                        <InputLabelwithDowninput
                            label="Address 2"
                            name="address2"
                            register={register}
                        />
                        <InputLabelwithDowninput
                            label="Address 3"
                            name="address3"
                            register={register}
                        />

                    </div> */}
                    <div className="save-btn-wrap">
                       {companyLegalDetailsEditMode ?  <ActionButton label="Save" type="submit" /> : <></>}
                    </div>

                </form>
            </div>
        </div>
    )
}

export default LegalDetails