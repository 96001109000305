export const PRODUCT = "PRODUCT";
export const PRODUCTACTIVED = "PRODUCTACTIVED";
export const PRODUCT_BLOCKED = "PRODUCT_BLOCKED";
export const PRODUCT_INACTIVE = "PRODUCT_INACTIVE";
export const PRODUCT_COUNT = "PRODUCT_COUNT"
export const PRODUCT_CATEGORIES = "PRODUCT_CATEGORIES "
export const PRODUCT_ACTIVATED = "PRODUCT_ACTIVATED "
export const PRODUCT_UPDATE = "PRODUCT_UPDATE "
export const PRODUCTS_LIST = "PRODUCTS_LIST"
export const PRODUCTS_COMM_LIST = "PRODUCTS_COMM_LIST"
export const PRODUCTSCOMM_UPDATE = "PRODUCTSCOMM_UPDATE"

export type ProductType = {
    ProductsList: ProductsList[]

}

export type ProductsList = {
    products: {
        finalPrice: number
        listingPrice: number
        productDescription: string
        productTitle: string
        status: string
        stock: number
        usualPrice: number
        __v: number
        _id: string
    }
}

export type Product = {
    type: typeof PRODUCT,
    payload: {
        finalPrice: number
        listingPrice: number
        productDescription: string
        productTitle: string
        status: string
        stock: number
        usualPrice: number
        __v: number
        _id: string
    }

}
export type ProductCount = {
    type: typeof PRODUCT_COUNT,
    payload: {
        finalPrice: number
        listingPrice: number
        productDescription: string
        productTitle: string
        status: string
        stock: number
        usualPrice: number
        __v: number
        _id: string
    }

}

export type ProductActivated = {
    type: typeof PRODUCTACTIVED,
    payload: {
        productTitle: string
        productDescription: string
        listingPrice: number
        finalPrice: number
        stock: number
        usualPrice: number,
        status: string,
        url: string,
        productCategory: string,
        _id: string,
        userEmail: string
        deactivationReason: string,
        stockRemoveReason: string,
        stockModifiedDate: Date
    }

}


export type ProductBlocked = {
    type: typeof PRODUCT_BLOCKED,
    payload: {
        finalPrice: number
        listingPrice: number
        productDescription: string
        productTitle: string
        status: string
        stock: number
        usualPrice: number
        __v: number
        _id: string
    }

}


export type ProductInactive = {
    type: typeof PRODUCT_INACTIVE,
    payload: {
        finalPrice: number
        listingPrice: number
        productDescription: string
        productTitle: string
        status: string
        stock: number
        usualPrice: number
        __v: number
        _id: string
    }

}


export type ProductCategory = {
    type: typeof PRODUCT_CATEGORIES,
    payload: {
        productcategories: []
    }
}


export type Product_Activated = {
    type: typeof PRODUCT_ACTIVATED,
    payload: {
        productcategories: []
    }
}

export type Product_Update = {
    type: typeof PRODUCT_UPDATE,
    payload: {

    }
}

export type Product_List  = {
    type: typeof PRODUCTS_LIST,     
    payload: {

    }
}

export type productCommList  = {
    type: typeof PRODUCTS_COMM_LIST,     
    payload: {

    }
}


export type productCommUpdate  = {
    type: typeof PRODUCTSCOMM_UPDATE,     
    payload: {

    }
}

export type ProductDispatchTypes = Product | ProductActivated | ProductBlocked | ProductInactive | ProductCount | ProductCategory | Product_Activated
 | Product_Update | Product_List | productCommList | productCommUpdate
