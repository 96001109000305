import { showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import moment from 'moment'
import React from 'react'
import { useForm } from 'react-hook-form'

import "./styles.scss"


interface IDateRange{
    fromDate: string, 
    toDate: string
  }

const dateRangeFilter = {
    fromDate: {
      required: { value: true, message: '* from date is required' },
    }, 
    toDate: {
      required: { value: true, message: '* to date is required' },
    }, 
}


type DateRangeFilterProps = {
    onSelect?: (data: IDateRange) => void
}
  

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({onSelect}) => {

    const { register, handleSubmit, reset } = useForm<IDateRange>();

    const dateRangeSelectionHandler = (dateRange: IDateRange): void => {
        const todayDate = moment();
        const fromDate = moment(dateRange.fromDate);
        const toDate = moment(dateRange.toDate);

        /* Conditional check to ensure future dates not selected & the to date is greater than from date */
        if((fromDate > todayDate) || (toDate > todayDate)) {
            showNotification(STATUS.FAILURE, 'Future Dates are not allowed');
            return
        } else if(fromDate > toDate) {
            showNotification(STATUS.FAILURE, 'To date should be greater than from date');
            return
        }

        if(onSelect) {
            onSelect(dateRange);
        }

    }

  return (
    <form className="filter-wrapper" onSubmit={handleSubmit(dateRangeSelectionHandler)}>
        <div className="date-range">
        <h4>From</h4>        
        <input type="date" className='date-range-picker'
          ref={register(dateRangeFilter.fromDate)}
          name="fromDate"/>
        </div>
        <div className="date-range">
        <h4>To</h4>
        <input type="date" className='date-range-picker'
         ref={register(dateRangeFilter.toDate)}
         name="toDate"/>
        </div>
        <ActionButton label='Search'/>
        <ActionButton varient='outlined' label='Clear' onClick={() => reset()}/>
      </form>
  )
}

export default DateRangeFilter