import React, { useState } from 'react'
import './styles.scss'
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakInstance } from 'keycloak-js';
import { store } from 'redux/store';
import { LOGIN, USER } from 'redux/actions/UserActionTypes';
import useAdminEvaluation from 'hooks/useAdminEvaluation';



const AppheaderRightContent: React.FC = () => {

    const { isBusinessUser }= useAdminEvaluation();
    const [logout, setLogout] = useState<boolean>(false)
    const history = useHistory<History>()


    const { keycloak } = useKeycloak<KeycloakInstance>();



    const handleClose = () => {
        setLogout(false);
    };

    const logoutHandler = () => {
        localStorage.clear()
        store.dispatch({
            type: LOGIN,
            payload: []
          })
          store.dispatch({
            type: USER,
            payload: []
          })

        if(keycloak?.authenticated){
            keycloak?.logout();
        } else{
            history.push('/login')
        }
         
    }

    const switchProfileHandler = () => {
        localStorage.removeItem('seller_name')
        localStorage.removeItem('sellerMail')
        history.push("/sellerLayout/admin/seller")
    }

    return (
        <div className="rignt-seller-content">
            <AccountBoxRoundedIcon className="Account-ic-header" />
            <div className="name-email-wrapper">
                {isBusinessUser ? localStorage.getItem('seller_name') ? <p className="header-seller-name">{localStorage.getItem('seller_name')}</p> : <p className="header-seller-name">{localStorage.getItem('name')}</p>
                    : <p className="header-seller-name">{localStorage.getItem('name')}</p>}

                {isBusinessUser ? localStorage.getItem('sellerMail') ? <p className="header-seller-email">{localStorage.getItem('sellerMail')}</p> : <p className="header-seller-email">{localStorage.getItem('userMail')}</p>
                    : <p className="header-seller-email">{localStorage.getItem('userMail')}</p>}
            </div>

            <KeyboardArrowRightIcon className="header-down-icon" onClick={() => setLogout(true)} />
            <div className="menu-item-wrapper">

                <Menu
                    anchorEl={null}
                    className="login-menu"
                    open={logout}
                    onClose={handleClose}
                >
                    {isBusinessUser ? <MenuItem onClick={() => switchProfileHandler()}>Switch Other Account</MenuItem> :
                        <></>
                        }
                    <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                </Menu>
            </div>

        </div>
    )
}

export default AppheaderRightContent
