import { PRODUCT,PRODUCTS_LIST,PRODUCTS_COMM_LIST, PRODUCTSCOMM_UPDATE,PRODUCTACTIVED,PRODUCT_ACTIVATED,PRODUCT_UPDATE, PRODUCT_BLOCKED,PRODUCT_INACTIVE,PRODUCT_COUNT ,PRODUCT_CATEGORIES,ProductDispatchTypes } from '../actions/ProductActionTypes'

type ProductI = {
  loading: boolean,
  product?: any,
  productUpdate?:any
  activatedProducts?: any,
  blockedProducts?: any,
  inActiveproducts?:any,
  productCount?:any,
  productCategories?:any,
  productList?:any,
  productCommList?:any,
  productCommUpdate?:any
};

const productDefaultState: ProductI = {
  loading: false,

};

const productReducer = (state: ProductI = productDefaultState, action: ProductDispatchTypes): ProductI => {
  switch (action.type) {
    case PRODUCT:
      return {
        ...state,
        loading: false,
        product: action.payload
      }
      case PRODUCT_UPDATE:
        return {
          ...state,
          loading: false,
          productUpdate: action.payload
        }
    case PRODUCTACTIVED:
      return {
        ...state,
        loading: false,
        activatedProducts: action.payload
      }
    case PRODUCT_BLOCKED:
      return {
        ...state,
        loading: false,
        blockedProducts: action.payload
      }
      case PRODUCT_INACTIVE:
        return {
          ...state,
          loading: false,
          inActiveproducts: action.payload
        }
        case PRODUCT_COUNT:
        return {
          ...state,
          loading: false,
          productCount: action.payload
        }
        case PRODUCT_CATEGORIES:
          return {
            ...state,
            loading: false,
            productCategories: action.payload 
          }
          case PRODUCT_ACTIVATED:
            return {
              ...state,
              loading: false,
              activatedProducts: action.payload
            }
            case PRODUCTS_LIST:
              return {
                ...state,
                loading: false,
                productList: action.payload
              }
              case PRODUCTS_COMM_LIST:
                return {
                  ...state,
                  loading: false,
                  productCommList: action.payload
                }
                case PRODUCTSCOMM_UPDATE:
                  return {
                    ...state,
                    loading: false,
                    productCommUpdate: action.payload
                  }
    default:
      return state


  }
}

export default productReducer;