import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SignatureCanvas from 'react-signature-canvas';
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import FileDragandDrop from 'components/FileDragAndDrop'
import InputWithLabels from 'components/InputWithLabels'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { OnboardingService } from 'services/onBoarding.service'
import './styles.scss'
import WarningTextLayer from 'components/WarningTextLayer'
type TUploadDocumentProps = {
  nextStep: () => void
  movePrevious: () => void
}

const documentValidations = {
  gstin: {
    required: { value: true, message: 'GST Number is required' },
    minLength: { value: 15, message: 'Should contain 15 characters' }
  },
  pan: { required: { value: false, message: 'PAN Number is required' } },
  panExpiry: {
    required: { value: false, message: 'PAN Exipry date is required' }
  },
  tan: { required: { value: false, message: 'TAN is Required' } },
  cin: { required: { value: false, message: 'CIN is Required' } }
}

type IDocumentUploadData = {
  gstin: string
  pan: string
  panExpiry: string
  tan?: string
  cin?: string
}

const UploadDocumentsForm: React.FC<TUploadDocumentProps> = ({ movePrevious, nextStep }) => {
  let sigPad: any = {};
  const [gstIn, setGstIn] = useState<string>('')
  const [gstDocument, setGstDocument] = useState('')
  const [tanDocument, setTanDocument] = useState('')
  const [cinDocument, setCinDocument] = useState('')
  const [panDocument, setPanDocument] = useState('')
  const [signature, setSignature] = useState('')
  const [agreement, setAgreement] = useState('')
  const [rejectionNotes, setRejectionNotes] = useState<string>("")
  const [signDialog, setSignDialog] = useState<boolean>(false)
  const { register, handleSubmit, errors, setValue } = useForm<IDocumentUploadData>({ mode: 'all' })
  const onboardingService = new OnboardingService()

  const onSubmit = async (formData: IDocumentUploadData) => {
    if (!gstDocument) {
      showNotification(STATUS.FAILURE, 'Upload GST Document')

      return
    } else if (!signature) {
      showNotification(STATUS.FAILURE, 'Upload Signature')

      return
    } else if (!agreement) {
      showNotification(STATUS.FAILURE, 'Upload Agreement')

      return
    }

    const data = {
      gstin: {
        id: gstIn,
        file: gstDocument
      },
      pan: {
        id: formData.pan,
        exp_date: new Date(formData.panExpiry),
        file: panDocument
      },
      cin: {
        id: formData.cin,
        file: cinDocument
      },
      tan: {
        id: formData.tan,
        file: tanDocument
      },
      signature: {
        file: signature
      },
      agreement: {
        file: agreement
      }
    }

    try {
      const documentSaveResult: ISuccessResponse | IErrorResponse = await onboardingService.saveOnBoardingData({ ...data, step: 'TAXATION_AND_DOCUMENTS' })

      if (documentSaveResult.status === STATUS.SUCCESS) {
        nextStep()
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to save document Informations')
    }
  }

  /* Need to check conditionally whether signature canvas exists  */
  const signatureCaptureHandler = async () => {
    if(!sigPad.isEmpty()) {
      setSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'));
      setSignDialog(false)
   } else {
     showNotification('failure', "Empty signature not allowed! Use mouse to draw your signature")
   }
  }

  useEffect(() => {
    const getTaxationInfo = async () => {
      try {
        const getTaxationResponse: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(4)

        if (getTaxationResponse.status === STATUS.SUCCESS) {
          const successData = getTaxationResponse as ISuccessResponse
          setGstIn(successData.data.gstin)
          setValue('gstin', successData.data.gstin)
          setValue('pan', successData.data.pan)
          setValue('cin', successData.data.cin)
          setValue('tan', successData.data.tan)



          /* Documents */
          setAgreement(successData.data.agreement)
          setSignature(successData.data.signature)
          setGstDocument(successData.data.gstDocument)
          setCinDocument(successData.data.cinDocument)
          setTanDocument(successData.data.tanDocument)
          setPanDocument(successData.data.panDocument)
          setRejectionNotes(successData.data.rejection_notes)
        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
      }
    }

    getTaxationInfo()
  }, [])

  return (
    <div className="document-uploading-page">
      <WarningTextLayer info={rejectionNotes} closeIcon />

      <p className="form-subtitle text-doc-wrapper">Documents</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field">
              <InputWithLabels
                error={errors.gstin?.type !== undefined}
                errorText={errors.gstin ? errors.gstin.message : ''}
                maxLength={15}
                name="gstin"
                register={register(documentValidations.gstin)}
                text="GSTIN"
                disabled
              />
            </div>
            <div className="drop-area">
              {gstDocument ? (
                <div>
                  <input
                    className="btn-to-link"
                    type="button"
                    value="Click to View GST Document"
                    onClick={() => window.open(gstDocument, '_blank')}
                  />
                  <CloseIcon onClick={() => setGstDocument('')} />
                </div>
              ) : (
                <FileDragandDrop
                  fileSelected={(file) => setGstDocument(file)}
                  text="Click to upload or Drag Drop GST Document"
                />
              )}
            </div>
          </div>
        </div>

        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field">
              <InputWithLabels
                error={errors.tan?.type !== undefined}
                errorText={errors.tan ? errors.tan.message : ''}
                name="tan"
                notrequired
                register={register(documentValidations.tan)}
                text="TAN"
              />
            </div>
            <div className="drop-area">
              {tanDocument ? (
                <div>
                  <input
                    className="btn-to-link"
                    type="button"
                    value="Click to View TAN Document"
                    onClick={() => window.open(tanDocument, '_blank')}
                  />
                  <CloseIcon onClick={() => setTanDocument('')} />
                </div>
              ) : (
                <FileDragandDrop
                  fileSelected={(file: string) => setTanDocument(file)}
                  text="Click to upload or Drag Drop TAN Document"
                />
              )}
            </div>
          </div>
        </div>

        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field">
              <InputWithLabels
                error={errors.cin?.type !== undefined}
                errorText={errors.cin ? errors.cin.message : ''}
                name="cin"
                notrequired
                register={register(documentValidations.cin)}
                text="CIN"
              />
            </div>
            <div className="drop-area">
              {cinDocument ? (
                <div>
                  <input
                    className="btn-to-link"
                    type="button"
                    value="Click to View CIN Document"
                    onClick={() => window.open(cinDocument, '_blank')}
                  />
                  <CloseIcon onClick={() => setCinDocument('')} />
                </div>
              ) : (
                <FileDragandDrop
                  fileSelected={(file) => setCinDocument(file)}
                  text="Click to upload or Drag Drop CIN Document"
                />
              )}
            </div>
          </div>
        </div>

        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field">
              <InputWithLabels
                error={errors.pan?.type !== undefined}
                errorText={errors.pan ? errors.pan.message : ''}
                name="pan"
                notrequired
                register={register(documentValidations.pan)}
                text="PAN"
              />
            </div>
            <div className="drop-area">
              {panDocument ? (
                <div>
                  <input
                    className="btn-to-link"
                    type="button"
                    value="Click to View PAN Document"
                    onClick={() => window.open(panDocument, '_blank')}
                  />
                  <CloseIcon onClick={() => setPanDocument('')} />
                </div>
              ) : (
                <FileDragandDrop
                  fileSelected={(file) => setPanDocument(file)}
                  text="Click to upload or Drag Drop PAN Document"
                />
              )}
            </div>

          </div>
        </div>

        <div className="file-uploading-wrapper">
          <div className="field-and-drag-drop">
            <div className="detail-field" />
            <div className="drop-area">{/* <InputWithLabels text="PAN Expiry Date"/> */}</div>
          </div>
        </div>

        <div className="aggrement-wrapper">
          <p className="form-subtitle text-doc-wrapper">Signature & Agreement</p>
          <ActionButton label="Download Agreement " onClick={() => window.open("https://storage.googleapis.com/seller-portal/1626255719708local.pdf")} />


        </div>

        <div className="field-and-drag-drop-signature-and-agreement">
          <div className="drop-area">
            {/* {signature ? (
              <div>
                <input
                  type="button"
                  className="btn-to-link"
                  onClick={() => window.open(signature, '_blank')}
                  value="Click to View Signature"
                />
                <CloseIcon onClick={() => setSignature('')} />
              </div>
            ) : (
              <FileDragandDrop
                text="Click to upload or Drag Drop Singnature"
                fileSelected={(file) => setSignature(file)}
              />
            )} */}
            {signature ? (
              <div className="sign-content-wrap">
                <img className="digital-sign-img" src={signature} onClick={() => setSignDialog(true)} />
                <CloseIcon onClick={() => setSignature('')} />
              </div>
            ) : (
              <>
                <ActionButton label="Digital Signature" type="button" onClick={() => setSignDialog(true)} />
              </>
            )}
          </div>
          <div className="drop-area">
            {agreement ? (
              <div>
                <input
                  className="btn-to-link"
                  type="button"
                  value="Click to View Agreement"
                  onClick={() => window.open(agreement, '_blank')}
                />
                <CloseIcon onClick={() => setAgreement('')} />
              </div>
            ) : (
              <FileDragandDrop
                fileSelected={(file) => setAgreement(file)}
                text="Click to upload or Drag Drop Agreement"
              />
            )}
          </div>
        </div>
        {/* <ActionButton varient="outlined" label="Upload GST Document"/> <br />
            <ActionButton varient="outlined" label="Upload CIN Document"/> <br />
            <ActionButton varient="outlined" label="Upload TAN Document"/> <br />
            <ActionButton varient="outlined" label="Upload Signature"/> <br />
            <ActionButton varient="outlined" label="Upload Agreement"/> <br /> */}

        <div>
          <ActionButton label="< Back" varient="outlined" onClick={() => movePrevious()} />
          <ActionButton label="Save & Next" type="submit" />
        </div>
      </form>
      <Dialog className="digital-signature-dialog" open={signDialog}>
        {signature ? <>
        <div className="image-viewer">        
        <p className="singature-panel-hint">View Signature</p>
        <div className="image-wrapper">
        <img className="digital-sign-img" src={signature}/>
        </div>
        <div className="dialog-action-btn">
        <ActionButton label="Modify" type="button"  varient="outlined" onClick={() => setSignature('')} />
        <ActionButton label="Close" type="button" onClick={() => setSignDialog(false)} />
        </div>
         </div>
        </> : <>
        <p className="singature-panel-hint">Use mouse to sign and save</p>
        <SignatureCanvas
          ref={(ref) => {
            sigPad = ref;
          }}
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          penColor="blue"
        />
        <div className="dialog-action-btn">
          <ActionButton label="Clear" type="button" varient="outlined" onClick={() => sigPad.clear()} />
          <ActionButton label="Save" type="button" onClick={() => signatureCaptureHandler()} />
        </div>
        </>}
      </Dialog>
    </div>
  )
}

export default UploadDocumentsForm
