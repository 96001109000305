import { AxiosError } from "axios";
import { API_ENDPOINTS, ON_BORADING_STEPS, parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/common.interface";

export class OnboardingService extends BaseService {

    /* Pass email argument from the business user account to fetch the details  */
    public async getOnboardingData(step: number, email?: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.GET_ONBOARDING_DATA, { step: ON_BORADING_STEPS[step - 1], email: email ? email : localStorage.getItem('userMail') })

            localStorage.setItem('seller_code', data.data.account_code)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }


    }

    public async getOnUserData(step: number, email?: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.GET_USER_DATA, { step: ON_BORADING_STEPS[step - 1], email: email ? email : localStorage.getItem('userMail') })
            // localStorage.setItem('seller_code', data.data.code)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getOTP(mobile: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const sellerData= localStorage.getItem("seller_code");
            const { data } = await this.httpClient.get(API_ENDPOINTS.SEND_OTP +`?mobile=${mobile}&sellerdata=${sellerData}`)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async verifyOTP(mobile: string, otp: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.VERIFY_OTP + `?mobile=${mobile}&otp=${otp}`)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async saveOnBoardingData(personalInformations: any): Promise<ISuccessResponse | IErrorResponse> {
       
        console.log(personalInformations, 'code');
        
        const role: string | null = localStorage.getItem('role')
        const isAdmin: boolean =
          role === 'ROOT_ADMIN' ||
          role === 'PMPL_CATALOGUE_MANAGER' ||
          role === 'PMPL_OPERATIONS_MANAGER' ||
          role === 'PMPL_FINANCE_MANAGER'

        try {

            const headers = {
                'email': isAdmin ? localStorage.getItem('sellerMail') : localStorage.getItem('userMail')
            }

            const { data } = await this.httpClient.post(API_ENDPOINTS.SAVE_ONBOARDING_DATA, { ...personalInformations }, { headers })
            
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async saveOnBoardingUserData(personalInformations: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.SAVE_USER_DATA, { ...personalInformations })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }
    public async savePmplUserData(personalInformations: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.SAVE_PMPL_USER_DATA, { ...personalInformations })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getBankSuggestions(ifsc: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.GET_IFSC_SUGGESTION + `?ifsc=${ifsc}`)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async uploadDocument(fileData: FormData): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.UPLOAD_DOCUMENT_TO_CLOUD, fileData)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async uploadCheckLeaf(filepath: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.CHEQUELEAF_PATH, {filepath, code : localStorage.getItem('seller_code')})

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async updateOnBoardingData(personalInformations: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.UPDATE_ONBOARDING_DATA, { ...personalInformations })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async activateSeller(email): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.ACTIVATE_SELLER,  {email})

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    
    public async getHistorylog(email) {

        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.SELLER_HISTORY, { email })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }



    public async getGstIndetails(gstNumber: string) {

        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.GST_INFORMATION + `?gstNumber=${gstNumber}`);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }



}