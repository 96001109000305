export type INotification = {
    assigned_to: string,
    create_at: string,
    description: string,
    modified_at: string,
    notification_id: string,
    notification_type: string,
    seller_code: string,
    seller_email: string,
    status: boolean,
    title: string
  }

  export enum NotificationType {
    "listings" = "listing", 
    "registration" = "registration", 
    "purchaseOrder" = "purchaseOrder"

  }
  