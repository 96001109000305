import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'

type LayoutHeaderProps = {
    title: string;
    to?: any
    edit?: boolean
    remove?: boolean
    onClick?: () => void;
    state?: string[]
    editOnClick?: () => void


}
const LayoutHeader: React.FC<LayoutHeaderProps> = ({ title, to, edit, remove, onClick,state ,editOnClick}) => {
    return (

        <div className="legal-details-Header">
            <p>{title}</p>
            <div className="edit-delete-btn-wrapper">

                {edit ?
                    <Link to={{pathname : to , state: state }} className="edit-btn-wrapper" onClick={editOnClick}>
                        Edit

                    </Link>
                    : <> </>

                }
                {remove ?
                    <div className="edit-btn-wrapper" onClick={onClick}>
                        Delete

                    </div>
                    : <> </>

                }
            </div>



        </div>
    )



}

export default LayoutHeader