
import React from 'react'
import './styles.scss'

type landingCardProps = {
    title: string;
    label: string;
    img?: string;
    onClick?: () => void
}


const LandingCard: React.FC<landingCardProps> = ({  title, img, onClick,label }) => {
    return (
        <div className="landing-card-wrapper" onClick={onClick}>
            {/* <div className="profile-wrap">
                <div className="avatar-profile">
                
                    <img src={img} alt="ic" className="img-ic" />
                </div>
            </div>
            <div className="detail-wrap">
                <h3 className="title-wrap">{title}</h3>
                <p className="desc-wrap">{label}</p>
            </div> */}

            <div className="profile-wrap">
                
                    <img src={img} alt="ic" className="img-ic" />
                    <h3 className="title-wrap">{title}</h3>
              
            </div>
            <div className="detail-wrap">
                <p className="desc-wrap">{label}</p>
            </div>
        </div>
    )
}

export default LandingCard