import React from 'react'
import { NavLink, useLocation, } from 'react-router-dom'

import './styles.scss'

type SidenavbarProps = {
    label: string;
    to: string,
    isActive?: any
    icon?: any
    coloricon?: any
    disabled?: boolean
    onClick?: () => void
}


const SideMenuItems: React.FC<SidenavbarProps> = ({ icon, coloricon, to, label, isActive, disabled, onClick }) => {
    const { pathname } = useLocation()




    return (
        <div className={disabled ? "side-menu-item cursor-notAllow" : "side-menu-item"} >

            {/* isActive={() => productSubPaths.includes(pathname)} */}
            <NavLink className={disabled ? 'menu-link disabled-link' : 'menu-link'} isActive={() => isActive ? isActive.includes(pathname) : ''} to={to} onClick={onClick}>
                <div className='menu-ic'>
                    <img className='menu-img' src={icon} />
                    <img className='menu-color-img' src={coloricon} />
                </div>
                <div className="menu-title">
                    {label}
                </div>
            </NavLink>
        </div>
    )
}

export default SideMenuItems