import AppHeaderLayout from 'components/AppHeaderLayout';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Route, useHistory } from 'react-router-dom';
import PaymentsTable from './PaymentsTable';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CSVLink } from 'react-csv'

import './styles.scss';
import { IPaymentData } from 'services/interfaces/payment.interface';
import { paymentStatus, showNotification, STATUS } from 'common/constant';
import { PaymentsService } from 'services/payments.service';
import moment from 'moment';


const PaymentDetailsPage: React.FC = () => {

  const history = useHistory();
  

  const [paymentStatusType, setPaymentStatusType] = useState<string>("All");
  const [allPaymentData, setAllPaymentsData] = useState<any>([]);
  const [seller_code] = useState<string>(localStorage.getItem('seller_code') as string)
  const inputEl =  useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)
  
  /*Services */
  const paymentsService = new PaymentsService()
  
  

  useEffect(() => {
    /* Default selection to  all payments tab*/
    history.push("/sellerLayout/payments/all");
  }, []);

  /* Downloading as excel functionality */
  const exportAsExcelHandler = async (): Promise<void> => {
    try {
      const getAllPaymentsResponse = await paymentsService.getPaymentList(seller_code, undefined, 1);

      if(getAllPaymentsResponse.status === STATUS.SUCCESS) {

        const paymentInfo: IPaymentData[] = getAllPaymentsResponse.data.payments;
        
        if(paymentInfo.length) {

          const excelData = paymentInfo.map((payment: IPaymentData) => {

            const data = {};
            data['Invoice Date'] = moment(payment.invoice_date).format("DD-MMM-YYYY");
            data['Invoice Number'] = payment.invoice_no;
            data['Total Amount'] = payment.total_amt;
            data['Payment Status'] = payment.payment_status;
            data['Payment Date'] = moment(payment.payment_date).format("DD-MMM-YYYY");
            data['Document Reference Number'] = payment.document_ref_no;
            data['Payment Mode'] = payment.payment_mode;
            data['Transaction ID'] = payment.transaction_id;

            return data;
          })

          setAllPaymentsData(excelData);
        } else {
          const data = {};
          data['Invoice Date'] = "";
          data['Invoice Number'] = "";
          data['Total Amount'] = "";
          data['Payment Status'] = "";
          data['Payment Date'] = "";
          data['Document Reference Number'] = "";
          data['Payment Mode'] = "";
          data['Transaction ID'] = "";


          setAllPaymentsData(data);
        }
        
        inputEl.current.link.click();


      } else {
        showNotification(STATUS.SUCCESS, 'Unable to download payment Informations');
      }
      
    } catch (error) {
      
    }

  }

  return (
    <div className='payments-page'>
    <AppHeaderLayout title="Payments :"/>
    <div className="payments-table-layout">
      <div className="payments-tables-header-wrapper">
        <div className="payments-tables-header">
      {paymentStatus.map((paymentType) => (
                <>
                  <NavLink key={paymentType.name} className="table-option-type" to={paymentType.path} onClick={() =>setPaymentStatusType(paymentType.name)}>
                    <p>{paymentType.name}</p>
                  </NavLink>
                  <span>|</span>
                </>
              ))}
              </div>
              <div>
              <button className="export-excel" type='button' onClick={exportAsExcelHandler}>
        <FontAwesomeIcon className="excel-icon" icon={faFileExcel as IconProp} />
        <span className="btn-text">Export All Payments</span>
      </button>
              </div>
              <CSVLink
            filename={`all-payments-${new Date().toISOString()}.csv`}
            data={allPaymentData}
            asyncOnClick
            onClick={(event, done) => done()}
            style={{ display: 'none' }}
            ref={inputEl}
          />
      </div>
      <Route component={() => <PaymentsTable paymentStatusType={paymentStatusType}/>} path="/sellerLayout/payments/all"/>
      <Route component={() => <PaymentsTable paymentStatusType={paymentStatusType}/>} path="/sellerLayout/payments/pending"/>
      <Route component={() => <PaymentsTable paymentStatusType={paymentStatusType}/>} path="/sellerLayout/payments/initiated"/>
    </div>
    </div>
  )
}

export default PaymentDetailsPage