import SortIcon from 'components/SortIcon';
import React, { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@mui/material/Tooltip';

import './styles.scss';
import Pagination from '@mui/material/Pagination';
import { useForm } from 'react-hook-form';
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField';
import ActionButton from 'components/ActionButton';
import DateRangeFilter from 'components/DateRangeFilter';
import { PaymentsService } from 'services/payments.service';
import { showNotification, STATUS } from 'common/constant';
import { IEditPayment, IPaymentData } from 'services/interfaces/payment.interface';
import moment from 'moment';
import Spinner from 'components/Spinner';
import useAdminEvaluation from 'hooks/useAdminEvaluation';

const paymentFormValidations = {
  paymentDate: {
    required: { value: true, message: '* Payment Date is required' },
  }, 
  paymentMode: {
    required: { value: true, message: '* Payment mode is required' },
  }, 
  transactionID: {
    required: { value: true, message: '* transactionID is required' },
  }, 
  documentRefNo: {
    required: { value: true, message: '* document Reference No is required' },
  }
}

interface IPaymentsForm {
  paymentDate: string, 
  paymentMode: string, 
  transactionID: string,
  documentRefNo: string
}

type paymentsTableProps = {
  paymentStatusType: string
}





const PaymentsTable: React.FC<paymentsTableProps> = ({paymentStatusType}) => {
    
    const [showPaymentDetailsSlider, setShowPaymentDetailsSlider] = useState<boolean>(false);
    const [payments, setPayments] = useState<IPaymentData[] | []>([]);
    const [selectedPayment, setSelectedPayments] = useState<IPaymentData | undefined>(undefined);
    const { register, handleSubmit, setValue } = useForm<IPaymentsForm>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { isBusinessUser } = useAdminEvaluation();
    const [seller_code]  = useState<string>(localStorage.getItem("seller_code") as string);
    
    /*Services */
    const paymentsService = new PaymentsService();
  

    /* Getting payment list  */
    const getPayments = async (): Promise<void> => {
      try {
        setShowLoader(true);
        const paymentsResponse = await paymentsService.getPaymentList(seller_code, paymentStatusType);
        
        if(paymentsResponse.status === STATUS.SUCCESS) {
          setPayments(paymentsResponse?.data?.payments);
        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Payment List');
        }
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        showNotification(STATUS.FAILURE, 'Unable to get Payment List');
      }
    };

    const onPaymentSubmit = async (formData: IPaymentsForm): Promise<void> => {
      try {
        const paymentEditInformation: IEditPayment = {
          seller_code, 
          payment_mode: formData.paymentMode,
          payment_code: selectedPayment?.payment_code as string,
          transaction_id: formData.transactionID,
          document_ref_no: formData.documentRefNo,
          payment_date: formData.paymentDate
        }
        setShowLoader(true);

        const paymentStatusUpdateResponse = await paymentsService.editPaymentStatus(paymentEditInformation);
        if(paymentStatusUpdateResponse.status === STATUS.SUCCESS) {
          showNotification(STATUS.SUCCESS, 'Payment Information updated successfully');
          setShowPaymentDetailsSlider(false);
          await getPayments();
        } else {
          showNotification(STATUS.FAILURE, 'Unable to save payment Information');  
        }
        setShowLoader(false);

    } catch (error) {
      setShowLoader(false);
        showNotification(STATUS.FAILURE, 'Unable to save payment Information');
    }
    };

  /* Sorting functionality for on column header click action*/
  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    console.log({ columnName, sortType })
  }

  /* To open the payments details slider */
  const paymentInfoHandler = async (paymentData: IPaymentData): Promise<void> => {
    setSelectedPayments(paymentData);
    setValue("paymentMode", paymentData?.payment_mode as string);
    setValue("documentRefNo", paymentData?.document_ref_no as string);
    setValue("transactionID", paymentData?.transaction_id as string);
    setValue("paymentDate", moment(paymentData?.payment_date).format("YYYY-MM-DD"));
    setShowPaymentDetailsSlider(true);
  } 

  useEffect(() => {
    getPayments();
  }, [])

  return (
    <div className="payments-data-table">
      <Spinner loading={showLoader}/>
      <DateRangeFilter/>
      <table className="payments-table">
        <thead>
          <tr>
            <th>
              Invoice Date <SortIcon name="date" onClick={sortingFunctionalityHandler} />
            </th>
            <th>
              Inv. No <SortIcon name="invoiceNumber" onClick={sortingFunctionalityHandler} />
            </th>
            <th>
              Total Amount <SortIcon name="amount" onClick={sortingFunctionalityHandler} />
            </th>
            <th>
              Payment Status <SortIcon name="status" onClick={sortingFunctionalityHandler} />
            </th>
            <th>
              Payment Date <SortIcon name="status" onClick={sortingFunctionalityHandler} />
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {payments?.map((data: IPaymentData) => (
              <tr>
                <td>{moment(data?.invoice_date).format("DD-MMM-YYYY")}</td>
                <td>{data?.invoice_no}</td>
                <td>&#x20b9; {data?.total_amt}</td>
                <td className="payment-status-info">
                  <p className={data?.payment_status === 'Pending' ? 'pending' : 'initiated'}>{data?.payment_status}</p>
                </td>
                <td>{data?.payment_date ? moment(data?.payment_date).format("DD-MMM-YYYY") :  '-'}</td>
                <td>
                  <div className="action-col">
                  <Tooltip  title="View Invoice" placement="top">
                    <DescriptionIcon className="btn-icon" onClick={() => window.open(data?.invoice_img, '_blank')} />
                    </Tooltip>
                    <Tooltip  title="More Info" placement="top">
                    <InfoIcon className="btn-icon" onClick={() => paymentInfoHandler(data)} />
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="pagination-wrapper">
        <Pagination count={1} variant="outlined" shape="rounded" />
      </div>
      <div
        className={
          showPaymentDetailsSlider ? 'payment-details-slider' : 'payment-details-slider hide-payment-details-slider'
        }
      >
        <div className="slider-content">
          <div className="header-wrapper">
            <h4 className="header">Payment Details</h4>
            <HighlightOffIcon className="close-icon" onClick={() => setShowPaymentDetailsSlider(false)} />
          </div>

          <div className="payment-information-card">
          <div className="payment-data">
              <h6 className="description"># Invoice No</h6>
              <h6 className="Amount">{selectedPayment?.invoice_no}</h6>
            </div>
            <div className="payment-data">
              <h6 className="description">Invoice Date</h6>
              <h6 className="Amount">{moment(selectedPayment?.invoice_date).format("DD-MMM-YYYY")}</h6>
            </div>
            {/* <div className="payment-data">
              <h6 className="description">Item Total</h6>
              <h6 className="Amount"> &#x20b9; {selectedPayment?.total_amt}</h6>
            </div>
            <div className="payment-data">
              <h6 className="description">GST</h6>
              <h6 className="Amount"> &#x20b9; 3,059</h6>
            </div> */}
            <div className="payment-data">
              <h6 className="description">Total (Inclusive of GST 18%)</h6>
              <h6 className="Amount"> &#x20b9; {selectedPayment?.total_amt}</h6>
            </div>
            <div className="payment-data">
              <h6 className="description">Status</h6>
              <h6 className="Amount payment-status">{selectedPayment?.payment_status}</h6>
            </div>
          </div>

          <form className="payments-update-form" action="" onSubmit={handleSubmit(onPaymentSubmit)}>
            <div className="payment-date-picker">
            <p className="text-label">Payment Date *</p>
            <input type="date" placeholder="MM/DD/YYYY"
            ref={register(paymentFormValidations.paymentDate)}
            name="paymentDate"
            disabled={!isBusinessUser}
            />
            </div>
            <InputLabelwithDowninput
              label="Payment Mode *"
              register={register(paymentFormValidations.paymentMode)}
              disabled={!isBusinessUser}
              name="paymentMode"
            />
            <InputLabelwithDowninput
              label="Transaction ID *"
              register={register(paymentFormValidations.transactionID)}
              disabled={!isBusinessUser}
              name="transactionID"
            />
            <InputLabelwithDowninput
              label="Document Reference No *"
              register={register(paymentFormValidations.transactionID)}
              disabled={!isBusinessUser}
              name="documentRefNo"
            />
           {isBusinessUser && <div className="flex-center">
            <ActionButton label='Save'/>
            </div>}
          </form>
        </div>
      </div>
    </div>
  )
}

export default PaymentsTable
