import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showNotification, STATUS } from 'common/constant';
import InputLabelwithContent from 'components/InputLabelwithContent';
import LayoutHeader from 'components/LayoutHeader';
import { RootStore, store } from 'redux/store';
import { AccountsService } from 'services/accounts.service';
import { IAccountList } from 'services/interfaces/accounts.interface';
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface';
import './styles.scss'
import { ADDITIONAL_INFORMATION, BANK_AND_FINANCIAL, CONTACT_INFORMATION, LEGAL_INFORMATIONS, TAX_AND_FINACIAL_INFORMATION } from 'redux/actions/profile.action';


type sellerListCard = {
    onClick: ()=> void;

}
const SellerListAdmin: React.FC<sellerListCard> = ({ onClick }) => {
    const accountService = new AccountsService();
    const history = useHistory()
    const [userList, setUserList] = useState<IAccountList[] | []>([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState("");

    const userStep = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);


    const getAccountsList = async (pageCount?: number, page?: number, searchQuery?: string, isactive?: any, orderbyValues?: string, sortType?: string, multipleSearch?: string) => {


        try {
            const accountListResponse: ISuccessResponse | IErrorResponse = await accountService.getAccounts(pageCount, page, searchQuery, isactive, orderbyValues, sortType, multipleSearch);

            if (accountListResponse.status === STATUS.SUCCESS) {
                const accountList = accountListResponse as ISuccessResponse;
                const data = accountList.data.accounts
                setTotalPages(accountList.data.totalPages);
                setUserList(data)
            }
        } catch (error) {
            showNotification(STATUS.FAILURE, "Unable to fetch the account list")
        }

    }

    /* Impersonate login from the root admin to seller account */
    const impersonateLoginSeller = async (sellerEmail: string, code: string, name: string) => {
        localStorage.setItem('seller_code', code);
        localStorage.setItem('seller_name', name);
        localStorage.setItem('sellerMail', sellerEmail);
        store.dispatch({type: ADDITIONAL_INFORMATION, payload: {additionalInformation: {edit: false}}});
        store.dispatch({ type: CONTACT_INFORMATION, payload: { contactDetails: { edit: false } } })
        store.dispatch({type: BANK_AND_FINANCIAL, payload: {bankAccountDetails: {edit: false}}});
        store.dispatch({type: LEGAL_INFORMATIONS, payload: {legalDetails: {edit: false}}});
        store.dispatch({type: TAX_AND_FINACIAL_INFORMATION, payload: {taxAndFinancialsDetails: {edit: false}}});
        history.push("/sellerLayout/profileDetials/Overview");
    };


    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        getAccountsList(10, value, "", true, "", "", searchText);

    }

    console.log("searchText", searchText)

    const searchAccountHandler = (text: string): void => {

        if (text) {
            getAccountsList(0, 0, "", true, "", "", text);
            setSearchText(text);
        } else {
            getAccountsList(10, 1, "", true)
            setSearchText("");
        }

    }

    useEffect(() => {
        localStorage.removeItem('seller_name')
        localStorage.removeItem('sellerMail')        
        console.log(userStep);
   getAccountsList(10, 1, "", true);
    }, [])
    /*event.target.value ? getAccountsList(0, 0, "", true, "", "", event.target.value) : getAccountsList(10, 1, "", true) */

    return (
        <div className="seller-list-page">
            <div className="seller-header-wrapper">
                <div >
                    <h1 className="registered-sellers">Active Sellers</h1>
                    <p className="active-seller-hint-text">(Select Seller to view their profile)</p>
                </div>
                <input className="search-input" placeholder="Search" type="text" onChange={(event) => searchAccountHandler(event.target.value)} />

            </div>

            <div className="sellers-card-wrapper">
                {userList?.map((item) => (
                    <div className="active-Sellers-list-card" key={item.code} onClick={() => {impersonateLoginSeller(item.email, item.code, item.name);onClick()}}>
                        <LayoutHeader title={item.name} />
                        <div className="legal-details-content">
                            <InputLabelwithContent
                                label="Email"
                                content={item.email}
                            />
                            <InputLabelwithContent
                                label="Account Code"
                                content={item.code}
                            />
                            <InputLabelwithContent
                                label="GST Number"
                                content={item.tax?.gstin.id}
                            />
                            <InputLabelwithContent
                                label="City"
                                content={item.billing?.address.city}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="all-products-pagination">
                <Pagination className="all-products-pagination" count={totalPages} variant="outlined" shape="rounded" onChange={(event, page) => handleChange(event, page)} />

            </div>
        </div>
    )



}


export default SellerListAdmin