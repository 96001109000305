import React from 'react'
import { Redirect, Route } from 'react-router-dom'

type IAuthRouteProps = {
  component: React.FC<any>
  path: string
}

const AuthRoute: React.FC<IAuthRouteProps> = ({ component,path }) => {
  const Component = component
  const token = localStorage.getItem('accessToken')

  return token ? <Route  component={Component} path={path} /> : <Redirect to="/login" />
}

export default AuthRoute
