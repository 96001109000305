import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { NavLink, Route } from 'react-router-dom'
import moment from 'moment'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import AppHeaderLayout from 'components/AppHeaderLayout'
import AppHeaderTowLayout from 'components/AppHeaderTowLayout'
import { OnPurchaseOrderService } from 'services/purchaseOrder.service'
import {
  IDateRange,
  IErrorResponse,
  IErrorResponses,
  ISuccessResponse,
  ISuccessResponses
} from 'services/interfaces/common.interface'
import { isVisible, LIST_OF_ROLES, showNotification, STATUS } from 'common/constant'
import { RootStore } from 'redux/store'
import { IPurchaseOrder, IPurchaseOrderExport } from 'services/interfaces/purchaseorder.interface'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Grid } from '@material-ui/core'

import PurchaseTable from './PurchaseOrderTable'
import './styles.scss'
import Spinner from 'components/Spinner'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ActionButton from 'components/ActionButton'
import { useForm } from 'react-hook-form'

const role = localStorage.getItem('role') === 'ROOT_ADMIN'


const dateRangeFilter = {
  fromDate: {
    required: { value: true, message: '* from date is required' },
  }, 
  toDate: {
    required: { value: true, message: '* to date is required' },
  }, 
}

interface IDateRangeFilter{
  fromDate: string, 
  toDate: string
}

const PurchaseOrders: React.FC = () => {
  const inputEl = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)
  /* State Variabled */
  const [days] = useState<string>('15')
  const [orders, setOrders] = useState<IPurchaseOrder[]>([])
  const [pending, setPending] = useState<number>(0)
  const [accepted, setAccepted] = useState<number>(0)
  const [invoiced, setInvoiced] = useState<number>(0)
  const [closed, setClosed] = useState<number>(0)
  const [shipped, setShipped] = useState<number>(0)
  const [rejected, setRejected] = useState<number>(0)
  const [exp_orders, setExpOrders] = useState<IPurchaseOrderExport[]>([])
  const [SupplierCode, setSupplierCode] = useState<string>('')
  const [dateRange, setDateRange] = useState<IDateRange>({ fromDate: new Date(), toDate: new Date() })
  const [linkHeader, setLinkHeader] = useState<string>('Pending')
  const [loading, setLoading] = useState<boolean>(false)

  const { register, handleSubmit, reset } = useForm<IDateRangeFilter>();

  /* State Management  */
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)

  /* Services  */
  const getPurchaseOrderList: OnPurchaseOrderService = new OnPurchaseOrderService()

  const getPurchaseOrders = async (dateSelectionEvent?: boolean) => {
    setLoading(true)

    try {
      const companycode = 'PMPL1'
      const branchcode = '0'
      let fromdate: string
      let enddate: string

      if (dateSelectionEvent) {
        fromdate = moment(dateRange.fromDate).format('YYYYMMDD')
        enddate = moment(dateRange.toDate).format('YYYYMMDD')
      } else {
        fromdate = moment().subtract(days, 'd').format('YYYYMMDD')
        enddate = moment().format('YYYYMMDD')
      }

      const status = 'SALL'

      const Purchaseorderlistdetails: ISuccessResponse | IErrorResponse =
        await getPurchaseOrderList.getPurchaseOrderListDatas(
          companycode,
          branchcode,
          SupplierCode,
          fromdate,
          enddate,
          status
        )
      const getdata = Purchaseorderlistdetails as ISuccessResponse
      let purchase_list = getdata.data

      purchase_list = purchase_list.reverse()
      let accepted = 0,
        pending = 0,
        invoiced = 0,
        shipped = 0,
        rejected = 0,
        closed = 0
      purchase_list = purchase_list.map((item) => {
        if (item.AdditionalStatus === 'ACTI' || item.AdditionalStatus === '') pending++
        else if (item.AdditionalStatus === 'PROC') accepted++
        else if (item.AdditionalStatus === 'INVO') invoiced++
        else if (item.AdditionalStatus === 'SHIP') shipped++
        else if (item.AdditionalStatus === 'CANC') rejected++
        else closed++


        return item
      })

      setOrders(purchase_list)
      setPending(pending)
      setAccepted(accepted)
      setInvoiced(invoiced)
      setShipped(shipped)
      setRejected(rejected)
      setClosed(closed)

      purchase_list = purchase_list.slice(0, 10)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Purchase Order list')
    }

    setLoading(false)
  }

  const getSupplierCode = async () => {
    try {
      const seller_code = localStorage.getItem('seller_code')
      const getPurchaseOrderSupplierCode: ISuccessResponses | IErrorResponses =
        await getPurchaseOrderList.getPurchaseOrderSuppliercode(seller_code)

      if (getPurchaseOrderSupplierCode.StatusMessage === STATUS.SUCCESS) {
        const SupplierCode = getPurchaseOrderSupplierCode as ISuccessResponses
        setSupplierCode(SupplierCode.Data.accountsdetails[0].external.supplier_id)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Seller Code');
    }
  }

  useEffect(() => {
    getSupplierCode()
  }, [])

  useEffect(() => {
    if (SupplierCode !== '') getPurchaseOrders()
  }, [days, SupplierCode])

  const statusName = (varient) => {
    if (varient === 'ACTI' || varient === '') return 'Pending'
    else if (varient === 'PROC') return 'PO Accepted'
    else if (varient === 'INVO') return 'Invoiced'
    else if (varient === 'SHIP') return 'Dropshipped'
    else if (varient === 'CANC') return 'Rejected'

    return 'Closed'
  }

  const handleDownload = async () => {
    try {
      if (orders?.length !== 0) {
        const data: IPurchaseOrderExport[] = []
        orders?.map((item) => {
          item.Items.map((product) => {
            const order = {}

            order['Order ID'] = item.OrderNumb
            order['Item Name'] = product.ItemName
            order['Poorvika Code'] = product.ItemCode
            order['Order Quantity'] = product.OrderQty
            order['Total Order Value'] = item.OrderAmount
            order['Order Date'] = moment(item.OrderDate, 'YYYYMMDD').format('DD-MM-YYYY')
            order['Order Status'] = statusName(item.AdditionalStatus)

            data.push(order as IPurchaseOrderExport)

            return product
          })

          return item
        })

        data.reverse()
        setExpOrders(data)
      } else showNotification(STATUS.FAILURE, 'No data to download')

      setTimeout(() => {
        inputEl.current.link.click()
      }, 1000)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to download the document')
    }
  }

  const breadcrumbs = [
    <div className="bread-crumb-navlink" key="1">
      Purchase Orders
    </div>,
    <div className="bread-crumb-navlink" key="2">
      {' '}
      {linkHeader}{' '}
    </div>
  ]

  const dateRangeSelectionHandler = (dateRange: IDateRange): void => { 
    setDateRange(dateRange)
  }

  /* On date Selection Recalling the API  */
  useEffect(() => {
    getPurchaseOrders(true)
  }, [dateRange])

  const productStatus = [
    { name: 'Pending', count: pending, path: '/sellerLayout/purchaseOrder/Pending' },
    { name: 'Accepted', count: accepted, path: '/sellerLayout/purchaseOrder/Accepted' },
    { name: 'Invoiced', count: invoiced, path: '/sellerLayout/purchaseOrder/Invoiced' },
    { name: 'Drop Shipped', count: shipped, path: '/sellerLayout/purchaseOrder/DropShipped' },
    { name: 'Rejected', count: rejected, path: '/sellerLayout/purchaseOrder/Rejected' },
    { name: 'All', count: orders.length, path: '/sellerLayout/purchaseOrder/ALLOrders' }
  ]

  return (
    <div className="purchase_drop">
      <Spinner loading={loading} />

      <AppHeaderLayout
        title="Purchase Order :"
        to={
          role
            ? '/admin/seller/sellerLayout/ProductListing/searchProduct'
            : '/sellerLayout/productListing/searchProduct'
        }
      />
      <Grid item md={11} xs={12} className="bread-crumb-layout">
        <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>

      <form className="filter-wrapper" onSubmit={handleSubmit(dateRangeSelectionHandler)}>
        <div className="date-range">
        <h4>From</h4>
        <input type="date" className='date-range-picker'
        ref={register(dateRangeFilter.fromDate)}
        name="fromDate"/>
        </div>
        <div className="date-range">
        <h4>To</h4>
        <input type="date" className='date-range-picker'
         ref={register(dateRangeFilter.toDate)}
         name="toDate"/>
        </div>
        <ActionButton label='Search'/>
        <ActionButton varient='outlined' type="button" label='Clear' onClick={() => reset()}/>

      </form>
      <div className="listed-product-layout">
        <div className="product-header-btn" />
        <div className="Inventory-table-layout">
          <div className="Inventory-table-header" style={{ justifyContent: 'space-between' }}>
            <div className="listed-products-selector">
              {productStatus.map((status) => (
                <>
                  <NavLink key={status.name} className="products-selector" to={status.path}>
                    <p onClick={() => setLinkHeader(status?.name)}>
                      {status.name} ({status.count})
                    </p>
                  </NavLink>
                  <span>|</span>
                </>
              ))}
            </div>

            <div>
              {isVisible([LIST_OF_ROLES.EXPORT_ORDERS], { user: userPermission }) ? (
                <>
                  <button className="addproduct-btn-wrapper" onClick={() => handleDownload()}>
                    <FontAwesomeIcon className="excel-icon" icon={faFileExcel as IconProp} />
                    <span className="btn-text">Export All Orders</span>
                  </button>
                  <CSVLink
                    ref={inputEl}
                    data={exp_orders}
                    filename="purchase_orders.csv"
                    style={{ display: 'none' }}
                    asyncOnClick
                    onClick={(event, done) => {
                      done()
                    }}
                  />
                </>
              ) : null}
            </div>
          </div>

          <Route component={() => <PurchaseTable orders={orders} />} path="/sellerLayout/purchaseOrder/ALLOrders" />
          <Route
            component={() => (
              <PurchaseTable
                status
                orders={orders.filter((order) => order.AdditionalStatus === 'ACTI' || order.AdditionalStatus === '')}
              />
            )}
            path="/sellerLayout/purchaseOrder/Pending"
          />
          <Route
            component={() => (
              <PurchaseTable
                getPurchaseOrders={getPurchaseOrders}
                orders={orders.filter((order) => order.AdditionalStatus === 'PROC')}
              />
            )}
            path="/sellerLayout/purchaseOrder/Accepted"
          />
          <Route
            component={() => <PurchaseTable orders={orders.filter((order) => order.AdditionalStatus === 'INVO')} />}
            path="/sellerLayout/purchaseOrder/Invoiced"
          />
          <Route
            component={() => <PurchaseTable orders={orders.filter((order) => order.AdditionalStatus === 'SHIP')} />}
            path="/sellerLayout/purchaseOrder/DropShipped"
          />
          <Route
            component={() => (
              <PurchaseTable status orders={orders.filter((order) => order.AdditionalStatus === 'CANC')} />
            )}
            path="/sellerLayout/purchaseOrder/Rejected"
          />
        </div>
      </div>
    </div>
  )
}

export default PurchaseOrders
