export const PROFILE_INFORMATION = 'PROFILE_INFORMATION'
export const LEGAL_INFORMATIONS = 'LEGAL_INFORMATIONS'
export const TAX_AND_FINACIAL_INFORMATION = 'TAX_AND_FINACIAL_INFORMATION'
export const CONTACT_INFORMATION = 'CONTACT_INFORMATION'
export const BANK_AND_FINANCIAL = 'BANK_AND_FINANCIAL'
export const ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION'

export type EditAdditionalInformation = {
  type: typeof PROFILE_INFORMATION
  payload: {
    legalDetails?: {
      edit: boolean
    },
    bankAccountDetails?: {
      edit: boolean,
    }, 
    taxAndFinancialsDetails?: {
      edit: boolean
    }, 
    contactDetails?: {
      edit: boolean
    },
    additionalInformation?: {
      edit: boolean
    }
  }
}

export type ADDITIONALINFORMATIONTYPE = EditAdditionalInformation
