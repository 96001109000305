import React, { useEffect, useState, useRef } from 'react'
import './styles.scss'
import SearchIcon from '@material-ui/icons/Search'
import NestedMenuItem from 'material-ui-nested-menu-item'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, MenuItem, PopoverPosition } from '@mui/material'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { showNotification, STATUS } from '../../common/constant'
import { IErrorResponse, ISuccessResponse } from '../../services/interfaces/common.interface'
import ActionButton from '../ActionButton'
import { ProductService } from '../../services/productService'
import { IconProp } from '@fortawesome/fontawesome-svg-core'



type IBrandImg = {
  height: number
  shape: string
  url: string
  width: number
}
type IBrand = {
  Image: IBrandImg
  code: string
  name: string
}

const ProductSearchBlock: React.FC<any> = ({  resetSearchInputs, listProductsHandler,setProductSearchValue, setCategoryCode, setBrandValue, categoryCode , brandValue , productSearchValue }) => {
 
  const [menuPosition, setMenuPosition] = useState<PopoverPosition | undefined>()
  const [brandPosition, setBrandPosition] = useState<PopoverPosition | undefined>()
  const [categoryPosition, setCategoryPosition] = useState<PopoverPosition | undefined>()
  const [subCategory1Position, setSubCategory1Position] = useState<PopoverPosition | undefined>()
  const [subCategory2Position, setSubCategory2Position] = useState<PopoverPosition | undefined>()
  const [subCategory3Position, setSubCategory3Position] = useState<PopoverPosition | undefined>()
  const [categoryValue, setCategoryValue] = useState<string>()
  const [categories, setCategories] = useState<any>([])
  const searchRef = useRef<HTMLInputElement>(null)
  const [BrandList, setBrandList] = useState<IBrand[]>([])

  const [categoryCode1, setCategoryCode1] = useState<string>('')
  const [categoryCode2, setCategoryCode2] = useState<string>('')
  const [categoryCode3, setCategoryCode3] = useState<string>('')
  const [categoryCode4, setCategoryCode4] = useState<string>('')

  /* Services*/
  const productService: ProductService = new ProductService()

  const getPIMProductCategories = async () => {
    try {
      const Pimproducts: ISuccessResponse | IErrorResponse = await productService.getPIMProductsCategory(1)
      const getpimproducts = Pimproducts as ISuccessResponse
      setCategories(getpimproducts[0]?.categories)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Catergory informations')
    }
  }

  const getPIMBrandList = async () => {
    try {
      const PimBrands: ISuccessResponse | IErrorResponse = await productService.getPIMBrandList('1')
      const getpimbrands = PimBrands as ISuccessResponse
      setBrandList(getpimbrands.data)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  useEffect(()=>{
    getPIMProductCategories()
    getPIMBrandList()
  }, [])

  const handleItemClick = (categoryValue: string, categoryCode: string) => {
    setMenuPosition(undefined)
    setCategoryValue(categoryValue)
    setCategoryCode(categoryCode)
  }



  function Comment({ comment }) {
    const nestedComments = comment.children?.map((comment) => {
      return <Comment key={comment.id} comment={comment} />
    })

    return (
      <div>
        {comment.children?.length ? (
          <NestedMenuItem
            label={`${comment.name}`}
            parentMenuOpen={!!menuPosition}
            onClick={() => handleItemClick(`${comment.name}`, comment.code)}
          >
            {nestedComments}
            {comment.children?.map((sCate) => {
              if (sCate.children === 0) {
                return <MenuItem onClick={() => handleItemClick(`${sCate.code}`, sCate.code)}>{sCate.name}</MenuItem>
              }

              return null
            })}
          </NestedMenuItem>
        ) : (
          <MenuItem onClick={() => handleItemClick(`${comment.name}`, comment.code)}>{comment.name}</MenuItem>
        )}
      </div>
    )
  }

  /* Brand Select Handler*/
  function Brand({ comment }) {
    const nestedComments = comment.children?.map((comment) => {
      return <Comment key={comment.id} comment={comment} />
    })

    const handleBrandClick = (e) => {
      setBrandPosition(undefined)
      setBrandValue(e)
    }

    return (
      <div>
        {comment.children?.length ? (
          <NestedMenuItem
            label={`${comment.name}`}
            parentMenuOpen={!!brandPosition}
            onClick={() => handleBrandClick(comment?.name)}
          >
            {nestedComments}
            {comment.children?.map((sCate) => {
              if (sCate.children === 0) {
                return <MenuItem>{sCate.name}</MenuItem>
              }

              return null
            })}
          </NestedMenuItem>
        ) : (
          <MenuItem onClick={() => handleBrandClick(comment?.name)}>{comment.name}</MenuItem>
          // comment.children?.map((sCate) => {
          //     return <MenuItem onClick={() => handleItemClick(`${sCate.name}`)}>{sCate.name}</MenuItem>
          // })
        )}
      </div>
    )
  }

  const onClickBrand = (event: React.MouseEvent) => {
    if (brandPosition) {
      return
    }
    event.preventDefault()
    setBrandPosition({
      top: event.pageY,
      left: event.pageX
    })
  }

  const categoryClickHandler = (event: React.MouseEvent) => {
    if (categoryPosition) {
      return
    }
    event.preventDefault()

    setCategoryPosition({
      top: event.pageY,
      left: event.pageX
    })
  }

  const categorySelectionHandler = (category: any) => {
    setCategoryPosition(undefined)
    setCategoryValue(category.name)
    setCategoryCode1(category.name)
    setCategoryCode(category.code)
    setCategoryCode2('')
    setCategoryCode3('')
    setCategoryCode4('')
  }

  const subCategory1ClickHandler = (event: React.MouseEvent) => {
    if (subCategory1Position) {
      return
    }
    event.preventDefault()

    setSubCategory1Position({
      top: event.pageY,
      left: event.pageX
    })
  }

  const subCategory1SelectionHandler = (category: any) => {
    setSubCategory1Position(undefined)
    setCategoryCode(category.code)
    setCategoryCode2(category.name)
    setCategoryCode3('')
    setCategoryCode4('')
  }

  const subCategory2ClickHandler = (event: React.MouseEvent) => {
    if (subCategory2Position) {
      return
    }
    event.preventDefault()

    setSubCategory2Position({
      top: event.pageY,
      left: event.pageX
    })
  }

  const subCategory2SelectionHandler = (category: any) => {
    setSubCategory2Position(undefined)
    setCategoryCode(category.code)
    setCategoryCode3(category.name)
    setCategoryCode4('')
  }

  const subCategory3ClickHandler = (event: React.MouseEvent) => {
    if (subCategory3Position) {
      return
    }
    event.preventDefault()

    setSubCategory3Position({
      top: event.pageY,
      left: event.pageX
    })
  }

  const subCategory3SelectionHandler = (category: any) => {
    setSubCategory3Position(undefined)
    setCategoryCode(category.code)
    setCategoryCode4(category.name)
  }




  /* Reset the search conditions */
  const resetValues = (): void => {
    setBrandValue('')
    setCategoryValue('')
    setProductSearchValue('')
    setCategoryCode1('')
    setCategoryCode2('')
    setCategoryCode3('')
    setCategoryCode4('')
    resetSearchInputs()
    
  }



  



  const getCategory1 = () => {
    const categories1 = categories?.find((item) => item.name === categoryCode1)

    if (categories1?.children) {
      return categories1.children
    }

    return []
  }

  const getCategory2 = () => {
    const categories1 = categories?.find((item) => item.name === categoryCode1)

    const categories2 = categories1?.children?.find((item) => item.name === categoryCode2)

    if (categories2?.children) {
      return categories2.children
    }

    return []
  }

  const getCategory3 = () => {
    const categories1 = categories?.find((item) => item.name === categoryCode1)
    const categories2 = categories1?.children?.find((item) => item.name === categoryCode2)
    const categories3 = categories2?.children?.find((item) => item.name === categoryCode3)

    if (categories3?.children) {
      return categories3.children
    }

    return []
  }

  return (
      <div className="search-product-list">
        <p className="search-product-header"> Search for the Product</p>
        <div className="search-product-layout">
          <div className="search-box-grid">
              <div className="search-container">
                <p className="subheading-search">Search Product by name</p>
                <div className="search-input-field">
                  <SearchIcon className="search-ic" />
                  <input
                    ref={searchRef}
                    placeholder="Search by product name"
                    value={productSearchValue}
                    type="search"
                    onChange={(event) => {
                      setProductSearchValue(event.target.value)
                    }}
                  />
                </div>
              </div>
          </div>

          <div className="category-selection-box">
            <div className="custom-dropdown">
              <p>Category</p>
              <div className="dropdown-field" onClick={categoryClickHandler}>
                <p className="selected-item">{categoryValue ? categoryValue : 'Select'}</p>
                <FontAwesomeIcon className="downside-arrowmark" icon={faChevronDown as IconProp} />
              </div>
              <div>
                <div>
                  <Menu
                    anchorPosition={categoryPosition}
                    anchorReference="anchorPosition"
                    open={!!categoryPosition}
                    onClose={() => setCategoryPosition(undefined)}
                  >
                    {categories?.map((category: any) => (
                      <MenuItem key={category.name} onClick={() => categorySelectionHandler(category)}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
            </div>
            {categoryCode1 && getCategory1()?.length ? (
              <div className="custom-dropdown">
                <p>Sub Category 1</p>
                <div className="dropdown-field" onClick={subCategory1ClickHandler}>
                  <p className="selected-item">{categoryCode2 ? categoryCode2 : 'Select'}</p>
                  <FontAwesomeIcon className="downside-arrowmark" icon={faChevronDown as IconProp} />
                </div>
                <div>
                  <div>
                    <Menu
                      anchorPosition={subCategory1Position}
                      anchorReference="anchorPosition"
                      open={!!subCategory1Position}
                      onClose={() => setSubCategory1Position(undefined)}
                    >
                      {getCategory1()?.map((category: any) => (
                        <MenuItem key={category.name} onClick={() => subCategory1SelectionHandler(category)}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {categoryCode2 && getCategory2()?.length ? (
              <div className="custom-dropdown">
                <p>Sub Category 2</p>
                <div className="dropdown-field" onClick={subCategory2ClickHandler}>
                  <p className="selected-item">{categoryCode3 ? categoryCode3 : 'Select'}</p>
                  <FontAwesomeIcon className="downside-arrowmark" icon={faChevronDown as IconProp} />
                </div>
                <div>
                  <div>
                    <Menu
                      anchorPosition={subCategory2Position}
                      anchorReference="anchorPosition"
                      open={!!subCategory2Position}
                      onClose={() => setSubCategory2Position(undefined)}
                    >
                      {getCategory2()?.map((category: any) => (
                        <MenuItem key={category.name} onClick={() => subCategory2SelectionHandler(category)}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {categoryCode3 && getCategory3()?.length ? (
              <div className="custom-dropdown">
                <p>Sub Category 3</p>
                <div className="dropdown-field" onClick={subCategory3ClickHandler}>
                  <p className="selected-item">{categoryCode4 ? categoryCode4 : 'Select'}</p>
                  <FontAwesomeIcon className="downside-arrowmark" icon={faChevronDown as IconProp} />
                </div>
                <div>
                  <div>
                    <Menu
                      anchorPosition={subCategory3Position}
                      anchorReference="anchorPosition"
                      open={!!subCategory3Position}
                      onClose={() => setSubCategory3Position(undefined)}
                    >
                      {getCategory3()?.map((category: any) => (
                        <MenuItem key={category.name} onClick={() => subCategory3SelectionHandler(category)}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="brand-box">
            <div className="custom-dropdown">
              <p>Brand</p>
              <div className="dropdown-field" onClick={onClickBrand}>
                <p className="selected-item">{brandValue ? brandValue : 'Select'}</p>
                <FontAwesomeIcon className="downside-arrowmark" icon={faChevronDown as IconProp} />
              </div>
              <div>
                <div>
                  <Menu
                    anchorPosition={brandPosition}
                    anchorReference="anchorPosition"
                    open={!!brandPosition}
                    onClose={() => setBrandPosition(undefined)}
                  >
                    {BrandList?.map((value: IBrand) => {
                      return <Brand key={value.name} comment={value} />
                    })}
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <div className="button-wrapper-box">
            <div className= 'product-search-btn'>
              <ActionButton
                label='Search'
                onClick={() => listProductsHandler()}
              />
              {categoryCode || brandValue || productSearchValue ? (
                <ActionButton label="Clear" onClick={() => resetValues()} />
              ) : (
                <> </>
              )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default ProductSearchBlock
