import { Tooltip } from '@material-ui/core'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakInstance } from 'keycloak-js'


import { store } from '../../redux/store';
import { LOGIN } from '../../redux/actions/UserActionTypes';


const Logout: React.FC = () => {
  const history = useHistory()
  const { keycloak } = useKeycloak<KeycloakInstance>();


  const logoutHandler = () => {
    localStorage.clear()
    store.dispatch({
        type: LOGIN,
        payload: []
      })

    if(keycloak?.authenticated){
        keycloak.logout();
    }
    history.push('/login')


     
}

  return (
    <Tooltip title="logout">
      <Link to="login">
        <ExitToAppIcon className="icons-white" onClick={logoutHandler} />
        <button className="logout-btn" onClick={logoutHandler} >Logout</button>
      </Link>
    </Tooltip>
  )
}

export default Logout
