import React from 'react'

import './style.scss'


type TEmailVerificationFieldValidation = {
    isEmailVerified?: boolean,
    text :string,
    error?: boolean,
    errorText? : string,
    register?: any,
    name?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const InputWithEmailVerification: React.FC<TEmailVerificationFieldValidation> = ({register, name, text, error, errorText, isEmailVerified, ...props}) => {
    return (
        <div className="input-with-email-verification">
            <label className="txt-label">{text} <span className="label-star">*</span>  :</label>
            <div className="input-and-error">
            <input ref={register} defaultValue={props.defaultValue} disabled={isEmailVerified} name={name} type="text" value={props.value} {...props} className={error ? 'input-field-box-error' : 'input-field-box'} placeholder={text}/>
            <div className="verified-text">&#10003; Verified</div>
            { error ? <p className="error-text">* {errorText}</p> : '' }
            </div>
            
        </div>
    )
}

export default InputWithEmailVerification
