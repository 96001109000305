import { combineReducers } from 'redux'

import productReducer from './productReducer'
import userReducer from './userReducer'
import OnBoardingReducer from './onboardingReducer'
import SpinnerReducer from './spinner.reducer'
import profileReducer from './profile.reducer'
import InventoryReducer from './inventory.reducer'

const RootReducer = combineReducers({
    product: productReducer,
    userDetails : userReducer,
    onBoardingData: OnBoardingReducer,
    spinner: SpinnerReducer, 
    profile: profileReducer,
    inventory:InventoryReducer

});

export default RootReducer;