import React, { useEffect, useState } from 'react'
import './styles.scss'
// import { decrypt } from 'common/encryptor'

import InputLabelwithContent from 'components/InputLabelwithContent'
import LayoutHeader from 'components/LayoutHeader'
import { OnboardingService } from 'services/onBoarding.service'
import { STATUS } from 'common/constant'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import {
  ILegalInformations,
  IBillingAddress,
  IPrimaryContact,
  IBankingInformations,
  IDocumentIds
} from 'services/interfaces/onBoarding.interface'
import { decrypt } from 'common/encryptor'
import BankVerificationStatus from 'components/BankVerificationStatus'
import Spinner from 'components/Spinner'

const ProfileDetailsOverview: React.FC = () => {
  const onboardingService = new OnboardingService()
  const [LegalInformation, setLegalInformation] = useState<ILegalInformations | null>(null)
  const [BillingInformation, setBillingInformation] = useState<IBankingInformations | null>(null)
  const [BillAddress, setBillAddress] = useState<IBillingAddress | null>(null)
  const [primaryContact, setprimaryContact] = useState<IPrimaryContact | null>(null)
  const [documentIds, setDocumentIds] = useState<IDocumentIds | null>(null)
  const [loading, setLoading] = useState<boolean>(false);


  const getReviewData = async () => {
    try {
      const role: string | null = localStorage.getItem('role');
      const isAllowed: boolean =
      role === 'ROOT_ADMIN' ||
      role === 'PMPL_CATALOGUE_MANAGER' ||
      role === 'PMPL_OPERATIONS_MANAGER' ||
      role === 'PMPL_FINANCE_MANAGER'

    const sellerMail: string = localStorage.getItem('sellerMail') as string
    
    setLoading(true)
    const getPersonalInformations: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(5, isAllowed ? sellerMail : '')

    if (getPersonalInformations.status === STATUS.SUCCESS) {
      const successResponse = getPersonalInformations as ISuccessResponse
      setLegalInformation(successResponse.data.LegalInformation)
      successResponse.data.BillingInformation.account_number = decrypt(
        successResponse.data.BillingInformation.account_number
      )
      setBillingInformation(successResponse.data.BillingInformation)
      setBillAddress(successResponse.data.BillAddress)
      setprimaryContact(successResponse.data.primaryContact)
      setDocumentIds(successResponse.data.TaxationInfo.ids)
      setLoading(false)
    } else {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
  }
  }
  useEffect(() => {  

    getReviewData()
  }, [])

  return (
    <div className="profileOveriew-layout">
      <Spinner loading={loading}/>
      <div className="legal-layout-wrapper">
        <LayoutHeader title="Legal Details" to="/sellerLayout/profileDetials/LegalDetails" />

        <div className="legal-details-content">
          <InputLabelwithContent label="Account Name" content={LegalInformation?.Account_Name} />
          <InputLabelwithContent label="Account Code" content={LegalInformation?.Account_Code} />
          <InputLabelwithContent label="GST Number" content={documentIds?.GSTIN} />
          <InputLabelwithContent label="Legal Name" content={LegalInformation?.Legal_Name} />
          <InputLabelwithContent
            label="Registered Address"
            content={
              LegalInformation?.Address1 +
              ', ' +
              LegalInformation?.Address2 +
              ' , ' +
              LegalInformation?.city +
              ',' +
              LegalInformation?.state +
              ',' +
              LegalInformation?.pin +
              '.'
            }
          />
          <InputLabelwithContent
            label="Billing Address"
            content={
              BillAddress?.Address1 +
              ', ' +
              BillAddress?.Address2 +
              ' , ' +
              BillAddress?.city +
              ',' +
              BillAddress?.state +
              ',' +
              BillAddress?.pin +
              '.'
            }
          />
        </div>
      </div>
      <div className="legal-layout-wrapper">
        <LayoutHeader title="Bank Account" to="/sellerLayout/profileDetials/BankAccount" />
        <div className="legal-details-content">
          <BankVerificationStatus/>
          <InputLabelwithContent label="Bank Name" content={BillingInformation?.bank_name} />
          <InputLabelwithContent label="Account Number" content={BillingInformation?.account_number} />
          <InputLabelwithContent label="IFSC Code" content={BillingInformation?.IFSC} />
        </div>

        <div className="bank-tax-wrapper" />
        <LayoutHeader title="Tax & Financials" to="/sellerLayout/profileDetials/Tax&Financials" />

        <div className="legal-details-content">
          <InputLabelwithContent label="GST Number" content={documentIds?.GSTIN} />
          <InputLabelwithContent label="TAN Number" content={documentIds?.TAN} />
          <InputLabelwithContent label="PAN Number" content={documentIds?.PAN} />
        </div>
      </div>

      <div className="legal-layout-wrapper">
        <LayoutHeader title="Contacts" to="/sellerLayout/profileDetials/Contacts" />
        <div className="legal-details-content">
          <InputLabelwithContent
            label="Primary Contact"
            content={primaryContact?.name}
            number={primaryContact?.mobile}
          />
          <InputLabelwithContent
            label="Escalations"
            content={primaryContact?.escalation_name}
            number={primaryContact?.escalation_mobile}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileDetailsOverview
