import { Checkbox } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { FinancePermission, InventoryPermission, ProductListingPermission, PurchaseOrderPermission, SellerInformationPermissions, SellersPermission, showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import InputWithLabelsDown from 'components/InputWithLabelsDown'

import './styles.scss'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { RolesService } from 'services/roles.service'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import InformationCard from 'components/InformationCard'
import { RolesList } from 'services/interfaces/Roles.interface'



type RolePermission = {
    Role: string,

    LEAGAL_CREATE: boolean,
    LEAGAL_UPDATE: boolean,
    LEAGAL_VIEW: boolean,
    BANK_CREATE: boolean,
    BANK_UPDATE: boolean,
    BANK_VIEW: boolean,
    CONTACT_CREATE: boolean,
    CONTACT_UPDATE: boolean,
    CONTACT_VIEW: boolean,
    TAX_FINANCE_CREATE: boolean,
    TAX_FINANCE_UPDATE: boolean,
    TAX_FINANCE_VIEW: boolean,
    KYC_CREATE: boolean,
    KYC_UPDATE: boolean,
    KYC_VIEW: boolean,
    SELLER_SUMMARY: boolean,

    PRODUCT_VIEW: boolean,
    PRODUCT_ADD: boolean,
    PRODUCT_EDIT: boolean,
    PRODUCT_DELIST: boolean,
    PRODUCT_APPROVE_REJECT: boolean,
    PRODUCT_BULK_UPLOAD: boolean,

    WAREHOUSE_CREATE: boolean,
    WAREHOUSE_EDIT: boolean,
    WAREHOUSE_DELETE: boolean,
    WAREHOUSE_LIST: boolean,
    WAREHOUSE_ADD_PRODUCT: boolean,
    WAREHOUSE_VIEW_STOCK: boolean,
    WAREHOUSE_UPDATE_STOCK: boolean,
    EXPORT_INVENTORY: boolean,
    BULK_UPLOAD_STOCK: boolean,
    PURCHASE_ORDER_VIEW: boolean,
    PURCHASE_ORDER_SEARCH: boolean,
    PURCHASE_ORDER_UPDATE: boolean,
    DC_INWARD: boolean,
    EXPORT_ORDERS: boolean,

    FINANCE_SUMMARY: boolean,
    FINANCE_PAYMENT_LIST: boolean,
    FINANCE_DOWNLOAD_SETTLEMENT: boolean,
    FINANCE_SEARCH_ORDER: boolean,

    SELLER_LIST: boolean,
    SELECT_SELLER_LIST: boolean,

}
const RolePermission: React.FC = () => {
    const { register, handleSubmit, reset, setValue } = useForm<RolePermission>({ mode: 'all' })



    const [addRoles, setAddRoles] = React.useState<boolean>(false)

    const [roleList, setRolesList] = useState<RolesList[] | []>([]);
    const [SellerInformation, setSellerInformation] = useState<any>([]);
    const [productListing, setProductListing] = useState<any>([]);
    const [Inventory, setInventory] = useState<any>([]);
    const [purchaseOrder, setpurchaseOrder] = useState<any>([]);
    const [finance, setFinance] = useState<any>([]);
    const [sellers, setSellers] = useState<any>([]);
    const [activeRole, setActiveRole] = useState<any>()
    const [pendingCard, setPendingCard] = useState<boolean>(true)
    const [roleName, setRoleName] = useState<string>();


    const rolesService: RolesService = new RolesService()


    const onSubmit = async (formData: RolePermission) => {
        console.log(formData);


        if (!addRoles) {
            try {
                const updateValidation = {
                    Role: roleName,
                    Permission: {
                        Seller_Information: {
                            LEAGAL_CREATE: formData.LEAGAL_CREATE,
                            LEAGAL_UPDATE: formData.LEAGAL_UPDATE,
                            LEAGAL_VIEW: formData.LEAGAL_VIEW,
                            BANK_CREATE: formData.BANK_CREATE,
                            BANK_UPDATE: formData.BANK_UPDATE,
                            BANK_VIEW: formData.BANK_VIEW,
                            CONTACT_CREATE: formData.CONTACT_CREATE,
                            CONTACT_UPDATE: formData.CONTACT_UPDATE,
                            CONTACT_VIEW: formData.CONTACT_VIEW,
                            TAX_FINANCE_CREATE: formData.TAX_FINANCE_CREATE,
                            TAX_FINANCE_UPDATE: formData.TAX_FINANCE_UPDATE,
                            TAX_FINANCE_VIEW: formData.TAX_FINANCE_VIEW,
                            KYC_CREATE: formData.KYC_CREATE,
                            KYC_UPDATE: formData.KYC_UPDATE,
                            KYC_VIEW: formData.KYC_VIEW,
                            SELLER_SUMMARY: formData.SELLER_SUMMARY,
                        },
                        Product_Listing: {
                            PRODUCT_VIEW: formData.PRODUCT_VIEW,
                            PRODUCT_ADD: formData.PRODUCT_ADD,
                            PRODUCT_EDIT: formData.PRODUCT_EDIT,
                            PRODUCT_DELIST: formData.PRODUCT_DELIST,
                            PRODUCT_APPROVE_REJECT: formData.PRODUCT_APPROVE_REJECT,
                            PRODUCT_BULK_UPLOAD: formData.PRODUCT_BULK_UPLOAD,
                        },
                        Inventory: {
                            WAREHOUSE_CREATE: formData.WAREHOUSE_CREATE,
                            WAREHOUSE_EDIT: formData.WAREHOUSE_EDIT,
                            WAREHOUSE_DELETE: formData.WAREHOUSE_DELETE,
                            WAREHOUSE_LIST: formData.WAREHOUSE_LIST,
                            WAREHOUSE_ADD_PRODUCT: formData.WAREHOUSE_ADD_PRODUCT,
                            WAREHOUSE_VIEW_STOCK: formData.WAREHOUSE_VIEW_STOCK,
                            WAREHOUSE_UPDATE_STOCK: formData.WAREHOUSE_UPDATE_STOCK,
                            EXPORT_INVENTORY: formData.EXPORT_INVENTORY,
                            BULK_UPLOAD_STOCK: formData.BULK_UPLOAD_STOCK,
                        },
                        Purchase_Order: {
                            PURCHASE_ORDER_VIEW: formData.PURCHASE_ORDER_VIEW,
                            PURCHASE_ORDER_SEARCH: formData.PURCHASE_ORDER_SEARCH,
                            PURCHASE_ORDER_UPDATE: formData.PURCHASE_ORDER_UPDATE,
                            DC_INWARD: formData.DC_INWARD,
                            EXPORT_ORDERS: formData.EXPORT_ORDERS,
                        },
                        Finance: {
                            FINANCE_SUMMARY: formData.FINANCE_SUMMARY,
                            FINANCE_PAYMENT_LIST: formData.FINANCE_PAYMENT_LIST,
                            FINANCE_DOWNLOAD_SETTLEMENT: formData.FINANCE_DOWNLOAD_SETTLEMENT,
                            FINANCE_SEARCH_ORDER: formData.FINANCE_SEARCH_ORDER,
                        },
                        Sellers: {
                            SELLER_LIST: formData.SELLER_LIST,
                            SELECT_SELLER_LIST: formData.SELECT_SELLER_LIST,
                        },

                    },
                }
                const addRoles: ISuccessResponse | IErrorResponse = await rolesService.updateRoles(updateValidation)
                showNotification(STATUS.SUCCESS, addRoles.message)

                getRolesList();
                setPendingCard(true)
                setAddRoles(false)
                setActiveRole("")

            } catch (error) {
                showNotification(STATUS.FAILURE, 'Unable to update Role')

            }
        } else if (addRoles) {
            try {
                const rolesValidation = {
                    Role: formData.Role,
                    Permission: {
                        Seller_Information: {
                            LEAGAL_CREATE: formData.LEAGAL_CREATE,
                            LEAGAL_UPDATE: formData.LEAGAL_UPDATE,
                            LEAGAL_VIEW: formData.LEAGAL_VIEW,
                            BANK_CREATE: formData.BANK_CREATE,
                            BANK_UPDATE: formData.BANK_UPDATE,
                            BANK_VIEW: formData.BANK_VIEW,
                            CONTACT_CREATE: formData.CONTACT_CREATE,
                            CONTACT_UPDATE: formData.CONTACT_UPDATE,
                            CONTACT_VIEW: formData.CONTACT_VIEW,
                            TAX_FINANCE_CREATE: formData.TAX_FINANCE_CREATE,
                            TAX_FINANCE_UPDATE: formData.TAX_FINANCE_UPDATE,
                            TAX_FINANCE_VIEW: formData.TAX_FINANCE_VIEW,
                            KYC_CREATE: formData.KYC_CREATE,
                            KYC_UPDATE: formData.KYC_UPDATE,
                            KYC_VIEW: formData.KYC_VIEW,
                            SELLER_SUMMARY: formData.SELLER_SUMMARY,
                        },
                        Product_Listing: {
                            PRODUCT_VIEW: formData.PRODUCT_VIEW,
                            PRODUCT_ADD: formData.PRODUCT_ADD,
                            PRODUCT_EDIT: formData.PRODUCT_EDIT,
                            PRODUCT_DELIST: formData.PRODUCT_DELIST,
                            PRODUCT_APPROVE_REJECT: formData.PRODUCT_APPROVE_REJECT,
                            PRODUCT_BULK_UPLOAD: formData.PRODUCT_BULK_UPLOAD,
                        },
                        Inventory: {
                            WAREHOUSE_CREATE: formData.WAREHOUSE_CREATE,
                            WAREHOUSE_EDIT: formData.WAREHOUSE_EDIT,
                            WAREHOUSE_DELETE: formData.WAREHOUSE_DELETE,
                            WAREHOUSE_LIST: formData.WAREHOUSE_LIST,
                            WAREHOUSE_ADD_PRODUCT: formData.WAREHOUSE_ADD_PRODUCT,
                            WAREHOUSE_VIEW_STOCK: formData.WAREHOUSE_VIEW_STOCK,
                            WAREHOUSE_UPDATE_STOCK: formData.WAREHOUSE_UPDATE_STOCK,
                            EXPORT_INVENTORY: formData.EXPORT_INVENTORY,
                            BULK_UPLOAD_STOCK: formData.BULK_UPLOAD_STOCK,

                        },
                        Purchase_Order: {
                            PURCHASE_ORDER_VIEW: formData.PURCHASE_ORDER_VIEW,
                            PURCHASE_ORDER_SEARCH: formData.PURCHASE_ORDER_SEARCH,
                            PURCHASE_ORDER_UPDATE: formData.PURCHASE_ORDER_UPDATE,
                            DC_INWARD: formData.DC_INWARD,
                            EXPORT_ORDERS: formData.EXPORT_ORDERS,
                        },
                        Finance: {
                            FINANCE_SUMMARY: formData.FINANCE_SUMMARY,
                            FINANCE_PAYMENT_LIST: formData.FINANCE_PAYMENT_LIST,
                            FINANCE_DOWNLOAD_SETTLEMENT: formData.FINANCE_DOWNLOAD_SETTLEMENT,
                            FINANCE_SEARCH_ORDER: formData.FINANCE_SEARCH_ORDER,
                        },
                        Sellers: {
                            SELLER_LIST: formData.SELLER_LIST,
                            SELECT_SELLER_LIST: formData.SELECT_SELLER_LIST,
                        },

                    },
                }

                const addRoles: ISuccessResponse | IErrorResponse = await rolesService.addRoles(rolesValidation)
                showNotification(STATUS.SUCCESS, addRoles.message)
                setPendingCard(true)
                setAddRoles(false)
                getRolesList();
                reset()

            } catch (error) {
                showNotification(STATUS.FAILURE, 'Unable to get Product informations')

            }
        }


    }

    const getRolesList = async () => {
        const getRoles: ISuccessResponse | IErrorResponse = await rolesService.getRolesList() as ISuccessResponse
        setRolesList(getRoles.data)

    }

    const getRolesPermission = async (data: string) => {
        setRoleName(data)
        setPendingCard(false)
        setSellerInformation([])
        setProductListing([])
        setInventory([])
        setpurchaseOrder([])
        setFinance([])
        setSellers([])
        setActiveRole(data)

        try {
            const getPermission: ISuccessResponse | IErrorResponse = await rolesService.getRolesPermission(data) as ISuccessResponse
            const permissionData = getPermission.data[0]
            setAddRoles(false);
            setValue("Role", data)
            setSellerInformation(permissionData?.Permission?.Seller_Information)
            setProductListing(permissionData?.Permission?.Product_Listing)
            setInventory(permissionData?.Permission?.Inventory)
            setpurchaseOrder(permissionData?.Permission?.Purchase_Order)
            setFinance(permissionData?.Permission?.Finance)
            setSellers(permissionData?.Permission?.Sellers)
        } catch {
            showNotification(STATUS.FAILURE, 'Unable to get Permission informations')

        }


    }

    const addRolesHandleer = async () => {
        setSellerInformation([])
        setProductListing([])
        setInventory([])
        setpurchaseOrder([])
        setFinance([])
        setSellers([])
        setAddRoles(true);
        setValue("Role", "")
        setPendingCard(false)
        setActiveRole("")
    }

    useEffect(() => {
        getRolesList();
    }, [])

    return (
        <div className="roles-and-permission-page">

            <div className="left-wrapper">

                <div className="save-btn-wrap">
                    <ActionButton label="+ Add Roles" onClick={() => addRolesHandleer()} />
                </div>

                {roleList ?
                    <>
                        {
                            roleList.map((item) => (
                                <div key={item.Role} className={activeRole === item.Role ? "roles-list-wrapper bgactive" : "roles-list-wrapper"} onClick={() => { getRolesPermission(item.Role); }}>

                                    <p className="roles-name">{item.Role}</p>
                                    <ChevronRightIcon />
                                </div>

                            ))
                        }
                    </>
                    : <> </>}

            </div>
            {!pendingCard ?
                <>

                    {addRoles ?
                        <div className="right-wrapper">
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="right-content">
                                    <p className="add-role-header">Add Role</p>

                                    <div className="save-btn-wrap ">
                                        <ActionButton label="Save" type="submit" />

                                    </div>

                                    <div className="name-role-input">
                                        <InputWithLabelsDown
                                            register={register({
                                                required: 'Please enter Role'
                                            })}
                                            name="Role"
                                            text="Role"

                                        />

                                    </div>
                                    <p className="permisssion-txt-wrap ">Permissions</p>
                                    <div className="roles-content-wrap">
                                        <p className="roles-header">Seller Information</p>
                                        {SellerInformationPermissions.map((item) => (
                                            <div key={item.name} className="roles-select-wrap">
                                                <p>{item.name}</p>
                                                <Checkbox key={item.value} name={item.value} inputRef={register} />
                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Product Listing</p>
                                        {ProductListingPermission.map((item) => (
                                            <div key={item.name} className="roles-select-wrap">
                                                <p>{item.name}</p>
                                                <Checkbox key={item.value} name={item.value} inputRef={register} />




                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Inventory</p>
                                        {InventoryPermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>
                                                <Checkbox key={item.value} name={item.value} inputRef={register} />

                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Purchase order</p>
                                        {PurchaseOrderPermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>
                                                <Checkbox key={item.value} name={item.value} inputRef={register} />
                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Finance</p>
                                        {FinancePermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>
                                                <Checkbox key={item.value} name={item.value} inputRef={register} />

                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Sellers</p>
                                        {SellersPermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>
                                                <Checkbox key={item.value} name={item.value} inputRef={register} />



                                            </div>
                                        ))

                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        : <></>}

                    {!addRoles ?
                        <div className="right-wrapper editRole-custom-checkbox">
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="right-content">
                                    <p className="add-role-header">Edit Role</p>
                                    <div className="save-btn-wrap">
                                        <ActionButton label="Save" type="submit" />
                                    </div>

                                    <div className="name-role-input">
                                        <InputWithLabelsDown
                                            register={register({
                                                required: 'Please enter Role'
                                            })}
                                            name="Role"
                                            text="Role"
                                            disabled
                                        />

                                    </div>
                                    <p className="permisssion-txt-wrap ">Permissions</p>
                                    <div className="roles-content-wrap">
                                        <p className="roles-header">Seller Information</p>
                                        {SellerInformationPermissions.map((item) => (

                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>
                                                <input name={item.value} key={item.value} type="checkbox" ref={register} defaultChecked={SellerInformation[item.value]} />
                                                <span className="checkmark" />
                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Product Listing</p>
                                        {ProductListingPermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>

                                                <input name={item.value} key={item.value} type="checkbox" ref={register} defaultChecked={productListing[item.value]} />

                                                <span className="checkmark" />
                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Inventory</p>
                                        {InventoryPermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>


                                                <input name={item.value} key={item.value} type="checkbox" ref={register} defaultChecked={Inventory[item.value]} />
                                                <span className="checkmark" />
                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Purchase order</p>
                                        {PurchaseOrderPermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>
                                                <input name={item.value} key={item.value} type="checkbox" ref={register} defaultChecked={purchaseOrder[item.value]} />

                                                <span className="checkmark" />
                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Finance</p>
                                        {FinancePermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>

                                                <input name={item.value} key={item.value} type="checkbox" ref={register} defaultChecked={finance[item.value]} />
                                                <span className="checkmark" />
                                            </div>
                                        ))

                                        }
                                        <p className="roles-header"> Sellers</p>
                                        {SellersPermission.map((item) => (
                                            <div className="roles-select-wrap" key={item.value}>
                                                <p>{item.name}</p>
                                                <input name={item.value} key={item.value} type="checkbox" ref={register} defaultChecked={sellers[item.value]} />
                                                <span className="checkmark" />

                                            </div>
                                        ))

                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        : <></>}
                </>
                :
                <InformationCard message="Please select role to view their permissions" />
            }



        </div>
    )
}

export default RolePermission
