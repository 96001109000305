import React from 'react'

import './styles.scss'

type TCodeInputFieldProps = {
  text: string
  error?: boolean
  errorText?: string
  register?: any
  name?: string
  isModeEdit: boolean
  codeSaved: (code: string) => void
} & React.InputHTMLAttributes<HTMLInputElement>

const CodeInput: React.FC<TCodeInputFieldProps> = ({
  register,
  name,
  text,
  error,
  errorText,
  codeSaved,
  isModeEdit,
  ...props
}) => {

  return (
    <div className="seller-unique-code-input">
      <label className="txt-label">{text} :</label>
      <div className="input-and-error">
        <div className="input-and-button">
          <input
            ref={register}
            disabled
            name={name}
            type="text"
            value={props.value}
            onChange={(event) => codeSaved(event.target.value)}
            {...props}
            className={error ? 'input-field-box-error' : 'input-field-box'}
            placeholder={text}
          />
        </div>
        <p className="hint-text">(This is unique code for each sellers, can't be modified later)</p>
        {error ? <p className="error-text">* {errorText}</p> : ''}
      </div>
    </div>
  )
}

export default CodeInput
