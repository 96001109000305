import React, { useEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination';
import moment from "moment"

import './styles.scss'
import { AuditLogService } from 'services/auditLogs.service';
import { IAuditLogItem, IAuditLogRequest } from 'services/interfaces/auditLogs.interface';
import { showNotification, STATUS } from 'common/constant';
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface';

const ProductListingHistory: React.FC = () => {
    const auditLogsService = new AuditLogService();
    const [logs, setLogs]  = useState<IAuditLogItem[] | []>([]);

    const getLogs = async (): Promise<void> => {
        try {
        const auditRequest: IAuditLogRequest = {
            seller_code: localStorage.getItem("seller_code") as string,
            email: localStorage.getItem("userMail") as string,
            module_name: "Product Listing",
        };

        const auditLogResponse: ISuccessResponse | IErrorResponse = await auditLogsService.getLogs(auditRequest);

        if(auditLogResponse.status === STATUS.SUCCESS) {
            const { data } = auditLogResponse as ISuccessResponse;
            setLogs(data);
        } else {
            showNotification(STATUS.FAILURE, 'Unable to get auditlogs');
        }
    } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get auditlogs');
    }
        

    };

    useEffect(()=> {
        getLogs();
    },[])



    return (
        <div className="product-listing-history-page">
            <h1 className="history-heading">Product Listing History</h1>
            <p className="captions">Captions: NA - Not Applicable</p>
            <table className="history-table">
            <thead>
            <tr>
            <th>Date</th>
            <th>Activity Type</th>
            <th>Description</th>
          
            <th>Updated by</th>
            </tr>
            </thead>
            <tbody>
                {logs.map((log: IAuditLogItem) => {
                   return <tr key={log._id}>
                    <td>{moment(log.row_added_dttm).format("DD-MM-YYYY hh:mm A")}</td>
                    <td>{log.activity === 'Insert' ? 'New' : 'Update'}</td>
                    <td>{log.description}</td>
                    <td>{log.row_added_by}</td>
                </tr>
                })}
            </tbody>
            </table>
            <div className='pagination-zone'>
            <Pagination count={1} variant="outlined" shape="rounded" />
            </div>
            
        </div>
    )
}

export default ProductListingHistory
