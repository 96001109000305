import { DISABLE_LOADING, ENABLE_LOADING, LOADING_DISPATCH_TYPE } from "../actions/SpinnerTypes";

type ISpinner = {
loading: boolean
}
const spinnerDefaultState: ISpinner = {
    loading: false
};

const SpinnerReducer = (state: ISpinner = spinnerDefaultState, action: LOADING_DISPATCH_TYPE): ISpinner => {

    switch(action.type) {
        case ENABLE_LOADING: 
        return {
            ...state,
            loading: action.payload
        }
        case DISABLE_LOADING: 
        return {
            ...state,
            loading: action.payload
        }
        default:
            return state
    }
}


export default SpinnerReducer