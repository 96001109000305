import React, { useEffect, useState } from 'react'

import './styles.scss'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import LayoutHeader from '../../../components/LayoutHeader'
import { isVisible, LIST_OF_ROLES, showNotification, STATUS } from '../../../common/constant'
import { InventoryService } from '../../../services/inventory.service'
import ActionButton from '../../../components/ActionButton'
import Verification from '../../../assets/verification.png'
import { RootStore } from '../../../redux/store'
import { IErrorResponse, ISuccessResponse } from '../../../services/interfaces/common.interface';
import { editLocation } from 'redux/actions/inventory.action';



type ActiveLocationsprops = {

    onStatus: (data) => void;

}

const ActiveLocations: React.FC<ActiveLocationsprops> = ({ onStatus }) => {

    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
    const dispatch = useDispatch()

    /*State Variables */
    const [warehouselist, setWarehouselist] = useState<any>([])
    const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false)
    const [deletedcode, setDeletedCode] = React.useState<string>()

    /* Services */
    const inventoryService: InventoryService = new InventoryService()

    /*Get Active Warehouse List Handler */
    const getWareHouse = async () => {
        try {

            const data = localStorage.getItem('seller_code')
            const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getwarehouselist(data, 1)

            setWarehouselist(getWareHouseResponse.data)
            onStatus(getWareHouseResponse.data ? getWareHouseResponse.data.length + ' ' + "Active" : 0 + ' ' + "Active")

        } catch (error) {
            showNotification(STATUS.FAILURE, ' Unable to get users list')
        }
    }

    const deletedialog = (deletedcode) => {
        setDeletedCode(deletedcode)
        setDeleteDialog(true)
    }
    
    useEffect(()=>{
        dispatch(editLocation(false))
    },[])

    const deletewarehouse = async () => {
        setDeleteDialog(false)

        try {
            const deleteWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.deletewarehouse(deletedcode)
            showNotification(STATUS.SUCCESS, deleteWareHouseResponse.data)
        } catch (error) {
            showNotification(STATUS.FAILURE, ' Unable to delete warehouse')
        }

        getWareHouse()
    }

    useEffect(() => {
        getWareHouse()
    }, [])

    
    const handleOnEdit = () => {

        dispatch(editLocation(true))
    }

    return (
        <div className="Activelocations-page">
            {warehouselist ?
                <div>
                    {
                        warehouselist.map((warehouseData) => {
                            return (
                                <div className="location-layout-wrapper" key={warehouseData.warehouse_code}>
                                    <LayoutHeader title={warehouseData.warehouse_name + ' ( ' + warehouseData.warehouse_code + ')'} edit={isVisible(["WAREHOUSE_EDIT"],{user: userPermission} )}  remove={isVisible(["WAREHOUSE_DELETE"],{user: userPermission} )} to="/sellerLayout/inventory/AddLocations"  state={warehouseData} onClick={() => deletedialog(warehouseData.warehouse_code)} editOnClick={handleOnEdit}/>
                                    <div className="activelocation-details-card">
                                        <div className="activelocation-details-content">
                                            <ImageOutlinedIcon className="warehouse-image-ic" />
                                            <div className="registered-address-content">
                                                <p className="warehouse-details-label">Registered Address</p>
                                                <p className="warehouse-details-content">{warehouseData.address1 + ',' + warehouseData.address2 + ','
                                                    + warehouseData.city + ',' + warehouseData.state + ',' + warehouseData.pincode}</p>
                                            </div>
                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Primary Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_mobile}</p>

                                            </div>

                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Escalations Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_mobile}</p>

                                            </div>

                                        </div>
                                        {isVisible([LIST_OF_ROLES.WAREHOUSE_ADD_PRODUCT],{user:userPermission} ) ? 
                                        <NavLink className="see-listed-product" to={{ pathname:"/sellerLayout/inventory/AddInventoryProducts", state: warehouseData }}>
                                            <div style={{display:'flex', justifyContent:'end'}}>
                                               <ActionButton label="+ Add products to Warehouse" type="button" />

                                            </div>

                                        </NavLink> : <></>}
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                :
                <div className="location-layout-wrapper no-warehouse">
                    <div className="No-Active-card-wrapper">
                        <img src={Verification} alt="verification" className="verification-image" />

                        No Active Locations Found

                    </div>
                </div>
            }


            <Dialog open={deleteDialog} className="warehouse-delete-dialog">
                <DialogTitle className="delete-title" id="alert-dialog-title">
                    Are You Sure want Delete this Warehouse
                </DialogTitle>

                <DialogActions style={{ margin: '0 auto' }}>
                    <ActionButton label="Cancel" varient="outlined" onClick={() => setDeleteDialog(false)} />

                    <ActionButton label="Delete" onClick={() => deletewarehouse()} />
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default ActiveLocations