import React, { useEffect, useState } from 'react'
import { Slider } from '@material-ui/core'
import { faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Pagination } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'

import { InventoryService } from '../../../../services/inventory.service'
import { showNotification, STATUS } from '../../../../common/constant'
import { IErrorResponse, ISuccessResponse } from '../../../../services/interfaces/common.interface'
import ActionButton from '../../../../components/ActionButton'
import { IProductInventory } from '../../../../services/interfaces/inventory.interface'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300
  },
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ff632b',
    '&~&': {
      background: '#ff632b'
    }
  },
  mark: {
    background: 'black'
  },
  rail: {
    background: 'grey'
  },
  track: {
    background: '#ff632b !important'
  },
  valueLabel: {
    '&>*': {
      background: 'black'
    }
  }
}))

const ALLInventoryTable: React.FC<{ isUpdated: boolean; inventory: IProductInventory[] }> = ({
  isUpdated,
  inventory
}) => {
  const classes = useStyles()

  const [productStatus, setProductStatus] = useState<[IProductInventory] | []>()
  const [pageCount, setPageCount] = useState<number>()
  const inventoryService: InventoryService = new InventoryService()
  const [sortingType, setSortingType] = useState<string>('ASC')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [filterSku, setFilterSku] = useState<any>({})
  const [filterBrand, setfilterBrand] = useState<any>({})
  const [filterCategory, setfilterCategory] = useState<any>({})
  const [filterErpCode, setFilterErpCode] = useState<any>({})
  const [filterWarName, setFilterWarName] = useState<any>({})
  const [filterPsn, setFilterPsn] = useState<any>({})
  const [filterSelCode, setFilterSelCode] = useState<any>({})
  const [filterStock, setFilterStock] = useState<number[]>([0, 35000])
  const [maxStock, setMaxStock] = useState<number>(0)
  const [menu_text, setMenuText] = useState<string>('1')

  const multipleProductFilter = async (data, orderbyvalues?, sortType?: string) => {
    try {
      setProductStatus([])
      const Pimproducts: ISuccessResponse | IErrorResponse = await inventoryService.multipleSearchProduct(
        'all',
        1,
        data,
        orderbyvalues,
        sortType
      )
      const getpimproducts = Pimproducts as ISuccessResponse

      setProductStatus(getpimproducts.data)
      setPageCount(getpimproducts.pagecount)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const getInventorylist = async (pageNo) => {
    try {
      const getInventorylistResponse = await inventoryService.getInventorylist('all', pageNo)
      setProductStatus(getInventorylistResponse.data)
      setPageCount(getInventorylistResponse.pagecount)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  useEffect(() => {
    const maxStock =
      inventory?.length > 0 ? Math.max(...inventory.map((item: IProductInventory) => item.current_stock)) : 0
    setMaxStock(maxStock)
    setFilterStock([0, maxStock])
  }, [inventory])

  /* Pagination Handler */
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    getInventorylist(value)
  }

  /* To sort the table data based on column */
  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    const data = {
      erp_item_code: filterErpCode?.erp_item_code ?? '',
      sku: filterSku?.sku ?? '',
      psn: filterPsn?.psn ?? '',
      ext_prod_id: filterSelCode?.ext_prod_id ?? '',
      warehouse_name: filterWarName?.warehouse_name ?? '',
      minStock: filterStock[0],
      maxStock: filterStock[1]
    }
    multipleProductFilter(data, columnName, sortType)
    setSortColumn(columnName)
    setSortingType(sortingType === 'ASC' ? 'DESC' : 'ASC')
  }

  const handleProductFilter = () => {
    const data = {
      erp_item_code: filterErpCode?.erp_item_code ?? '',
      sku: filterSku?.sku ?? '',
      psn: filterPsn?.psn ?? '',
      brand: filterBrand?.brand ?? '',
      category: filterCategory?.category ?? '',
      ext_prod_id: filterSelCode?.ext_prod_id ?? '',
      warehouse_name: filterWarName?.warehouse_name ?? '',
      minStock: filterStock[0],
      maxStock: filterStock[1]
    }
    multipleProductFilter(data)
  }

  useEffect(() => {
    getInventorylist(1)
  }, [isUpdated])

  return (
    <div className="Inventory-table-body">
      <div className="filter-head">
        <div className="filter-item">
          <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={inventory || []}
            value={filterSku}
            getOptionLabel={(option: IProductInventory) => option.sku || ''}
            onChange={(e, val) => setFilterSku(val)}
            style={{ width: 200 }}
            // classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="SKU" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={inventory || []}
            value={filterErpCode}
            getOptionLabel={(option: IProductInventory) => option.erp_item_code || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterErpCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Poorvika Code" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={inventory || []}
            value={filterSelCode}
            getOptionLabel={(option: IProductInventory) => option.ext_prod_id || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterSelCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Seller Code" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={inventory || []}
            value={filterPsn}
            getOptionLabel={(option: IProductInventory) => option.psn || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterPsn(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="PSN" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={
              inventory?.filter(
                (v: IProductInventory, i, a) =>
                  a.findIndex((t: IProductInventory) => t.warehouse_name === v.warehouse_name) === i
              ) || []
            }
            value={filterWarName}
            getOptionLabel={(option: IProductInventory) => option.warehouse_name || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterWarName(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Warehouse" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            value={filterBrand}
            options={
              inventory?.filter(
                (v: IProductInventory, i, a) => a.findIndex((t: IProductInventory) => t.brand === v.brand) === i
              ) || []
            }
            getOptionLabel={(option: IProductInventory) => option.brand || ''}
            style={{ width: 160 }}
            onChange={(value) => setfilterBrand(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Brand" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={
              inventory?.filter(
                (v: IProductInventory, i, a) => a.findIndex((t: IProductInventory) => t.category === v.category) === i
              ) || []
            }
            value={filterCategory}
            getOptionLabel={(option: IProductInventory) => option.category || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setfilterCategory(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Catagory" variant="outlined" />}
          />
        </div>
        <div className="filter-item" style={{ width: '250px' }}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Stock</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={menu_text}
              style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              label="Stock"
              onClose={() => setMenuText('1')}
            >
              <MenuItem value="" style={{ display: 'flex', flexDirection: 'column' }}>
                <Slider
                  getAriaLabel={() => 'Stock range'}
                  value={filterStock}
                  min={0}
                  max={maxStock}
                  valueLabelDisplay="off"
                  onChange={(event, value: any) => setFilterStock(value)}
                  track="inverted"
                  classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel
                  }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    size="small"
                    disabled
                    value={filterStock[0]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingRight: '10px' }}
                  >
                    {' '}
                  </TextField>
                  to{' '}
                  <TextField
                    size="small"
                    disabled
                    value={filterStock[1]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingLeft: '10px' }}
                  >
                    {' '}
                  </TextField>
                </div>
              </MenuItem>
              <MenuItem
                value="1"
                style={{ display: 'none' }}
              >{`Stock Range : ${filterStock[0]} - ${filterStock[1]}`}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="filter-item">
          <ActionButton
            onClick={() => {
              handleProductFilter()
            }}
            label="Apply"
          />
          <ActionButton
            label="Clear"
            onClick={() => {
              getInventorylist(1)
              setFilterSku(null)
              setFilterPsn(null)
              setfilterCategory(null)
              setfilterBrand(null)
              setFilterErpCode(null)
              setFilterWarName(null)
              setFilterSelCode(null)
              setFilterStock([0, maxStock])
            }}
          />
        </div>
      </div>
      <table className="inventory-manaagement-table">
        <thead>
          <tr>
            <th>
              Product
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'name' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('name', sortingType)}
              />
            </th>
            <th>
              Category
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'category' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('category', sortingType)}
              />
            </th>
            <th>
              Brand
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'brand' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('brand', sortingType)}
              />
            </th>
            <th>
              Global SKU
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'sku' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('sku', sortingType)}
              />
            </th>
            <th>
              Poorvika Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'erp_item_code' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('erp_item_code', sortingType)}
              />
            </th>
            <th>
              Seller Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'ext_prod_id' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('ext_prod_id', sortingType)}
              />
            </th>
            <th>
              PSN
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'psn' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('psn', sortingType)}
              />
            </th>
            <th>
              Stock in Hand
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'qty_on_hand' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('qty_on_hand', sortingType)}
              />
            </th>

            <th>
              Warehouse
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'warehouse_name' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('warehouse_name', sortingType)}
              />
            </th>
            <th>
              {' '}
              Status
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'listing_status' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('listing_status', sortingType)}
              />
            </th>
          </tr>
        </thead>
        {productStatus ? (
          <tbody>
            {productStatus.map((item) => {
              return (
                <tr key={item.erp_item_code}>
                  <td>
                    <div className="product-col">
                      <div className="pimg-wrap">
                        <img className="product-img" alt="product-img" src={item.image} />
                      </div>
                      <div className="pcontent-wrap">{item.name}</div>
                    </div>
                  </td>
                  <td>{item.category}</td>
                  <td>{item.brand}</td>
                  <td>{item.sku}</td>
                  <td>{item.erp_item_code}</td>
                  <td>{item.ext_prod_id}</td>
                  <td>{item.psn}</td>

                  <td>{item.current_stock}</td>

                  <td>{item.warehouse_name}</td>

                  {/* <td className="add-stock-remove-action">
                                        <AddStockDialog data={1} warehouseData={item} index={0} label="Update stock" isAdd cancelClose={() => getInventorylist(1)} />


                                    </td> */}
                  <td>
                    <p
                      className={
                        item.listing_status === 'Active'
                          ? 'seller-account-status-active'
                          : 'seller-account-status-in-active'
                      }
                    >
                      {item.listing_status}
                    </p>
                  </td>
                  <td> </td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="empty-data" colSpan={12}>
                No data to display
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="all-products-pagination">
        <Pagination
          className="all-products-pagination"
          count={pageCount}
          shape="rounded"
          variant="outlined"
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
export default ALLInventoryTable
