import React, { useEffect } from 'react'

import './styles.scss'
import { useForm } from 'react-hook-form'
import {useHistory } from 'react-router-dom'
import queryString from 'query-string';

import loginPageIllustration from 'assets/images/Delivery-address-amico.svg'
import PoorvikaLogo from 'assets/images/poorvika-Logo.svg'
import { showNotification, STATUS } from 'common/constant'
import { LoginService } from 'services/login.service'
import { IResetPassword } from 'services/interfaces/login.interface'
import { encrypt } from 'common/encryptor'
import { ISuccessResponse, IErrorResponse } from 'services/interfaces/common.interface';

const queryParams = queryString.parse(window.location.search);

type TResetPassword= {
password: string;
confirmPassword: string;
}


const ResetPasswordPage: React.FC = () => {

  const { register, handleSubmit, errors, watch } = useForm<TResetPassword>({ mode: 'all' });
  const history = useHistory();
  const loginService: LoginService = new LoginService();

  

  const formValidations = {
    password: {
        required: { value: true, message: "* Password field can't be Empty" },
        minLength: { value: 6, message: '* Password must be at least 6 characters long' }
      },
      confirmPassword: {
        required: { value: true, message: "* Confirm password field can't be Empty" },
        validate: (value: string) => value === watch('password') || "Passwords don't match."
      },
  }


  const onSubmit = async (formData: TResetPassword) => {
    try {

      const resetDataInformation: IResetPassword = {
        token: queryParams.token as string,
        password: encrypt(formData.password)
      }

      const data: ISuccessResponse | IErrorResponse = await loginService.resetPassword(resetDataInformation);

      if(data.status === STATUS.SUCCESS) {
        showNotification(STATUS.SUCCESS, data.message);
        history.push("/login")
      } else {
        history.push("/forgotPassword");
        showNotification("failure", data.message);
      }

    } catch (error) {
      showNotification(STATUS.FAILURE, (error as Error)?.message);
    }
  }

  useEffect(()=> {
    if(!queryParams?.token) {
      showNotification('failure', "Password reset Link is invalid, Kindly submit new request");
      history.push("/forgotPassword");
    }
  })



  return (

    <div className="login-page-wrapper">
      <div className="login-page">
        <div className="left-content">
          <div className="left-content-align">
            <img className="poorvika-svg-logo" draggable="false" src={PoorvikaLogo} />
            <h3 className="seller-portal-name-wrapper">SELLER PORTAL</h3>
            <img alt="login illustration" className="login-illustration" draggable="false" src={loginPageIllustration} />
          </div>

        </div>
        <div className="right-content">
          <div>
            <h3 className="sign-in-name-wrapper">Reset Password</h3>
            <p className="seller-account-name">(Kindly change your password to login to the system)</p>
            <form className="loginForm-container" autoComplete="new-password" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group email-ip">
                <input
                  id="password"
                  ref={register(formValidations.password)}
                  className='login-input-field-box reset-input-field'
                  placeholder="Password"
                  type="password"
                  name="password"
                />
                <label htmlFor="password" className="label-helper">Password</label>

                {errors.password ? <div className="error-text">{errors.password.message}</div> : ''}
              </div>
              <div className="form-group email-ip">
                <input
                  id="confirmPassword"
                  ref={register(formValidations.confirmPassword)}
                  className='login-input-field-box reset-input-field'
                  placeholder="Confirm Password"
                  type="password"
                  name="confirmPassword"
                />
                {/* <label htmlFor="confirmPassword" className="label-helper">Confirm Password</label> */}

                {errors.confirmPassword ? <div className="error-text">{errors.confirmPassword.message}</div> : ''}
              </div>
              <button className="login-btn" type="submit">
                Reset Password
              </button>
            </form>
          </div>
        </div>

      </div>

    </div>
  )
}

export default ResetPasswordPage