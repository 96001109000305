import { TextField, TextFieldProps } from '@material-ui/core'
import React from 'react'

import './InputField.scss'

const InputField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return (
    <TextField {...props} autoComplete="false" className="common-input" size="small" style={{ margin: '10px 0' }} variant="outlined" fullWidth />
  )
}

export default InputField
