import React ,{useState, useEffect}from 'react'

import './styles.scss'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { OnPurchaseOrderService } from 'services/purchaseOrder.service'
type Props = {
   
  data: any,
  OrderQty?: number,
  setAccepted: (arg1:boolean) => void,

  
}

const Getstock: React.FC<Props> = ({data, setAccepted, OrderQty}) => {
  const [purchaseOrderStock, setPurchaseOrderStock] = useState<number>()
  const getPurchaseOrderList = new OnPurchaseOrderService()
  useEffect(() => {
    
    const getReviewData = async () => {
     
      const PurchaseorderGetStock: ISuccessResponse | IErrorResponse = await getPurchaseOrderList.getPurchaseOrderGetStock(data)
              const getdata = PurchaseorderGetStock as ISuccessResponse;

              const stock = getdata.total_stock || 0;
              const qty =  OrderQty || 0

              if(stock <= qty){
                setAccepted(false)
              }


            setPurchaseOrderStock(stock);
            
             
    }

    getReviewData();

}, [])
 
  

    return (
            

     <div className="Getstock">{purchaseOrderStock}</div> 
        
    
      )
}

export default Getstock
