import React from 'react'

import './styles.scss'
import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import { faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  FormControl,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import AppHeaderLayout from 'components/AppHeaderLayout'
import { UserService } from 'services/users.service';
import { showNotification, STATUS } from 'common/constant';
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface';
import { IUser } from 'services/interfaces/pmplusers.interface';
import Pagination from '@material-ui/lab/Pagination';
import ActionButton from 'components/ActionButton';
import InputWithLabels from 'components/InputWithLabels'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300
  },
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ff632b',
    '&~&': {
      background: '#ff632b'
    }
  },
  mark: {
    background: 'black'
  },
  rail: {
    background: 'grey'
  },
  track: {
    background: '#ff632b !important'
  },
  valueLabel: {
    '&>*': {
      background: 'black'
    }
  }
}))
const UserManagement: React.FC = () => {
    const userService = new UserService();
  const classes = useStyles()

  const { register, handleSubmit } = useForm({ mode: 'all' })

    const [accounts, setAccounts] = useState<IUser[] | []>([]);
    const [totalPages, setTotalPages] = useState(1);
    // const [accountsCount, setAccountsCount] = useState(1);
    const [loader, setLoader] = useState(false)
    const [activeColor, setActiveColor] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [emailText, setEmailText] = useState("");
    const [mobileText, setMobileText] = useState("");
    const [accountText, setAccountText] = useState("");
    const [roleText, setRoleText] = useState("");
    const [pagecount, setpageCount] = useState(10);
    const [pagevalue, setpageValue] = useState(1);
  const [filterName, setFilterName] = useState<any>({})
  const [filterEmail, setFilterEmail] = useState<any>({})
  const [filterRole, setFilterRole] = useState<any>({})
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortingType, setSortingType] = useState<string>('1')
  const [sortDialog, setsortDialog] = React.useState<boolean>(false)
  const history = useHistory();


      const getAccountsList = async (pageCount?: number, page?: number, searchQuery?: string, emailText?: string, mobileText?: string, accountText?: string, roleText?: string, isactive?: boolean, orderBy?: string, sort?: any) => {
          setLoader(true)

          try {
         

            const accountListResponse: ISuccessResponse | IErrorResponse = await userService.getPmplUsersList(pageCount, page, searchQuery, emailText, mobileText, accountText, roleText, isactive, orderBy, sort);
            
            if (isactive) {
            
              setActiveColor(true)
              setTotalPages(1)
      
            } else {
              setActiveColor(false)
              
            }
            setLoader(false)
      
      
            if (accountListResponse.status === STATUS.SUCCESS) {
              const accountList = accountListResponse as ISuccessResponse;
              setAccounts(accountList.data.user);
              setTotalPages(accountList.data.totalPages);
              console.log(accountList.data)
      
            }
          } catch (error) {
            setLoader(false)
      
            showNotification(STATUS.FAILURE, "Unable to fetch the account list")
          }
        }

        const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
          // getAccountsList(10, value, "", true, "", "", searchText);
          getAccountsList(10, value, searchText, emailText, mobileText, accountText, roleText, true)
          setpageCount(10)
          setpageValue(value)
      }

        console.log("pagevalue, pagecount")
    
        console.log(pagevalue, pagecount)

  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    // multipleProductFilter('', columnName, sortType)
    console.log(columnName, sortType)

    setSortColumn(columnName)
    setSortingType(sortingType === '1' ? '-1' : '1')
    getAccountsList(0, 0, "", "", "", "", "",false, columnName, sortType )

  }


  const handleProductFilter = () => {
    const data = {

      name: filterName ? filterName.firstname : '',
      email: filterEmail ? filterEmail.email : '',
      role: filterRole ? filterRole.role : '',
    }
    console.log("activeColor",activeColor)
    getAccountsList(0, 0, data.name, data.email, "", "", data.role, activeColor)

  }

        const usersearchHandler = async (data) => {
          console.log(data)
            setSearchText(data.name)
            // console.log("search text",text)
            setEmailText(data.email)
            setMobileText(data.mobile)
            setAccountText("")
            
            setRoleText(data.role)
            // // console.log("email text",emailText)

            if (!data.name.length && !data.email.length && !data.mobile.length && !data.role) {
            console.log("empty text")

              getAccountsList(0, 1, "", "", "", "", "")
            } else{
            console.log("email text",data.email)

              getAccountsList(0, 0, data.name, encodeURIComponent(data.email), data.mobile, "", data.role, activeColor)
            }
          }

          // console.log("accounts test data", accounts)
          useEffect(() => {
            getAccountsList(10, 1, "", "", "", "", "")
      //  getAccountsList(10, 1, "", true);
        }, [])

    return (
      
        <div className="location-inventory-page">
            <AppHeaderLayout title="User Management" />
            <div className="inventory-management-page">

                <button className="export-button-wrapper" onClick={() => history.push('/sellerLayout/admin/AddUser')}>Add User</button>
                <div className="Inventory-table-layout">
                <div className="filter-head ">
                <div className="filter-item">
          <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />

        </div>
        <div className="filter-item">
          <FormControl fullWidth>
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: any) => option.firstname || ''}
              id="combo-box-demo"
              options={accounts || []}
              renderInput={(params) => <TextField {...params} label="Name" size="small" variant="outlined" />}
              style={{ width: 230 }}
              value={filterName}
              onChange={(e, value: any) => setFilterName(value)}
            />
          </FormControl>
        </div>
        <div className="filter-item">
          <FormControl fullWidth>
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: any) => option.email || ''}
              id="combo-box-demo"
              options={accounts || []}
              renderInput={(params) => <TextField {...params} label="Email" size="small" variant="outlined" />}
              style={{ width: 230 }}
              value={filterEmail}
              onChange={(e, value: any) => setFilterEmail(value)}
            />
          </FormControl>
        </div>
        <div className="filter-item">
          <FormControl fullWidth>
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: any) => option.role || ''}
              id="combo-box-demo"
              options={[{role: "PMPL_CATALOGUE_MANAGER"}, {role: "PMPL_FINANCE_MANAGER"}, {role: "PMPL_OPERATIONSS_MANAGER"}] || []}
              renderInput={(params) => <TextField {...params} label="Role" size="small" variant="outlined" />}
              style={{ width: 230 }}
              value={filterRole}
              onChange={(e, value: any) => setFilterRole(value)}
            />
          </FormControl>
        </div>
        <div className="filter-item">
          <ActionButton
            label="Apply"
            onClick={() => {
              handleProductFilter()
            }}
          />
          <ActionButton
            label="Clear"
            onClick={() => {
              getAccountsList(0, 0, "", "", "", "", "")
              setFilterName({})
              setFilterEmail({})
              setFilterRole({})             
            }}
          />
        </div>
                  </div>

                    <table className="inventory-products-table">
                        <thead>
                            <tr>
                                {/* <th>
                                    <input type="checkbox">
                                    </input>
                                </th> */}
                                <th>Name
                                <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'firstname' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('firstname', sortingType)}
              /> <br />
                                </th>
                                <th>Email
                                <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'email' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('email', sortingType)}
              /><br />
                                </th>
                                <th>Mobile 
                                <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'mobile' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('mobile', sortingType)}
              /><br />
                                </th>
                    
                                <th>Role 
                                <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'role' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('role', sortingType)}
              /><br />
                            {/* <input type="text" className="products-search" placeholder="Search" onChange={(event) => usersearchHandler(searchText, emailText, mobileText, accountText, event.target.value)}></input> */}
                                </th>
                                {/* <th>Action</th> */}
                {/* <th>
                  {' '}
                  <FilterListIcon className="searchfilter-ic" onClick={() => setsortDialog(true)} />
                </th>     */}
                            </tr>
                        </thead>
                        {accounts ? (
                        <tbody>
          {loader ?
              <tr>
                {/* <td className="empty-data" colSpan={12}>
                  <img src={loaderGif} className="loaderGif"></img>

                </td> */}

              </tr> :
              <> </>} 
              {accounts.map((item) => {
          // {accounts.map((userData: IUser) => {
            return (<tr key={item.email}>
                {/* <td> <input type="checkbox">
                                    </input></td> */}
           <td>{item.name}</td>
           <td>{item.email}</td>
           <td>{item.mobile}</td>
           {/* <td>{userData.account_code}</td> */}
           {/* <td>{userData.role.split('_').join(' ')}</td> */}
           <td><p>{item.role}</p> </td>
           {/* <td className="map-locations-column">
                        <ActionButton
                          label="View"
                          onClick={() => {
                            verifyWizardHandler(userData.email)
                          }}
                        />
                      </td> */}
           {/* <td className="map-locations-column"> <ActionButton label="View" onClick={() => verifyWizardHandler(userData.email)}/></td> */}
          </tr>
          )
        })}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td className="empty-data" colSpan={12}>
            No data to display
          </td>
        </tr>
      </tbody>
    )}

                    </table>
                    {/* <div className="all-products-pagination">
          <Pagination
            className="all-products-pagination"
            count={totalPages}
            shape="rounded"
            variant="outlined"
            // onChange={(pageCount, page, isActive) => paginationHandler(pageCount, page, isActive)}
          />
        </div> */}
                   <div className="all-products-pagination">
                <Pagination className="all-products-pagination" count={totalPages} variant="outlined" shape="rounded" onChange={(event, page) => handleChange(event, page)} />

            </div>  
                    {/* <div className="pagination-wrapper">
          <Pagination totalCount={accountsCount} totalPages={totalPages} onPageChange={(pageCount, page, isActive) => paginationHandler(pageCount, page, isActive)} />
      </div> */}
                </div>


            </div>
            <Dialog
        onBackdropClick={() => setsortDialog(false)}
        open={sortDialog}
        className="search-filter-products-dialog searchproduct-sort-dialog filtercard"
      >
        <form onSubmit={handleSubmit(async (data) => usersearchHandler(data))}>
          <div className="search-sort-dialog">
            <InputWithLabels name="name" register={register} text="Name"  notrequired />
            <InputWithLabels name="email" register={register} text="Email" notrequired />

            <InputWithLabels name="mobile" register={register} text="Mobile" notrequired />
            <InputWithLabels name="role" register={register} text="Role" notrequired />
          </div>

          <DialogActions style={{ margin: '0 auto' }}>
            <ActionButton label="Cancel" type="button" varient="outlined" onClick={() => setsortDialog(false)} />

            <ActionButton label="Search" type="submit" />
          </DialogActions>
        </form>
      </Dialog>
        </div>
    )
}

export default UserManagement
