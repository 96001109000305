import React from 'react'

import './styles.scss'
import loginPageIllustration from 'assets/images/Delivery-address-amico.svg'
import PoorvikaLogo from 'assets/images/poorvika-Logo.svg'
import { useForm } from 'react-hook-form'
import { showNotification, STATUS } from 'common/constant'
import { Link, useHistory } from 'react-router-dom'
import { LoginService } from 'services/login.service'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'


const formValidations = {
  email: {
    required: { value: true, message: '* Email field is empty' },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  }
}

type ForgotPasswordDetails = {
  email: string
}

const ForgotPasswordPage: React.FC = () => {

  const { register, handleSubmit, errors } = useForm<ForgotPasswordDetails>({ mode: 'all' });
  const history = useHistory();
  const loginService: LoginService = new LoginService();


  const onSubmit = async (data: ForgotPasswordDetails) => {
    try {
      const forgotPasswordResponse: ISuccessResponse | IErrorResponse = await loginService.forgotPassword(data.email);

      if (forgotPasswordResponse.status === STATUS.SUCCESS) {
        showNotification('success', forgotPasswordResponse.message)
        history.push('/login')
      } else {
        showNotification('failure', forgotPasswordResponse.message)
      }

    } catch (error) {
      showNotification(STATUS.FAILURE, (error as Error)?.message);
    }
  }



  return (

    <div className="login-page-wrapper">
      <div className="login-page">
        <div className="left-content">
          <div className="left-content-align">
            <img className="poorvika-svg-logo" draggable="false" src={PoorvikaLogo} />
            <h3 className="seller-portal-name-wrapper">SELLER PORTAL</h3>
            <img alt="login illustration" className="login-illustration" draggable="false" src={loginPageIllustration} />
          </div>

        </div>
        <div className="right-content">
          <div>
            <h3 className="sign-in-name-wrapper">Forgot Password</h3>
            <p className="seller-account-name">(Password reset link will be sent to registered email)</p>
            <form className="loginForm-container" autoComplete="new-password" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group email-ip">
                <input
                  id="email_id"
                  ref={register(formValidations.email)}
                  className='login-input-field-box'
                  placeholder="Login ID or Email"
                  name="email"
                />
                <label htmlFor="email_id" className="label-helper">Login ID or Email </label>

                {errors.email ? <div className="error-text">{errors.email.message}</div> : ''}
              </div>
              <div className="forgot-password-area">

                <Link className="forgot-password" to="/login">
                  Remembered Password ? Login
                </Link>
              </div>
              <button className="login-btn" type="submit" >
                Get Reset Link
              </button>
            </form>
          </div>
        </div>

      </div>

    </div>
  )
}

export default ForgotPasswordPage