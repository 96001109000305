import React, { useEffect, useState } from 'react'
import GoogleMapReact, { ClickEventValue } from 'google-map-react'
import Geocode from 'react-geocode'
import { IWarehouseDetails } from 'services/interfaces/inventory.interface'
import { showNotification, STATUS } from 'common/constant'
import './styles.scss'

type TMapcontainerProps = {
  data?: any
  onLocationSelected: (locationDetails: any) => void,
  lat?: number
  lng?: number
}

const Marker = (lat: any, lng: any) => {
  return (
    <>
      <div className="pin" {...lat} />
      <div className="pulse" {...lng} />
    </>
  )
}

const MapsContainer: React.FC<TMapcontainerProps> = ({  lat, lng,  onLocationSelected }) => {
  const [postion, setPostion] = useState({
    lat: 20.5937,
    lng: 78.9629
  })
  const [zoom, setZoom] = useState(3.5)

console.log(lat);


  useEffect(() => {
    // console.log("Map Position", postion)
    const latlng = [postion.lat, postion.lng].join()

    // console.log(latlng)
    if (postion.lat > 8 && postion.lat < 38 && postion.lng > 68 && postion.lng < 98) {
      Geocode.setApiKey('AIzaSyD-6hZF4mxTyZXc-AqgJzkaxyGyfwCFBM0')
      Geocode.fromLatLng(postion.lat, postion.lng).then(
        (response) => {
          const plusCode = response.plus_code

          const address = response.results[0].address_components
          const lengthaddress = address.length

          if (lengthaddress <= 1) {
            showNotification(STATUS.FAILURE, 'Cannot fetch location')
          } else if (address[0].long_name) {
            const addressData: IWarehouseDetails = {
              plusCode,              latlng,
              city: address[lengthaddress - 4] === null ? '' : address[lengthaddress - 4].long_name,
              state: address[lengthaddress - 3] === null ? '' : address[lengthaddress - 3].long_name,
              pincode: address[lengthaddress - 1] === null ? '' : address[lengthaddress - 1].long_name
            }
            // console.log(addressData)
            onLocationSelected(addressData)
          } else {
            showNotification(STATUS.FAILURE, 'Cannot fetch location')
          }
        },
        (error) => {
          console.error(error)
        }
      )
    } else {
      showNotification(STATUS.FAILURE, 'Please Select inside INDIA')
    }
  }, [postion])

 

  useEffect(()=>{
    setPostion({
      lat: lat ? lat : 20.5937,
      lng: lng ? lng : 78.9629
    })

    if(lat)
     setZoom(10)
  },[lat, lng])

  return (
    <div className="google-map-container-size">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAAbzqszQe_UbUoQQ-50DF1o6g5PnL6pDY', language: 'EN' }}
        defaultCenter={postion}
        defaultZoom={zoom}
        zoom={zoom}
        center={postion}
        options={{
          fullscreenControl: false,
        }
        }

        onClick={(data: ClickEventValue) => setPostion({ lat: data.lat, lng: data.lng })}
      >
        <Marker lat={postion.lat} lng={postion.lng} />
      </GoogleMapReact>
    </div>
  )
}

export default MapsContainer
