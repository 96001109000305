import React from 'react'

import './styles.scss';

type TInputFieldProps = {
    text: string,
    error?: boolean,
    errorText?: string,
    register?: any,
    name?: string
    hint?: string
    notrequired?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>




const InputWithLabels: React.FC<TInputFieldProps> = ({ register, name, text, hint, error, errorText, notrequired, ...props }) => {

    return (
        <div className="input-with-labels">
            {notrequired ?
                <label className="txt-label">{text} :</label>

                : <label className="txt-label">{text}  <span className="label-star">*</span>  :</label>

            }
            
            <div className="input-and-error">
                <input ref={register} autoComplete="new-password" className={error ? 'input-field-box-error' : 'input-field-box'} name={name} placeholder={text}  {...props} />
                {error ? <p className="error-text">* {errorText}</p> : ''}
                {hint ? <p className="hint-text">{hint}</p> : ''}
            </div>

        </div>
    )
}

export default InputWithLabels
