import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControl, InputLabel, Select } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';

import InputField from 'components/InputField/InputField'
import './SignUpForm.scss'
import { IAccountDetails } from 'services/interfaces/login.interface'
import slugify from 'common/slugify'
import { showNotification, STATUS } from 'common/constant'
import { LoginService } from 'services/login.service'
import { encrypt } from 'common/encryptor'
import LinkButton from 'components/LinkButton';
import ActionButton from 'components/ActionButton';
import { IAPIResponse, IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface';
import { SettingService } from 'services/settings.service';

type signupData = {

  type: string
  name: string
  code: string
  email: string
  password: string
  confirmPassword: string
}

const SignUpForm: React.FC = () => {
  const { register, handleSubmit, errors, watch, } = useForm<signupData>({ mode: 'onChange' })
  const history = useHistory()

  /* State Variables */
  const [checkedTnC, setCheckedTnC] = useState<boolean>(false)
  const [codeValue, setCodeValue] = useState("");
  const [type, setType] = useState("WHOLESALER");
  const [viewTnC, setViewTnc] = useState<boolean>(false);
  const [termsAndConditionsDocument, setTermsAndConditionsDocument] = useState<string>("");

  /* Services */
  const loginService: LoginService = new LoginService()
  const settingService: SettingService = new SettingService()

  const getTermsAndConditions = async (): Promise<void> => {
    try {
      let  tncData: IAPIResponse = await settingService.getSettings("TERMS_AND_CONDITIONS");

      if(tncData.status === STATUS.SUCCESS) {
        setTermsAndConditionsDocument(tncData?.data[0]?.url);
      };  
    } catch (error) {
      showNotification(STATUS.FAILURE, (error as Error)?.message);
    }
    
  }





  const typeHandler = (event: any) => {
    setType(event.target.value)
    // const dropdownData = event.target.value
    // console.log(dropdownData)
  }

  const signupFormValidations = {
    name: {
      required: { value: true, message: "* Full Name can't be Empty" }
    },
    type: {
      required: { value: true, message: "* Account Type can't be Empty" }
    },
    code: {
      required: { value: true, message: "* Account code can't be Empty" }
    },
    email: {
      required: { value: true, message: "* Email field can't be Empty" },
      pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
    },
    password: {
      required: { value: true, message: "* Password field can't be Empty" },
      minLength: { value: 6, message: '* Password must be at least 6 characters long' }
    },
    confirmPassword: {
      required: { value: true, message: "* Confirm password field can't be Empty" },
      validate: (value: string) => value === watch('password') || "Passwords don't match."
    }
  }

  const onSubmit = async (data: IAccountDetails) => {
    data.type = type;
    // console.log(type)
    data.code = codeValue;
    // console.log('the registration data', data)
    data.password = encrypt(data.password);

    if (!checkedTnC) {
      showNotification(STATUS.FAILURE, 'Please agree the Terms & Conditions')
    } else {
      try {
        const addAccount: ISuccessResponse | IErrorResponse = await loginService.addSeller(data)

        if (addAccount.status === STATUS.SUCCESS) {
          history.push('/emailActivation')
          showNotification(STATUS.SUCCESS, 'Seller Registered Successfully')
        } else {
          showNotification(STATUS.FAILURE, 'Email ID Already Exists')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, (error as Error)?.message);
      }

    }
  }

  /* Unique code validation  */
  const codeValidationHandler = async (code: string) => {

    if (!code) return

    try {
      const checkAccount: ISuccessResponse | IErrorResponse = await loginService.checkCode(code);

      if (checkAccount.status === STATUS.SUCCESS) {
        setCodeValue(slugify(code))
      } else {
        codeValidationHandler(code + " " + Math.random().toString(36).substring(2, 7));
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, (error as Error)?.message);
    }

  }

  useEffect(() => {
    getTermsAndConditions();
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='company_name_hide'>
      <InputField
        error={!!errors.name}
        helperText={errors.name ? errors.name.message : ''}
        inputRef={register(signupFormValidations.name)}
        label="Company Name"
        name="name"
        onChange={(e) => codeValidationHandler(slugify(e.target.value))}
      /></div>
      <div className="account-code-hide">
        <InputField
          error={!!errors.code}
          inputRef={register(signupFormValidations.code)}
          label="code"
          name="code"
          value={codeValue}
          disabled
        />
      </div>

      <div className="type-dropdown">
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>

          <Select
            native
            value={type}
            onChange={typeHandler}
            label="Age"
            inputProps={{
              name: 'age',
              id: 'outlined-age-native-simple',
            }}
          >

            <option value="MANUFACTURE">Manufacture</option>
            <option value="WHOLESALER">Wholesaler</option>
           
          </Select>



        </FormControl>
      </div>

      <InputField
        error={!!errors.email}
        helperText={errors.email ? errors.email.message : ''}
        inputRef={register(signupFormValidations.email)}
        label="Email"
        name="email"
      />
      <InputField
        error={!!errors.password}
        helperText={errors.password ? errors.password.message : ''}
        inputRef={register(signupFormValidations.password)}
        label="Password"
        name="password"
        type="password"
      />
      <InputField
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
        inputRef={register(signupFormValidations.confirmPassword)}
        label="Confirm password"
        name="confirmPassword"
        type="password"
      />
      <div className="terms-and-conditions">
        <Checkbox checked={checkedTnC} onChange={(event) => setCheckedTnC(event.target.checked)} /> I have read and
        agree the <LinkButton text="Terms & Conditions" onClick={() => setViewTnc(true)}/>
      </div>
      <Button className="btn-signup" color="primary" type="submit" variant="contained" fullWidth>
        Register
      </Button>

      <p className="registration-seller">
        Already have seller account ? <Link to="/login">Login</Link>
      </p>

      <Dialog open={viewTnC}>
      <div className="view-terms-and-conditions">
         {/* <iframe src="https://firebasestorage.googleapis.com/v0/b/poorvika-7599c.appspot.com/o/tnc.pdf?alt=media&token=6aa481f6-6b24-4e84-9981-c421074281ea" title='terms' className='pdf-viewer-wrapper'/> */}
         <embed
    src={`${termsAndConditionsDocument}#toolbar=0`}
    type="application/pdf"
    className='pdf-viewer-wrapper'
  />
     </div>
     <div className="flex-center">
     <ActionButton label='Close' onClick={() => setViewTnc(false)}/>
     </div>
     
      </Dialog>
    </form>
  )
}

export default SignUpForm
