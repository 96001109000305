import { ADDITIONALINFORMATIONTYPE, PROFILE_INFORMATION } from '../actions/profile.action'

type IProfileDetails = {
  legalDetails: {
    edit: boolean
  },
  bankAccountDetails: {
    edit: boolean,
  }, 
  taxAndFinancialsDetails: {
    edit: boolean
  }, 
  contactDetails: {
    edit: boolean
  },
  additionalInformation: {
    edit: boolean
  }
}

const ProfileDetailsDeafultState: IProfileDetails = {
  legalDetails: {
    edit: false
  },
  bankAccountDetails: {
    edit: false,
  }, 
  taxAndFinancialsDetails: {
    edit: false
  }, 
  contactDetails: {
    edit: false
  },
  additionalInformation: {
    edit: false
  }
}

const profileReducer = (state: IProfileDetails = ProfileDetailsDeafultState, action: ADDITIONALINFORMATIONTYPE) => {
  switch (action.type) {
    case PROFILE_INFORMATION:
      return { ...state, ...action.payload }

    default:
      return ProfileDetailsDeafultState
  }
}

export default profileReducer
