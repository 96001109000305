import { AxiosError } from "axios";

import { API_ENDPOINTS, parseAxiosError } from "common/constant";

import { BaseService } from "./base.service";
import { ISuccessResponse, IErrorResponse } from "./interfaces/common.interface";

export class NotificationService extends BaseService {



    public async getNotifications(status?: string, notificationType?: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const filter = notificationType ? [notificationType] : []
            const {data} = await this.httpClient.post(API_ENDPOINTS.GET_NOTIFICATION+`?status=${status ? status : 'all'}`,{filter});
            
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
            
        }

    }


    public async updateStatus(notification_id: string, seller_email?: string): Promise<ISuccessResponse | IErrorResponse> { 

        try {
            const {data} = await this.httpClient.put(API_ENDPOINTS.READ_NOTIFICATION_STATUS ,{},{ params: {notification_id, seller_email}});
           
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }



}