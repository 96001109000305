import React, { useMemo, useState } from 'react'
import { faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, TextField, FormControl, Pagination } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import ActionButton from 'components/ActionButton'
import PurchaseOrderList from 'components/PurchaseOrderList'
import { IPurchaseOrder } from 'services/interfaces/purchaseorder.interface'

import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ALLPurchaseprops = {
  orders: IPurchaseOrder[],
  getPurchaseOrders? : () => void,
  status? : boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300
  },
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ff632b',
    '&~&': {
      background: '#ff632b'
    }
  },
  mark: {
    background: 'black'
  },
  rail: {
    background: 'grey'
  },
  track: {
    background: '#ff632b !important'
  },
  valueLabel: {
    '&>*': {
      background: 'black'
    }
  }
}))

const ALLpurchaseListTable: React.FC<ALLPurchaseprops> = ({ orders, getPurchaseOrders,status }) => {
  const classes = useStyles()
  const [purchaseList, setPurchaseList] = useState<IPurchaseOrder[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [total_pages, setTotalPages] = useState<number>(0)
  const [filterRefNo, setFilterRefNo] = useState<any>({})
  const [sortingType, setSortingType] = useState<string>('0')
  const [sortColumn, setSortColumn] = useState<string>('')

  const dynamicSort = (col, sort_order) => {
    return function (a, b) {
      const result = a[col] < b[col] ? -1 : a[col] > b[col] ? 1 : 0

      return result * sort_order
    }
  }

  const dynamicSortLength = (sort_order) => {
    return function (a, b) {
      const result = a.Items[0].OrderQty < b.Items[0].OrderQty ? -1 : a.Items[0].OrderQty > b.Items[0].OrderQty ? 1 : 0

      return result * sort_order
    }
  }

  const dynamicSortName = (sort_order) => {
    return function (a, b) {
      const result = a.Items[0].ItemName < b.Items[0].ItemName ? -1 : a.Items[0].ItemName > b.Items[0].ItemName ? 1 : 0

      return result * sort_order
    }
  }

  const sortingFunctionalityHandler = (columnName: string, sortingType: string) => {
    let purchase_list
    setSortColumn(columnName)
    setSortingType(sortingType === '1' ? '-1' : '1')

    if (columnName === 'ref') purchase_list = purchaseList.sort(dynamicSort('OrderNumb', sortingType))

    if (columnName === 'amt') purchase_list = purchaseList.sort(dynamicSort('OrderAmount', sortingType))
    
    if (columnName === 'date') purchase_list = purchaseList.sort(dynamicSort('RefBillDate', sortingType))

    if (columnName === 'qty') purchase_list = purchaseList.sort(dynamicSortLength(sortingType))

    if (columnName === 'name') purchase_list = purchaseList.sort(dynamicSortName(sortingType))

    purchase_list = purchase_list.slice(0, 10)
    setPurchaseList(purchase_list)
    setPageCount(1)
  }

  const clearFilter = () => {
    setFilterRefNo({})
    const purchase_list = orders.slice(0, 10)
    setPurchaseList(purchase_list)
    const len = Math.ceil(orders.length / 10)
    setTotalPages(len)
    setPageCount(1)
  }

  useMemo(() => {
    const purchase_list = orders.slice(0, 10)
    setPurchaseList(purchase_list)

    const len = Math.ceil(orders.length / 10)
    setTotalPages(len)
    setPageCount(1)
  }, [orders])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageCount(value)

    const purchase_list = orders.slice((value - 1) * 10, value * 10)

    setPurchaseList(purchase_list)
  }

  const handleFilter = () => {
    const purchase_list = orders.filter((item) => {
      let check = false

      if (filterRefNo === null || Object.keys(filterRefNo).length === 0) check = true

     else {
        if (filterRefNo?.OrderNumb === item.OrderNumb) check = true
      }

      return check
    })
    setPurchaseList(purchase_list)
    const len = Math.trunc(purchase_list.length / 10)
    setTotalPages(len)
  }

  return (
    <>
      <div className="Inventory-table-body">
        <div className="filter-head ">
          <div className="filter-item">
            <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
          </div>

          <div className="filter-item">
            <FormControl fullWidth>
              <Autocomplete
                classes={classes}
                getOptionLabel={(option: IPurchaseOrder) => option?.OrderNumb || ''}
                id="combo-box-demo"
                options={orders || []}
                renderInput={(params) => <TextField {...params} label="PO ID" size="small" variant="outlined" />}
                style={{ width: 200 }}
                value={filterRefNo}
                onChange={(e, val) => setFilterRefNo(val)}
              />
            </FormControl>
          </div>

     {status? <></> :  <div className="filter-item">
          <FormControl fullWidth>
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: IPurchaseOrder) => option?.RefBillNo || ''}
              id="combo-box-demo"
              options={orders || []}
              renderInput={(params) => <TextField {...params} label="Invoice" size="small" variant="outlined" />}
              style={{ width: 200 }}
              value={filterRefNo}
              onChange={(e, val) => setFilterRefNo(val)}
            />
          </FormControl>
        </div> 
        }

          <div className="filter-item">
            <ActionButton
              label="Apply"
              onClick={() => {
                handleFilter()
              }}
            />
            <ActionButton label="Clear" onClick={() => clearFilter()} />
          </div>
        </div>
        <table className="inventory-products-table">
          <thead>
            <tr>
            <th>
               Date
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'date' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('date', sortingType)}
                />
              </th>
              <th>
                PO ID
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'ref' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('ref', sortingType)}
                />
              </th>
              <th>
                Product
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'name' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('name', sortingType)}
                />
              </th>
              
              <th>
                Order Value
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'amt' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('amt', sortingType)}
                />
              </th>
              <th>
                Product Qty
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'qty' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('qty', sortingType)}
                />
              </th>
              <th>Status</th>
            </tr>
          </thead>
          {purchaseList ? (
            <tbody>
              {purchaseList.map((item) => {
                return <PurchaseOrderList getPurchaseOrders = {getPurchaseOrders} key={item.OrderNumb} data={item} />
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="empty-data" colSpan={12}>
                  No data to display
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <div className="all-products-pagination">
          <Pagination
            className="all-products-pagination"
            count={total_pages}
            page={pageCount}
            shape="rounded"
            variant="outlined"
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  )
}
export default ALLpurchaseListTable
