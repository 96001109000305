
import React from 'react'
import Verification from 'assets/verification.png'
import './styles.scss'
type CardProps = {
    message?: string
}
const InformationCard: React.FC<CardProps> = ({ message }) => {
    return (
        <div className="pending-activation-page">
            <div className="pending-card">
                <img src={Verification} alt="verification" className="verification-image" />

                <h1 className="heading">{message}</h1>

            </div>
        </div>
    )
}

export default InformationCard
