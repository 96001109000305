import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { showNotification, STATUS } from 'common/constant'
// import InputWithLabels from 'components/InputWithLabels'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
// import { LoginService } from 'services/login.service'
import { OnboardingService } from 'services/onBoarding.service'
// import defaultProfileImage from 'assets/default_profile_image.png'
import LayoutHeader from 'components/LayoutHeader'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import './styles.scss'
import AppHeaderLayout from 'components/AppHeaderLayout'
// import bufferGif from 'assets/buffer.gif'
import { useSelector } from 'react-redux'
import { RootStore } from 'redux/store'
// import ActionButton from 'components/ActionButton'
// import WarningTextLayer from 'components/WarningTextLayer'
import SelectDropDownWithLabel from 'components/SelectDropDown/input'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type TPersonalInformations = {
  firstName: string
  lastName: string
  role: string
  auth_provider: string
  password: string
  name: string
  accountcode: string
  email: string | undefined
  mobile: string
  picture: string
}


const personalInformationValidation = {
  firstName: {
    required: { value: true, message: "First Name can't be Empty" }
    // minLength: { value: 5, message: 'First name must be minimum 5 Letters' },
  },
  role: {
    required: { value: true, message: '' }
  },
  auth_provider: {
    required: { value: true, message: '' }
  },
  lastName: {
    required: { value: true, message: "Last Name can't be Empty" }
    // minLength: { value: 5, message: 'Last name must be minimum 5 Letters' },
  },
  name: {
    required: { value: true, message: "Name can't be Empty" }
  },
  accountcode: {
    required: { value: true, message: " Account code can't be Empty" }
  },
  email: {
    required: { value: true, message: "Email field can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: ' Email format is invalid' }
  },
  password: {
    required: { value: true, message: "* Password field can't be Empty" },
    minLength: { value: 6, message: '* Password must be at least 6 characters long' }
  },
  mobile: {
    required: { value: true, message: "Phone Number can't be Empty" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" }
  }
}

const PersonalInformationReviewForm: React.FC = () => {
  const { register, handleSubmit, errors, setValue } = useForm<TPersonalInformations>({ mode: 'onChange' })
  // const loginService = new LoginService()
  const onBoardingService = new OnboardingService()
  const selectedUserEmail = useSelector((state: RootStore) => state.onBoardingData.userEmail?.email);
  // const [rejectionNotes, setRejectionNotes] = useState<string>("")
  // const [profilepic, setProfilePic] = useState('')
  // const [bufferimg, setbufferimg] = useState(false)

  const onSubmit = async (data: TPersonalInformations) => {
    // console.log("profilepic",profilepic)
    // data.picture = profilepic
    console.log("onsubmit", data)
    // if(rejectionNotes) {
      // try {
      //   const saveRejectionNotesResponse = await onBoardingService.updateOnUserData(
      //     {
      //       ...data,
      //       step: ON_BORADING_STEPS[0],
      //       rejection_notes: {
      //         step: ON_BORADING_STEPS[0],
      //         notes: rejectionNotes
      //       }
      //     })

      //     if(saveRejectionNotesResponse.status === STATUS.SUCCESS) {
      //       showNotification(STATUS.SUCCESS, 'User Updated successfully')
      //     } else {
      //       showNotification(STATUS.FAILURE, 'User not updated')
      //     }
          
      // } catch (error) {
      //   showNotification(STATUS.FAILURE, 'User not updated')
        
      // }
    // } else {
    //   showNotification(STATUS.FAILURE, 'Kindly add rejection notes to save the details')
    // }
   
  }

  useEffect(() => {
    const getPersonalData = async () => {
      try {
        const personalData: ISuccessResponse | IErrorResponse = await onBoardingService.getOnUserData(1, selectedUserEmail as string)
        // const personalData1 = await onBoardingService.getOnUserData(1, selectedUserEmail as string)

        if (personalData.status === STATUS.SUCCESS) {
          const successData = personalData as ISuccessResponse
        
          setValue('email', successData.data.email)
          setValue('firstName', successData.data.firstname)
          setValue('lastName', successData.data.lastname)
          setValue('name', successData.data.firstname)
          setValue('mobile', successData.data.mobile)
          // setValue('auth_provider', successData.data.auth_provider)
          setValue('role', successData.data.role)
          setValue('accountcode', successData.data.account_code)
          // // setValue('code', successData.data.code)
          // setRejectionNotes(successData.data.rejection_notes)
        } else {
          showNotification(STATUS.FAILURE, 'Unable to get personal informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get personal informations')
      }
    }


    getPersonalData()
  }, [])
  // const profilePictureHandler= async (event: any) => {
  //   setbufferimg(true)
  //   if(event.target.files && event.target.files[0]) {
  //     const data = new FormData();
  //     data.append('file', event.target.files[0])
  //     const uploadResult = await onBoardingService.uploadDocument(data);
  //     if(uploadResult.status ===STATUS.SUCCESS) {
  //       const result = uploadResult as ISuccessResponse;
  //       setProfilePic(result.data.remotePath)
        
  //     }
  //   }
  //   // setbufferimg(false)
  // }

  
  return (
    <div className="personal-information-form-page">
    <AppHeaderLayout title="User Management" />
   <div className="bank-account-page">
   <div className="filter-item">
          {/* <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
        </div>
        <div className="filter-item">
          <FormControl fullWidth>
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: any) => option.name || ''}
              id="combo-box-demo"
              options={products || []}
              renderInput={(params) => <TextField {...params} label="Product Name" size="small" variant="outlined" />}
              style={{ width: 230 }}
              value={filterName}
              onChange={(e, val) => setFilterName(val)}
            />
          </FormControl> */}
        </div>
  <form className="personal-information-form" onSubmit={handleSubmit(onSubmit)}>

        <div className="legal-layout-wrapper">
            <LayoutHeader title="User Account" />

            <div className="legal-details-content">
                <InputLabelwithDowninput
                    label="First Name"
                    type = "text"
                    // register={register}
                    // name="bankName"
                    error={errors.firstName?.type !== undefined}
                    errorText={errors.firstName ? errors.firstName.message : ''}
                    name="firstName"
                    register={register(personalInformationValidation.firstName)}
                    // text="First Name"
                    // type="text"
                />
                <InputLabelwithDowninput
                    error={errors.lastName?.type !== undefined}
                    errorText={errors.lastName ? errors.lastName.message : ''}
                    name="lastName"
                    register={register(personalInformationValidation.lastName)}
                    label="Last Name"
                />
                <InputLabelwithDowninput
      error={errors.accountcode?.type !== undefined}
      errorText={errors.accountcode ? errors.accountcode.message : ''}
      // hint="(This name will be used in the entire system)"
      name="accountcode"
      register={register(personalInformationValidation.accountcode)}
      label="Account Code" 

                />
                <InputLabelwithDowninput
                              error={errors.email?.type !== undefined}
                              errorText={errors.email ? errors.email.message : ''}
                              // hint="(This name will be used in the entire system)"
                              name="email"
                              register={register(personalInformationValidation.email)}
                              label="Email"
                              type="email"

                />
                {/* <InputLabelwithDowninput
                            error={!!errors.password}
                            errorText={errors.password ? errors.password.message : ''}
                            register={register(personalInformationValidation.password)}
                            label="Password"
                            name="password"
                            type="password"
                /> */}
                <SelectDropDownWithLabel
      name="role"
      register={register(personalInformationValidation.role)}
      options={['SELLER_ADMIN', 'MANAGER']}
      selectedItem="SELLER_ADMIN"
      title="Roles"
    />
                <InputLabelwithDowninput
                              error={errors.mobile?.type !== undefined}
                              errorText={errors.mobile ? errors.mobile.message : ''}
                              name="mobile"
                              type="number"
                              register={register(personalInformationValidation.mobile)}
                              label="Mobile"
                />
        {/* <ActionButton label="Create User" type="submit" varient="outlined" />  <></> */}

            </div>
            
        </div>
        </form>
    </div>
    </div>
  )
}

export default PersonalInformationReviewForm
