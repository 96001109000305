import React, { useState } from 'react'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ISortProps = {
    name: string,
    onClick: (columnName: string, sortType: string) => void
}

const SortIcon: React.FC<ISortProps> = ({name, onClick}) => {
    
    const [isAscendingSort, setIsAscendingSort] = useState<boolean>(true);
    const [initialSorting, setInitialSorting] = useState<boolean>(true);

    const sortingHandler = () => {
        setInitialSorting(false);
        setIsAscendingSort(!isAscendingSort);
        onClick(name, !isAscendingSort ? '1' : '-1');
    };

  return (
    <FontAwesomeIcon
    className="sort-icon"
    icon={initialSorting ? faSort as IconProp : isAscendingSort ? faSortDown as IconProp : faSortUp as IconProp}
    onClick={sortingHandler}
  />
  )
}

export default SortIcon