import React, { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'
import { KeycloakInstance } from 'keycloak-js'


import { ROLES, showNotification, STATUS } from 'common/constant'
import { ILoginError, ILoginResponse } from 'services/interfaces/login.interface'
import { LoginService } from 'services/login.service'
// import { store } from 'redux/store';
// import { LOGIN } from 'redux/actions/UserActionTypes';

const SSOPage: React.FC = () => {
  const { keycloak } = useKeycloak<KeycloakInstance>()
  const history = useHistory()
  const loginService: LoginService = new LoginService()

  const getLoginData = async (token: string) => {

    const loginResponse: ILoginResponse | ILoginError = await loginService.ssoLogin(token)

    if (loginResponse.status === STATUS.SUCCESS) {
      const { data } = loginResponse as ILoginResponse
      localStorage.setItem('code', data.code)
      localStorage.setItem('accessToken', data.accessToken)

      if (
        data.role === ROLES.ROOT_ADMIN ||
        data.role === ROLES.PMPL_CATALOGUE_MANAGER ||
        data.role === ROLES.PMPL_FINANCE_MANAGER ||
        data.role === ROLES.PMPL_OPERATIONS_MANAGER
      ) {
        // history.push('/admin/accounts')
        history.push('/sellerLayout/admin/accounts')
      } else if (data.role === ROLES.SELLER_ADMIN) {
        if (!data.sellerOnBoarded) {
          history.push('/onBoarding')
        }
      } else if (data.role === ROLES.SELLER_FINANCE_MANAGER) {
        if (!data.sellerOnBoarded) {
          history.push('/onBoarding')
        }
      } else if (data.role === ROLES.SELLER_OPERATION_MANAGER) {
        if (!data.sellerOnBoarded) {
          history.push('/onBoarding')
        }
      } else if (data.role === ROLES.SELLER_CATALOGUE_MANAGER) {
        if (!data.sellerOnBoarded) {
          history.push('/onBoarding')
        }
      }
    } else {
      showNotification(STATUS.FAILURE, 'You are not authorized to Login')
      history.push('/login')
    }
  }

  useEffect(() => {
    if (keycloak?.authenticated) {
      const token: any = keycloak?.token

      console.log(keycloak, 'key');
      

      try {
        getLoginData(token)
      } catch (error) {
        showNotification(STATUS.FAILURE, (error as Error).message)
      }
    }
  }, [keycloak?.authenticated])

  return <div className="login-page-wrapper"> Loading...</div>
}

export default SSOPage
