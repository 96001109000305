import React from 'react'
import { useSelector } from 'react-redux'

import { isVisible, LIST_OF_ROLES } from 'common/constant'
import { RootStore } from 'redux/store'
import useAdminEvaluation from 'hooks/useAdminEvaluation';


import './styles.scss'

type ButtonProps = {
  status: string
  pim_code: string
  statusProductHandler: (a: any, b: string) => void
}

const ListingStatus: React.FC<ButtonProps> = ({ status, pim_code, statusProductHandler }) => {
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)
  const { isBusinessUser, isAdmin }= useAdminEvaluation();

  const isDisplay = () => {
    const visible = isVisible([LIST_OF_ROLES.PRODUCT_APPROVE_REJECT], { user: userPermission })

    return visible
  }

  return (
    <>
      {isVisible([LIST_OF_ROLES.PRODUCT_DELIST, LIST_OF_ROLES.PRODUCT_APPROVE_REJECT], { user: userPermission }) ? (
        <select
          className={status === 'Listed' ? 'status-dropdown' : 'delisted-dropdown'}
          value={status}
          disabled={!isDisplay() && (status === 'Pending' || status === 'Rejected' || status === 'Blocked')}
          name="status"
          onChange={(event) => statusProductHandler(event.target.value, pim_code)}
        >
          <option value="Listed">Listed</option>
          {!(isAdmin || isBusinessUser)  && <option value="Delisted">Delisted</option>}
          {(status === 'Pending')  && <option value="Pending">Pending</option>}

          <option
            style={{
              display: isDisplay() ? '' : 'none'
            }}
            value="Rejected"
          >
            Rejected
          </option>
          <option
            style={{
              display: isDisplay() ? '' : 'none'
            }}
            value="Blocked"
          >
            Blocked
          </option>
        </select>
      ) : (
        <p className={status === 'Listed' ? 'seller-account-status-active' : 'seller-account-status-in-active'}>
          {status}
        </p>
      )}
    </>
  )
}

export default ListingStatus
