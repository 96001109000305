import React from 'react'
import moment from 'moment'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { useHistory } from 'react-router-dom'

import './styles.scss'
import { INotification, NotificationType } from 'services/interfaces/notification.interface'
import { NotificationService } from 'services/notification.service'
import { STATUS } from 'common/constant'
import { ACCOUNT_EMAIL } from 'redux/actions/OnBoardingActionTypes'
import { store } from 'redux/store'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import useAdminEvaluation from 'hooks/useAdminEvaluation'




type NotificationProps = {
NotificationData: INotification,
refreshList? : () => void
}

const NotificationItem: React.FC<NotificationProps> = ({NotificationData, refreshList}) => {
  const { isBusinessUser } = useAdminEvaluation();

  const notificationService = new NotificationService();
  const history = useHistory();
  

  /* Onclick changing notification read status */
  const readNotificationHandler = async (notificationId: string): Promise<void> => {
    
    const notificationUpdateInfo: ISuccessResponse | IErrorResponse  = await notificationService.updateStatus(notificationId);

    if(notificationUpdateInfo.status === STATUS.SUCCESS) {
      /* Routing related changes */
      if(refreshList) refreshList();

      /* Notification click Action Handling */
      if(NotificationData.notification_type.toUpperCase() === NotificationType.listings.toUpperCase() && isBusinessUser) {
        /*seller_code sellerMail*/
        localStorage.setItem("seller_code", NotificationData.seller_code);
        localStorage.setItem("seller_email", NotificationData.seller_email);
        history.push("/sellerLayout/ListedProducts/Pending"); 
      }

      if(NotificationData.notification_type.toUpperCase() === NotificationType.listings.toUpperCase() && !isBusinessUser) {
        history.push("/sellerLayout/inventory/ActiveLocations"); 
      }

      if(NotificationData.notification_type.toUpperCase() === NotificationType.registration.toUpperCase()) {
        store.dispatch({
          type: ACCOUNT_EMAIL,
          payload: { email: NotificationData.seller_email }
        })
        history.push('/sellerLayout/admin/verify')
      }
    }
  }

  return (
    <div className={NotificationData.status ? `read-notification-item notification-item` : `notification-item`} onClick={() => readNotificationHandler(NotificationData.notification_id)}>
      <div className="icon-wrapper">
      <div className="notification-icon-wrapper">
        <NotificationsActiveIcon className="notification-icon" />
      </div>
      </div>
      <div className="notification-title-and-description">
        <p className="notification-title">{NotificationData.title}</p>
        <p className="notification-description">{NotificationData.description}</p>
      </div>
      <div className="notification-date-wrapper">
        <p className="notification-date">{moment(NotificationData.create_at).format("DD-MM-YYYY hh:mm A")}</p>
      </div>
    </div>
  )
}

export default NotificationItem
