import React from 'react'

import './styles.scss';

type TInputFieldProps = {
    text :string,
    error?: boolean,
    errorText? : string,
    register?: any,
    name?: string
    hint?: string
    value?: string
    type?: string
} & React.InputHTMLAttributes<HTMLInputElement>




const InputWithLabelsDown: React.FC<TInputFieldProps> = ({register, name, text, hint, error,type, errorText,value, ...props}) => {

    return (
        <div className="input-with-labels-down">
            <label className="txt-label">{text} <span className="label-star">*</span>  :</label>
            <div className="input-and-error">
            <input autoComplete="off"  type={type} ref={register} className={error ? 'input-field-box-error' : 'input-field-box'} name={name} placeholder={text} value={value} {...props}/>
            { error ? <p className="error-text">* {errorText}</p> : '' }
            { hint ? <p className="hint-text">{hint}</p> : '' }
            </div>
            
        </div>
    )
}

export default InputWithLabelsDown
