import React from 'react'

import './styles.scss'


type TRejectionNotes = {
  info: string, 
  closeIcon?: boolean
}

const WarningTextLayer: React.FC<TRejectionNotes> = ({info}) => {

    return (
      <div>
      {info ?
        <div className="review-notes-wrapper">
        <p className="warning-step"> <strong>Update information and Resubmit : </strong> {info}</p>
        </div> : <></> }
      </div>
    )
}

export default WarningTextLayer
