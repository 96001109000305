import React, { useState } from 'react';
import ActionButton from 'components/ActionButton'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import LinkButton from 'components/LinkButton';
import { IAPIResponse } from 'services/interfaces/common.interface';
import { showNotification, STATUS } from 'common/constant';
import { SettingService } from 'services/settings.service';

const OnboardingSettings: React.FC = () => {
    const [edit, setEdit] = useState(false)
    const [url, seturl] = useState("")
    const settingService: SettingService = new SettingService()
    const Settingsupdate = async () => {

        try {
            const UpdateResponse:IAPIResponse= await settingService.updateSettings({value:"AGREEMENT",url});
        setEdit(false);
        if (UpdateResponse.status === STATUS.SUCCESS) {
            showNotification(STATUS.SUCCESS, 'updated Successfully')
          }
          else {
            showNotification(STATUS.FAILURE,'Unable to update Settings');   
          }
        } catch (error) {
            showNotification(STATUS.FAILURE,'Unable to update Settings')
          }
    }
    return (
        <div className='settings-container registration-settings'>
            <div className="settings-header">
                Onboarding Settings
                {!edit ? (
                        <LinkButton text="Edit" onClick={() => setEdit(true)} />
                    ) : (
                        <></>
                    )}
            </div>
            <div className="settings-content">
            <InputLabelwithDowninput label='Agreement Template Link'  onChange={(e) => seturl(e)} disabled={!edit}/>
            <div className="action-button-wrapper">
            {edit ?<ActionButton label='Save' type="submit" onClick={() => Settingsupdate()} />: <></>}
            </div>
            </div>
        </div>
    )
}

export default OnboardingSettings
