import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker as MatDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Dialog } from '@material-ui/core'
import LayoutHeader from 'components/LayoutHeader'
import MomentUtils from '@date-io/moment'
import ActionButton from 'components/ActionButton'
import { ALL_DAYS } from 'common/constant'


import './styles.scss'

type WorkingDaysFormprops = {
  title?: string
  open: boolean
  cancelClose: () => void
  onDaysSelected: (data) => void
  Defaultdays: any
}

type TWorkingDaysInformation = {
  start: string
  end: string
}

const WorkingDaysForm: React.FC<WorkingDaysFormprops> = ({ open, cancelClose, onDaysSelected, Defaultdays }) => {
  const { handleSubmit, control, watch, errors, register } = useForm<TWorkingDaysInformation>({ mode: 'onChange' })

  const [customDays, setCustomDays] = useState<string[]>([])


  const customdaysSelectionHander = async (isSelected: boolean, selectedDay: string) => {
    if (isSelected) {
      setCustomDays([...customDays, selectedDay])
    } else {
      const selectedItemIndex = customDays.indexOf(selectedDay)

      if (selectedItemIndex >= 0) {
        setCustomDays(customDays?.filter((item) => item !== selectedDay))
      }
    }
  }

  const onSubmit = async (data) => {
    data.selectedDays = customDays

    const workinghrs = {
      Sunday: {
        start: data?.Sundaystart?._d ? data?.Sundaystart?._d.toString().split(' ')[4] : '08:00:00',
        end: data?.Sundayend?._d ? data?.Sundayend?._d.toString().split(' ')[4] : '20:00:00'
      },
      Monday: {
        start: data?.Mondaystart?._d ? data?.Mondaystart?._d.toString().split(' ')[4] : '08:00:00',
        end: data?.Mondayend?._d ? data?.Mondayend?._d.toString().split(' ')[4] : '20:00:00'
      },
      Tuesday: {
        start: data?.Tuesdaystart?._d ? data?.Tuesdaystart?._d.toString().split(' ')[4] : '08:00:00',
        end: data?.Tuesdayend?._d ? data?.Tuesdayend?._d.toString().split(' ')[4] : '20:00:00'
      },
      Wednesday: {
        start: data?.Wednesdaystart?._d ? data?.Wednesdaystart?._d.toString().split(' ')[4] : '08:00:00',
        end: data?.Wednesdayend?._d ? data?.Wednesdayend?._d.toString().split(' ')[4] : '20:00:00'
      },
      Thursday: {
        start: data?.Thursdaystart?._d ? data?.Thursdaystart?._d.toString().split(' ')[4] : '08:00:00',
        end: data?.Thursdayend?._d ? data?.Thursdayend?._d.toString().split(' ')[4] : '20:00:00'
      },
      Friday: {
        start: data?.Fridaystart?._d ? data?.Fridaystart?._d.toString().split(' ')[4] : '08:00:00',
        end: data?.Fridayend?._d ? data?.Fridayend?._d.toString().split(' ')[4] : '20:00:00'
      },
      Saturday: {
        start: data?.Saturdaystart?._d ? data?.Saturdaystart?._d.toString().split(' ')[4] : '08:00:00',
        end: data?.Saturdayend?._d ? data?.Saturdayend?._d.toString().split(' ')[4] : '20:00:00'
      }
    }
    const nameArray: any = []
    customDays.forEach((data) => {
      nameArray.push({ [data]: workinghrs[data] })
    })
    // const JSONnameArray = JSON.stringify(nameArray)
    // eslint-disable-next-line no-console
    // console.log(nameArray)
    data.working_hours = nameArray
    onDaysSelected(data)
    cancelClose()
    setCustomDays([])
  }

  useEffect(() => {
    // console.log("this is effect");
    // console.log("=================",Defaultdays);
    if (Defaultdays) {
      setCustomDays(Defaultdays?.selectedDays)
    }

  }, [open])

  return (
    <Dialog open={open} className="addware-popup">
      <LayoutHeader title="Working hours & Days" />

      <form className="workingDays-form" onSubmit={handleSubmit(onSubmit)}>
        <p className="working-days-text">Working Days*</p>

        <div className="checkbox-layout">
          {ALL_DAYS.map((day) => (
            <div className="round" key={day}>
              <input
                type="checkbox"
                id={day}
                onChange={(event) => customdaysSelectionHander(event.target.checked, day)}
                defaultChecked={Defaultdays ? Defaultdays.selectedDays.includes(day) : null}
              />
              <label className="checkbox-label" htmlFor={day} />
              <p className="days-content-wrapper">{day}</p>

              <div className="working-hours-wrapper">
                <Controller
                  control={control}
                  defaultValue={Defaultdays ? typeof Defaultdays[day + 'start'] !== 'string' ? Defaultdays[day + 'start']._d.toString() : Defaultdays[day + 'start'] : "Mon Aug 18 2014 08:00:54 GMT+0530 (India Standard Time)"}
                  // defaultValue="Mon Aug 18 2014 08:00:54 GMT+0530 (India Standard Time)"
                  name={day + 'start'}
                  size='large'
                  ref={register}
                  render={(props) => (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <MatDatePicker className="datepicker" value={props.value} onChange={props.onChange} />
                      <KeyboardTimePicker
                        className="timepicker"
                        margin="normal"
                        id={day}
                        label="Open Time"
                        value={props.value ? props.value : '2014-08-18T08:00:54'}
                        onChange={props.onChange}
                        size='medium'

                      />
                    </MuiPickersUtilsProvider>
                  )}
                />


                <Controller
                  control={control}
                  defaultValue={Defaultdays ? typeof Defaultdays[day + 'end'] !== 'string' ? Defaultdays[day + 'end']._d.toString() : Defaultdays[day + 'end'] : "Mon Aug 18 2014 20:00:54 GMT+0530 (India Standard Time)"}
                  // defaultValue="Mon Aug 18 2014 20:00:54 GMT+0530 (India Standard Time)"
                  name={day + 'end'}
                  ref={register}
                  rules={{
                    validate: {
                      timeDiff: (value) =>
                        new Date(value) >
                        new Date(watch(day + 'start')),
                    },
                  }}
                  render={(props) => (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <MatDatePicker className="datepicker" minDate={watch(day + 'start')} value={props.value} onChange={props.onChange} />
                      <KeyboardTimePicker
                        className="timepicker"
                        margin="normal"
                        id={day}
                        label="Close Time"
                        value={props.value ? props.value : '2014-08-18T20:00:54'}
                        onChange={props.onChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </div>


              {errors[day + 'end']?.type === "timeDiff" && (
                <span className="text-danger" >
                  *End time must be greater than start time
                </span>
              )}
            </div>

          ))}
        </div>

        <div className="workingdays-form-btn-wrapper">
          <ActionButton label="Cancel" varient="outlined" onClick={cancelClose} />
          <ActionButton label="Submit" />
        </div>
      </form>
    </Dialog>
  )
}

export default WorkingDaysForm
