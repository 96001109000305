import { ROLES } from 'common/constant';
import { useEffect, useState } from 'react'

interface IUserResult {
    isAdmin: boolean,
    isBusinessUser: boolean
}

/* Custom hook to identify the logged in user Role */
const useAdminEvaluation = (): IUserResult => {
    const role = localStorage.getItem('role');
    const [isAdmin, setIsAdmin] = useState<boolean>(role === ROLES.ROOT_ADMIN);
    const [isBusinessUser, setIsBusinessUser] = useState<boolean>(role === ROLES.ROOT_ADMIN || role === ROLES.PMPL_CATALOGUE_MANAGER || role === ROLES.PMPL_OPERATIONS_MANAGER || role === ROLES.PMPL_FINANCE_MANAGER);
   
    useEffect(() => {
        setIsAdmin(role === ROLES.ROOT_ADMIN);
        setIsBusinessUser(role === ROLES.ROOT_ADMIN || role === ROLES.PMPL_CATALOGUE_MANAGER || role === ROLES.PMPL_OPERATIONS_MANAGER || role === ROLES.PMPL_FINANCE_MANAGER);
    }, [role])


    return {isAdmin, isBusinessUser}
}

export default useAdminEvaluation