import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import './styles.scss'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import { OnboardingService } from 'services/onBoarding.service'
import { STATUS, showNotification, validatePermission, LIST_OF_ROLES } from 'common/constant'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import ActionButton from 'components/ActionButton'
import CardHeader from 'components/CardHeader'
import { RootStore, store } from 'redux/store'
import { TAX_AND_FINACIAL_INFORMATION } from 'redux/actions/profile.action'
import { AccountsService } from 'services/accounts.service'



type IDocumentUploadData = {
    gstin: string
    pan: string
    panExpiry: string
    tan: string
    cin: string
    gstStatus: string
}

const TaxFinancialsPage: React.FC = () => {
    const onboardingService = new OnboardingService();
    const accountService = new AccountsService();
    const { register, setValue, handleSubmit } = useForm<IDocumentUploadData>({ mode: 'all' })

    const taxAndFinancialEditMode = useSelector((store: RootStore) => store.profile.taxAndFinancialsDetails.edit);
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);

    const onSubmit = async (formData: IDocumentUploadData) => {

        if(formData.gstin.length < 15) {
            showNotification(STATUS.FAILURE, 'GST number should be 15 digits');

            return
        }

        try {

            const updateResponse: ISuccessResponse | IErrorResponse = await accountService.updateProfile(formData, "TAX_AND_FINANCIALS");

            if(updateResponse.status === STATUS.SUCCESS) {
                showNotification(STATUS.SUCCESS, 'Tax & Financial Details updated successfully')
                store.dispatch({type: TAX_AND_FINACIAL_INFORMATION, payload: {taxAndFinancialsDetails: {edit: false}}});
            } else {
                showNotification(STATUS.FAILURE, 'Unable to update Tax & Financial Details')
            }
            
        } catch (error) {
            showNotification(STATUS.FAILURE, 'Unable to update Tax & Financial Details')
        }




       
    }
    useEffect(() => {
        const role: string | null = localStorage.getItem('role')
        const isAllowed: boolean =
          role === 'ROOT_ADMIN' ||
          role === 'PMPL_CATALOGUE_MANAGER' ||
          role === 'PMPL_OPERATIONS_MANAGER' ||
          role === 'PMPL_FINANCE_MANAGER'
    

        const sellerMail: string = localStorage.getItem('sellerMail') as string;
        const getTaxationInfo = async () => {
            try {
                const getTaxationResponse: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(4, isAllowed ? sellerMail as string : "")

                if (getTaxationResponse.status === STATUS.SUCCESS) {
                    const successData = getTaxationResponse as ISuccessResponse
                    setValue('gstin', successData.data.gstin)
                    setValue('pan', successData.data.pan)
                    setValue('cin', successData.data.cin)
                    setValue('tan', successData.data.tan)
                    setValue('gstin', successData.data.gstin)
                    setValue('gstStatus', successData.data.gstStatus)

                } else {
                    showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
                }
            } catch (error) {
                showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
            }
        }

        getTaxationInfo()
    }, [])
    
    const hasLegalEditPermission = (): boolean => {
        const permission = validatePermission([LIST_OF_ROLES.LEAGAL_UPDATE], userPermission);
        return permission;
    };

    const gstInVerify = async (gstNumber: string) => {

        const filteredGSTNumber = gstNumber?.replace(/[^a-z0-9]/gi,'').substring(0,15);
        
        setValue("gstin", filteredGSTNumber)
    }

    return (
        <div className="tax-finanicals-page">
            <div className="legal-layout-wrapper">
                <CardHeader title="Tax & Financials" edit={hasLegalEditPermission() && !taxAndFinancialEditMode} action={TAX_AND_FINACIAL_INFORMATION}/>

                <form className="legal-details-content" onSubmit={handleSubmit(onSubmit)}>
                    <InputLabelwithDowninput
                        label="GST Registration Status"
                        register={register}
                        name="gstStatus"
                        disabled

                    />
                    <InputLabelwithDowninput
                        label="GST Number*"
                        register={register}
                        disabled={!taxAndFinancialEditMode}
                        onChange={(value) => gstInVerify(value)}
                        name="gstin"

                    />
                    <InputLabelwithDowninput
                        label="TAN Number"
                        register={register}
                        disabled={!taxAndFinancialEditMode}
                        name="tan"


                    />
                    <InputLabelwithDowninput
                        label="PAN Number"
                        register={register}
                        disabled={!taxAndFinancialEditMode}
                        name="pan"


                    />
                    <InputLabelwithDowninput
                        label="CIN Number"
                        register={register}
                        disabled={!taxAndFinancialEditMode}
                        name="cin"

                    />
                    <div className="form-data-hide">
                        <InputLabelwithDowninput
                            label="Designation"
                            name="designation"
                            disabled={!taxAndFinancialEditMode}
                            register={register}
                        />

                    </div>
                    <div className="save-btn-wrap">
                        { taxAndFinancialEditMode ? <ActionButton label="Save" type="submit" /> : <></>}
                    </div>
                </form>
            </div>

        </div>
    )
}

export default TaxFinancialsPage