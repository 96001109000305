import React, { useState, useEffect } from 'react'

import './styles.scss'
import { STATUS } from 'common/constant'
import { ISuccessResponses } from 'services/interfaces/common.interface'
import { IPurchaseOrderList } from 'services/interfaces/purchaseorder.interface'
import { OnPurchaseOrderService } from 'services/purchaseOrder.service'
type Props = {
  data?: any
}

const ItemcodeImgs: React.FC<Props> = ({ data }) => {
  const [purchaseOrderLists, setPurchaseOrderLists] = useState<IPurchaseOrderList[] | []>([])
  const getPurchaseOrderList = new OnPurchaseOrderService()
  useEffect(() => {
    const getReviewData = async () => {
      const getPurchaseOrderImage = await getPurchaseOrderList.getPurchaseOrderListImage(data)

      if (getPurchaseOrderImage.StatusMessage === STATUS.SUCCESS) {
        const successimage = getPurchaseOrderImage as ISuccessResponses

        setPurchaseOrderLists(successimage.Data.parchaseorders)
      }
    }

    getReviewData()
  }, [])

  console.log(data)

  return (
    <>
      <div className="product-col">
        <div className="pimg-wrap">
          <img
            className="product-img"
            src={purchaseOrderLists[0] && purchaseOrderLists[0].image}
            style={{ width: '40px' }}
          />
        </div>
        <p>{purchaseOrderLists[0] && purchaseOrderLists[0].name}</p>
      </div>
    </>
  )
}

export default ItemcodeImgs
