import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useForm } from 'react-hook-form'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import InputField from 'components/InputField/InputField'

import './style.scss'
import { InventoryService } from 'services/inventory.service'
import { showNotification, STATUS } from 'common/constant'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'

type AddStockProps = {
  data: number
  index: number
  label: string
  isAdd: boolean
  warehouseData?: any
  cancelClose: () => void
}

const AddStockDialog: React.FC<AddStockProps> = ({ isAdd, label, warehouseData, cancelClose }) => {
  const [open, setOpen] = React.useState(false)
  const inventoryService: InventoryService = new InventoryService()
  // const DataProduct = useSelector((state: RootStore) => state.product.activatedProducts)

  const { register, handleSubmit, errors } = useForm({ mode: 'onChange' })

  const UpdateStock = async (stockData) => {
    const data = {
      warehouse_code: warehouseData.warehouse_code,
      qty_on_hand: parseInt(stockData.stock),
      seller_code: localStorage.getItem('seller_code')
    }

    try {
      const updateproducts: ISuccessResponse | IErrorResponse = await inventoryService.updateStock(
        data,
        warehouseData.pim_code
      )

      if (updateproducts.status === 'success') {
        showNotification(STATUS.SUCCESS, 'Stock Updated Successfully')
        setOpen(false)
        cancelClose()
      } else showNotification(STATUS.FAILURE, 'Unable to Update the Stock')
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to Update the Stock')
      setOpen(false)
    }
    setOpen(false)
  }

  return (
    <div>
      <button className="btn-primary-edit" onClick={() => setOpen(true)}>
        {label}
      </button>
      <Dialog aria-labelledby="form-dialog-title" disableBackdropClick open={open} onClose={() => setOpen(false)}>
        <DialogActions>
          <div className="closeIcon" onClick={() => setOpen(false)}>
            <HighlightOffIcon />
          </div>
        </DialogActions>

        <DialogContent className="dialogContent">
          <span>
            <span>Product Name - </span>
            <span className="">{warehouseData.name}</span>
          </span>

          <form onSubmit={handleSubmit(UpdateStock)}>
            {/* <DatePicker
              control={control}
              error={!!errors.dateOfBirth}
              helperText={errors.dateOfBirth ? errors.dateOfBirth.message : ''}
              label="Enter Date "
              name="stockModifiedDate"
              valdate={false}
            /> */}
            {isAdd ? (
              <>
                <InputField
                  error={!!errors.stock}
                  helperText={errors.stock ? errors.stock.message : ''}
                  inputRef={register({
                    required: 'Please enter stock'
                  })}
                  label="Stock"
                  name="stock"
                  style={{ margin: '5px' }}
                  type="text"
                  onChange={(event) =>
                    (event.target.value = event.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*?)\..*/g, '$1')
                      .slice(0, 5))
                  }
                  variant="outlined"
                />
              </>
            ) : (
              <>
                {' '}
                <InputField
                  error={!!errors.stock}
                  helperText={errors.stock ? errors.stock.message : ''}
                  inputRef={register({
                    required: 'Please enter stock'
                  })}
                  label="stock"
                  name="stock"
                  style={{ margin: '5px' }}
                  type="text"
                  variant="outlined"
                />
                <InputField
                  error={!!errors.stock}
                  helperText={errors.stock ? errors.stock.message : ''}
                  inputRef={register({
                    required: 'Please enter Reason'
                  })}
                  label="Reason"
                  name="stockRemoveReason"
                  style={{ margin: '5px' }}
                  variant="outlined"
                />
              </>
            )}
            <button className="btn-primary" type="submit">
              Update Stock
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddStockDialog
