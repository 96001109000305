export const ENABLE_LOADING = "ENABLE_LOADING";
export const DISABLE_LOADING = "DISABLE_LOADING";

export type ENABLE_LOADING = {
    type: typeof ENABLE_LOADING,
    payload: boolean
}

export type DISABLE_LOADING = {
    type: typeof DISABLE_LOADING,
    payload: boolean
}


export type LOADING_DISPATCH_TYPE = ENABLE_LOADING | DISABLE_LOADING;