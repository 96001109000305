import React from 'react'
import { useForm } from 'react-hook-form';
import loginPageIllustration from 'assets/images/Delivery-address-amico.svg'
import { useHistory } from 'react-router-dom';
import { LoginService } from 'services/login.service';
import { STATUS, showNotification } from 'common/constant';
import { Link } from 'react-router-dom';
import { ISuccessResponse, IErrorResponse } from 'services/interfaces/common.interface';
import PoorvikaLogo from 'assets/images/poorvika-Logo.svg'

const formValidations = {
    email: {
      required: { value: true, message: '* Email field is empty' },
      pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
    }
  };

  type ForgotPasswordDetails = {
    email: string;
  }

const ResendActivationLinkPage: React.FC = () => {

    const { register, handleSubmit, errors } = useForm<ForgotPasswordDetails>({ mode: 'all' });
    const history = useHistory();
    const loginService: LoginService = new LoginService();

    const onSubmit = async (data: ForgotPasswordDetails) => {
        try {
          const resendLinkResponse: ISuccessResponse | IErrorResponse = await loginService.getActivationLink(data.email);
    
          if (resendLinkResponse.status === STATUS.SUCCESS) {
            showNotification('success', resendLinkResponse.message)
            history.push('/login')
          } else {
            showNotification('failure', resendLinkResponse.message)
          }
    
        } catch (error) {
          showNotification(STATUS.FAILURE, (error as Error)?.message);
        }
      }
    
    return (
        <div className="login-page-wrapper">
        <div className="login-page">
          <div className="left-content">
            <div className="left-content-align">
              <img className="poorvika-svg-logo" draggable="false" src={PoorvikaLogo} alt="poorvika" />
              <h3 className="seller-portal-name-wrapper">SELLER PORTAL</h3>
              <img alt="login illustration" className="login-illustration" draggable="false" src={loginPageIllustration} />
            </div>
  
          </div>
          <div className="right-content">
            <div>
              <h3 className="sign-in-name-wrapper">Resend Activation Link</h3>
              <p className="seller-account-name">(Activation Link will be sent again to the registered Email)</p>
              <form className="loginForm-container" autoComplete="new-password" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group email-ip">
                  <input
                    id="email_id"
                    ref={register(formValidations.email)}
                    className='login-input-field-box'
                    placeholder="Registered Email"
                    name="email"
                  />
                  <label htmlFor="email_id" className="label-helper">Login ID or Email </label>
  
                  {errors.email ? <div className="error-text">{errors.email.message}</div> : ''}
                </div>
                <div className="forgot-password-area">
  
                  <Link className="forgot-password" to="/login">
                    Remembered Password ? Login
                  </Link>
                </div>
                <button className="login-btn" type="submit" >
                  Get Activation Link
                </button>
              </form>
            </div>
          </div>
  
        </div>
  
      </div>
    )
}

export default ResendActivationLinkPage
