import React, { useEffect, useState } from 'react'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import LayoutHeader from '../../../components/LayoutHeader'
import { showNotification, STATUS } from '../../../common/constant'
import { InventoryService } from '../../../services/inventory.service'
import Verification from '../../../assets/verification.png'
import { ISuccessResponse, IErrorResponse } from '../../../services/interfaces/common.interface';



type ActiveLocationsprops = {

    onStatus: (data) => void;

}

const INActiveLocations: React.FC<ActiveLocationsprops> = ({ onStatus }) => {


    /*State Variables */
    const [warehouselist, setWarehouselist] = useState<any>([])

    


    /* Services */
    const inventoryService: InventoryService = new InventoryService()

    /*IN-Active GetWarehouse List Handler */
    const getWareHouse = async () => {
        try {

            const data = localStorage.getItem('seller_code')
            const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getwarehouselist(data, 0)
            

            setWarehouselist(getWareHouseResponse.data)
            onStatus(getWareHouseResponse.data? getWareHouseResponse.data.length + ' ' + "Active": 0+ ' ' + "In-Active")

        } catch (error) {
            showNotification(STATUS.FAILURE, ' Unable to get users list')
        }
    }



    useEffect(() => {
        getWareHouse()
    }, [])

    return (
        <div className="Activelocations-page">
            {warehouselist ?
                <div>
                    {
                        warehouselist.map((warehouseData) => {
                            return (
                                <div className="location-layout-wrapper" key={warehouseData.warehouse_code}>
                                    <LayoutHeader title={warehouseData.warehouse_name + ' ( ' + warehouseData.warehouse_code + ')'} />
                                    <div className="activelocation-details-card">
                                        <div className="activelocation-details-content">
                                            <ImageOutlinedIcon className="warehouse-image-ic" />
                                            <div className="registered-address-content">
                                                <p className="warehouse-details-label">Registered Address</p>
                                                <p className="warehouse-details-content">{warehouseData.address1 + ',' + warehouseData.address2 + ','
                                                    + warehouseData.city + ',' + warehouseData.state + ',' + warehouseData.pincode}</p>
                                            </div>
                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Primary Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_mobile}</p>

                                            </div>

                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Escalations Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_mobile}</p>

                                            </div>

                                        </div>


                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                : <div className="location-layout-wrapper">
                    <div className="No-Active-card-wrapper">
                    <img src={Verification} alt="verification" className="verification-image" />
                        No Inactive Locations Found

                    </div>
                </div>
            }




        </div>
    )
}

export default INActiveLocations