import React from 'react'

import './style.scss'

type TEmailVerificationFieldValidation = {
  isPhoneVerified?: boolean
  text: string
  error?: boolean
  errorText?: string
  register?: any
  name?: string,
  buttonText: string,
  getOTPClicked: () => void
} & React.InputHTMLAttributes<HTMLInputElement>

const MobileOTPVerification: React.FC<TEmailVerificationFieldValidation> = ({
  register,
  name,
  text,
  error,
  errorText,
  buttonText,
  isPhoneVerified,
  ...props
}) => {


    const getOTP = (): void => props.getOTPClicked();



  return (
    <div className="mobile-verification-input">
      <label className="txt-label">{text} :</label>
      <div className="input-and-error">
        <div className="input-and-button">
          <input
          ref={register}
            disabled={isPhoneVerified}
            name={name}
            type="text"
            // value={props.value}
            {...props}
            className={error ? 'input-field-box-error' : 'input-field-box'}
            placeholder={text}
          />
          <button  className="btn-get-otp" disabled={buttonText === 'Verified'} type="button" onClick={()=> getOTP()}>{buttonText === 'Verified' ? <>&#10003; {buttonText} </> : buttonText}</button>
          <br />
        </div>
        {error ? <p className="error-text">* {errorText}</p> : ''}
      </div>
    </div>
  )
}

export default MobileOTPVerification
