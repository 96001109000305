import React from 'react'

import './style.scss'
import Verification from 'assets/verification.png'

const PendingAccountActivationPage: React.FC = () => {
  return (
    <div className="seller-pending-activation-page">
      <div className="pending-card">
        <img src={Verification} alt="verification" className="verification-image" />
        <h1 className="heading">Your Account is under review</h1>
        <p className="pending-content">
          We have recieved your documents. Your account will be activated once we have verified the documents submitted.
        </p>
      </div>
    </div>
  )
}

export default PendingAccountActivationPage
