import React from 'react'
import { Route } from 'react-router-dom'

import AppHeaderLayout from '../../components/AppHeaderLayout'
import POInvoiceForm from '../../containers/form/POInvoiceForm'

import './styles.scss'


const PurchaseOrders: React.FC = () => {
  return (
    <div className="purchase_drop">
      <AppHeaderLayout title="Purchase Order Accepted :" to="/sellerLayout/purchaseOrder/ALLProducts" />

      <div className="listed-product-layout po-back">
        <Route component={POInvoiceForm} path="/sellerLayout/DispatchOrder/ProductsAccepted" />
      </div>
    </div>
  )
}

export default PurchaseOrders
