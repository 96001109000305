import React from 'react'
import './styles.scss'





const TrackProducts: React.FC = () => {

    const productStatus = [
        { name: 'Samsung', listingID: '664654545', sku: 'IOEO754IIUA', Mop: '$14,359', lastupdate: 'Apr 01, 2021 1:40 PM', details: 'Description Not Matching With Vertical | The invoice is not matching the vertical and Product description of the goods applied for approval, hence invoice is invalid. Kindly reapply with a valid invoice.', Status: "Yes" },
        { name: 'Oneplus', listingID: '846544777', sku: 'IOEO754IIUA', Mop: '$1,02,164', lastupdate: 'Apr 01, 2021 1:40 PM', details: 'Description Not Matching With Vertical | The invoice is not matching the vertical and Product description of the goods applied for approval, hence invoice is invalid. Kindly reapply with a valid invoice.', Status: "No" },
        { name: 'Iphone', listingID: '121326455', sku: 'IOEO754IIUA', Mop: '$14,359', lastupdate: 'Apr 01, 2021 1:40 PM', details: 'Description Not Matching With Vertical | The invoice is not matching the vertical and Product description of the goods applied for approval, hence invoice is invalid. Kindly reapply with a valid invoice.', Status: "Yes" },
        { name: 'Redmi', listingID: '787874212', sku: 'IOEO754IIUA', Mop: '$1,02,164', lastupdate: 'Apr 01, 2021 1:40 PM', details: 'Description Not Matching With Vertical | The invoice is not matching the vertical and Product description of the goods applied for approval, hence invoice is invalid. Kindly reapply with a valid invoice.', Status: "NO" },

    ]
    
    return (
        <div className="track-product-layout">
            <div className="search-product-list">
                <p className="track-product-header"> Track status of product listing</p>
                <div className="search-product-layout">

                    <table className="track-products-table">
                        <thead>


                            <th>Request ID</th>
                            <th>Product Attributes</th>
                            <th>Status</th>
                            <th>Details</th>
                            <th>Last updated</th>


                        </thead>
                        {productStatus ?
                            <tbody>
                                {productStatus.map((item) => {
                                    return (

                                        <tr key={ item.listingID}>
                                            <td className="request-Id">
                                                {item.listingID}
                                            </td>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                {item.Status === "Yes" ?
                                                    <p className="status-approved">Approved</p>
                                                    :

                                                    <p className="status-disapproved">Disapproved</p>
                                                }
                                            </td>
                                            <td className="product-description-details">
                                                {item.details}
                                            </td>
                                            <td>
                                                {item.lastupdate}
                                            </td>

                                        </tr>
                                    )




                                })}



                            </tbody>
                            : <></>}


                    </table>
                </div>

            </div>
            <div>
            </div>
        </div>

    )
}

export default TrackProducts