import React, { useEffect, useState } from 'react'

import './styles.scss'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import EmailImage from 'assets/verify_email.jpg'
import ActionButton from 'components/ActionButton'
import { LoginService } from 'services/login.service'
import { showNotification, STATUS } from 'common/constant'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'

const queryParams = queryString.parse(window.location.search)

const EmailActivationSentPage: React.FC = () => {
  const history = useHistory()
  const [verificationStatus, setVerificationStatus] = useState(false)
  /* Page Types - NEW_REGISTRATION, VERIFIED, ALREADY_VERIFIED */
  const loginService = new LoginService()

  useEffect(() => {
    const getActivationStatus = async (token: string) => {
      try {
        const activationStatus: ISuccessResponse | IErrorResponse = await loginService.activateEmail(token)

        if (activationStatus.status === STATUS.SUCCESS) {
          setVerificationStatus(true);
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Invalid Activation Link')
        history.push('/login')
      }
    }

    if (queryParams.token) {
      getActivationStatus(queryParams.token as string)
    }
  }, [])

  return (
    <div className="email-verification-page">
      <div className="email-verification-card">
        <img alt="email illustration" src={EmailImage} />
        {!verificationStatus ? <div>
        <h1>Verification Email Sent</h1>
        <p>
          A Verification email sent to your email address. Please click the link has been sent to verify your account
          email and continue the registration process
        </p>
        </div> : 
        <div>
           <h1>Email Verified Successfully</h1>
        <p>
         You email has been verified successfully, Now you could login to the system
        </p>
        </div>
        }
        <ActionButton label="Login" onClick={() => history.push('/login')} />
      </div>
    </div>
  )
}

export default EmailActivationSentPage
