import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { STATUS } from 'common/constant';
import { decrypt } from 'common/encryptor';
import { RootStore } from 'redux/store';
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface';
import { IBankingInformations, IBillingAddress,  IDocumentIds, ILegalInformations,  IPrimaryContact } from 'services/interfaces/onBoarding.interface';
import { OnboardingService } from 'services/onBoarding.service';
import BrandLogo from 'components/common/BrandLogo';

type Props = {
    form: any;
    periods?: any;
}

const PrintableConfirmation = React.forwardRef<HTMLDivElement, Props>(({ }, ref) => {
    const onboardingService = new OnboardingService();
    const [LegalInformation, setLegalInformation] = useState<ILegalInformations | null>(null);
    const [BillingInformation, setBillingInformation] = useState<IBankingInformations | null>(null);
    const [BillAddress, setBillAddress] = useState<IBillingAddress | null>(null)
    const [primaryContact, setprimaryContact] = useState<IPrimaryContact | null>(null)
    const [documentIds, setDocumentIds] = useState<IDocumentIds | null>(null);

    const selectedUserEmail = useSelector((state: RootStore) => state.onBoardingData.userEmail?.email);



    useEffect(() => {
        const getReviewData = async () => {
            const getPersonalInformations: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(5, selectedUserEmail as string);

            if (getPersonalInformations.status === STATUS.SUCCESS) {
                const successResponse = getPersonalInformations as ISuccessResponse;
     
                setLegalInformation(successResponse.data.LegalInformation)
                successResponse.data.BillingInformation.account_number = decrypt(successResponse.data.BillingInformation.account_number)
                setBillingInformation(successResponse.data.BillingInformation)
                setBillAddress(successResponse.data.BillAddress)
                setprimaryContact(successResponse.data.primaryContact)
                setDocumentIds(successResponse.data.TaxationInfo.ids)
           

            }
        }

        getReviewData();

    }, [])

    return (
        <div ref={ref}>
            <BrandLogo />
            <div className="print-sub-heading">
                <p >Accounts Informations</p>
            </div>
            <div className="details-confirmation-page">


               
                <p className="form-subtitle">Legal Information</p>
                {_.map(LegalInformation, (value, prop) => {
                    return <div className="name-and-details" key={prop}>
                        <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                        <p className="value-text">{value}</p>
                    </div>
                })}
                <p className="form-subtitle">Banking Information</p>
                {_.map(BillingInformation, (value, prop) => {
                    return <div className="name-and-details" key={prop}>
                        <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                        <p className="value-text">{value}</p>
                    </div>
                })}
                <div className="print-page-content" />
                <p className="form-subtitle">Billing Information</p>
                {_.map(BillAddress, (value, prop) => {
                    return <div className="name-and-details" key={prop}>
                        <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                        <p className="value-text">{value}</p>
                    </div>
                })}
                <p className="form-subtitle">Primary Contact</p>
                {_.map(primaryContact, (value, prop) => {
                    return <div className="name-and-details" key={prop}>
                        <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                        <p className="value-text">{value}</p>
                    </div>
                })}
                <p className="form-subtitle">Taxation & Documents</p>
                {_.map(documentIds, (value, prop) => {
                    return <div className="name-and-details" key={prop}>
                        <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                        <p className="value-text">{value}</p>
                    </div>
                })}



                <div />
            </div>
        </div>

    )










});

export default PrintableConfirmation;
