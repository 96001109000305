import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { NavLink, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Grid } from '@material-ui/core';

import AppHeaderLayout from '../../../components/AppHeaderLayout';
import { RootStore } from '../../../redux/store';
import { ProductService } from '../../../services/productService';
import { showNotification, STATUS } from '../../../common/constant'
import { IErrorResponse, ISuccessResponse } from '../../../services/interfaces/common.interface';
import { IListingProduct } from '../../../services/interfaces/product.interface';

import ALLProductTable from './AllProductTable';
import ListedTable from './ListedTable';
import DelistedTable from './DelistedTable';
import PendingTable from './PendingTable';
import RejectTable from './RejectTable';

import './styles.scss'

import BlockedTable from './BlockedTable';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


const ListedProduct: React.FC = () => {

    const role: string | null = localStorage.getItem('role');
    const isAllowed:boolean = (role === "ROOT_ADMIN" || role === "PMPL_CATALOGUE_MANAGER" ||role === "PMPL_OPERATIONS_MANAGER" ||role === "PMPL_FINANCE_MANAGER" )
  
    // const history = useHistory<History>()
    const [all_products, setAllProducts] = React.useState<any>([])
    const [pending, setPending] = useState<number>(0)
    const [listed, setListed] = useState<number>(0)
    const [delisted, setDelisted] = useState<number>(0)
    const [rejected, setRejected] = useState<number>(0)
    const [blocked, setBlocked] = useState<number>(0)
    const [csvData, setCsvData] = useState([])
    const [linkHeader,setLinkHeader] = useState<string>("All");
    const inputEl =  useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)


    const breadcrumbs = [
        <div className='bread-crumb-navlink' key="1" >Product Listing</div>,
        <div className='bread-crumb-navlink' key="2" >View Listed Products</div>,
        <div className='bread-crumb-navlink' key="3" > {linkHeader} </div>,
    ];

    const categories = useSelector((state: RootStore) => state.product.productCategories)
    const productService: ProductService = new ProductService()
    const getProductCategories = () => productService.getProductCategories()

   


    const getAllProducts = async () => {
        try {
          const all_products: ISuccessResponse | IErrorResponse = await productService.getAllSellerProducts()
          const get_all_products = all_products as ISuccessResponse

          const purchase_data = get_all_products.data

      let listed = 0,
      delisted = 0,
      pending = 0,
      rejected = 0,
      blocked = 0,
      purchase_datas = purchase_data.map((item) => {
        if (item.listing_status === 'Pending' || item.AdditionalStatus === '') pending++
        else if (item.listing_status === 'Delisted') delisted++
        else if (item.listing_status === 'Listed') listed++
        else if (item.listing_status === 'Rejected') rejected++
        else if (item.listing_status === 'Blocked') blocked++

        return item
      })
      console.log(purchase_datas)

      setPending(pending)
      setListed(listed)
      setDelisted(delisted)
      setRejected(rejected)
      setBlocked(blocked)
          console.log("get_all_products?.data", get_all_products?.data)
           setAllProducts(get_all_products?.data)

        } catch (error) {
          showNotification(STATUS.FAILURE, 'Unable to get Seller Product informations')
        }
    }

    const handleDownload = async () => {
        try {
          const downloadResult: ISuccessResponse | IErrorResponse = await productService.getAllSellerProducts();
          setAllProducts(downloadResult.data);
    
          if(downloadResult?.data?.length !== 0){
            const data = downloadResult.data.map((item) => 
              {
                const product = {};

    
                 product['Seller Code'] = item.seller_code
                 product['Product'] = item.name
                 product['Category'] = item.category
                 product['Brand'] = item.brand
                 product['Global SKU'] = item.sku
                 product['Poorvika Product Code'] = item.erp_item_code
                 product['Seller Product code'] = item.ext_prod_id
                 product['PSN'] = item.psn
    
                 product['Wholesale Price'] = item.wholesale_price
                 product['MRP'] = item.mrp
                 product['Status'] = item.listing_status
    
                 return product
            
            })
            setCsvData(data)
    
          }
          else showNotification(STATUS.FAILURE, 'No data to download')
    
          inputEl.current.link.click()
        } catch (error) {
          showNotification(STATUS.FAILURE, 'Unable to download the document')
        }
      }

    useEffect(() => {

        getProductCategories()
        getAllProducts()
        console.log(categories);


    }, [])

    const productStatus = [
        { name: 'All',  count:  all_products.length, path: '/sellerLayout/ListedProducts/ALLProducts'} ,
        { name: 'Listed',  count: listed, path: '/sellerLayout/ListedProducts/Listed' },
        { name: 'Delisted',  count: delisted, path:'/sellerLayout/ListedProducts/Delisted' },
        { name: 'Pending', count: pending, path: '/sellerLayout/ListedProducts/Pending' },
        { name: 'Rejected',  count : rejected , path: '/sellerLayout/ListedProducts/Rejected' },
        { name: 'Blocked',  count : blocked , path: '/sellerLayout/ListedProducts/Blocked' },
    ]

    return (
        <div>
            <AppHeaderLayout title="Product Listings :" to={isAllowed ? "/admin/seller/sellerLayout/productListing/searchProduct" : "/sellerLayout/productListing/searchProduct"} />
            <Grid item md={11} xs={12} className='bread-crumb-layout'>
                <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <div className="listed-product-layout">
            <div className="product-header-btn">


                <button className="addproduct-btn-wrapper" onClick={() => handleDownload()}>
                <FontAwesomeIcon className="excel-icon" icon={faFileExcel as IconProp} /> <span className="btn-text">Download All Products</span>
                </button>

            <CSVLink
              ref={inputEl}
              data={csvData}
              filename="product_template.csv"
              style={{ display: 'none' }}
              asyncOnClick
              onClick={(event, done) => {
                done()
              }}
            />
                    {/* <button className="addproduct-btn-wrapper export-btn-wrapper more-action-btn" onClick={() => setMoreAction(!moreAction)}>More Action
                        <ArrowDropDownIcon className={moreAction ? 'more-action-ic' : ""} />
                    </button>
                    <div className={moreAction ? 'more-action-dropdown' : "more-action-hide"}>
                        <p>Set Active</p>
                        <p>Delist Product(s)</p>
                        <p>Archive Product</p>
                        <p>Add Tag</p>
                    </div> */}

                </div>
                <div className="Inventory-table-layout">
                    <div className="Inventory-table-header">

                        {productStatus.map((status) => (
                            <div className="listed-products-selector" key={status.name}>
                                <NavLink  className="products-selector" to={status.path}>
                                    <p onClick={() => {
                                        getAllProducts()
                                        setLinkHeader(status?.name)
                                    }
                                        }> {status.name}({status.count}) </p>
                                </NavLink>
                                <span>|</span>
                            </div>
                        ))}
                        <div className="search-input-field">
                            <SearchIcon className="search-ic" />

                            <input className="products-search" placeholder="Search by Product Name" type="search" onChange={(event) => console.log(event.target.value)} />
                        </div>
                        {/* <div className="products-filters">
                            <div className="catogeries-wrapper">
                                <Button className="catogeries-btn-primary">
                                    <Typography style={{ cursor: 'pointer', backgroundColor: '#efefef' }} onClick={onClickMenu}>
                                        {categoryValue !== '' ? categoryValue : 'categories'}
                                    </Typography>
                                </Button>

                                <div>
                                    <Menu
                                        anchorPosition={menuPosition}
                                        anchorReference="anchorPosition"
                                        open={!!menuPosition}
                                        onClose={() => setMenuPosition(null)}
                                    >
                                        {categories?.productcategories[0]?.categories.map((value) => {
                                            return <Comment key={value.code} comment={value} />
                                        })}
                                    </Menu>
                                </div>

                            </div>

                            <select name="status" className="status-dropdown" onChange={(data) => console.log(data.target.value)} >
                                <option value="listit">Brand</option>
                                <option value="listit">Listed</option>
                                <option value="Delist">Delist</option>
                                <option value="Pending">Pending</option>

                            </select>
                            <select name="status" className="status-dropdown" onChange={(data) => console.log(data.target.value)} >
                                <option value="listit">Product</option>
                                <option value="listit">Listed</option>
                                <option value="Delist">Delist</option>
                                <option value="Pending">Pending</option>

                            </select>

                            <button className="sort-btn">Sort
                                <ImportExportIcon className="sort-ic" />
                            </button>


                        </div> */}


                    </div>

                    <Route component={() => <ALLProductTable   products={all_products}  />} path="/sellerLayout/ListedProducts/ALLProducts"  />
                    <Route component={() => <ALLProductTable   products={all_products}  />} path="/admin/seller/sellerLayout/ListedProducts/ALLProducts"  />

                    <Route component={() => <ListedTable getAllProducts={getAllProducts}  products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Listed')} />} path="/sellerLayout/ListedProducts/Listed"  />
                    <Route component={() => <ListedTable getAllProducts={getAllProducts}  products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Listed')} />} path="/admin/seller/sellerLayout/ListedProducts/Listed"  />                 
                   
                    <Route component={() => <DelistedTable  getAllProducts={getAllProducts}  products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Delisted')} />} path="/sellerLayout/ListedProducts/Delisted"  />
                    <Route component={() => <DelistedTable  getAllProducts={getAllProducts}  products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Delisted')} />} path="/admin/seller/sellerLayout/ListedProducts/Delisted"  />

                    <Route component={() => <PendingTable getAllProducts={getAllProducts}  products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Pending')} />} path="/sellerLayout/ListedProducts/Pending"  />
                    <Route component={() => <PendingTable getAllProducts={getAllProducts} products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Pending')}  />} path="/admin/seller/sellerLayout/ListedProducts/Pending"  />

                    <Route component={() => <RejectTable  searchInput='' products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Rejected')}  />} path="/sellerLayout/ListedProducts/Rejected"  />
                    <Route component={() => <RejectTable  searchInput='' products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Rejected')}  />} path="/admin/seller/sellerLayout/ListedProducts/Rejected"  />

                    <Route component={() => <BlockedTable  searchInput='' products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Blocked')}  />} path="/sellerLayout/ListedProducts/Blocked"  />
                    <Route component={() => <BlockedTable  searchInput='' products={all_products?.filter((item:IListingProduct)=>item.listing_status === 'Blocked')}  />} path="/admin/seller/sellerLayout/ListedProducts/Blocked"  />
                </div>
            </div>
            
        </div>


    )
}

export default ListedProduct