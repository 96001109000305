import { Dialog } from '@material-ui/core';
import { showNotification, STATUS, validatePermission } from 'common/constant';
import { ADD_CONTACT, MODIFY_CONTACT } from 'common/permissionMatrix';
import ActionButton from 'components/ActionButton';
import AddContactDialog from 'containers/Dialogs/AddContactDialog';
import DeleteContactDialog from 'containers/Dialogs/DeleteDialog';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootStore } from 'redux/store';
import { AccountsService } from 'services/accounts.service';
import { IContactInformation } from 'services/interfaces/accounts.interface';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import './style.scss';

const AdditionalContacts: React.FC = () => {

    /* State variables */
  const [addNewContactDialogVisibility, setAddNewContactDialogVisibility] = useState<boolean>(false);
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState<boolean>(false);
  const [contacts, setContacts] = useState<IContactInformation[]>([]);
  const [contactId, setContactId] = useState<string>('');
  const [isEditdialogMode, setEditDialogMode] = useState<boolean>(false);
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)

  /* Services */
  const accountsService = new AccountsService();

  const getAdditionalContacts = async (): Promise<void> => {
    try {

      const data = await accountsService.getContactList();
      
      if(data.status === STATUS.SUCCESS) {
        setContacts(data.data)
      } else {
        throw Error();
      }      
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Additional Contacts')
    }
  }

  const saveContactHandler = (isSaveAction: boolean): void => {
    if(isSaveAction) {
      getAdditionalContacts();
    }
    setAddNewContactDialogVisibility(false);
    setEditDialogMode(false);
  };

  const editContactHandler = (contactId: string): void => {
    setAddNewContactDialogVisibility(true);
    setEditDialogMode(true);
    setContactId(contactId);
  }

  const deleteContactHandler = (isDeleted: boolean): void => {
    if (isDeleted) {
      getAdditionalContacts();
    }
    setDeleteDialogVisibility(false)
  }

  const deleteDialogHandler = (contactId: string): void => {
    setDeleteDialogVisibility(true);
    setContactId(contactId);
  };

  const addNewContactHandler = (): void => {
    setContactId('');
    setAddNewContactDialogVisibility(true);
    setEditDialogMode(false);
  }

  useEffect(() => {
    getAdditionalContacts();
  }, [])



  return (
      <div className='contacts-container'>
      <div>
          <div className='additional-contact-informations'>
        <div className='flex contacts-header'>
        <div className='flex50'>
        <h1 className='heading'>Additional Contacts</h1>
        </div>
        <div className='flex flex50 flexEnd vertical-center'>
          {validatePermission(ADD_CONTACT,userPermission) && <ActionButton label='Add new contact' onClick={() => addNewContactHandler()}/>}
        </div>
        </div>

      </div>
      </div>
    <div className="contacts-table">
      <table>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Name</th>
          <th>Designation</th>
          <th>Email</th>
          <th>Contact Number</th>
          {validatePermission(MODIFY_CONTACT, userPermission) &&<th>Action</th>}

        </tr>
       
      </thead>
      <tbody>
       {contacts?.length ? contacts.map((contact: IContactInformation, index: number) => {
        return <tr key={contact.email}><td>{index + 1}</td>
        <td>{contact.name}</td>
        <td>{contact.designation}</td>
        <td>{contact.email}</td>
        <td>{contact.contactNumber}</td>
        {validatePermission(MODIFY_CONTACT, userPermission) &&  <td>
          <div className='flex-center'>
            <div className='flex50'><EditIcon className='btn' onClick={() => editContactHandler(contact._id as string)}/></div>
            <div className='flex50'><DeleteIcon className='clr-warning btn' onClick={() => deleteDialogHandler(contact._id as string)}/></div>
          </div>
        </td>}
        </tr>
       }) :  <tr>
       <td className="empty-data" colSpan={6}>
         No data to display
       </td>
     </tr>
        }
        </tbody>
      </table>

      <Dialog open={addNewContactDialogVisibility} >
        <AddContactDialog isEditMode={isEditdialogMode} contactId={contactId} onClose={(isSaveAction: boolean) => saveContactHandler(isSaveAction)}/>
      </Dialog>
      <Dialog open={deleteDialogVisibility} >
        <DeleteContactDialog id={contactId} onClose={(isDeleted: boolean) => deleteContactHandler(isDeleted)}/>
      </Dialog>
      </div>
      </div>
  )
}

export default AdditionalContacts