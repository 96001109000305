import { AxiosError } from "axios";

import { API_ENDPOINTS, parseAxiosError } from "common/constant";

import { BaseService } from "./base.service";
import { ISuccessResponse, IErrorResponse } from "./interfaces/common.interface";


export class RolesService extends BaseService {
    public async addRoles(rolesData): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_ROLES, rolesData);

            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getRolesList(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ROLESLIST}`)


            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getRolesPermission(roleName: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ROLEPERMISSION}?role=${encodeURIComponent(roleName)}`)


            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }
    public async updateRoles(rolesData): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.UPDATE_ROLES, rolesData);

            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

}