import { OnBoardingDispatchType, ACCOUNT_EMAIL } from '../actions/OnBoardingActionTypes'

type IEmail = {
  loading: boolean
  userEmail?: { email: string }
}

const onBoardingDefaultState: IEmail = {
  loading: false
}

const OnBoardingReducer = (state: IEmail = onBoardingDefaultState, action: OnBoardingDispatchType): IEmail => {
  switch (action.type) {
    case ACCOUNT_EMAIL:
      return {
        ...state,
        loading: false,
        userEmail: action.payload
      }
    default:
      return state
  }
}

export default OnBoardingReducer
