import React, { useEffect, useState } from 'react'

import './styles.scss'
import LayoutHeader from 'components/LayoutHeader'
import InputFieldWithButton from 'components/InputFieldWithButton'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { showNotification, STATUS } from 'common/constant'
import { OnboardingService } from 'services/onBoarding.service'

const KYCDocumentsPage: React.FC = () => {
  const onboardingService = new OnboardingService()

  /* State Variables */
  const [gstDocument, setGstDocument] = useState('')
  const [tanDocument, setTanDocument] = useState('')
  const [cinDocument, setCinDocument] = useState('')
  const [signature, setSignature] = useState('')
  const [chequeDocument, setchequeDocument] = useState<string>('')
  const [agreement, setAgreement] = useState('')

  const role: string | null = localStorage.getItem('role')
    const isAllowed: boolean =
      role === 'ROOT_ADMIN' ||
      role === 'PMPL_CATALOGUE_MANAGER' ||
      role === 'PMPL_OPERATIONS_MANAGER' ||
      role === 'PMPL_FINANCE_MANAGER'

    const sellerMail: string = localStorage.getItem('sellerMail') as string

  const getTaxationInfo = async () => {
    try {
      const getTaxationResponse: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(
        4,
        isAllowed ? (sellerMail as string) : ''
      )

      if (getTaxationResponse.status === STATUS.SUCCESS) {
        const successData = getTaxationResponse as ISuccessResponse
        /* Documents */
        setAgreement(successData.data.agreement)
        setSignature(successData.data.signature)
        setGstDocument(successData.data.gstDocument)
        setTanDocument(successData.data.tanDocument)
        setchequeDocument(successData.data.chequeDocument)
        setCinDocument(successData.data.cinDocument)
      } else {
        showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
    }
  }

  useEffect(() => {
    getTaxationInfo()
  }, [])

  return (
    <div className="legal-details-page">
      <div className="legal-layout-wrapper">
        <LayoutHeader title="KYC Documents" />

        <div className="legal-details-content">
          <InputFieldWithButton label="GST Documents" file={gstDocument} />
          <InputFieldWithButton label="CIN Documents" file={cinDocument} />
          <InputFieldWithButton label="TAN Documents" file={tanDocument} />
          <InputFieldWithButton label="Agreement" file={agreement} />
          <InputFieldWithButton label="Cancelled cheaque Leaf" file={chequeDocument} />
          <div className="kyc-signature-container">
            <p className="signature-header">Digital Signature</p>
            <div className="signature-panel">
              <img src={signature} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KYCDocumentsPage
