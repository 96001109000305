import React, { useEffect, useState } from 'react'

import './styles.scss'
import { useForm } from 'react-hook-form'

import ActionButton from 'components/ActionButton'
// import contact from 'assets/contact.jpg'
import InputWithLabels from 'components/InputWithLabels'
import { OnboardingService } from 'services/onBoarding.service'
import { showNotification, STATUS } from 'common/constant'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import WarningTextLayer from 'components/WarningTextLayer'

type TContactInformationProps = {
  nextStep: () => void;
  movePrevious: () => void;
}
type IContactInformations = {
  title: string,
  designation: string,
  name: string,
  office: string,
  mobile: string,
  email: string,
  esclation_title: string,
  esclation_name: string,
  esclation_mobile: string,
  esclation_email: string,
  esclation_designation: string
}


const contactFormValidations = {

  title: { required: { value: true, message: "Title is required" } },
  name: { required: { value: true, message: "Name is required" } },
  designation: { required: { value: true, message: "Designation is required" } },
  esclation_designation: { required: { value: true, message: "Esclation Designation is required" } },

  office: { required: { value: true, message: "Office Number is required" } },
  mobile: { required: { value: true, message: "Mobile Number is required" },
  minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
  maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" } },
  email: {
    required: { value: true, message: "Email is required" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },

  esclation_title: { required: { value: true, message: "Title is required" } },
  esclation_name: { required: { value: true, message: "Name is required" } },
  esclation_mobile: { required: { value: true, message: "Mobile Number is required" },
  minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
  maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" } },
  esclation_email: {
    required: { value: true, message: "Email is required" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
}

const ContactInformationFrom: React.FC<TContactInformationProps> = ({ movePrevious, nextStep }) => {

  const { register, handleSubmit, errors, setValue } = useForm<IContactInformations>({ mode: 'all' })
  const onboardingService = new OnboardingService();
  const [rejectionNotes, setRejectionNotes] = useState<string>("");

  const onSubmit = async (formData: IContactInformations) => {
    if (formData.mobile.trim() === formData.esclation_mobile.trim()) {
      showNotification(STATUS.FAILURE, "Primary and Esclation Mobile numbers should be different");

      return
    } else if (formData.email.trim() === formData.esclation_email.trim()) {
      showNotification(STATUS.FAILURE, "Primary and Esclation Email should be different")

      return
    }

    try {
      const addPrimaryContactResponse: ISuccessResponse | IErrorResponse = await onboardingService.saveOnBoardingData({ ...formData, step: 'PRIMARY_CONTACT' })

      if (addPrimaryContactResponse.status = STATUS.SUCCESS) {
        nextStep();
      } else {
        showNotification(STATUS.FAILURE, "Unable to save primary contact info")
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, "Unable to save primary contact info")
    }

  }

  useEffect(() => {

    const getContactInformations = async () => {
      try {
        const contactInformations: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(3);

        if (contactInformations.status === STATUS.SUCCESS) {
          const successResponse = contactInformations as ISuccessResponse;

          setValue("title", successResponse.data.title ? successResponse.data.title : "MR");
          setValue("designation", successResponse.data.designation);
          setValue("name", successResponse.data.name);
          setValue("office", successResponse.data.office);
          setValue("mobile", successResponse.data.mobile);
          setValue("email", successResponse.data.email);
          setValue("esclation_title", successResponse.data.esclation_title ? successResponse.data.esclation_title : "MR");
          setValue("esclation_name", successResponse.data.esclation_name);
          setValue("esclation_mobile", successResponse.data.esclation_mobile);
          setValue("esclation_email", successResponse.data.esclation_email);
          setValue("esclation_designation", successResponse.data.esclation_designation);
          setRejectionNotes(successResponse.data.rejection_notes);

        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Contact informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Contact informations')
      }
    }

    getContactInformations();

  }, [])


  return (
    <div>
      <WarningTextLayer info={rejectionNotes} />
      <form action="" className="add-contact-form" onSubmit={handleSubmit(onSubmit)}>
        <p className="form-subtitle">Primary Contact Information</p>
        <div className="account-title-and-name">
          <label className="txt-label">Name * :</label>
          <select className="input-selector"
            name="title"
            ref={register(contactFormValidations.title)}>
            <option value="MR" selected>Mr</option>
            <option value="MRS">Mrs</option>
            <option value="MS">Ms</option>
          </select>
          <input
            className="name-box"
            placeholder="Name"
            type="text"
            name="name"
            ref={register(contactFormValidations.name)}
          />

        </div>
        <div className="contact-name-error">
          {(errors.name?.type !== undefined) ? <p className="error-text">* {errors.name ? errors.name.message : ''}</p> : ''}
        </div>

        <InputWithLabels
          text="Designation"
          error={errors.designation?.type !== undefined}
          errorText={errors.designation ? errors.designation.message : ''}
          name="designation"
          register={register(contactFormValidations.designation)}
        />
        <InputWithLabels
          text="Office Phone"
          name="office"
          maxLength={15}
          onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
          error={errors.office?.type !== undefined}
          errorText={errors.office ? errors.office.message : ''}
          register={register(contactFormValidations.office)}
        />
        <InputWithLabels
          text="Mobile"
          name="mobile"
          maxLength={10}
          onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
          error={errors.mobile?.type !== undefined}
          errorText={errors.mobile ? errors.mobile.message : ''}
          register={register(contactFormValidations.mobile)}
        />
        <InputWithLabels
          text="Email"
          name="email"
          error={errors.email?.type !== undefined}
          errorText={errors.email ? errors.email.message : ''}
          register={register(contactFormValidations.email)}
        />
        <div>
          <p className="form-subtitle">Escalation Contact Information</p>
          <div className="account-title-and-name">
            <label className="txt-label">Name * :</label>
            <select className="input-selector"
              name="esclation_title"
              ref={register(contactFormValidations.title)}>
              <option value="MR" selected>Mr</option>
              <option value="MRS">Mrs</option>
              <option value="MS">Ms</option>
            </select>
            <input
              className="name-box"
              placeholder="Name"
              type="text"
              name="esclation_name"
              ref={register(contactFormValidations.esclation_name)}
            />

          </div>
          <div className="contact-name-error">
            {(errors.esclation_name?.type !== undefined) ? <p className="error-text">* {errors.esclation_name ? errors.esclation_name.message : ''}</p> : ''}
          </div>
          <InputWithLabels
            text="Designation"
            name="esclation_designation"
            register={register(contactFormValidations.esclation_designation)}
            error={errors.esclation_designation?.type !== undefined}
            errorText={errors.esclation_designation ? errors.esclation_designation.message : ''}
          />
          <InputWithLabels
            text="Mobile"
            name="esclation_mobile"
            maxLength={10}
            onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
            error={errors.esclation_mobile?.type !== undefined}
            errorText={errors.esclation_mobile ? errors.esclation_mobile.message : ''}
            register={register(contactFormValidations.esclation_mobile)}
          />
          <InputWithLabels
            text="Email"
            name="esclation_email"
            error={errors.esclation_email?.type !== undefined}
            errorText={errors.esclation_email ? errors.esclation_email.message : ''}
            register={register(contactFormValidations.esclation_email)}
          />

          <ActionButton label="< Back" varient="outlined" onClick={() => movePrevious()} />
          <ActionButton label="Save & Next" type="submit" />
        </div>
      </form>
    </div>
  )
}

export default ContactInformationFrom
