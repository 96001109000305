import { AxiosError } from 'axios'
import { API_ENDPOINTS, parseAxiosError } from 'common/constant'
import { BaseService } from 'services/base.service'
import { IAdditionalInformation, IContactInformation } from 'services/interfaces/accounts.interface'
import { IAPIResponse, IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'

export class AccountsService extends BaseService {
  public async getAllAccounts(isactive:boolean): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.ACCOUNT_LIST_ALL + `?isactive=${isactive}`)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getAccounts(
    pageCount?: number,
    page?: number,
    searchText?: any,
    isactive?: any,
    orderbyValues?: string,
    sortType?: string,
    multipleSearch?: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    const searchQuery = searchText ? searchText : ''
    const isactiveQuery = isactive ? isactive : false
    multipleSearch = multipleSearch ? multipleSearch : ''
    console.log(isactiveQuery)

    try {
      if (pageCount && page) {
        const { data } = await this.httpClient.get(
          API_ENDPOINTS.ACCOUNT_LIST +
            `?limit=${pageCount}&page=${page}&search=${searchQuery}&isactive=${isactiveQuery}&multipleSearch=${multipleSearch}`)

        return data
      } else if (searchText) {
        const { data } = await this.httpClient.get(
          API_ENDPOINTS.ACCOUNT_LIST +
            `?search=${'true'}&name=${searchText.name}&email=${encodeURIComponent(searchText.email)}&code=${
              searchText.code
            }&type=${searchText.type}&onboardingStep=${searchText.onboardingStep}&isactive=${isactiveQuery}`)

        return data
      } else if (multipleSearch) {
        const { data } = await this.httpClient.get(
          API_ENDPOINTS.ACCOUNT_LIST + `?multipleSearch=${multipleSearch}&isactive=${isactiveQuery}`
        )

        return data
      } else if (sortType) {
        if (page != 0) {
          const { data } = await this.httpClient.get(
            API_ENDPOINTS.ACCOUNT_LIST +
              `?isactive=${isactiveQuery}&orderbyValues=${orderbyValues}&sort=${sortType}&page=${page}`
          )

                    return data
                } 
            }

            
                const { data } = await this.httpClient.get(API_ENDPOINTS.ACCOUNT_LIST + `?limit=${pageCount}&page=${page}&search=${searchQuery}&isactive=${isactiveQuery}`);

                return data
            

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

  }

  public async saveAdditionalInformation(
    AdditionalInformation: IAdditionalInformation
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.UPDATE_ADDITIONAL_INFORMATION, AdditionalInformation)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateProfile(body: any, type: string): Promise<ISuccessResponse | IErrorResponse> {
    const role: string | null = localStorage.getItem('role')
    const isAdmin: boolean =
      role === 'ROOT_ADMIN' ||
      role === 'PMPL_CATALOGUE_MANAGER' ||
      role === 'PMPL_OPERATIONS_MANAGER' ||
      role === 'PMPL_FINANCE_MANAGER'

    const headers = {
      email: isAdmin ? localStorage.getItem('sellerMail') : localStorage.getItem('userMail'),
      type
    }

    try {
      const { data } = await this.httpClient.put(API_ENDPOINTS.UPDATE_ACCOUNT, body, { headers })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getDashboardData(): Promise<ISuccessResponse | IErrorResponse> {
    const seller_code = localStorage.getItem('seller_code')

    try {
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_DASHBOARD_INFO}?seller_code=${seller_code}`)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async setBankVerificationStatus(code: string, bank_verified: boolean): Promise<IAPIResponse> {
    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.BANK_VERIFICATION_STATUS}?code=${code}&bank_verified=${bank_verified}`)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getBankVerificationStatus(sellerCode: string): Promise<IAPIResponse> {
    try {
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.BANK_VERIFICATION_STATUS}?code=${sellerCode}`);

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async saveContact(contactInformation: IContactInformation): Promise<IAPIResponse> {
    try {

      const { data } = await this.httpClient.post(API_ENDPOINTS.CONTACTS, {...contactInformation});

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getContactList(): Promise<IAPIResponse> {
    try {

      const { data } = await this.httpClient.get(`${API_ENDPOINTS.CONTACTS}?code=${localStorage.getItem('seller_code')}`)

      return data;
    } catch (error) {
       return parseAxiosError(error as AxiosError)
    }
  }

  public async getContactInformation(contactId: string) :Promise<IAPIResponse> {
    try {
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.CONTACTS}/${contactId}?code=${localStorage.getItem('seller_code')}`)

      return data;

    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async updateContactInformation(contactId: string, contactData: IContactInformation): Promise<IAPIResponse> {
    try {
      const { data } = await this.httpClient.put(`${API_ENDPOINTS.CONTACTS}/${contactId}`, contactData)

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async deleteContact(contactId: string):Promise <IAPIResponse> {
    try {
      const seller_code: string = localStorage.getItem('seller_code') as string;

      const { data } = await this.httpClient.delete(`${API_ENDPOINTS.CONTACTS}/${seller_code}/${contactId}`);
      
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

}
