
import { AxiosError } from 'axios'
import { intersection, isEmpty } from 'lodash';
import * as toaster from 'toastr'
import { IErrorResponse , IErrorResponses} from 'services/interfaces/common.interface'

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_DOMAIN_URL = process.env.REACT_APP_BASE_DOMAIN_URL;
export const INVENTORY_API_URL = process.env.REACT_APP_INVENTORY_API_URL;

/* All API endpoints  */
export const API_ENDPOINTS = {
  //Login or Register
  LOGIN: '/users/login',
  SSO_LOGIN: '/users/sso/login',
  SELLERADMIN: '/users/impersonateLogin',
  GET_USER_DETAILS: '/users/getUserDetails',
  UPDATE_USER_DETAILS: '/users/updateUserDetails',
  SELLER_REGISTRATION: '/users/register',
  ON_BOARDING: '/users/onBoarding',
  ON_BOARDING_STATUS: '/users/onBoardingStatus',
  FORGOT_PASSWORD: '/users/forgotPassword',
  RESEND_ACTIVATION_LINK: '/users/resendActivationEmail',
  RESET_PASSWORD: '/users/resetPassword',
  ADD_ACCOUNT: '/accounts/add',
  CHECK_ACCOUNT_CODE: '/accounts/checkCode',
  AUTH_MODE_CHECK_CODE: '/accounts/authModeCheckCode',
  GET_ROLE: '/users/getRole',
  ADD_PMPL_ADMIN: '/users/addPoorvikaAdmin',
  ADD_SELLER_ACCOUNT: '/users/addSellerAdmin',
  ACTIVATE_EMAIL: '/users/activateEmail',

  /* On boarding  */
  GET_ONBOARDING_DATA: '/accounts/onboardingData',
  SEND_OTP: '/users/sendOTP',
  VERIFY_OTP: '/users/verifyOTP',
  SAVE_ONBOARDING_DATA: '/accounts/saveOnboardingData',
  GET_IFSC_SUGGESTION: '/accounts/getBankData',
  UPLOAD_DOCUMENT_TO_CLOUD: '/accounts/documentUpload',
  UPDATE_ONBOARDING_DATA: '/accounts/updateOnboardingData',
  ACTIVATE_SELLER: '/accounts/activateSeller',
  SELLER_HISTORY: '/accounts/history',
  CHEQUELEAF_PATH: '/accounts/updateCheckLeaf',
  
  /* Users */
  GET_USERS_LIST: '/users/list',
  SAVE_USER_DATA: '/users/adduserlist',
  SAVE_PMPL_USER_DATA: '/admin/adduser',

  GET_USER_DATA: '/users/onUserData',



  /* Accounts Endpoints */
  ACCOUNT_LIST: '/accounts/list',
  ACCOUNT_LIST_ALL: '/accounts/alllist',
  ACCOUNT_LIST_SORT: '/accounts/sortAccountslist',
  GST_INFORMATION: '/accounts/getGSTInformations',
  UPDATE_ADDITIONAL_INFORMATION: '/accounts/additionalInformation',
  UPDATE_ACCOUNT: '/accounts/update', 
  GET_DASHBOARD_INFO: `${INVENTORY_API_URL}/dashboard/list`,
  BANK_VERIFICATION_STATUS: "/accounts/bank/verified",
  CONTACTS: "/contacts",

  //Products
  ADD_PRODUCTS: 'product/addProduct',
  GET_PRODUCTS: 'product/getProducts',
  EDIT_PRODUCT_CATALOG: `${INVENTORY_API_URL}/seller/catelogue/pricing`,


  GET_PRODUCTS_COUNT: 'product/getProductsCount',
  GET_PRODUCTS_LIST: 'product/getProductsList',

  //Admin Products
  GET_ADMIN_PRODUCTS: 'admin/getAllProducts',
  GET_ADMIN_PRODUCTS_INACTIVE: 'admin/getInActiveProducts',
  GET_ADMIN_PRODUCTS_COUNT: 'admin/getAllProductsCount',
  GET_ADMIN_PRODUCT_ACTIVATE: 'admin/makeProductActivated',
  GET_ADMIN_PRODUCT_DEACTIVATE: 'admin/makeProductDeActive',
  GET_ADMIN_PRODUCT_BLOCKED: 'admin/makeProductBlocked',

  UPDATE_PRODUCTS: 'product/updateProducts',
  GET_PRODUCT_CATEGORIES: 'product/getProductCategories',
  ADD_PRODUCT_COMM: 'admin/addProductComm',
  GET_PRODUCT_COMM: 'admin/getProductCommList',
  UPDATE_PRODUCT_COMM: 'admin/updateProductComm',

  //Orders-
  GET_ORDERS: 'order/getOrders',
  UPDATE_ORDERS: 'order/updateOrders',
  GET_ORDERS_COUNT: 'order/getOrdersCount',
  CANCEL_ORDER: "/order/cancelOrders",

  //Promotion
  ADD_PROMOTION: 'promotion/addPromotion',
  GET_PROMOTIONS: 'promotion/getPromotions',
  UPDATE_PROMOTION: 'promotion/updatePromotion',
  REMOVE_PROMOTION: 'promotion/removePromotion'
  ,
  //AdminPromotions
  GET_ALL_PROMOTION: 'admin/getPromotions',
  GET_ALL_FREESHIPPING_PROMO: 'admin/getFreeShippingList',
  GET_ALL_LUCKYDRAW_PROMO: 'admin/getLuckyDrawList',
  GET_ALL_SOCIALMEDIA_PROMO: 'admin/getSocialMediaPromo',

  //LuckyDraw
  ADD_LUCKYDRAW: 'luckyDraw/addLuckyDrawPromo',
  GET_LUCKYDRAWLIST: "luckyDraw/getLuckyDrawList",
  UPDATE_LUCKYDRAWLIST: "luckyDraw/updateLuckyDrawPromotion",
  REMOVE_LUCKYDRAW_PROMOTION: 'luckyDraw/removePromotion',


  //Free Shipping
  ADD_FREE_SHIPPING: 'freeShipping/addFreeShiipingPromo',
  FREE_SHIPPING_LIST: 'freeShipping/getFreeShippingList',
  UPDATE_SHIPPINGPROMO: 'freeShipping/updateFreeShippingPromotion',
  REMOVE_FREESHIPPING_PROMOTION: 'freeShipping/removePromotion',

  //SocialMedia
  ADD_SOCIALMEDIA: 'socialMedia/addSocialMediaPromo',
  GET_SOCIALMEDIA: 'socialMedia/getSocialMediaPromo',
  UPDATE_SOCIAL_PROMOTION: 'socialMedia/updatePromotion',
  REMOVE_SOCIALMEDIA_PROMOTION: 'socialMedia/removePromotion',

  //Paymnets
  GET_PAYMENTS: "payment/getPayments",
  GET_ADMIN_PAYMENTS: "admin/getPayments",
  PAYMENT_ACTIVATE: "admin/activatePayments",
  PAYMENT_DEACTIVATE: "admin/deActivatePayments",

  /* payments Releated API */
  PAYMENT_LIST: "payments/list",
  PAYMENT_UPDATE: "payments/editpayment",
  //Inventory
  // WAREHOUSE: "https://seller-4jfuxn4p5q-ez.a.run.app/",
  ADD_WAREHOUSE: `${INVENTORY_API_URL}/warehouse`,
  UPDATE_WAREHOUSE: `${INVENTORY_API_URL}/update_warehouse`,
  DELETE_WAREHOUSE: `${INVENTORY_API_URL}/delete_warehouse`,
  GET_WAREHOUSE_LIST: `${INVENTORY_API_URL}/warehouse_list/`,
  WAREHOUSE: `${INVENTORY_API_URL}/`,
  // WAREHOUSE: "http://localhost:8080/",
  // WAREHOUSE: "https://seller-testing-d2jn4z2hbq-el.a.run.app/",
  

  /*Role */
  ADD_ROLES: '/roles/addroles',
  GET_ROLESLIST: '/roles/getRoles',
  GET_ROLEPERMISSION: '/roles/getRoleandPermissions',
  UPDATE_ROLES: '/roles/updaterolse',

  /* Purchase order end points */
  GET_SUPPLIER_CODE: "/purchaseorder/getSupplierCode/",
  GET_PO_LIST_IMAGE: "/purchaseorder/getimagepurchaseorder/",
  PURCHASEORDER_LIST: '/purchaseorder/getPurchaseList',
  PURCHASEORDER_STATUS_UPDATE: "/purchaseorder/updatePurchaseOrder",
  PURCHASEORDER_STATUS_ADD:`/payments/adds`,
  

  BULK_UPLOAD_INVENTORY : "gcs/inventory/stock",
  BULK_UPLOAD_PRODUCT : "sellercatalogue/bulkupload?SellerCode=",
  DOWNLOAD_INVENTORY : 'inventory/list/template/',
  DOWNLOAD_PRODUCT : "bulk/upload/product",
  DOWNLOAD_PRODUCT_LIST : "seller/catelogue/template/",
  UPLOAD_INENTORY_HISTORY : "gcs/bulkupdate/history",
  UPLOAD_PRODUCT_HISTORY : "gcs/sellercatalogue/history",

  /* Notification */
  GET_NOTIFICATION: `${INVENTORY_API_URL}/notification/list`,
  READ_NOTIFICATION_STATUS: `${INVENTORY_API_URL}/notification`,

  

  GET_USER_LIST: '/users/userlist',
  PMPL_GET_USER_LIST: '/users/pmpluserlist',

  /*PIM Products */
  PIM: 'https://pimapi-d2jn4z2hbq-el.a.run.app/', 
  // PIM: "http://localhost:8080/",

  /* Audit Logs */
  GET_AUDIT_LOGS: "/auditLogs/list",

  /* DOCUMENT END POINTS */
  GET_SETTINGS: "/settings/list",
  
}

export const STATUS = {
  SUCCESS: 'success',
  SUCCESSS: 'Success',
  FAILURE: 'failure', 
  WARNING: "warning"
}

export const ROLES = {
  ROOT_ADMIN: 'ROOT_ADMIN',
  PMPL_CATALOGUE_MANAGER: 'PMPL_CATALOGUE_MANAGER',
  PMPL_OPERATIONS_MANAGER: 'PMPL_OPERATIONS_MANAGER',
  PMPL_FINANCE_MANAGER: 'PMPL_FINANCE_MANAGER',
  SELLER_ADMIN: 'SELLER_ADMIN',
  SELLER_CATALOGUE_MANAGER: 'SELLER_CATALOGUE_MANAGER',
  SELLER_OPERATION_MANAGER: 'SELLER_OPERATION_MANAGER',
  SELLER_FINANCE_MANAGER: 'SELLER_FINANCE_MANAGER',
  USERS: 'USERS'
}

export const productSubPaths = [
  '/seller/products/activeProducts',
  '/seller/products/readyForActivation',
  '/seller/products/blockedProducts',
  '/seller/products/inactiveProducts',
  '/seller/products/archivedProducts',
  '/seller/products/adminReadyForActivation',
  "/seller/products/adminactiveProducts",
  "/seller/products/adminarchivedProducts",
  "/seller/products/adminblockedProducts",
  "/seller/products/adminInactiveProducts",
  '/seller/addProduct',
]


export const inventorySubpath = [
  '/seller/warehouse',
  '/seller/addwarehouse'
]

export const productsDetailsSubpath = [
  '/sellerLayout/profileDetials',

]

export const orderSubPaths = [
  '/seller/orders/pendingLabels',
  '/seller/orders/pendingRTD',
  '/seller/orders/pendingHandover',
  '/seller/orders/inTransit',
  '/seller/orders/pendingServices',
  '/seller/orders/completedOrders',
  '/seller/orders/UpcomingOrders',
  '/seller/orders/cancelledOrders'
]

export const PromotionSubPaths = [
  '/seller/promotions',
  '/seller/addPromotion',
  '/seller/addPromotionForm',
  '/seller/LuckyDrawForm',
  '/seller/FreeShipingForm',
  '/seller/SocialMediaPromotionsForm',
  '/seller/promotions/AdminPromotionsTable',
  '/seller/promotions/PromotionsTable',
  '/seller/promotions/FreeShipingPromoTable',
  '/seller/promotions/LuckyDrawPromotionsTable',
  '/seller/promotions/SocialMediaPromotionsTable',
]
export const ProductCommPaths = [
  '/seller/ProductCommLayout/',
  '/seller/AddProductCommForm'
]

export const usersSubPaths = [
  '/admin/users',
  '/admin/addusers'
]


export const adminAccountsPath = [
  '/admin/accounts',
  '/admin/accounts/verify'
]

export const ON_BORADING_STEPS = [
  'LEGAL_INFORMATION',
  'BILLING_AND_TAXING_INFORMATION',
  'PRIMARY_CONTACT',
  'TAXATION_AND_DOCUMENTS',
  'REVIEW_AND_COMPLETE',
  'COMPLETED',
  'ADDITIONAL_INFORMATION'
]

export const dashboardPath = ['/sellerLayout/dashboard'];

export const profileDetailsSubPaths = [
  '/sellerLayout/profileDetials/Overview',
  '/sellerLayout/profileDetials/LegalDetails',
  '/sellerLayout/profileDetials/Tax&Financials',
  '/sellerLayout/profileDetials/KYCDocuments',
  '/sellerLayout/profileDetials/Contacts',
  '/sellerLayout/profileDetials/BankAccount',
  '/sellerLayout/profileDetials/information',
  /* Admin Seller Url */
  '/admin/seller/sellerLayout/profileDetials/Overview',
  '/admin/seller/sellerLayout/profileDetials/LegalDetails',
  '/admin/seller/sellerLayout/profileDetials/BankAccount',
  '/admin/seller/sellerLayout/profileDetials/Tax&Financials',
  '/admin/seller/sellerLayout/profileDetials/KYCDocuments',
  '/admin/seller/sellerLayout/profileDetials/Contacts',
  '/admin/seller/sellerLayout/profileDetials/information'
]
export const WarehouseAndInventorySubPaths = [
  '/sellerLayout/inventory/ActiveLocations',
  '/sellerLayout/inventory/InactiveLocations',
  '/sellerLayout/InventoryManagement',
  '/sellerLayout/InventoryManagement/AllInventory',
  '/sellerLayout/InventoryManagement/ActiveInventory',
  '/sellerLayout/InventoryManagement/InActiveInventory',
  '/sellerLayout/AddLocations',
  '/sellerLayout/inventory/AddInventoryProducts',
    /* Admin Seller Url */
    '/admin/seller/sellerLayout/inventory/ActiveLocations',
    '/admin/seller/sellerLayout/inventory/InactiveLocations',
    '/admin/seller/sellerLayout/AddLocations',
    '/admin/seller/sellerLayout/InventoryManagement',
    '/admin/seller/sellerLayout/InventoryManagement/AllInventory',
    '/admin/seller/sellerLayout/InventoryManagement/ActiveInventory',
    '/admin/seller/sellerLayout/InventoryManagement/InActiveInventory',


]
export const productListSubPaths = [
  '/sellerLayout/productListing',
  '/sellerLayout/ListedProducts',
  '/sellerLayout/productListing/searchProduct',
  '/sellerLayout/productListing/searchProduct/catergoryVertical',
  '/sellerLayout/productListing/searchProduct/SubCategory',
  '/sellerLayout/productListing/searchProduct/Brand',
  "/sellerLayout/ListedProducts/ALLProducts",
  '/sellerLayout/ListedProducts/Listed',
  '/sellerLayout/ListedProducts/Delisted',
  '/sellerLayout/ListedProducts/Pending',
  '/sellerLayout/ListedProducts/Rejected',
  '/sellerLayout/Addproducts',
  '/sellerLayout/AddProducts',
  '/sellerLayout/productListing/bulkupload',
  /* Admin Seller Url */
  '/admin/seller/sellerLayout/productListing/searchProduct',
  '/admin/seller/sellerLayout/ListedProducts/ALLProducts',
  '/admin/seller/sellerLayout/ListedProducts/Listed',
  '/admin/seller/sellerLayout/ListedProducts/Delisted',
  '/admin/seller/sellerLayout/ListedProducts/Pending',
  '/admin/seller/sellerLayout/ListedProducts/Rejected',
  '/admin/seller/sellerLayout/Addproducts',
  '/admin/seller/sellerLayout/productListing/trackProduct',
  '/admin/seller/sellerLayout/productListing/bulkupload',
  '/admin/seller/sellerLayout/inventory/AddInventoryProducts'


]

export const UserSubPaths = [
  '/sellerLayout/UserManagement',
  '/sellerLayout/AddUser',
  '/sellerLayout/ViewUser'
  
]

export const settingsSubpaths = [
  '/sellerLayout/admin/settings',
  '/sellerLayout/admin/settings/registration', 
  '/sellerLayout/admin/settings/onboarding', 
  '/sellerLayout/admin/settings/history', 
]

export const parseAxiosError = (error: AxiosError): IErrorResponse => {
  if (error.isAxiosError && error.response) {
    return { status: STATUS.FAILURE, message: error.response.data.message, error }
  }

  return { status: STATUS.FAILURE, message: error.message, error }
}
// ajima
export const parseAxiosErrors = (error: AxiosError): IErrorResponses => {
  if (error.isAxiosError && error.response) {
    return { StatusMessage: STATUS.FAILURE, message: error.response.data.message, error }
  }

  return { StatusMessage: STATUS.FAILURE, message: error.message, error }
}



export const showNotification = (type: string, message: string): void => {
  const toasterOptions = { positionClass: 'toast-top-right' }
  toaster.remove();

  if (type === STATUS.SUCCESS) {
    toaster.success(message, '', toasterOptions)
  } else if (type === STATUS.FAILURE) {
    toaster.error(message, '', toasterOptions)
  } else if (type === STATUS.WARNING) {
    toaster.warning(message, '', toasterOptions)
  }
}


export const WEEK_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
export const ALL_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


/* Visibility Evaluation for Roles & Permissions */
export const isVisible = (roles: Array<string>, allowedRoles: { admin?: Array<string>, user?: Array<string> }): boolean => {
  
  
  if (!isEmpty(roles) && !isEmpty(allowedRoles)) {
    const adminRoles = allowedRoles?.admin ? allowedRoles.admin : [];
    const userRoles = allowedRoles.user;

    if (!isEmpty(adminRoles) && intersection(roles, adminRoles).length > 0) {
      return true;
    }
    else if (!isEmpty(userRoles) && intersection(roles, userRoles).length > 0) {
      return true;
    }

    return false;
  }

 return false;
}

/* Validate Roles */
export const validatePermission = (permission: Array<string>, allowedPermission: Array<string>): boolean => {

  if (!isEmpty(permission) && intersection(permission, allowedPermission).length > 0) {
    return true;
  }

  return false;
};


export const SellerInformationPermissions = [
  { value: "LEAGAL_CREATE", name: 'Create Legal Details' },
  { value: "LEAGAL_UPDATE", name: 'Update Legal Details' },
  { value: "LEAGAL_VIEW", name: 'View Legal Details' },
  { value: "BANK_CREATE", name: 'Create Bank Details' },
  { value: "BANK_UPDATE", name: 'Update Bank Details	' },
  { value: "BANK_VIEW", name: 'View Bank Details' },
  { value: "CONTACT_CREATE", name: 'Create Contact' },
  { value: "CONTACT_UPDATE", name: 'Update Contact' },
  { value: "CONTACT_VIEW", name: ' View Contact' },
  { value: "TAX_FINANCE_CREATE", name: 'Create Tax and Finance Information' },
  { value: "TAX_FINANCE_UPDATE", name: 'Update Tax and Finance Information' },
  { value: "TAX_FINANCE_VIEW", name: 'View Tax and Finance Information' },
  { value: "KYC_CREATE", name: 'Create KYC and Documents' },
  { value: "KYC_UPDATE", name: 'Update KYC and Documents' },
  { value: "KYC_VIEW", name: 'View KYC and Documents' },
  { value: "SELLER_SUMMARY", name: 'Seller Summary Screen' },
]


export const ProductListingPermission = [
  { value: "PRODUCT_VIEW", name: 'Search Product & View Listing' },
  { value: "PRODUCT_ADD", name: 'Add Product to Listing' },
  { value: "PRODUCT_EDIT", name: 'Edit Product in Listing' },
  { value: "PRODUCT_DELIST", name: 'Delist Listing' },
  { value: "PRODUCT_APPROVE_REJECT", name: 'Approve/Reject Listing	' },
  { value: "PRODUCT_BULK_UPLOAD", name: 'Bulk Upload Listing' },

]


export const InventoryPermission = [
  { value: "WAREHOUSE_CREATE", name: 'Create warehouse' },
  { value: "WAREHOUSE_EDIT", name: 'Edit warehouse' },
  { value: "WAREHOUSE_DELETE", name: 'Delete Warehouse' },
  { value: "WAREHOUSE_LIST", name: 'List Warehouse details' },
  { value: "WAREHOUSE_ADD_PRODUCT", name: 'Add Product to Warehouse' },
  { value: "WAREHOUSE_VIEW_STOCK", name: 'View Stock in Warehouse	' },
  { value: "WAREHOUSE_UPDATE_STOCK", name: 'Update Stock in Warehouse' },
  { value: "EXPORT_INVENTORY", name: 'Export Inventory' },
  { value: "BULK_UPLOAD_STOCK", name: 'Bulk Upload Stock' },

]


export const PurchaseOrderPermission = [
  { value: "PURCHASE_ORDER_VIEW", name: '	View Purchase Order' },
  { value: "PURCHASE_ORDER_SEARCH", name: '	Search / list Purchase Order	' },
  { value: "PURCHASE_ORDER_UPDATE", name: '	Update Purchase order status' },
  { value: "DC_INWARD", name: '	DC Inward' },
  { value: "EXPORT_ORDERS", name: 'Export Orders	' },

]

export const FinancePermission = [
  { value: "FINANCE_SUMMARY", name: 'Summary' },
  { value: "FINANCE_PAYMENT_LIST", name: 'List Payment Details' },
  { value: "FINANCE_DOWNLOAD_SETTLEMENT", name: 'Download Settlement details' },
  { value: "FINANCE_SEARCH_ORDER", name: 'Search by Order for Finance' },

]


export const SellersPermission = [
  { value: "SELLER_LIST", name: 'List Sellers' },
  { value: "SELECT_SELLER_LIST", name: 'Select Seller From List' },
  { value: "ACTIVATE_SELLER", name: 'Activate Seller'}


]

export enum LIST_OF_ROLES {
  /*Inventory and Warehouse*/
  "WAREHOUSE_CREATE" = "WAREHOUSE_CREATE",
  "WAREHOUSE_EDIT" = "WAREHOUSE_EDIT",
  "WAREHOUSE_DELETE" = "WAREHOUSE_DELETE",
  "WAREHOUSE_LIST" = "WAREHOUSE_LIST",
  "WAREHOUSE_VIEW_STOCK" = "WAREHOUSE_VIEW_STOCK",
  "WAREHOUSE_UPDATE_STOCK" = "WAREHOUSE_UPDATE_STOCK",
  "WAREHOUSE_ADD_PRODUCT" = "WAREHOUSE_ADD_PRODUCT",
  "EXPORT_INVENTORY" = "EXPORT_INVENTORY",
  "BULK_UPLOAD_STOCK" = "BULK_UPLOAD_STOCK",
  /*Product Listing*/
  "PRODUCT_VIEW" = "PRODUCT_VIEW",
  "PRODUCT_ADD" = "PRODUCT_ADD",
  "PRODUCT_EDIT" = "PRODUCT_EDIT",
  "PRODUCT_DELIST" = "PRODUCT_DELIST",
  "PRODUCT_APPROVE_REJECT" = "PRODUCT_APPROVE_REJECT",
  "PRODUCT_BULK_UPLOAD" = "PRODUCT_BULK_UPLOAD",
  /*Seller Information */
  "LEAGAL_CREATE" = "LEAGAL_CREATE",
  "LEAGAL_UPDATE" = "LEAGAL_UPDATE",
  "LEAGAL_VIEW" = "LEAGAL_VIEW",
  "BANK_CREATE" = "BANK_CREATE",
  "BANK_UPDATE" = "BANK_UPDATE",
  "BANK_VIEW" = "BANK_VIEW",
  "CONTACT_CREATE" = "CONTACT_CREATE",
  "CONTACT_UPDATE" = "CONTACT_UPDATE",
  "CONTACT_VIEW" = "CONTACT_VIEW",
  "TAX_FINANCE_CREATE" = "TAX_FINANCE_CREATE",
  "TAX_FINANCE_UPDATE" = "TAX_FINANCE_UPDATE",
  "TAX_FINANCE_VIEW" = "TAX_FINANCE_VIEW",
  "KYC_CREATE" = "KYC_CREATE",
  "KYC_UPDATE" = "KYC_UPDATE",
  "KYC_VIEW" = "KYC_VIEW",
  "SELLER_SUMMARY" = "SELLER_SUMMARY",
  "PURCHASE_ORDER_VIEW" = "PURCHASE_ORDER_VIEW",
  "PURCHASE_ORDER_SEARCH" = "PURCHASE_ORDER_SEARCH",
  "PURCHASE_ORDER_UPDATE" = "PURCHASE_ORDER_UPDATE",
  "DC_INWARD" = "DC_INWARD",
  "EXPORT_ORDERS" = "EXPORT_ORDERS"
}

export const rolesPersmission = ["Seller_Information", "Product_Listing","Inventory", "Purchase_Order", "Finance", "Sellers"]

export const paymentStatus = [
  { name: 'All', count: 0, path: '/sellerLayout/payments/all' },
  { name: 'Pending', count: 0, path: '/sellerLayout/payments/pending' },
  { name: 'Initiated', count: 0, path: '/sellerLayout/payments/initiated' }
]