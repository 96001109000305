import React, { useEffect, useState } from 'react'
import { Dialog } from '@material-ui/core'

import LayoutHeader from '../../../components/LayoutHeader'
import { InventoryService } from '../../../services/inventory.service'
import { showNotification, STATUS } from '../../../common/constant'
import ActionButton from '../../../components/ActionButton'
import { IErrorResponse, ISuccessResponse } from '../../../services/interfaces/common.interface'

import './styles.scss'

type MapLocationFormprops = {
  open: boolean
  cancelClose: () => void
  selectedProduct?: any
}

const MappedLocationsForm: React.FC<MapLocationFormprops> = ({ open, cancelClose, selectedProduct }) => {
  const inventoryService: InventoryService = new InventoryService()
  const [warehouselist, setWarehouselist] = useState<any>([])
  console.log(warehouselist);
  
  const [selectedWarehouse, setWarehouseSelected] = useState<number>(0)
  const [addInventory, setAddInventory] = useState<string[]>([])

  alert()

  /*GetWarehouse List Handler */
  const getWareHouse = async () => {
    try {
      const data = localStorage.getItem('seller_code')
      const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getwarehouselist(data, 1)

      setWarehouselist(getWareHouseResponse.data)
    } catch (error) {
      showNotification(STATUS.FAILURE, ' Unable to get users list')
    }
  }

  const warehouseSelectionHander = async (isSelected: boolean, selectedWarehouseCode: string) => {
    if (isSelected) {
      setWarehouseSelected(selectedWarehouse + 1)
    } else {
      setWarehouseSelected(selectedWarehouse - 1)
    }

    const addProductDetails: any = {
      inv_profile: '',
      erp_item_code: selectedProduct.erp_item_code,
      pim_code: selectedProduct.pim_code,
      psn: selectedProduct.psn,
      ext_prod_id: selectedProduct.ext_prod_id,
      sku: selectedProduct.sku,
      listing_status: 'Listed',
      reserverd_qty: 0,
      qty_on_hand: 0,
      backorder_qty: 0,

      backorder_avail_date: '0',

      warehouse_name: selectedWarehouseCode
    }
    setAddInventory([...addInventory, addProductDetails])
    console.log(selectedWarehouseCode)
  }

  const closeDialog = async () => {
    cancelClose()
    setWarehouseSelected(0)
  }

  // const AddProductToWarehouse = async () => {
  //     console.log(addInventory);

  //     const getWareHouseResponse = await inventoryService.addInventory(addInventory)
  //     console.log(getWareHouseResponse)
  // }
  useEffect(() => {
    getWareHouse()
  }, [])

  return (
    <Dialog className="addware-popup" open={open}>
      <LayoutHeader title="Map product to locations" />
      <div className="mapped-locations-dialog">
        <div className="locations-details-header-wrapper">
          <p>Location Details</p>
          <p>
            Selected<span className="warehouse-selection-number"> {selectedWarehouse}</span> /{warehouselist.length}
          </p>
        </div>
        {warehouselist?.map((data) => (
          <div className="round" key={data.warehouse_code}>
            <div className="warehouse-name-code-wrapper">
              <input
                id={data.warehouse_name}
                type="checkbox"
                onChange={(event) => warehouseSelectionHander(event.target.checked, data.warehouse_name)}
              />
              <label className="checkbox-label" htmlFor={data.warehouse_name} />
              <p className="days-content-wrapper">
                {data.warehouse_name} - {data.warehouse_code}{' '}
              </p>
              <br />
            </div>
            <p className="warehouse-address-wrapper">
              {data.address1} , {data.address2}
              {','} {data.city} , <br />
              {data.state} , {data.pincode}{' '}
            </p>
          </div>
        ))}

        <div className="workingdays-form-btn-wrapper">
          <ActionButton label="Cancel" varient="outlined" onClick={closeDialog} />
          <ActionButton label="Submit" />
        </div>
      </div>
    </Dialog>
  )
}

export default MappedLocationsForm
