import { showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { AccountsService } from 'services/accounts.service'
import { IContactInformation } from 'services/interfaces/accounts.interface'
import { IAPIResponse } from 'services/interfaces/common.interface'

import './style.scss'

type TAddContactDialog = {
  isEditMode?: boolean,
  contactId? : string,
  onClose: (isSaveAction: boolean) => void
}

type TContactInformation = {
  name: string
  email: string
  designation: string
  contactNumber: string
}

const formValidations = {
  name: {
    required: { value: true, message: "Name can't be Empty" }
  },
  designation: {
    required: { value: true, message: "designation can't be Empty" }
  },
  email: {
    required: { value: true, message: " Email can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: ' Email format is invalid' }
  },
  contactNumber: {
    required: { value: true, message: "Contact Number can't be Empty" },
    minLength: { value: 10, message: 'Contact Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Contact Number can't be more than 10 digits" },
    pattern: { value: /^[0-9\b]+$/i, message: 'Contact Number format is invalid' }
  }
}

const AddContactDialog: React.FC<TAddContactDialog> = ({ isEditMode, onClose, contactId }) => {
  const { register, errors, setValue, handleSubmit } = useForm<TContactInformation>({ mode: 'all' })
  const accountsService = new AccountsService();

  const saveContactHandler = async (contactData: IContactInformation) : Promise<void> => {
    try {
      contactData.code = localStorage.getItem('seller_code') as string;
      const data = await accountsService.saveContact(contactData);     
      
      if(data.status === STATUS.SUCCESS) {
        showNotification(STATUS.SUCCESS, 'Contact Saved Successfully') 
        onClose(true);
      } else {
        showNotification(STATUS.FAILURE, 'Unable to save contact')  
      }
      
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to save contact')
    }
  }

  const editContactHandler = async (contactData: IContactInformation) : Promise<void> => {
    try {
      contactData.code = localStorage.getItem('seller_code') as string;
      const data = await accountsService.updateContactInformation(contactId as string, contactData);
      
      if(data.status === STATUS.SUCCESS) {
        showNotification(STATUS.SUCCESS, 'Contact updated Successfully'); 
        onClose(true);
      } else {
        showNotification(STATUS.FAILURE, 'Unable to update contact');  
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to update contact');
    }
  }


  const onSubmit = async (contactData: IContactInformation): Promise<void> => {
    if (isEditMode) {
      await editContactHandler(contactData);

     return;
    }
 
    await saveContactHandler(contactData);
   }

  const getContactInformation = async () : Promise<void> => {
    try {
      const contactData: IAPIResponse = await  accountsService.getContactInformation(contactId as string);
      
      if (contactData.status === STATUS.SUCCESS) {
        setValue('name', contactData.data.contactInformation.name);
        setValue('email', contactData.data.contactInformation.email);
        setValue('contactNumber', contactData.data.contactInformation.contactNumber);
        setValue('designation', contactData.data.contactInformation.designation);
        
      } else {
        showNotification(STATUS.FAILURE, 'Unable to get contact Information');
      }
      
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get contact Information');
    }
  }

  const mobileNumberChangeHandler = (value: string): void => {
    /* filter only number from a string and limit the length to 10 */
    const filteredMobileNumber = value.replace(/\D/g, '').substring(0, 10)
    setValue('contactNumber', filteredMobileNumber)
  }

  useEffect(() => {

    if (isEditMode && contactId) {
      getContactInformation();
    }

  }, [isEditMode])

  return (
    <div className="add-contact-dialog">
      <h1 className="dialog-heading">Add New Contact</h1>
      <form className="add-contact-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <InputLabelwithDowninput
          label="Name *"
          error={errors.name?.type !== undefined}
          errorText={errors.name ? errors.name.message : ''}
          register={register(formValidations.name)}
          name="name"
        />
        <InputLabelwithDowninput
          label="Designation *"
          error={errors.designation?.type !== undefined}
          errorText={errors.designation ? errors.designation.message : ''}
          register={register(formValidations.designation)}
          name="designation"
        />
        <InputLabelwithDowninput
          label="Email *"
          error={errors.email?.type !== undefined}
          errorText={errors.email ? errors.email.message : ''}
          register={register(formValidations.email)}
          name="email"
        />
        <InputLabelwithDowninput
          label="Contact Number *"
          error={errors.contactNumber?.type !== undefined}
          onChange={(value)=> mobileNumberChangeHandler(value)}
          errorText={errors.contactNumber ? errors.contactNumber.message : ''}
          register={register(formValidations.contactNumber)}
          name="contactNumber"
        />
        <div className='flex-center action-button-wrapper'>
        <div className='flex50 flex-center'>
          <ActionButton label="Cancel"  varient='outlined' type="button" onClick={() => onClose(false)}/>
        </div>
        <div className='flex50 flex-center'>
        <ActionButton label="Save" type="submit" />
        </div>
        </div>
      </form>
    </div>
  )
}

export default AddContactDialog
