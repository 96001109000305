import { Step, StepButton, Stepper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './OnBoarding.scss'
import { isVisible, LIST_OF_ROLES, ON_BORADING_STEPS } from 'common/constant'
import poorvikaLogo from 'assets/poorvika-logo-white.png'
import Logout from 'components/Logout/Logout'
import LegalInformationForm from 'containers/form/onBoardingForms/LegalInformationsForm'
import ContactInformationFrom from 'containers/form/onBoardingForms/ContactInformationForm'
import BillingAndTaxingForm from 'containers/form/onBoardingForms/BillingAndTaxingForm'
import UploadDocumentsForm from 'containers/form/onBoardingForms/UploadDocumentsForm'
import DetailsConfirmationPage from 'containers/form/onBoardingForms/DetailsConfirmationPage'
import { RootStore, store } from 'redux/store'
import PendingAccountActivationPage from 'pages/Seller/PendingActivation'
import { LOGIN } from 'redux/actions/UserActionTypes'


type TOnBoardingProps = {
  isAdmin?: boolean
}


/* Seller on boarding wizard */
const OnBoarding: React.FC<TOnBoardingProps> = ({ isAdmin }) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const userStep = useSelector((state: RootStore) => state.userDetails.userDetails?.sellerOnBoardingStep);
  const userActive = useSelector((state: RootStore) => state.userDetails.userDetails?.isActive);
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
  const userData = useSelector((state: RootStore) => state.userDetails.userDetails);
  const role: string | null = localStorage.getItem('role');

  const steps = [
    'Legal Informations',
    'Billing & Taxing Informations',
    'Contacts',
    'Taxation & Documents',
    'Review & Complete'
  ]
  const history = useHistory()
  let isAllowed = false;

  useEffect(() => {
    const getOnBoardingStatusValue = async () => {
      /* Checking on boarding Step completed or not */
      console.log("userActive", userActive)

      if (userActive) {
        history.push('/landing')

      } else {
        /* Routing the user to the incomplete or rejected step */
      console.log("userStep", userStep)

        setActiveStep(ON_BORADING_STEPS.indexOf(userStep))
      }
    }


    if (role !== 'ROOT_ADMIN') {
      getOnBoardingStatusValue()
    }

  }, [])

  const handleNext = () => {
    // sellerDetails = { ...sellerDetails, ...data }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const confirmDetails = (isAdmin?: boolean) => {
    store.dispatch({
      type: LOGIN,
      payload: {...userData, sellerOnBoardingStep: 'COMPLETED' }
    })
    
    if (isAdmin) {
      history.goBack()
    } else {
      window.location.replace("/");
    }

  }

  const handleStep = (index: number) => {
    setActiveStep(index)
  }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
         isAllowed = isVisible([LIST_OF_ROLES.LEAGAL_CREATE], { user:userPermission })

        if(isAllowed)
        return <LegalInformationForm movePrevious={() => setActiveStep(activeStep - 1)} nextStep={() => handleNext()} />
        handleNext()
        break;
      case 1:
         isAllowed = isVisible([LIST_OF_ROLES.TAX_FINANCE_CREATE], { user: userPermission })

        if(isAllowed)
        return <BillingAndTaxingForm movePrevious={() => setActiveStep(activeStep - 1)} nextStep={() => handleNext()} />
        handleNext()
        break;
      case 2:
        isAllowed = isVisible([LIST_OF_ROLES.CONTACT_CREATE], { user: userPermission })

        if(isAllowed)
        return <ContactInformationFrom movePrevious={() => setActiveStep(activeStep - 1)} nextStep={() => handleNext()} />
        handleNext()
        break
      case 3:
        isAllowed = isVisible([LIST_OF_ROLES.KYC_CREATE], { user: userPermission })

        if(isAllowed)
        return <UploadDocumentsForm movePrevious={() => setActiveStep(activeStep - 1)} nextStep={() => handleNext()} />
        handleNext()
        break
      case 4:

        isAllowed = isVisible([LIST_OF_ROLES.SELLER_SUMMARY], { user: userPermission })

        if(isAllowed)
      
        return <DetailsConfirmationPage movePrevious={() => setActiveStep(activeStep - 1)} nextStep={() => confirmDetails(isAdmin)} />
        break

      default:
        return <PendingAccountActivationPage />

    }
  }

  return (
    <div>
      <div className="app-header">
        <img alt="poorvika-logo" src={poorvikaLogo} />
        <div className="right-hand-menu">
          <Logout />
        </div>
      </div>
      <div className="stepper-main-wrapper">
        {activeStep === 5 ? <> </> : <Stepper activeStep={activeStep} alternativeLabel nonLinear>
          {steps.map((label: string, index: number) => (
            <Step key={label}>
              <StepButton onClick={() => handleStep(index)}>{label}</StepButton>
              {/* <StepLabel>{label}</StepLabel> */}
            </Step>
          ))}
        </Stepper>}
        <div>
          <div>{getStepContent(activeStep)}</div>
        </div>
      </div>
    </div>
  )
}

export default OnBoarding
