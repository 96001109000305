import React from 'react'

import './styles.scss'

type TInputFieldProps = {
    text?:string,
    error?: boolean,
    errorText? : string,
    register?: any,
    name?: string
    hint?: string,
    dropdown: string[] | []
    notrequired?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const InputWithDropDown: React.FC<TInputFieldProps> = ({register, name, text, hint, error, errorText, dropdown,notrequired,...props}) => {
    return (
        <div className="input-with-dropdown">
           {notrequired ? <label className="txt-label">{text }  :</label> :
            <label className="txt-label">{text } <span className="label-star">*</span> :</label>}
            <div className="input-and-error"> 
            <input ref={register} list="drop-down" autoComplete="false" className={error ? 'input-field-box-error' : 'input-field-box'} name={name} placeholder={text} {...props}/>
            { dropdown.length ? <datalist id="drop-down">
                {dropdown.map((element: string) => {
                    return <option key={element} value={element}/>
                })}
            </datalist> : <datalist id="drop-down"/> }
            { error ? <p className="error-text">* {errorText}</p> : '' }
            { hint ? <p className="hint-text">{hint}</p> : '' }
            </div>
            
        </div>
    )
}

export default InputWithDropDown
