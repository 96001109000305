export const ACCOUNT_EMAIL = "ACCOUNT_EMAIL";


export type AccountEmail = {
    type: typeof ACCOUNT_EMAIL,
    payload: {
        email: string,
    }
}


export type OnBoardingDispatchType = AccountEmail