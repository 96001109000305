import React, { useEffect, useState } from 'react'
import './styles.scss'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Breadcrumbs, Grid } from '@material-ui/core'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import NavlinkCardcontent from '../../../components/NavlinkCardcontent'
import AppHeaderLayout from '../../../components/AppHeaderLayout'
import ActiveLocations from '../../../pages/LocationAndInventory/ActiveLocations'
import INActiveLocations from '../../../pages/LocationAndInventory/InActiveLocations'
import AddInventoryProducts from '../../../pages/LocationAndInventory/AddInventoryProducts'
import { isVisible, LIST_OF_ROLES } from '../../../common/constant'
import { RootStore } from '../../../redux/store'
import WarehouseAuditLogs from '../../../pages/AuditLogs/WareHouse'
import AddLocations from 'pages/LocationAndInventory/AddLocation'

const WarehouseAndInventory: React.FC = () => {
  const [activelocationStatus, setActiveLocationStatus] = React.useState<string>()
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)
  const [linkHeader, setLinkHeader] = useState<string>('Active Locations')

  const breadcrumbs = [
    <div className="bread-crumb-navlink" key="1">
      Location Details{' '}
    </div>,
    <div className="bread-crumb-navlink" key="2">
      {' '}
      {linkHeader}{' '}
    </div>
  ]

  useEffect(() => {
    setActiveLocationStatus(activelocationStatus)
  }, [activelocationStatus])

  return (
    <div className="location-inventory-page">
      <AppHeaderLayout
        title="LOCATION DETAILS :"
        status={activelocationStatus}
        to="/sellerLayout/inventory/ActiveLocations"
      />
      <Grid item md={11} xs={12} className="bread-crumb-layout">
        <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      <div className="profile-details-layout">
        <div className="pd-sidemenu">
          <div className="layout-Header">
            <p>Locations & Inventory</p>

            <KeyboardArrowRightIcon />
          </div>
          <div className="layout-card-content">
            {isVisible([LIST_OF_ROLES.WAREHOUSE_LIST], { user: userPermission }) ? (
              <>
                <NavlinkCardcontent
                  onSelected={(e) => setLinkHeader(e)}
                  label="Active Locations"
                  to="/sellerLayout/inventory/ActiveLocations"
                />
                <NavlinkCardcontent
                  onSelected={(e) => setLinkHeader(e)}
                  label="Inactive Locations"
                  to="/sellerLayout/inventory/InactiveLocations"
                />
              </>
            ) : (
              <> </>
            )}
            {isVisible([LIST_OF_ROLES.WAREHOUSE_CREATE], { user: userPermission }) ? (
              <NavlinkCardcontent
                onSelected={(e) => setLinkHeader(e)}
                label="Add Location"
                to="/sellerLayout/inventory/AddLocations"
              />
            ) : (
              <></>
            )}
            {isVisible(
              [
                LIST_OF_ROLES.WAREHOUSE_VIEW_STOCK,
                LIST_OF_ROLES.WAREHOUSE_UPDATE_STOCK,
                LIST_OF_ROLES.EXPORT_INVENTORY,
                LIST_OF_ROLES.BULK_UPLOAD_STOCK
              ],
              { user: userPermission }
            ) ? (
              <NavlinkCardcontent
                onSelected={(e) => setLinkHeader(e)}
                label="Inventory Management"
                to="/sellerLayout/InventoryManagement/AllInventory"
              />
            ) : (
              <></>
            )}
            <NavlinkCardcontent
              onSelected={(e) => setLinkHeader(e)}
              label="History"
              to="/sellerLayout/inventory/history"
            />
            {/* <NavlinkCardcontent label="Price Management" to="/sellerLayout/inventory/PriceManagement" />
                        <NavlinkCardcontent label="Inventory bulk upload" to="/sellerLayout/inventory/Inventorybulkupload" />
                        <NavlinkCardcontent label="Price bulk upload" to="/sellerLayout/inventory/Price bulk upload" /> */}
          </div>
        </div>
        <div className="pd-container">
          <Route
            component={() => <ActiveLocations onStatus={(data) => setActiveLocationStatus(data)} />}
            path="/sellerLayout/inventory/ActiveLocations"
          />
          <Route
            component={() => <INActiveLocations onStatus={(data) => setActiveLocationStatus(data)} />}
            path="/sellerLayout/inventory/InactiveLocations"
          />
          <Route component={AddLocations} path="/sellerLayout/inventory/AddLocations" />
          <Route component={AddInventoryProducts} path="/sellerLayout/inventory/AddInventoryProducts" />
          <Route component={WarehouseAuditLogs} path="/sellerLayout/inventory/history" />
        </div>
      </div>
    </div>
  )
}

export default WarehouseAndInventory
