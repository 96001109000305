import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { ON_BORADING_STEPS, showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import InputWithLabels from 'components/InputWithLabels'
import WarningTextLayer from 'components/WarningTextLayer'
import { RootStore } from 'redux/store'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { OnboardingService } from 'services/onBoarding.service'

import './styles.scss'

type IContactInformations = {
  title: string,
  designation: string,
  name: string,
  office: string,
  mobile: string,
  primaryContactemail: string,
  esclation_title: string,
  esclation_name: string,
  esclation_mobile: string,
  esclation_email: string,
  esclation_designation: string
}
type TContactReviewFormProps = {

  onSelected: () => void;
}

const contactFormValidations = {

  title: { required: { value: false, message: "Title is required" } },
  name: { required: { value: false, message: "Name is required" } },
  designation: { required: { value: false, message: "Designation is required" } },
  esclation_designation: { required: { value: true, message: "Esclation Designation is required" } },
  office: { required: { value: false, message: "Office Number is required" } },
  mobile: { 
    required: { value: false, message: "Mobile Number is required" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" } },
  primaryContactemail: {
    required: { value: false, message: "Email is required" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
  esclation_title: { required: { value: true, message: "Title is required" } },
  esclation_name: { required: { value: true, message: "Name is required" } },
  esclation_mobile: { required: { value: true, message: "Mobile Number is required" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" } },
  esclation_email: {
    required: { value: true, message: "Email is required" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
}

const PrimaryContactReviewForm: React.FC<TContactReviewFormProps> = ({ onSelected }) => {
  const { register, handleSubmit, errors, setValue } = useForm<IContactInformations>({ mode: 'all' })
  const [rejectionNotes, setRejectionNotes] = useState<string>("")
  const onboardingService = new OnboardingService();
  const selectedUserEmail = useSelector((state: RootStore) => state.onBoardingData.userEmail?.email);

  const onSubmit = async (formData: IContactInformations) => {

    if (rejectionNotes) {

      try {
        const saveRejectionNotesResponse: ISuccessResponse | IErrorResponse = await onboardingService.updateOnBoardingData(
          {
            ...formData,
            step: ON_BORADING_STEPS[2],
            email: selectedUserEmail as string,
            rejection_notes: {
              step: ON_BORADING_STEPS[2],
              notes: rejectionNotes
            }
          })

        if (saveRejectionNotesResponse.status === STATUS.SUCCESS) {
          showNotification(STATUS.SUCCESS, 'Rejection notes saved successfully')
        } else {
          showNotification(STATUS.FAILURE, 'Rejection notes not saved')
        }
        onSelected();
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Rejection notes not saved')
      }

    } else {
      showNotification(STATUS.FAILURE, 'Add Rejection Notes')
    }

  }


  useEffect(() => {

    const getContactInformations = async () => {
      try {
        const contactInformations: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(3, selectedUserEmail as string);

        if (contactInformations.status === STATUS.SUCCESS) {
          const successResponse = contactInformations as ISuccessResponse;

          setValue("title", successResponse.data.title);
          setValue("designation", successResponse.data.designation);
          setValue("name", successResponse.data.name);
          setValue("office", successResponse.data.office);
          setValue("mobile", successResponse.data.mobile);
          setValue("esclation_designation", successResponse.data.esclation_designation);
          setValue("primaryContactemail", successResponse.data.email);
          setValue("esclation_title", successResponse.data.esclation_title ? successResponse.data.esclation_title : "MR");
          setValue("esclation_name", successResponse.data.esclation_name);
          setValue("esclation_mobile", successResponse.data.esclation_mobile);
          setValue("esclation_email", successResponse.data.esclation_email);
          setRejectionNotes(successResponse.data.rejection_notes)

        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Contact informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Contact informations')
      }
    }

    getContactInformations();

  }, [])



  return (
    <div>
      <WarningTextLayer info={rejectionNotes} closeIcon />
      <form action="" className="add-contact-form" onSubmit={handleSubmit(onSubmit)}>
        <p className="form-subtitle">Contact Information</p>
        <div className="account-title-and-name">
          <label className="txt-label">Name :</label>
          <select className="input-selector"
            name="title"
            ref={register(contactFormValidations.title)}>
            <option value="MR" selected>Mr</option>
            <option value="MRS">Mrs</option>
            <option value="MS">Ms</option>
          </select>
          <input
            className="name-box"
            placeholder="Name"
            type="text"
            name="name"
            ref={register(contactFormValidations.name)}
          />

        </div>
        <div className="contact-name-error">
          {(errors.name?.type !== undefined) ? <p className="error-text">* {errors.name ? errors.name.message : ''}</p> : ''}
        </div>

        <InputWithLabels
          text="Designation"
          error={errors.designation?.type !== undefined}
          errorText={errors.designation ? errors.designation.message : ''}
          name="designation"
          register={register(contactFormValidations.designation)}
        />
        <InputWithLabels
          text="Office Phone"
          name="office"
          maxLength={15}
          onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
          error={errors.office?.type !== undefined}
          errorText={errors.office ? errors.office.message : ''}
          register={register(contactFormValidations.office)}
        />
        <InputWithLabels
          text="Mobile"
          name="mobile"
          maxLength={10}
          onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
          error={errors.mobile?.type !== undefined}
          errorText={errors.mobile ? errors.mobile.message : ''}
          register={register(contactFormValidations.mobile)}
        />
        <InputWithLabels
          text="Email"
          name="primaryContactemail"
          error={errors.primaryContactemail?.type !== undefined}
          errorText={errors.primaryContactemail ? errors.primaryContactemail.message : ''}
          register={register(contactFormValidations.primaryContactemail)}
        />
        <p className="form-subtitle">Escalation Contact Information</p>
        <div className="account-title-and-name">
          <label className="txt-label">Name * :</label>
          <select className="input-selector"
            name="esclation_title"
            ref={register(contactFormValidations.title)}>
            <option value="MR" selected>Mr</option>
            <option value="MRS">Mrs</option>
            <option value="MS">Ms</option>
          </select>
          <input
            className="name-box"
            placeholder="Name"
            type="text"
            name="esclation_name"
            ref={register(contactFormValidations.esclation_name)}
          />

        </div>
        <div className="contact-name-error">
          {(errors.esclation_name?.type !== undefined) ? <p className="error-text">* {errors.esclation_name ? errors.esclation_name.message : ''}</p> : ''}
        </div>
        <InputWithLabels
          text="Designation"
          name="esclation_designation"
          register={register(contactFormValidations.esclation_designation)}
          error={errors.esclation_designation?.type !== undefined}
          errorText={errors.esclation_designation ? errors.esclation_designation.message : ''}
        />
        <InputWithLabels
          text="Mobile"
          name="esclation_mobile"
          maxLength={10}
          onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
          error={errors.esclation_mobile?.type !== undefined}
          errorText={errors.esclation_mobile ? errors.esclation_mobile.message : ''}
          register={register(contactFormValidations.esclation_mobile)}
        />
        <InputWithLabels
          text="Email"
          name="esclation_email"
          error={errors.esclation_email?.type !== undefined}
          errorText={errors.esclation_email ? errors.esclation_email.message : ''}
          register={register(contactFormValidations.esclation_email)}
        />
        <p className="form-subtitle">Rejection notes</p>
        <InputWithLabels
          text="Rejection Notes"
          value={rejectionNotes}
          onChange={(event) => setRejectionNotes(event.target.value)}
        />
        <div>
          <ActionButton label="Add Rejection Notes & Save" type="submit" />
        </div>
      </form>
    </div>
  )
}

export default PrimaryContactReviewForm
