import React from 'react'
import ActionButton from 'components/ActionButton'
import { AccountsService } from 'services/accounts.service'

import './styles.scss'
import { showNotification, STATUS } from 'common/constant'

type TDeleteDialogProps = {
  id: string,
  onClose: (isDeleted: boolean) => void
}

const DeleteContactDialog: React.FC<TDeleteDialogProps> = ({onClose, id}) => {

    const accountsService = new AccountsService();

    const deleteActionHandler = async (isDelete: boolean): Promise<void> => {
        if(isDelete) {
            try {
                const data = await accountsService.deleteContact(id);
                
                if(data.status === STATUS.SUCCESS) {
                    showNotification(STATUS.SUCCESS, 'Contact deleted successfully');
                    onClose(true);
                } else {
                    showNotification(STATUS.FAILURE, 'Unable to delete the contact')
                }

            } catch (error) {
                showNotification(STATUS.FAILURE, 'Unable to delete the contact')
            }
            





            console.log("========>", isDelete, id);
        } else {
            onClose(isDelete)
        }

    };

  return (
    <div className="delete-contact-dialog">
      <h1>Do you want delete this contact ?</h1>
      <p>Once the contact is deleted, it cannot be recovered</p>
      <div className="flex-center btn-wrapper">
      <ActionButton label="Cancel" varient='outlined' onClick={() => deleteActionHandler(false)}/>
      <ActionButton label="Delete" onClick={() => deleteActionHandler(true)} />
      </div>
    </div>
  )
}

export default DeleteContactDialog
