import React from 'react'

import "./styles.scss"

type IButtonLinkProps = {
    text: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const LinkButton: React.FC<IButtonLinkProps> = ({text, ...props}) => {
    return <button className="btn-link" type="button" {...props}>{text}</button>
}

export default LinkButton
