import React from 'react'

import './styles.scss'

type ButtonProps = {
    label: string;
    varient?: string;
    onClick?: (a?:any)=> void;
    type?: any,
    open?: boolean,
    disabled?:boolean
}

const ActionButton: React.FC<ButtonProps> = ({label, varient, onClick, type, disabled}) => {
    return (
      <button disabled={disabled} className={varient === 'outlined' ? 'outlined' : 'btn-action' } type={type} onClick={onClick}>{label}</button>
    )
}

export default ActionButton
