import { AxiosError } from 'axios'

import { API_ENDPOINTS, parseAxiosError } from 'common/constant'

import { IErrorResponse, ISuccessResponse } from './interfaces/common.interface'
import { BaseService } from './base.service'

export class UserService extends BaseService {
  public async getUserList() {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_USERS_LIST)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getUsersList(
    pageCount?: number,
    page?: number,
    searchText?: string,
    emailText?: string,
    mobileText?: string,
    accountText?: string,
    roleText?: string,
    isactiveQuery?: boolean,
    orderBy?: string, 
    sort?: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    const searchQuery = searchText ? encodeURIComponent(searchText) : ''
    const emailQuery = emailText ? encodeURIComponent(emailText) : ''
    const mobileQuery = mobileText ? encodeURIComponent(mobileText) : ''
    const accountQuery = accountText ? encodeURIComponent(accountText) : localStorage.getItem('code')
    const roleQuery = roleText ? encodeURIComponent(roleText) : ''
    const orderbyQuery = orderBy ? encodeURIComponent(orderBy) : ''
    const sortQuery = sort ? sort : ''

    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.GET_USER_LIST +
          `?limit=${pageCount}&page=${page}&search=${searchQuery}&email=${emailQuery}&mobile=${mobileQuery}&account=${accountQuery}&role=${roleQuery}&isactive=${isactiveQuery}&orderbyvalues=${orderbyQuery}&sort=${sortQuery}`)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getPmplUsersList(
    pageCount?: number,
    page?: number,
    searchText?: string,
    emailText?: string,
    mobileText?: string,
    accountText?: string,
    roleText?: string,
    isactiveQuery?: boolean,
    orderBy?: string, 
    sort?: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    const searchQuery = searchText ? encodeURIComponent(searchText) : ''
    const emailQuery = emailText ? encodeURIComponent(emailText) : ''
    const mobileQuery = mobileText ? encodeURIComponent(mobileText) : ''
    const accountQuery = accountText ? encodeURIComponent(accountText) : localStorage.getItem('code')
    const roleQuery = roleText ? encodeURIComponent(roleText) : ''
    const orderbyQuery = orderBy ? encodeURIComponent(orderBy) : ''
    const sortQuery = sort ? sort : ''

    try {
      const { data } = await this.httpClient.get(
        API_ENDPOINTS.PMPL_GET_USER_LIST +
          `?limit=${pageCount}&page=${page}&search=${searchQuery}&email=${emailQuery}&mobile=${mobileQuery}&account=${accountQuery}&role=${roleQuery}&isactive=${isactiveQuery}&orderbyvalues=${orderbyQuery}&sort=${sortQuery}`)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}
