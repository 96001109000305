import { AxiosError } from 'axios';
import { API_ENDPOINTS, parseAxiosError } from 'common/constant';
import { BaseService } from 'services/base.service';
import { IAPIResponse } from './interfaces/common.interface';
import { ISetting } from './interfaces/settings.interface';
export class SettingService extends BaseService {

  public async getSettings(value: string): Promise<IAPIResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_SETTINGS+`?name=${value}`);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async updateSettings(settingsInformation: ISetting): Promise<IAPIResponse> {
   
    try {
      const { data } = await this.httpClient.put(
        `/settings`,
       {value:  settingsInformation.value, url: settingsInformation.url })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}