import {EDIT_LOCATION } from "redux/actions/inventory.action";


type Location= {
    editLocation:any
}


const initialState = {
    editLocation:undefined
}

const InventoryReducer = (state = initialState, action: any): Location => {
    switch (action.type) {
      case EDIT_LOCATION:
        return {
          ...state,
          editLocation: action.payload
        }
      default:
        return state
    }
  }

  export default InventoryReducer;