import React, { useState } from 'react'
import { Route } from 'react-router-dom'

import './styles.scss'
// import AppHeaderLayout from 'components/AppHeaderLayout'
import NavlinkCardcontent from 'components/NavlinkCardcontent'

import RegistrationSettings from './registration'
import OnboardingSettings from './onboarding'
import { Breadcrumbs, Grid } from '@material-ui/core'
import AppHeaderLayout from 'components/AppHeaderLayout'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// import InputLabelwithDowninput from 'components/InputLabelwithDownInputField';

const SettingsPage: React.FC = () => {
  
  const [linkHeader, setLinkHeader] = useState<string>("Registration");

  const breadcrumbs = [
    <div className='bread-crumb-navlink' key="1" >Settings</div>,
    <div className='bread-crumb-navlink' key="3" > {linkHeader} </div>
];
  return (
    <div className="settings-page">
      <AppHeaderLayout title="Seller Portal Settings" />
      <Grid item md={11} xs={12} className='bread-crumb-layout'>
                <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
      <div className="settings-page-container">
        <div className="settings-page-sidemenu">
          <div className="layout-Header">
            <p>Seller Portal Settings</p>
          </div>
          <div className="layout-card-content">
            <NavlinkCardcontent
              label="Registration"
              to="/sellerLayout/admin/settings/registration"
              onSelected={(e) => setLinkHeader(e)}
            />
            <NavlinkCardcontent
              label="On-Boarding"
              to="/sellerLayout/admin/settings/onboarding"
              onSelected={(e) => setLinkHeader(e)}
            />
            <NavlinkCardcontent
              label="History"
              to="/sellerLayout/admin/settings/history"
              onSelected={(e) => setLinkHeader(e)}
            />
          </div>
        </div>
        <div className="settings-page-content">
        <Route component={RegistrationSettings} path="/sellerLayout/admin/settings/registration" />
        <Route component={OnboardingSettings} path="/sellerLayout/admin/settings/onboarding" />
        </div>
      </div>
    </div>
  )
}

export default SettingsPage
