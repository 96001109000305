
import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

type TDashboardCardProps = {
title: string, 
count: string,
link?:string, 
color?: string,
Icon?: any
}


const DashboardCard: React.FC<TDashboardCardProps> = ({title, count, color, Icon, link}) => {
    return (
        <Link to={link ?? '#'} className='dashboard-link'>
        <div className={`dashboard-card ${color}`}>
            <div className="icon-area">
                <div className="circle-layer">
                {/* <StorefrontIcon className="dashboard-card-icon"/> */}
                {Icon}
                </div>
            
            </div>
            <div className="count-area">
                <p className="count-description">{title}</p>
                <h1 className="count">{count}</h1>
            </div>
        </div>
        </Link>
        
    )
}

export default DashboardCard
