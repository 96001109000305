import React from 'react'

import './styles.scss'
import { ADDITIONAL_INFORMATION, BANK_AND_FINANCIAL, CONTACT_INFORMATION, LEGAL_INFORMATIONS, PROFILE_INFORMATION, TAX_AND_FINACIAL_INFORMATION } from '../../redux/actions/profile.action'
import { store } from 'redux/store'
import LinkButton from 'components/LinkButton'

type CardHeaderProps = {
  title: string
  edit?: boolean
  action?: string
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, edit, action }) => {
  const editActionHandler = () => {
    switch (action) {
      case ADDITIONAL_INFORMATION:
        store.dispatch({ type: PROFILE_INFORMATION, payload: { additionalInformation: { edit: true } } })
        break
      case CONTACT_INFORMATION:
        store.dispatch({ type: PROFILE_INFORMATION, payload: { contactDetails: { edit: true } } })
        break
      case TAX_AND_FINACIAL_INFORMATION:
        store.dispatch({ type: PROFILE_INFORMATION, payload: { taxAndFinancialsDetails: { edit: true } } })
        break
      case BANK_AND_FINANCIAL:
        store.dispatch({ type: PROFILE_INFORMATION, payload: { bankAccountDetails: { edit: true } } })
       break
       case LEGAL_INFORMATIONS:
        store.dispatch({ type: PROFILE_INFORMATION, payload: { legalDetails: { edit: true } } })
       break
      default:
        break
    }
  }

  return (
    <div className="legal-details-Header">
      <p>{title}</p>
      <div className="edit-delete-btn-wrapper">
        {edit ? (
         <LinkButton text="Edit" onClick={() => editActionHandler()}/>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default CardHeader
