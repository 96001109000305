 import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import AuthRoute from 'components/AuthRoute'
import EmailActivationSentPage from 'pages/EmailActivationConfirmationPage'
import LoginPage from 'pages/login/LoginPage'
import SSOLoginPage from 'pages/login/SSOLoginPage'
import SSOPage from 'pages/login/SSOPage'
import OnBoarding from 'pages/OnBoarding/OnBoarding'
import SignUp from 'pages/signup/SignUp'
import {store, persistor} from 'redux/store'
import LandlingPage from 'pages/LandingPage'
import SellerLayout from 'Layouts/NewHomeLayout'
import ForgotPasswordPage from 'pages/forgotPasswordPage'
import ResetPasswordPage from 'pages/resetPasswordPage'
import ResendActivationLinkPage from 'pages/ResendActivationPage'

import './App.scss'

function App(): React.ReactElement {
  return (
    <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>

    <Router>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>
        <Route component={LoginPage} path="/login" />
        <Route component={SSOLoginPage} path="/sso-login" />
        <Route component={SSOPage} path="/ssoLogin" />
        
        <AuthRoute component={OnBoarding} path="/onBoarding" />
        <Route component={LandlingPage} path="/landing" />
        <Route component={SellerLayout} path="/sellerLayout" />
        
        <Route component={SignUp} path="/register" />
        <Route component={EmailActivationSentPage} path="/emailActivation"/>
        <Route component={ForgotPasswordPage} path="/forgotPassword" />
        <Route component={ResetPasswordPage} path="/resetPassword" />
        <Route component={ResendActivationLinkPage} path="/resendActivationLink" />

      </Switch>
    </Router>
    </PersistGate>

    </Provider>
  )
}

export default App
