import { AxiosError } from "axios";


import { API_ENDPOINTS, parseAxiosError } from "common/constant";

import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/common.interface";
import { IWarehouseDetails } from "./interfaces/inventory.interface";


export class InventoryService extends BaseService {
  
 

  public async uploadDocument(uploadfile: FormData): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const seller_code = localStorage.getItem('seller_code');
    const url = `${API_ENDPOINTS.WAREHOUSE}${API_ENDPOINTS.BULK_UPLOAD_INVENTORY}?SellerCode=${seller_code}`;
    
      const { data } = await this.httpClient.post(url, uploadfile);

        return data;
    } catch (error) {
        return parseAxiosError(error as AxiosError)
    }
}

public async downloadDocument(): Promise<ISuccessResponse | IErrorResponse> {
  try {
    const seller_code = localStorage.getItem('seller_code');
    const url = `${API_ENDPOINTS.WAREHOUSE}${API_ENDPOINTS.DOWNLOAD_INVENTORY}${seller_code}/all`
    const { data } = await this.httpClient.get(url);

      return data;
  } catch (error) {
      return parseAxiosError(error as AxiosError)
  }
}

public async getUploadHistory(): Promise<ISuccessResponse | IErrorResponse> {
  try {
    const seller_code = localStorage.getItem('seller_code');
    const url = `${API_ENDPOINTS.WAREHOUSE}${API_ENDPOINTS.UPLOAD_INENTORY_HISTORY}?SellerCode=${seller_code}`;
    const { data } = await this.httpClient.get(url);

      return data;
  } catch (error) {
      return parseAxiosError(error as AxiosError)
  }
}
 



  public async addwarehouse(WarehouseData: IWarehouseDetails): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.WAREHOUSE + 'warehouse', WarehouseData);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async updatewarehouse(WarehouseData): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.put(`${API_ENDPOINTS.WAREHOUSE + 'warehouse'}/${WarehouseData.Warehouse_code}`, WarehouseData);


      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async deletewarehouse(WarehouseCode): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.delete(API_ENDPOINTS.WAREHOUSE + 'warehouse', {
        data: { "warehouse_code": WarehouseCode }
      });



      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getwarehouselist(seller_code: string | null, status: number): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.WAREHOUSE + 'warehouse/' + seller_code + "/" + status);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

 




  public async getInventorylist(listingStatus: string, pageNo: number) {

    const seller_code = localStorage.getItem('seller_code')

    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.WAREHOUSE + 'inventory/list/' + seller_code + '/' + listingStatus + '/' + pageNo);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async addInventory(productData, warehouse_code): Promise<ISuccessResponse | IErrorResponse> {
    const seller_code = localStorage.getItem('seller_code')

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.WAREHOUSE + 'inventory/' + seller_code + '/' + warehouse_code, productData);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }



  public async updateStock(productData, pimCode): Promise<ISuccessResponse | IErrorResponse> {


    try {
      const { data } = await this.httpClient.put(`${API_ENDPOINTS.WAREHOUSE + 'stock'}/${pimCode}`, productData);


      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async multipleSearchProduct(status: string, pageNo: number, searchInput, orderbyValues?: string, sortType?: string): Promise<ISuccessResponse | IErrorResponse> {
    const Seller_code = localStorage.getItem('seller_code')
    sortType = sortType ? sortType: '';
    orderbyValues = orderbyValues ? orderbyValues : ''

    try {
      if (orderbyValues) {
        const { data } = await this.httpClient.get(API_ENDPOINTS.WAREHOUSE + 'inventory/list/' + `${Seller_code}/${status}/${pageNo}?erp_item_code=&ext_prod_id=&sku=&qty_on_hand=&warehouse_name=&orderby=${orderbyValues}&sort=${sortType}`)

        return data
      }
 
        const { data } = await this.httpClient.get(API_ENDPOINTS.WAREHOUSE + 'inventory/list/' + `${Seller_code}/${status}/${pageNo}?erp_item_code=${searchInput?.erp_item_code}&ext_prod_id=${searchInput?.ext_prod_id}&sku=${searchInput?.sku}&psn=${searchInput?.psn}&warehouse_name=${searchInput?.warehouse_name}&min_stock=${searchInput?.minStock}&brand=${searchInput?.brand}&category=${searchInput?.category}&max_stock=${searchInput?.maxStock}&orderby=${orderbyValues}&sort=`)

        return data
      

    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}