//import React from 'react'

import React, { useState } from 'react';
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import { showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import LinkButton from 'components/LinkButton'

import './styles.scss'

import { IAPIResponse} from 'services/interfaces/common.interface';
import { SettingService } from 'services/settings.service';

const RegistrationSettings: React.FC = () => {
    const [edit, setEdit] = useState(false)
    const [url, seturl] = useState("")
    const settingService: SettingService = new SettingService()
    const Settingsupdate = async () => {
        try {
        const UpdateResponse: IAPIResponse = await settingService.updateSettings({value:"TERMS_AND_CONDITIONS",url});
        
        if (UpdateResponse.status === STATUS.SUCCESS) {
            setEdit(false);
            showNotification(STATUS.SUCCESS, 'Settings Updated Successfully');
          } else {
            showNotification(STATUS.FAILURE,'Unable to update Settings');   
          }
        } catch (error) {
            showNotification(STATUS.FAILURE,'Unable to update Settings');
          }

    }

    return (
        <div className='settings-container registration-settings'>
            <div className="settings-header">
                Registration Settings
                {!edit ? (
                        <LinkButton text="Edit" onClick={() => setEdit(true)} />
                    ) : (
                        <></>
                    )}
            </div>
            <div className="settings-content">
                <InputLabelwithDowninput label='Terms & Conditions Link' onChange={(e) => seturl(e)} disabled={!edit} />
                <div className="action-button-wrapper">\
                {edit ?<ActionButton label='Save' type="submit" onClick={() => Settingsupdate()} />: <></>}
                </div>
            </div>
        </div>
    )
}

export default RegistrationSettings
