import React from 'react'
import './style.scss'


type ButtonProps = {
    label: string;
    varient?: boolean;
    onClick?: ()=> void;
   
}


const ChipsButton:React.FC<ButtonProps>  =({label, varient, onClick}) => {
  
  
    return (
        <div className="marginlr">
            <button className={varient === true ? "chip-btn-bg" : "chip-btn"} onClick={onClick}>{label} </button>
        </div>

    )
}

export default ChipsButton;
