import React, { useState } from 'react'
import './styles.scss'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useLocation, Route } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Breadcrumbs, Grid } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavlinkCardcontent from 'components/NavlinkCardcontent';
import AppHeaderLayout from 'components/AppHeaderLayout';
import ProfileDetailsOverview from 'pages/ProfileDetails/OverviewPage';
import LegalDetails from 'pages/ProfileDetails/LegalDetails';
import BankAccountPage from 'pages/ProfileDetails/BankAccount';
import TaxFinancialsPage from 'pages/ProfileDetails/Tax&Financials';
import KYCDocumentsPage from 'pages/ProfileDetails/KYC Documents';
import ContactsPage from 'pages/ProfileDetails/Contacts';
import AdditionalInfoPage from 'pages/ProfileDetails/Additionalinfo';
import { RootStore } from 'redux/store';
import { isVisible, LIST_OF_ROLES, productsDetailsSubpath, validatePermission } from 'common/constant';
import { BANK_DETAILS_SUBMENU, CONTACT_SUBMENU, LEGAL_DETAILS_SUBMENU, TAX_AND_FINANCIALS_DETAILS_SUBMENU } from 'common/permissionMatrix';




const ProfileDetails: React.FC = () => {
    const { pathname } = useLocation()
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
    const [linkHeader, setLinkHeader] = useState<string>("Overview");

    const breadcrumbs = [
        <div className='bread-crumb-navlink' key="1" >Profile Details </div>,
        <div className='bread-crumb-navlink' key="2" > {linkHeader} </div>,
    ];

    return (
        <div className="profile-details-page">
            <AppHeaderLayout title="Profile Details:" />
            <Grid item md={11} xs={12} className='bread-crumb-layout'>
                <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <div className="profile-details-layout">

                <div className="pd-sidemenu">
                    <div className="layout-Header">
                        <p>Profile Details</p>
                        <KeyboardArrowRightIcon />
                    </div>
                    <div className="layout-card-content">

                        <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} isActive={() => productsDetailsSubpath.includes(pathname)} label="Overview" to="/sellerLayout/profileDetials/Overview" />
                        {validatePermission(LEGAL_DETAILS_SUBMENU, userPermission) ? <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="Legal Details" to="/sellerLayout/profileDetials/LegalDetails" /> : <></>}

                        {isVisible(BANK_DETAILS_SUBMENU, { user: userPermission }) ? <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="Bank Account" to="/sellerLayout/profileDetials/BankAccount" /> : <></>}

                        {isVisible(TAX_AND_FINANCIALS_DETAILS_SUBMENU, { user: userPermission }) ? <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="Tax & Financials" to="/sellerLayout/profileDetials/Tax&Financials" /> : <></>}
                        {isVisible([LIST_OF_ROLES.KYC_UPDATE], { user: userPermission }) ? <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="KYC Documents" to="/sellerLayout/profileDetials/KYCDocuments" /> : <></>}
                        {validatePermission(CONTACT_SUBMENU, userPermission) ? <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="Contacts" to="/sellerLayout/profileDetials/Contacts" /> : <></>}
                        {isVisible(TAX_AND_FINANCIALS_DETAILS_SUBMENU, { user: userPermission }) ? <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="Additional Information" to="/sellerLayout/profileDetials/information" /> : <></>}

                    </div>
                </div>
                <div className="pd-container">
                    <Route component={ProfileDetailsOverview} path="/sellerLayout/profileDetials/Overview" />

                    {validatePermission(LEGAL_DETAILS_SUBMENU, userPermission) ? <Route component={LegalDetails} path="/sellerLayout/profileDetials/LegalDetails" /> : null}
                    {isVisible([LIST_OF_ROLES.BANK_VIEW], { user: userPermission }) ? <Route component={BankAccountPage} path="/sellerLayout/profileDetials/BankAccount" /> : null}
                    {isVisible([LIST_OF_ROLES.TAX_FINANCE_VIEW], { user: userPermission }) ? <Route component={TaxFinancialsPage} path="/sellerLayout/profileDetials/Tax&Financials" /> : null}
                    {isVisible([LIST_OF_ROLES.KYC_UPDATE], { user: userPermission }) ? <Route component={KYCDocumentsPage} path="/sellerLayout/profileDetials/KYCDocuments" /> : null}
                    {validatePermission(CONTACT_SUBMENU, userPermission) ? <Route component={ContactsPage} path="/sellerLayout/profileDetials/Contacts" /> : null}
                    {isVisible([LIST_OF_ROLES.TAX_FINANCE_VIEW], { user: userPermission }) ? <Route component={AdditionalInfoPage} path="/sellerLayout/profileDetials/information" /> : null}
                    
                </div>
            </div>
        </div>

    )



}

export default ProfileDetails