import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { useHistory, useLocation } from 'react-router-dom'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import LayoutHeader from 'components/LayoutHeader'
import InputLabelinputWarehouse from 'components/InputLabelAndInputFieldforWarehouse'
import InputLabelwithContent from 'components/InputLabelwithContent'
import MapsContainer from 'components/MapContainer'
import { IWarehouseDetails } from 'services/interfaces/inventory.interface'
import InputLabelDownWithDropDown from 'components/InputlabeldownwithDropdown'
import WorkingDaysForm from 'containers/form/WorkingDaysForm'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ActionButton from 'components/ActionButton'
import { InventoryService } from 'services/inventory.service'
import { showNotification, STATUS } from 'common/constant'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { OnboardingService } from 'services/onBoarding.service'


import './styles.scss'
import { Istate } from 'services/interfaces/state.interface';
import { useSelector } from 'react-redux';
import { RootStore } from 'redux/store';
import Spinner from 'components/Spinner';

type ILocationForm = {
  warehouse_name: string
  Warehouse_code: string
  Ext_warehouse_code: string
  location_code: string
  registered_address: string
  working_hours_and_days: string
  primary_contact_name: string
  primary_contact_email: string
  primary_contact_mobile: string
  escalations_contact_name: string
  escalations_contact_email: string
  escalations_contact_mobile: string
  location_name: string
  plus_code: string
  location_link: string
  location_type: string
  fulfilment_type: string
  gst_number: string
  tan_number: string
  pan_number: string
  cst_number: string
  vat_number: string
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  pincode: string
}

const AddWarehouseValidation = {
  warehouse_name: {
    required: { value: true, message: "Name can't be Empty" }
  },
  Warehouse_code: {
    required: { value: true, message: "Location Code  can't be Empty" }
  },

  fulfilment_type: {
    required: { value: true, message: "Type  can't be Empty" }
  },
  primary_contact_name: {
    required: { value: true, message: "Name field can't be Empty" }
  },
  primary_contact_email: {
    required: { value: true, message: "Email field can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
  primary_contact_mobile: {
    required: { value: true, message: "Mobile field can't be Empty" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" }
  },
  escalations_contact_name: {
    required: { value: true, message: "Name field can't be Empty" }
  },
  escalations_contact_email: {
    required: { value: true, message: "Email field can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
  escalations_contact_mobile: {
    required: { value: true, message: "Mobile field can't be Empty" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" }
  },
  gst_number: {
    required: { value: true, message: "GST field can't be Empty" },
    minLength: { value: 15, message: 'GST must be 15 characters long' },
    maxLength: { value: 15, message: 'GST must be 15 characters long' }
  },

  pincode: {
    required: { value: true, message: "Pincode can't be Empty" },
    minLength: { value: 6, message: 'pincode must be 6 characters long' },
    maxLength: { value: 6, message: 'pincode must be 6 characters long' }
  },
  address1: {
    required: { value: true, message: "Address1 can't be Empty" }
},
address2: {
    required: { value: true, message: "Address2  can't be Empty" }
},
address3: {
    required: { value: true, message: "Address3  can't be Empty" }
},
city: {
    required: { value: true, message: "City field can't be Empty" },

},
state: {
    required: { value: true, message: "State field can't be Empty" },

}
}

const AddLocations: React.FC = () => {
  const { handleSubmit, register, errors, setValue, setError, reset } = useForm<ILocationForm>()
  const location = useLocation()
  const history = useHistory<History>()
  const onBoardingService = new OnboardingService();
  const onboardingService = new OnboardingService()
  const isEdit = useSelector((state:RootStore)=>state.inventory.editLocation)

  /* State variables */
  const [lat, setLat] = useState<number>()
  const [lng, setLng] = useState<number>()
  const [plusCode, setPlusCode] = useState<string>('')
  const [latlong, setlatlong] = useState<string>('')
  const [warehouseCode] = React.useState<string>('')
  const [workingdaysDialog, setWorkingdaysDialog] = React.useState(false)
  const [registerdAddress, setRegesiteredAddress] = React.useState<any>()
  const [workingdays, setWorkingdays] = React.useState<any>('')
  const [editWarehouseData, seteditWarehouseData] = React.useState<any>()
  const locationLink = 'https://www.google.com/maps/search/?api=1&query='
  const [locality, setLocality] = useState(null)
  const [datacheck,setDatacheck] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);

  /* Services */
  const inventoryService: InventoryService = new InventoryService()
  const onSubmit = async (data) => {
    
    /* location link availability check */
    if (!latlong) {
      showNotification(STATUS.FAILURE, 'Please Mark A Location on Map');
      return
    }  
    /*Update Wareohouse API */
    if (editWarehouseData) {
      console.log('editWarehouseData', data.gst_number)
      gstInVerify(editWarehouseData.gst_number)
      data.Warehouse_code = editWarehouseData.warehouse_code
      // data.Ext_warehouse_code = editWarehouseData.ext_warehouse_code
      data.address1 = registerdAddress ? registerdAddress.address1 : editWarehouseData.address1
      data.address2 = registerdAddress ? registerdAddress.address2 : editWarehouseData.address2
      data.city = registerdAddress ? registerdAddress.city : editWarehouseData.city
      data.state = registerdAddress ? registerdAddress.state : editWarehouseData.state
      data.pincode = registerdAddress ? registerdAddress.pincode : editWarehouseData.pincode
      data.Seller_code = localStorage.getItem('seller_code')
      data.working_days = workingdays ? workingdays.selectedDays : editWarehouseData.working_days
      data.working_hours_and_days = workingdays.working_hours
        ? JSON.stringify(workingdays.working_hours)
        : editWarehouseData.working_hours_and_days
      data.location_code = latlong ? latlong : editWarehouseData.latlng
      data.plus_code = plusCode
      data.location_link = latlong ? locationLink + latlong : locationLink + editWarehouseData.latlng
      data.latlng = latlong ? latlong : editWarehouseData.latlng
      data.is_legal_address= datacheck ? "true" : "false";
      data.is_business_address=  "false";

      try {
        setLoading(true)
        const updateWarehouse: ISuccessResponse | IErrorResponse = await inventoryService.updatewarehouse(data)
        setLoading(false)

        if (updateWarehouse.status === 'failure') {
          showNotification(STATUS.FAILURE, updateWarehouse.data)
        } else {
          showNotification(STATUS.SUCCESS, updateWarehouse.status)
         
          history.push('/sellerLayout/inventory/ActiveLocations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, (error as Error)?.message)
      }
    } else {
      /*Add Wareohouse API */
      if (registerdAddress) {
        console.log('registerdAddress', data.gst_number)
        data.Warehouse_code = warehouseCode
        data.Seller_code = localStorage.getItem('seller_code')
        data.address1 = registerdAddress.address1
        data.address2 = registerdAddress.address2
        data.city = registerdAddress.city
        data.state = registerdAddress.state
        data.pincode = parseInt(registerdAddress.pincode)
        data.working_hours_and_days = workingdays.working_hours ? JSON.stringify(workingdays.working_hours) : ''
        data.working_days = workingdays.selectedDays ? workingdays.selectedDays : ''
        data.plus_code = plusCode
        data.latlng = latlong
        data.location_name = locationLink + latlong
        data.is_legal_address= datacheck ? "true" : "false";
        data.is_business_address=  "false";
        try {
          setLoading(true)
          const addWarehouse: ISuccessResponse | IErrorResponse = await inventoryService.addwarehouse(data)
          setLoading(false)
          
          if (addWarehouse.status === 'failure') {
            showNotification(STATUS.FAILURE, addWarehouse.data)
          } else {
            showNotification(STATUS.SUCCESS, addWarehouse.data)
            history.push('/sellerLayout/inventory/ActiveLocations')
          }
        } catch (error) {
          showNotification(STATUS.FAILURE, (error as Error)?.message)
        }
      } else {
        showNotification(STATUS.FAILURE, 'Please Enter the Registered Address')
      }
    }
  }

  const handleReset = (value: string) => {
    if (value === 'No') {
      setDatacheck(false)
      reset()
    } else if (value === 'Yes') {
      setDatacheck(true)
      getTaxationInfo()
    }
  }


  const gstInVerify = async (gstNumber: string) => {
    const filteredGSTNumber = gstNumber?.replace(/[^a-z0-9]/gi, '').substring(0, 15)

    if (gstNumber.length === 15) {
      const regexPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/

      if (!regexPattern.test(filteredGSTNumber)) {
        setError('gst_number', {
          type: 'manual',
          message: 'GST Number format is invalid'
        })

        showNotification('failure', 'GST Number Format is invalid')

        return
      }

      try {
        setLoading(true)
        const { data }: ISuccessResponse | IErrorResponse = await onBoardingService.getGstIndetails(gstNumber);
        setLoading(false)

        if (!data.error) {
          const accountInformations = data.data;
          const addressData = accountInformations.pradr.addr;
          // setValue("primary_contact_name", accountInformations.lgnm);
          setValue("address1", addressData.bno + ", " + addressData.flno);
          setValue("address2", addressData.st);
          setValue("city", addressData.dst);
          setValue("state", addressData.stcd);
          setValue("pincode", addressData.pncd);   

          const registered_Address_data={
            address1:addressData.bno + ", " + addressData.flno,
            address2:addressData.st,
            city:addressData.dst,
            state:addressData.stcd,
            pincode:addressData.pncd,
          }
          setRegesiteredAddress(registered_Address_data)

          
        }
      } catch (error) {

        showNotification(STATUS.FAILURE, 'Unable to get GST Informations')
      }
    } else {
      setValue("address1", "");
      setValue("address2", "");
      setValue("pincode", "");
      setValue("city", '');
      setValue("state", '');

    }
  }

  const workingDaysHandler = async (data) => {
    setWorkingdays(data)
  }

  useEffect(()=>{

    if(isEdit) {setDatacheck(true);
      setLoading(true);
      getTaxationInfo();
      setLoading(false)}

  },[])

  useEffect(() => {
    if(datacheck) {
    setValue("gst_number", editWarehouseData?.gst_number);
    setValue("vat_number", editWarehouseData?.vat_number);
    setValue("cst_number", editWarehouseData?.cst_number);
    setValue("tan_number", editWarehouseData?.tan_number);
    setValue("fulfilment_type", editWarehouseData?.fulfilment_type);
    setValue("location_type", editWarehouseData?.location_type);
    setValue("warehouse_name", editWarehouseData?.warehouse_name);
    setValue("Ext_warehouse_code", editWarehouseData?.ext_warehouse_code);
    setValue("primary_contact_name", editWarehouseData?.primary_contact_name);
    setValue("primary_contact_email", editWarehouseData?.primary_contact_email);
    setValue("primary_contact_mobile", editWarehouseData?.primary_contact_mobile);
    setValue("escalations_contact_name", editWarehouseData?.escalations_contact_name);
    setValue("escalations_contact_email", editWarehouseData?.escalations_contact_email);
    setValue("escalations_contact_mobile", editWarehouseData?.escalations_contact_mobile);
    seteditWarehouseData(location.state)}
  }, [location, editWarehouseData,datacheck])

  useEffect(() => {
    const str: string = editWarehouseData?.latlng?.substring(1, editWarehouseData?.latlng?.length - 1)

    if (str !== undefined) {
      const lat_lng = str.split(',')
      setLat(Number(lat_lng[0]))
      setLng(Number(lat_lng[1]))
    }
  }, [editWarehouseData])

  const locationSelectionHandler = async (loactionAddress: IWarehouseDetails) => {
    if (loactionAddress.latlng !== '20.5937,78.9629') {
      const pluscode = loactionAddress.plusCode
      setPlusCode(pluscode.compound_code)
      setlatlong(loactionAddress.latlng)
    }
  }

  const setAddressLocation = (val) => {
    console.log(val)

    setLocality(val.label)
    setPlusCode(val.label)

    geocodeByAddress(val?.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const latlng = `${lat}, ${lng}`
        setlatlong(latlng)
        setLat(lat)
        setLng(lng)
      })
  }

    const getTaxationInfo = async () => {
      try {
        const email = localStorage.getItem('sellerMail') as string
        setLoading(true)
        const getTaxationResponse: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(4,email)
        setLoading(true)

        if (getTaxationResponse.status === STATUS.SUCCESS) {
          const successData = getTaxationResponse as ISuccessResponse
          setValue('gst_number', successData.data.gstin)
          gstInVerify(successData.data.gstin)
        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
      
    }}


  return (
    <div className="Addlocations-page">
      <form className="location-layout-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <Spinner loading={loading}/>
        <div className="location-core-details">
          <LayoutHeader title="Location Core Details" />

          <div className="legal-details-content">
            <div className="locationDetails">
              <p className="location-type">Is this location GST Number Same as legal GST Number ? * </p>
              <FormControl className="radio-btn-wrapper">
                <RadioGroup className="radio-group" defaultValue={isEdit ? "Yes":"No"} name="legal-gst-confirmation" onChange={(event) => handleReset(event.target.value)}>
                  <FormControlLabel value="Yes" control={<Radio/>} label="Yes" />
                  <FormControlLabel value="No" control={<Radio/>} label="No" />
                </RadioGroup>
              </FormControl>
              </div>
            <InputLabelinputWarehouse
              label="GST Number * "
              name="gst_number"
              maxLength={15}
              onChange={(event) => gstInVerify(event.target.value)}
              register={register(AddWarehouseValidation.gst_number)}
              error={errors.gst_number?.type !== undefined}
              errorText={errors.gst_number ? errors.gst_number.message : ''}
              disabled={ datacheck } 
             
            />
            <InputLabelinputWarehouse
              label="Warehouse Name *"
              name="warehouse_name"
              register={register(AddWarehouseValidation.warehouse_name)}
              error={errors.warehouse_name?.type !== undefined}
              errorText={errors.warehouse_name ? errors.warehouse_name.message : ''}
              tooltip="Warehouse_name"
            />
            <InputLabelinputWarehouse
              label="External Warehouse Code"
              name="Ext_warehouse_code"
              register={register}
            />
            <InputLabelDownWithDropDown
              text="Location Type"
              dropdown={['Warehouse', 'Store']}
              name="location_type"
              register={register}
              notrequired
            />
            <InputLabelDownWithDropDown
              text="Fulfilment Type "
              dropdown={['Fulfilled by Poorvika', 'Fulfilled by Seller']}
              name="fulfilment_type"
              register={register(AddWarehouseValidation.fulfilment_type)}
              error={errors.fulfilment_type?.type !== undefined}
              errorText={errors.fulfilment_type ? errors.fulfilment_type.message : ''}
            />
            <InputLabelinputWarehouse
              label="TAN Number"
              name="tan_number"
              register={register}
            />
            <InputLabelinputWarehouse
              label="CIN Number"
              name="cst_number"
              register={register}
            />
            <InputLabelinputWarehouse
              label="VAT Number"
              name="vat_number"
              register={register}
            />
          </div>
        </div>
        <div className="address-and-operating-hours">
          <LayoutHeader title="Address & Operating Hours" />
          <div className="address-content-container">
            {/* <div className="locationDetails">
              <p className="location-type">Is the address same as Legal Address ? * </p>
              <FormControl className="radio-btn-wrapper">
                <RadioGroup className="radio-group" defaultValue="No" name="legal-gst-confirmation">
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div> */}

            <InputLabelinputWarehouse
                    label="Address 1*"
                    name="address1"
                    maxLength={60}
                    register={register(AddWarehouseValidation.address1)}
                    error={errors.address1?.type !== undefined}
                    errorText={errors.address1 ? errors.address1.message : ''}
                    disabled={datacheck}
                />
                <InputLabelinputWarehouse
                    label="Address 2"
                    name="address2"
                    maxLength={60}
                    register={register}
                    disabled={datacheck}
                />
                <InputLabelinputWarehouse
                    label="Address 3"
                    name="address3"
                    register={register}
                    maxLength={60}
                    disabled={datacheck}
                />
                <div className="pincode-wrapper">
                    <InputLabelinputWarehouse
                        label="Pincode*"
                        name="pincode"
                        register={register(AddWarehouseValidation.pincode)}
                        error={errors.pincode?.type !== undefined}
                        errorText={errors.pincode ? errors.pincode.message : ''}
                        onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
                        maxLength={6}
                        disabled={datacheck}

                    />
                </div>
                <div className="city-state-wrapper">
                    <InputLabelinputWarehouse
                        label="City *"
                        name="city"
                        register={register(AddWarehouseValidation.city)}
                        error={errors.city?.type !== undefined}
                        errorText={errors.city ? errors.city.message : ''}
                        disabled={datacheck}
                    />
                   </div>
                   <div className="state-wrapper">
                    <InputLabelDownWithDropDown
                        text="State"
                        dropdown={Istate}
                        name="state"
                        register={register(AddWarehouseValidation.state)}
                        error={errors.state?.type !== undefined}
                        errorText={errors.state ? errors.state.message : ''}
                        disabled={datacheck}
                    /> 
                    </div>
              <div className="working-days-content">
              <p className="text-label">Working Hours & Days*</p>
              {workingdays ? (
                <div className="input-with-verified-icon">
                  {workingdays ? (
                    <div className="days-wrapper">
                      {workingdays.selectedDays.map((data: string, index) => (
                        <p>{data} {index !== workingdays.selectedDays.length - 1 ? <span>,&nbsp;</span> : <>.</>}</p>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                  <p className="text-label-content">
                    {workingdays.opentime ? workingdays.opentime + ' to ' + workingdays.closetime : ''}
                  </p>
                  <EditOutlinedIcon className="verified-icon" onClick={() => setWorkingdaysDialog(true)} />
                </div>
              ) : (
                <div className="input-with-verified-icon">
                  <p>{editWarehouseData ? editWarehouseData.working_days : ''}</p>
                  <EditOutlinedIcon className="verified-icon" onClick={() => setWorkingdaysDialog(true)} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="warehouse-contacts-wrapper">
          <div>
            <LayoutHeader title="Contacts" />
            <div className="legal-details-content">
              <p className="contacts-label">Primary Contact :</p>
              <InputLabelinputWarehouse
                label="Name *"
                name="primary_contact_name"
                register={register(AddWarehouseValidation.primary_contact_name)}
                error={errors.primary_contact_name?.type !== undefined}
                errorText={errors.primary_contact_name ? errors.primary_contact_name.message : ''}
              />
              <InputLabelinputWarehouse
                label="Email *"
                name="primary_contact_email"
                register={register(AddWarehouseValidation.primary_contact_email)}
                error={errors.primary_contact_email?.type !== undefined}
                errorText={errors.primary_contact_email ? errors.primary_contact_email.message : ''}
              />
              <InputLabelinputWarehouse
                label="Mobile * ( ex: 98******** )"
                name="primary_contact_mobile"
                maxLength={10}
                onChange={(event) =>
                  (event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))
                }
                register={register(AddWarehouseValidation.primary_contact_mobile)}
                error={errors.primary_contact_mobile?.type !== undefined}
                errorText={errors.primary_contact_mobile ? errors.primary_contact_mobile.message : ''}
              />
              <p className="contacts-label">Escalations :</p>

              <InputLabelinputWarehouse
                label="Name *"
                name="escalations_contact_name"
                register={register(AddWarehouseValidation.escalations_contact_name)}
                error={errors.escalations_contact_name?.type !== undefined}
                errorText={errors.escalations_contact_name ? errors.escalations_contact_name.message : ''}
              />
              <InputLabelinputWarehouse
                label="Email *"
                name="escalations_contact_email"
                register={register(AddWarehouseValidation.escalations_contact_email)}
                error={errors.escalations_contact_email?.type !== undefined}
                errorText={errors.escalations_contact_email ? errors.escalations_contact_email.message : ''}
              />
              <InputLabelinputWarehouse
                label="Mobile * ( ex: 98******** )"
                name="escalations_contact_mobile"
                maxLength={10}
                onChange={(event) =>
                  (event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))
                }
                register={register(AddWarehouseValidation.escalations_contact_mobile)}
                error={errors.escalations_contact_mobile?.type !== undefined}
                errorText={errors.escalations_contact_mobile ? errors.escalations_contact_mobile.message : ''}
              />
            </div>
          </div>
        </div>
        <div className="warehouse-contacts-wrapper">
          <div>
            <LayoutHeader title="Geo Location" />
            <div className="legal-details-content">
              <div style={{ paddingTop: '10px' }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyD-6hZF4mxTyZXc-AqgJzkaxyGyfwCFBM0"
                  apiOptions={{ region: 'in' }}
                  autocompletionRequest={{
                    bounds: [
                      { lat: 50, lng: 50 },
                      { lat: 100, lng: 100 }
                    ],
                    componentRestrictions: {
                      country: ['in']
                    }
                  }}
                  selectProps={{
                    placeholder: 'Search Pincode or Places',
                    locality,
                    onChange: setAddressLocation
                  }}
                />
              </div>

              <InputLabelwithContent
                label="Plus Code"
                content={editWarehouseData ? editWarehouseData?.plus_code : plusCode}
              />
              <InputLabelwithContent
                label="Location Link"
                content={
                  editWarehouseData ? locationLink + editWarehouseData?.latlng : latlong ? locationLink + latlong : ''
                }
              />
            </div>
          </div>
          <div>
            <MapsContainer
              lat={lat}
              lng={lng}
              onLocationSelected={(data: IWarehouseDetails) => locationSelectionHandler(data)}
            />
          </div>
          <div className="addwarehouse-submit-btn">
            <ActionButton label="Submit" type="submit" />
          </div>
        </div>
      </form>

      <WorkingDaysForm
        open={workingdaysDialog}
        title="Add Warehouse"
        cancelClose={() => setWorkingdaysDialog(false)}
        onDaysSelected={(e) => workingDaysHandler(e)}
        Defaultdays={workingdays}
      />
    </div>
  )
}
export default AddLocations
