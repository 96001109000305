import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IAPIResponse } from "./interfaces/common.interface";
import { IEditPayment } from "./interfaces/payment.interface";

export class PaymentsService extends BaseService {

    public async getPaymentList(seller_code?: string, status?: string, download?: number): Promise<IAPIResponse> {
        try {
            const params = { 
                seller_code, 
                payment_status: status === 'All' ? undefined : status,
                download
            };
            const { data } = await this.httpClient.get(API_ENDPOINTS.PAYMENT_LIST, {params});
        
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async editPaymentStatus(editPaymentData: IEditPayment):Promise<IAPIResponse> {
        try {
            const { data } = await this.httpClient.put(API_ENDPOINTS.PAYMENT_UPDATE, editPaymentData);
        
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

}