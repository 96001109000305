import React from 'react'

import './style.scss'

type TSellerStatus = {
  status: boolean
  
}

const SellerStatus: React.FC<TSellerStatus> = ({ status }) => {
  return (
    <div>
      {status ? (
        <p className= "seller-account-status-active">Active</p>
      ) : (
        <p className= "seller-account-status-in-active">In Active</p>
      )}
    </div>
  )
}

export default SellerStatus
